import React, { useState, useEffect, useMemo, forwardRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { useDispatch } from "react-redux";

import TableCustomHeader from "./CustomTableHeader/CustomTableHeader";

// imports are used inside proptypes, they are NOT unused
import {
  AgGridReact,
  AgGridColumnProps,
  AgGridReactProps,
} from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";

const AgGridTable = forwardRef(
  (
    /** @type AgGridTable.propTypes */
    {
      isSortingAction = true,
      dataTableRef,
      tableState,
      enableSorting,
      tableFetcherAction,
      className,
      columnDefs,
      defaultColDefProps,
      rowData,
      gridOptions,
    },
    gridRef
  ) => {
    const { t } = useTranslation();
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState("DESC");

    // Redux Hooks
    const dispatch = useDispatch();

    // Grid Hooks
    const gridStyle = useMemo(
      () => ({
        width: "100%",
        ...(rowData?.length > 20 && { height: "850px" }),
      }),
      [rowData]
    );

    // Table Sorting
    const handleHeaderClick = (selectedColumn) => {
      setSortDirection((prev) => (prev === "DESC" ? "ASC" : "DESC"));

      setSortColumn(selectedColumn);
    };

    useEffect(() => {
      if (enableSorting && sortColumn) {
        if (!isSortingAction) return tableFetcherAction();
        dispatch(
          tableFetcherAction({
            ref: dataTableRef,
            [dataTableRef]: {
              ...tableState,
              pagination: {
                ...tableState?.pagination,
                currentPage: 1,
              },
              orderBy: {
                column: sortColumn,
                order: sortDirection,
              },
            },
          })
        );
      }
    }, [sortColumn, sortDirection]);

    const components = useMemo(() => {
      return {
        agColumnHeader: TableCustomHeader,
      };
    }, []);

    // Column Default Settings
    /**
     * @type {AgGridColumnProps}
     */
    const defaultColDef = useMemo(() => {
      return {
        resizable: false,
        editable: false,
        suppressSizeToFit: false,
        suppressCellFlash: true,
        enableCellChangeFlash: false,
        lockPosition: true,
        sortable: false,
        wrapHeaderText: true,
        minWidth: 100,
        width: 100,
        cellStyle: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          color: "#666",
          borderRight: "1px solid #e5e7eb",
        },
        comparator: () => false,
        headerComponentParams: {
          sortColumn,
          sortDirection,
          enableSorting,
          handleHeaderClick: enableSorting ? handleHeaderClick : () => false,
        },

        ...defaultColDefProps,
      };
    }, [sortDirection, sortColumn, defaultColDefProps, enableSorting]);

    return (
      <div className="ag-theme-alpine" style={gridStyle}>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          suppressColumnMoveAnimation={true}
          suppressColumnVirtualisation={true}
          suppressMovableColumns={true}
          debounceVerticalScrollbar={true}
          columnHoverHighlight={false}
          maintainColumnOrder={true}
          rowClass={`${className}_row`}
          suppressRowHoverHighlight={false}
          suppressCellFocus={true}
          components={components}
          animateRows={false}
          className={className}
          headerHeight={45}
          rowHeight={50}
          rowBuffer={20}
          overlayNoRowsTemplate={t("no data available")}
          {...(rowData?.length > 20
            ? { domLayout: "normal" }
            : { domLayout: "autoHeight" })}
          {...gridOptions}
        />
      </div>
    );
  }
);

AgGridTable.displayName = "AgGridTable";

AgGridTable.propTypes = {
  /** @type {string} */
  className: PropTypes.string,

  /**
   * Columns to be displayed in the table
   * @defaultvalue [ ]
   * @type {Array<AgGridColumnProps>}
   * @Reference https://www.ag-grid.com/react-data-grid/column-definitions/
   */
  columnDefs: PropTypes.arrayOf(PropTypes.object).isRequired,

  /**
   * Row data to be inserted in the table
   * @reference https://www.ag-grid.com/react-data-grid/getting-started/
   */
  rowData: PropTypes.arrayOf(PropTypes.object).isRequired,

  /**
   * Default values for all columns
   * @type AgGridColumnProps
   * @Reference https://www.ag-grid.com/react-data-grid/column-properties/
   */
  defaultColDefProps: PropTypes.object,

  /**
   * Grid options
   * @type AgGridReactProps
   * @Reference https://www.ag-grid.com/react-data-grid/grid-properties/
   * @defaultvalue {}
   */
  gridOptions: PropTypes.object,

  /**
   * Should server side sorting be enabled. If enabled, you should provide the following props:
   ** tableFetcherAction
   ** dataTableRef
   ** tableState
   * @default false
   */
  enableSorting: PropTypes.bool,

  /**
   * Action to be dispatched to fetch table data from the server. Used when enableSorting is true
   * @default- void
   */
  tableFetcherAction: PropTypes.func,

  /**
   * Table name in the reduceer
   * @example "MonthlyReport"
   */
  dataTableRef: PropTypes.string,

  /**
   * The selector value to get the table data from the reducer
   * @example const tableState = useSelector((state) => state.super.monthlyReport);
   */
  tableState: PropTypes.object,
};

export default AgGridTable;

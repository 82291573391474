import React from "react";

import Chip from "@mui/material/Chip";

const statusColors = {
  ended: "#dc3545",
  ongoing: "#4CAF50",
  expired: "#dc3545",
  "expiring soon": "#fd7e14",
};

const SubscriptionStatusChip = ({ status = "ended" }) => {
  return (
    <Chip
      size="small"
      label={[status]}
      sx={{
        color: "white",
        bgcolor: statusColors?.[status?.toLowerCase()],
      }}
    />
  );
};

export default SubscriptionStatusChip;

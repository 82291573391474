import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";

import clsx from "clsx";
import HelperFns from "../../Helpers/HelperFns";
import { SidebarPrivilages } from "../../Constants/Nav";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";

import ModalContainer from "./ModalContainer";
import QuickActionsFB from "./QuickActionsFB";
import AttendanceNavbar from "./AttendanceNavbar";
import AttendanceSidebar from "./AttendanceSidebar";
import IconButtonWithTooltip from "../IconButtonWithTooltip";
import { faAlignLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Layout = ({ children, ...props }) => {
  // Sidebar toggler function starts here
  const [route, setRoute] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const isMatchMQuery = useMediaQuery("(max-width: 767px)");

  useEffect(() => {
    setIsSidebarOpen(!isMatchMQuery);
  }, [isMatchMQuery]);

  const handleMobileToggleSideBar = (e) => {
    if (isMatchMQuery) {
      setRoute(null);
      setIsSidebarOpen(!isSidebarOpen);
    }
  };
  // Sidebar toggler function ends here

  const SidebarToggler = () => (
    <HasPrivileges reqireMain={SidebarPrivilages} allowBP>
      <IconButtonWithTooltip
        onClick={handleMobileToggleSideBar}
        icon={<FontAwesomeIcon icon={faAlignLeft} />}
        label={`${isSidebarOpen ? "hide" : "show"} sidebar`}
        className={`d-md-none ${isSidebarOpen ? "text-white mb-4" : "px-3"}`}
      />
    </HasPrivileges>
  );

  return (
    <div
      style={{ paddingBottom: 30 }}
      className={clsx({
        "main-wrapper": HelperFns.checkPrivileges({
          privileges: SidebarPrivilages,
          allowBP: true,
        }),
      })}
    >
      <AttendanceNavbar Toggler={SidebarToggler} />

      <HasPrivileges reqireMain={SidebarPrivilages} allowBP>
        <AttendanceSidebar
          {...props}
          route={route}
          setRoute={setRoute}
          isMobile={isMatchMQuery}
          Toggler={SidebarToggler}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
          handleToggleSideBar={handleMobileToggleSideBar}
        />
      </HasPrivileges>

      <main
        className={clsx("page-wrapper", {
          "ml-0": !HelperFns.checkPrivileges({
            privileges: SidebarPrivilages,
            allowBP: true,
          }),
        })}
      >
        {children}
      </main>

      <QuickActionsFB />
      <ModalContainer />
    </div>
  );
};

export default Layout;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { gql, useLazyQuery, useMutation } from "@apollo/client";

import clsx from "clsx";
import moment from "moment";
import swal from "sweetalert";
import Tooltip from "@mui/material/Tooltip";
import Constants from "../../Constants";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import {
  fetchEmployeeWorkSchedulesQuery,
  fetchEmployeeLogsQuery,
} from "../../Graphql/query";
import { editEmployeeWorkScheduleAction } from "../../Store/Actions/shiftsActions/actions";
import WorkTeamDetails from "./WorkTeamDetails";
import { AccessTime } from "@mui/icons-material";
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
} from "@mui/material";
import {
  EditIconButton,
  RemoveIconButton,
} from "../../Components/IconButtonWithTooltip";
import Loader from "../../Components/Loader";
import { AddButton } from "../../Components/Buttons";
import { AttendanceTypeModal } from "../../Components/AttendanceTypeModal";
import {
  showErrorToast,
  showSuccessToast,
  toggleLogsModal,
} from "../../Store/Actions";
import { Spinner } from "reactstrap";
import { useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { v4 as uuid, validate as uuidValidate } from "uuid";

const DELETE_ATT_TYPE_CONFIG = gql`
  mutation deleteAttendanceTypeConfigurationMutation($id: ID!) {
    deleteAttendanceTypeConfiguration(id: $id) {
      ... on AttendanceTypeConfiguration {
        id
      }
      ... on GeneralException {
        __typename
        message
      }
    }
  }
`;

const workTeamInitState = {
  workTeam: null,
  start: null,
  end: null,
  id: uuid(),
};

const AttendanceTypeTab = () => {
  const { t } = useTranslation();
  const { userId } = useParams();
  const dispatch = useDispatch();

  // Local State
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Server State
  const { loading, data, refetch, error, networkStatus } = useQuery(
    fetchEmployeeWorkSchedulesQuery,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        id: userId,
      },
    }
  );

  /* ↓ State Effects ↓ */

  useEffect(() => {
    if (error) {
      dispatch(showErrorToast(error?.message));
    }
  }, [error]);

  /* ↓ Helpers ↓ */

  const handleEditEmployeeSchedule = (config, event) => {
    event.stopPropagation();
    dispatch(editEmployeeWorkScheduleAction(config));
    setIsModalOpen(true);
  };

  return (
    <>
      <div className="work_schedule_tab">
        {data?.employee?.user?.active ? (
          <HasPrivileges
            reqireMain={[Privilages.ADD_EDIT_ATTENDANCE_TYPE_CONFIGURATION]}
            allowBP
          >
            <div className="tab_header mb-4">
              <AddButton onClick={() => setIsModalOpen(true)}>
                {t("Add New Configuration")}
              </AddButton>
            </div>
          </HasPrivileges>
        ) : null}

        {loading ? ( // && networkStatus !== NetworkStatus.refetch
          <Loader />
        ) : (
          data?.employee?.attendanceTypeConfigurations?.map((config) => (
            <AttTypeConfig
              key={config?.id}
              config={config}
              refetch={refetch}
              networkStatus={networkStatus}
              onEditAttTypeConfig={handleEditEmployeeSchedule}
              loading={loading}
            />
          ))
        )}
      </div>

      {isModalOpen ? (
        <AttendanceTypeModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          fetchEmployeeWorkSchedules={refetch}
        />
      ) : null}
    </>
  );
};

const RowUnit = ({ isBool = false, label, txt = "", classes = "" }) => {
  const { t } = useTranslation();
  const isRender = isBool || (!isBool && Boolean(txt));

  return isRender ? (
    <div className={clsx("d-flex gap-10", classes)}>
      {label}
      <b
        dir="auto"
        style={{
          color: !isBool ? "#5d6979" : txt ? "green" : "red",
        }}
      >
        {!isBool ? txt : t(txt ? "Yes" : "No")}
      </b>
    </div>
  ) : null;
};

const Separator = ({ title }) => {
  const { t } = useTranslation();
  return (
    <>
      <Divider sx={{ gridColumn: "1/-1" }} />
      <strong style={{ gridColumn: "1/-1" }}>{t(title)}</strong>
    </>
  );
};

const AttTypeConfig = ({ config, refetch, loading, ...props }) => {
  const { t } = useTranslation();
  const { userId } = useParams();
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    setConfigWorkTeams(config?.workTeams);
    return () => {
      setConfigWorkTeams(config?.workTeams);
    };
  }, [expanded, config?.workTeams, props?.networkStatus, loading]);

  useEffect(() => {
    console.log("mount AttTypeConfig");

    return () => {
      console.log("unmount AttTypeConfig");
    };
  }, []);

  const [configWorkTeams, setConfigWorkTeams] = useState(
    config?.workTeams || []
  );
  const [
    deleteAttTypeConfig,
    { loading: deleteLoading, data: deleteData, error: deleteError },
  ] = useMutation(DELETE_ATT_TYPE_CONFIG, {
    onCompleted(res) {
      if (res?.deleteAttendanceTypeConfiguration?.id) {
        refetch();
        dispatch(showSuccessToast());
        return;
      }
      if (
        res?.deleteAttendanceTypeConfiguration?.__typename ===
        "GeneralException"
      ) {
        dispatch(
          showErrorToast(res?.deleteAttendanceTypeConfiguration?.message)
        );
        return;
      }
    },
    onError(error) {
      dispatch(
        showErrorToast(
          error?.graphQLErrors[0]?.extensions?.reason ||
            error?.graphQLErrors[0]?.message ||
            error?.message
        )
      );
    },
  });

  // Local State

  /* ↓ Helpers ↓ */

  const handleEditEmployeeSchedule = (config, event) => {
    props.onEditAttTypeConfig(config, event);
  };

  const handleDeleteEmployeeSchedule = (config, event) => {
    event.stopPropagation();
    if (deleteLoading) {
      return;
    }
    deleteAttTypeConfig({
      variables: {
        id: config?.id,
      },
    });
  };

  const handleAddWorkTeam = () => {
    setConfigWorkTeams((prevState) => [
      { ...workTeamInitState, id: uuid() },
      ...prevState,
    ]);
  };

  const handleDeleteNewWorkTeam = (id) => {
    setConfigWorkTeams((prevState) =>
      prevState?.filter((workTeam) => workTeam.id !== id)
    );
  };

  // Start of fetch employee logs query function
  const handleShowLogs = (e) => {
    // handle on click accordion doesn`t open when btn is clicked
    e.stopPropagation();

    // handle fetch employee logs
    fetchEmployeeLogs({
      variables: {
        id: userId,
        employeeAttTypeId: +config?.id,
      },
    });
  };

  const [fetchEmployeeLogs, { loading: employeeLogsLoading }] = useLazyQuery(
    fetchEmployeeLogsQuery,
    {
      onError(err) {
        // error toaster
        dispatch(
          showErrorToast(
            err?.graphQLErrors[0]?.extensions?.reason ||
              err?.graphQLErrors[0]?.message ||
              err?.message
          )
        );
      },
      onCompleted: (data) => {
        // handle show logs modal
        dispatch(
          toggleLogsModal({
            isOpen: true,
            data: data?.employee?.relevantAttendanceTypeConfigurationById?.logs,
            name: data?.employee?.user?.name,
            date: config?.start,
            fromWhere: "employeeAttTypeConfigTab",
          })
        );
      },
    }
  );
  // End of fetch employee logs query function

  return (
    <Accordion
      key={config?.id}
      TransitionProps={{ unmountOnExit: true }}
      onChange={(e, expanded) => setExpanded(expanded)}
      sx={{
        mb: "16px !important",
        "&::before": { display: "none" },
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.1)",
        "&.Mui-expanded": {
          px: "16px !important",
          boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2) !important",
        },
      }}
    >
      {/* Accordion Summary */}
      <AccordionSummary
        disabled={config?.is_suspension}
        id={`panel-content-${config?.id}`}
        aria-controls={`panel-header-${config?.id}`}
      >
        <RowUnit
          txt
          classes="flex-1"
          label={`${
            config?.start
              ? moment(config?.start).format("Do MMM YYYY")
              : t("now")
          } - ${
            config?.end ? moment(config?.end).format("Do MMM YYYY") : "-----"
          }`}
        />
        {config?.is_suspension ? (
          <RowUnit classes="flex-1" txt={t("Suspended")} />
        ) : (
          <>
            <RowUnit
              classes="flex-1"
              txt={t(
                config?.type?.toLowerCase() ===
                  Constants.attendanceTypes.SHIFT_BASED
                  ? "shift based"
                  : config?.type
              )}
            />
            {config?.type.toLowerCase() ===
            Constants.attendanceTypes.SHIFT_BASED ? (
              <RowUnit
                classes="flex-1"
                label={t("work group")}
                txt={config?.workGroup?.name ?? "--- ----- --"}
              />
            ) : (
              <RowUnit
                classes="flex-1"
                label={t("attendance profile")}
                txt={config?.attendanceProfile?.name ?? "--- ----- --"}
              />
            )}

            <HasPrivileges
              reqireMain={[Privilages.ADD_EDIT_ATTENDANCE_TYPE_CONFIGURATION]}
              allowBP
            >
              <div
                style={{ pointerEvents: "all" }}
                className="align-items-center d-flex gap-10 user-select-all"
              >
                {/* employee attendance config logs icon */}
                {config?.logs && config?.logs?.length ? (
                  <div className="sign-in-logs-button-container">
                    {employeeLogsLoading ? (
                      <Spinner
                        style={{
                          width: "1rem",
                          height: "1rem",
                          color: "#333",
                        }}
                      />
                    ) : (
                      <Tooltip title={t("view logs")}>
                        <button
                          className="show-sign-in-logs-button-style"
                          onClick={(e) => handleShowLogs(e)}
                          style={{
                            color: "red",
                          }}
                        >
                          <AccessTime />
                        </button>
                      </Tooltip>
                    )}
                  </div>
                ) : null}

                {/* {config?.canEdit ? ( */}
                <EditIconButton
                  onClick={(e) => handleEditEmployeeSchedule(config, e)}
                  disabled={loading}
                />
                {/* ) : null} */}

                {config?.canDelete ? (
                  deleteLoading ? (
                    <Spinner
                      style={{
                        width: "1rem",
                        height: "1rem",
                        color: "#ff6a6a",
                      }}
                    />
                  ) : (
                    <RemoveIconButton
                      onClick={(e) => handleDeleteEmployeeSchedule(config, e)}
                    />
                  )
                ) : null}
              </div>
            </HasPrivileges>
          </>
        )}
      </AccordionSummary>

      {/* Accordion Details */}
      <AccordionDetails className="p-0 pb-2">
        <Box
          sx={{
            gap: 1.25,
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
          }}
        >
          {/* Attendance Settings */}
          {config?.type?.toLowerCase() ===
          Constants.attendanceTypes.SHIFT_BASED ? (
            <>
              <Separator title="Attendance Settings" />
              <RowUnit label={t("current team")} txt={config?.workTeam?.name} />
              <RowUnit
                isBool
                label={t("allow multiple check-ins during shifts")}
                txt={config?.attendanceProfile?.allow_multiple_check_ins}
              />
              <RowUnit
                isBool
                label={t("allow employee to sign-in on their days off")}
                txt={config?.attendanceProfile?.allow_work_on_day_off}
              />
              {/* <RowUnit
                        label={t("default normal work timing")}
                        txt={
                          config?.attendanceProfile?.activeConfiguration
                            ?.first_half_work_timing?.name
                        }
                      />
                      <RowUnit label={t("work timing weight")} txt />
                      <RowUnit
                        isBool
                        label={t(
                          "allow employee to request half days on their days day off"
                        )}
                        txt={
                          config?.attendanceProfile?.activeConfiguration
                            ?.allow_half_day
                        }
                      />
                      <RowUnit
                        label={t("default day office/work place")}
                        txt={
                          config?.attendanceProfile
                            ?.default_office_holiday_profile
                        }
                      />
                      <RowUnit
                        label={t("default 1st half work timing")}
                        txt={
                          config?.attendanceProfile?.activeConfiguration
                            ?.first_half_work_timing?.name
                        }
                      />
                      <RowUnit
                        label={t("default 2st half work timing")}
                        txt={
                          config?.attendanceProfile?.activeConfiguration
                            ?.second_half_work_timing?.name
                        }
                      /> */}
            </>
          ) : null}

          {/* Work Remotely Settings */}

          <Separator title="Remote work settings" />
          <RowUnit
            isBool
            label={t("allow employee to work remotely")}
            txt={config?.workRemotelySettings?.can_work_home}
          />
          <RowUnit
            isBool
            label={t("allow employee to request work remotely")}
            txt={config?.workRemotelySettings?.employee_can_request}
          />
          <RowUnit
            label={t("max. days per week")}
            txt={config?.workRemotelySettings?.max_homeDays_per_week}
          />
          {config?.type?.toLowerCase() !==
          Constants.attendanceTypes.SHIFT_BASED ? (
            <>
              <RowUnit
                isBool
                label={t(
                  "set default days where the employee can work remotely"
                )}
                txt={config?.workRemotelySettings?.flexible_home}
              />
              <RowUnit
                label={t("default days")}
                txt={config?.workRemotelySettings?.home_days}
              />
              <RowUnit
                isBool
                label={t("employee can request exchanging days")}
                txt={config?.workRemotelySettings?.can_ex_days}
              />
            </>
          ) : null}
        </Box>

        {moment(config?.start).isBefore() &&
        config?.type?.toLowerCase() ===
          Constants.attendanceTypes.SHIFT_BASED ? (
          <>
            <div className="align-items-baseline gap-20 mx-0 mt-2 mb-1 row">
              <h5>{t("work teams")}</h5>
              <HasPrivileges
                reqireMain={[Privilages.ADD_REMOVE_EMPLOYEES_FROM_TEAMS]}
                allowBP
              >
                <button
                  className="border-info btn-add btn-info rounded"
                  onClick={handleAddWorkTeam}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </HasPrivileges>
            </div>

            {configWorkTeams?.map((workTeam) => (
              <>
                <WorkTeamDetails
                  key={workTeam?.id}
                  newWorkTeam={uuidValidate(workTeam?.id)}
                  workTeam={workTeam}
                  options={config?.workGroup?.WorkTeams}
                  employeeId={userId}
                  attendanceTypeConfigurationId={config?.id}
                  refetch={refetch}
                  handleDeleteNewWorkTeam={handleDeleteNewWorkTeam}
                />
              </>
            ))}
          </>
        ) : null}
      </AccordionDetails>
    </Accordion>
  );
};

export default AttendanceTypeTab;

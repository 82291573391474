import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLazyQuery, useMutation } from "@apollo/client";

import { showToast } from "../../Helpers/HelperFns";
import { onFormResetAction, updateValueAction } from "../../Store/Actions";
import { endSubscriptionQuery } from "../../Graphql/query/PaymentTracking";
import { endSubscriptionMutation } from "../../Graphql/mutation/PaymentTracking";
import { serializeUpsertSubscription } from "../../Helpers/HelperFns/PaymentTracking";

import {
  InputForm,
  DateTimePickerForm,
  CheckboxBooleanForm,
} from "form-builder";
import Loader from "../Loader";
import Box from "@mui/material/Box";
import MainModal from "../MainModal";
import DataTable from "react-data-table-component";

const reducer = "paymentTracking";
const formName = "endSubscriptionModal";
const formNameValidation = "paymentTrackingClientValidation";
const formServerValidation = "paymentTrackingServerValidation";

const modalInitState = { isOpen: false, data: null };
const formatEndData = (data, subData) => {
  const json = JSON.stringify({
    to: data?.endDate,
    ...((data?.addToCredit || data?.invoice) && {
      amount: data?.amount,
      type: data?.invoice ? "INVOICE" : "ADD",
    }),

    ...(subData && {
      args: serializeUpsertSubscription({
        ...subData,
      }),
    }),
  });

  return {
    json,
    from: data?.from,
    company_id: +data?.company_id,
  };
};

const EndSubscriptionModal = ({
  onClose,
  refetchList,
  openAddSubscriptionModal,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [errMsg, setErrMsg] = React.useState("");
  const [data, setData] = React.useState(props?.data);
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [modalState, setModalState] = React.useState(modalInitState);

  // Reducer State
  const formData = useSelector((state) => state?.[reducer]?.[formName]);
  const formClientValidation = useSelector(
    (state) => state?.[reducer]?.[formNameValidation]
  );

  // Server State
  const [getData, { loading }] = useLazyQuery(endSubscriptionQuery);
  const [endSubscription, { loading: endLoading }] = useMutation(
    endSubscriptionMutation
  );

  // Constants
  const FormProps = {
    reducer,
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting,
  };

  /* ↓ Helpers ↓ */

  const handleSetDefaultAmount = () => {
    dispatch(updateValueAction(formName, "amount", data?.remainingAmount));
  };

  const handleGetData = (value) => {
    getData({
      variables: {
        from: data?.from,
        companyId: +data?.company_id,
        to: value.format("YYYY-MM-DD"),
      },
      onCompleted: ({ data = {} }) => {
        setData((prev) => ({
          ...prev,
          dueItems: data?.dueItems,
          paidItems: data?.paidItems,
          dueAmount: data?.dueAmount,
          paidAmount: data?.paidAmount,
          remainingAmount: data?.remainingAmount,
        }));
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message
        );
      },
    });
  };

  const handleCloseModal = () => {
    onClose();
    dispatch(onFormResetAction(formName));
    dispatch(onFormResetAction(formNameValidation));
    dispatch(onFormResetAction(formServerValidation));
  };

  const handleSubmit = () => {
    setFormSubmitting(true);
    if (formClientValidation?.length) return;

    const endData = {
      ...formData,
      from: data?.from,
      company_id: data?.company_id,
    };

    if (data?.isCreateNew) {
      handleCloseModal();
      openAddSubscriptionModal(null, {
        formatEndCreateData: (subData) => formatEndData(endData, subData),
      });
    } else {
      endSubscription({
        variables: formatEndData(endData),
        onCompleted: () => {
          refetchList();
          handleCloseModal();
          showToast("success");
        },
        onError: (err) => {
          const validation = err?.graphQLErrors?.[0]?.extensions?.validation;
          dispatch(onFormResetAction(formServerValidation, validation || {}));

          const msg =
            validation?.json?.[0] ||
            err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message;
          setErrMsg(msg || "");
        },
      });
    }
  };

  return (
    <MainModal
      isOpen
      modalTitle="End Current Subscription"
      toggle={handleCloseModal}
      btnOnClick={handleSubmit}
      btnSubmitLoading={endLoading}
      btnLabel="End Subscription"
    >
      {loading ? <Loader fixed /> : null}

      <DateTimePickerForm
        {...FormProps}
        name="endDate"
        label="End Date"
        requestFormat="YYYY-MM-DD"
        labelStyle="mb-2 pt-1"
        containerStyle="w-100"
        validateBy="textRequired"
        hasIcon
        validationName="to"
        onIntercept={handleGetData}
      />

      <strong className="d-block mt-3 mb-1 dark-color">
        {t("Payment Settlement")}
      </strong>
      <ul className="list-unstyled">
        <Box
          component="li"
          className="d-flex justify-content-between"
          onClick={
            +data?.dueAmount
              ? () =>
                  setModalState({
                    isOpen: true,
                    data: { arr: data?.dueItems },
                  })
              : undefined
          }
          sx={
            +data?.dueAmount
              ? {
                  "&:hover": {
                    color: "#23aaeb",
                    cursor: "pointer",
                    textDecoration: "underline",
                  },
                }
              : {}
          }
        >
          <span>{t("Amount Due")}</span>
          {data?.dueAmount || 0}
        </Box>
        <Box
          component="li"
          className="d-flex justify-content-between"
          onClick={
            +data?.paidAmount
              ? () =>
                  setModalState({
                    isOpen: true,
                    data: { arr: data?.paidItems },
                  })
              : undefined
          }
          sx={
            +data?.paidAmount
              ? {
                  "&:hover": {
                    color: "#23aaeb",
                    cursor: "pointer",
                    textDecoration: "underline",
                  },
                }
              : {}
          }
        >
          <span>{t("Amount Paid")}</span>
          {data?.paidAmount || 0}
        </Box>
        <li className="d-flex justify-content-between">
          <span>{t("Remaining")}</span>
          {data?.remainingAmount || 0}
        </li>
      </ul>

      <strong className="d-block mt-3 mb-1 dark-color">
        {t("Settlement Method")}
      </strong>

      <div className="d-flex gap-20 align-items-center">
        <CheckboxBooleanForm
          {...FormProps}
          setWithValue
          name="addToCredit"
          containerStyle="m-0 mt-2"
          options={["Add the owed amount to client credit"]}
          dependOn="invoice"
          dependancyType="equal"
          dependancyValue={[false]}
          onInterceptInputOnChange={handleSetDefaultAmount}
        />
        <InputForm
          {...FormProps}
          name="amount"
          placeholder={t("Enter Amount")}
          inputContainerStyle="w-100"
          rootStyle="flex-1"
          validateBy="textRequired"
          dependOn="addToCredit"
          dependancyType="equal"
          dependancyValue={[true]}
        />
      </div>
      <div className="d-flex gap-20 align-items-center">
        <CheckboxBooleanForm
          {...FormProps}
          setWithValue
          name="invoice"
          containerStyle="m-0 mt-2"
          options={["Invoice the remaining amount"]}
          dependOn="addToCredit"
          dependancyType="equal"
          dependancyValue={[false]}
          onInterceptInputOnChange={handleSetDefaultAmount}
        />
        <InputForm
          {...FormProps}
          name="amount"
          placeholder={t("Enter Amount")}
          inputContainerStyle="w-100"
          rootStyle="flex-1"
          validateBy="textRequired"
          dependOn="invoice"
          dependancyType="equal"
          dependancyValue={[true]}
        />
      </div>

      {errMsg ? (
        <p role="alert" className="red-color mt-2 mb-0">
          {t(errMsg)}
        </p>
      ) : null}

      {modalState.isOpen ? (
        <History
          data={modalState.data}
          onClose={() => setModalState(modalInitState)}
        />
      ) : null}
    </MainModal>
  );
};

export default EndSubscriptionModal;

const History = ({ data, onClose }) => {
  const columns = [
    { name: "name", selector: "name" },
    { name: "amount", selector: "amount" },
  ];

  return (
    <MainModal
      isOpen
      toggle={onClose}
      modalTitle="History"
      hasModalFooter={false}
    >
      <DataTable noHeader columns={columns} data={data?.arr} />
    </MainModal>
  );
};

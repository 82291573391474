import React, { useEffect } from "react";
import MainModal from "../../Components/MainModal";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { showToast } from "../../Helpers/HelperFns";
import { Alert, AlertTitle } from '@mui/material';
import "./employeesActionsStyles.scss";
import swal from "sweetalert";
import { verifyMultipleEmployeesMutation } from "../../Graphql/mutation";

const VerifyActivate = (props) => {
    const { t } = useTranslation();
   

  const [verifyMultipleEmployees, { loading: verifyMultipleEmployeesLoading, error:verifyMultipleEmployeesError }] =
  useMutation(verifyMultipleEmployeesMutation, {
    onCompleted: (res) => {
        if(res?.verifyMultipleEmployees?.status==="success"){
          props.resetSelection()
          props.refetch()
          props.close()
        }
      showToast(res?.verifyMultipleEmployees?.status, res?.verifyMultipleEmployees?.message)
     },
    onError: (error) => {
      console.log(error.graphQLErrors[0].extensions?.validation["input.employees_ids"][0]);
      if(error.graphQLErrors[0].extensions?.validation["input.employees_ids"][0]){
        let array = error.graphQLErrors[0].extensions?.validation["input.employees_ids"][0].split(" ");

        let substring = array.slice(0, 8).join(" ");
        let rest = array.slice(8).join(" ");
        console.log(substring,"__", rest);
        swal({
          title: t("are you sure"),
          text: t(substring, { name: rest }), // t("employees selected", { count: selectedEmployees?.length })
          icon: "warning",
          className: "swal-warning-style",
          dangerMode: true,
          buttons: [t("Cancel"), t("confirm")],
        }).then((confirm) => {
          if (confirm) {
            verifyMultipleEmployees({
              variables: {
                input: {
                  employees_ids:props.selectedEmployees,
                  force_send:true
                }
              }
            })
          }
        })
      }
      else{
        showToast("fail", error?.message)
      }
    },
  });

  const handleSave = () => { 
    verifyMultipleEmployees({
        variables: {
        input: {
            employees_ids:props.selectedEmployees,
            force_send:false
        }
        }
    })
  }

  return (
    <MainModal
      isOpen={props.isOpen}
      size="lg"
      modalTitle={t("Bulk Actions")}
      btnSubmitLoading={verifyMultipleEmployeesLoading}
      btnOnClick={handleSave}
      toggle={props.close}
      className="p-5 "
    >
      <div className="p-2">
        <Alert severity="info">
                <AlertTitle>
                    {t("Verify / Activate")}
                </AlertTitle>
                <strong>{t("Employees will be effected", { count: props.selectedEmployees.length })}</strong>
          </Alert>
      </div>
    </MainModal>
  );
};

export default VerifyActivate;
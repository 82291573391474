import Types from "../Actions/types";

export const initState = {
  dashboardChartsColumns: {
    view_employee_daily_statuses: 1,
    view_employee_attendance_history_chart: 1,
    view_employee_overtime_chart: 1,
    view_employees_per_department_chart: 1,
    view_the_expenses_chart_of_my_office: 1,
    view_turnover_chart: 1,
    view_open_payroll_months: 1,
    VIEW_MISSING_EXPIRED_DOCUMENT: 1,
    // VIEW_EMPLOYEE_REQUESTS: 1,
  },

  crmColumnsVisibility: {
    name: 1,
    contacts: 1,
    "added on": 1,
    country: 1,
    comments: 1,
    employees: 1,
    industry: 1,
    source: 1,
    "assigned to": 1,
    tags: 1,
    notes: 1,
    "last activity date": 1,
    "view related activities": 1,
    "next reminder": 1,
    status: 1,
  },
};

export const dashboardUiReducer = (state = initState, action) => {
  switch (action.type) {
    case Types.HANDLE_DASHBOARD_UI_CHECKBOX_TOGGLE_ACTION:
      if (state[action.payload.formName]) {
        return {
          ...state,
          [action.payload.formName]: {
            ...state[action.payload.formName],
            [action.payload.event.target.name]:
              +action.payload.event?.target?.checked,
          },
        };
      }
      return state;

    case Types.LOG_OUT:
    case "SWITCH_ACCOUNT":
      return { ...initState };

    default:
      return state;
  }
};

import React, { useEffect, useState } from "react";
import MainModal from "../../../Components/MainModal";
import { useDispatch, useSelector } from "react-redux";
import {
  onFormResetAction,
  toggleEmployeeAssetRequestModal,
} from "../../../Store/Actions";
import { useTranslation } from "react-i18next";
import { InputForm, BSelect } from "form-builder";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ASSET_TYPES_MENU } from "../../../Graphql/query";
import { REQUEST_ASSETS } from "../../../Graphql/mutation";
import { showToast } from "../../../Helpers/HelperFns";

const formName = "employeeAssetsRequestForm";
const formNameValidation = "employeeAssetRequestClientValidations";

const EmployeeAssetRequestModal = ({ handleRefetch = () => undefined }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { data, loading } = useQuery(GET_ASSET_TYPES_MENU);

  const assetTypeOptions = data?.assetTypes?.data ?? [];

  const [formSubmitting, setFormSubmitting] = useState(false);

  const isOpen = useSelector(
    (state) => state?.assets?.employeeAssetsRequestModal?.isOpen
  );

  const formData = useSelector((state) => state?.assets?.[formName]);

  const clientValidations = useSelector(
    (state) => state?.assets?.[formNameValidation]
  );

  const [attemptRequestAssets, { loading: requestAssetsLoading }] = useMutation(
    REQUEST_ASSETS,
    {
      onCompleted: (data) => {
        if (data?.storeAssetRequest?.__typename == "GeneralException") {
          showToast("error", data?.storeAssetRequest?.message);

          return;
        }
        dispatch(toggleEmployeeAssetRequestModal({ isOpen: false }));
        handleRefetch();
      },
      onError(err) {
        showToast(
          "error",
          err?.graphQLErrors?.[0].extensions?.reason ?? err?.message
        );
      },
    }
  );

  useEffect(() => {
    return () => {
      dispatch(onFormResetAction(formName));
      dispatch(onFormResetAction(formNameValidation));
    };
  }, []);

  const dismiss = () => {
    dispatch(toggleEmployeeAssetRequestModal({ isOpen: false }));
  };

  const handleSubmit = () => {
    setFormSubmitting(true);

    if (!clientValidations?.length) {
      attemptRequestAssets({
        variables: {
          input: {
            asset_type_id: formData?.assetType,
            description: formData?.description,
          },
        },
      });
    }
  };

  return (
    <MainModal
      isOpen={isOpen}
      toggle={dismiss}
      modalTitle={t("New asset request")}
      btnLabel={t("save")}
      btnOnClick={handleSubmit}
      btnSubmitLoading={requestAssetsLoading}
    >
      <BSelect
        formName={formName}
        formNameValidation={formNameValidation}
        formSubmitting={formSubmitting}
        reducer="assets"
        label="asset type"
        name="assetType"
        labelStyle="mb-2"
        keepDefaultStyle
        isLoading={loading}
        options={assetTypeOptions}
        inputContainerStyle=""
        placeholder={t("select asset type")}
        icon="faWarehouse"
      />

      <InputForm
        formName={formName}
        formNameValidation={formNameValidation}
        formSubmitting={formSubmitting}
        reducer="assets"
        validateBy="textRequired"
        name="description"
        placeholder={t("description")}
        label="description"
        labelStyle="mb-2"
        containerStyle="mt-0 mb-3"
      />
    </MainModal>
  );
};

export default EmployeeAssetRequestModal;

import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Apollo
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  fetchWorkTeamQuery,
  fetchWorkTeamEmployeesQuery,
} from "../../Graphql/query";

import {
  deleteWorkTeamMembersMutation,
  deleteWorkTeamMutation,
} from "../../Graphql/mutation";

// Ui
import Loader from "../../Components/Loader";
import Swal from "sweetalert2";
import Skeleton from "@mui/material/Skeleton";
import Tooltip from "@mui/material/Tooltip";

// Components
import HrEmployeeCard from "../../Components/HrEmployeeCard";
import Pagination from "../../Components/Pagination";
import {
  AddWorkTeamMembersModal,
  EditWorkTeamModal,
  MoveWorkTeamMembersModal,
} from "../../Components/WorkTeamActionModals";
import { handleActionMutation } from "../../Helpers/HelperFns";

// Icons
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import Info from "@mui/icons-material/Info";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import { DateTimePickerForm } from "form-builder";
import moment from "moment";
import { InputForm } from "form-builder";

const WorkTeamDetails = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checkedValues, setCheckedValues] = useState([]);
  const [currentAction, setCurrentAction] = useState("");
  const [search, setSearch] = useState("");
  const [date, setDate] = useState(null);

  const [pagination, setPagination] = useState({
    lastItem: 20,
    total: 20,
    perPage: 20,
    currentPage: 1,
    hasMorePages: true,
    firstItem: 1,
    lastPage: 4,
  });

  const { t } = useTranslation();
  const { workTeamId } = useParams();
  const history = useHistory();

  // apollo hooks
  const [fetchWorkTeam, { data: workTeamData, loading: fetchWorkTeamLoading }] =
    useLazyQuery(fetchWorkTeamQuery, {
      variables: {
        id: workTeamId,
      },
      onError: (error) => {
        Swal.fire({
          title: t("error"),
          text: error.message ? error.message : t("something went wrong"),
          icon: "error",
          className: "swal-error-style",
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
          showDenyButton: false,
        });
      },
    });

  const [
    fetchWokTeamEmployees,
    { data: employeesData, loading: employeeDataLoading },
  ] = useLazyQuery(fetchWorkTeamEmployeesQuery, {
    variables: {
      id: workTeamId,
      name: search,
      first: pagination.perPage,
      page: pagination?.currentPage,
    },
    onCompleted: (data) => {
      setPagination(data?.teamEmployees?.paginatorInfo);
    },
    onError: (error) => {
      Swal.fire({
        title: t("error"),
        text: error.message ? error.message : t("something went wrong"),
        icon: "error",
        className: "swal-error-style",
        timer: 2000,
        showCancelButton: false,
        showConfirmButton: false,
        showDenyButton: false,
      });
    },
  });

  const activeWorkTeamEmployees = employeesData?.teamEmployees?.data?.filter(
    (employee) => {
      if (date) {
        const employeeExistedInTeam = !!employee?.workTeamEmployee?.find(
          (workTeamEmp) =>
            moment(workTeamEmp?.start, "YYYY-MM-DD") <= moment(date) &&
            (!workTeamEmp?.end || moment(workTeamEmp?.end) >= moment(date))
        );

        return employeeExistedInTeam;
      } else {
        let endDate =
          employee?.workTeamEmployee[employee?.workTeamEmployee?.length - 1]
            ?.end;
        return (
          endDate === null ||
          (endDate !== null &&
            new Date(endDate).getTime() > new Date().getTime())
        );
      }
    }
  );

  const isAllChecked =
    activeWorkTeamEmployees?.length &&
    activeWorkTeamEmployees?.every((employee) =>
      checkedValues?.includes(employee?.user?.id)
    );

  const [removeWorkTeamMembers, { loading: removeWorkTeamMembersLoading }] =
    useMutation(deleteWorkTeamMembersMutation);

  const [deleteWorkTeam, { loading: deleteWorkTeamLoading }] = useMutation(
    deleteWorkTeamMutation
  );

  useEffect(() => {
    fetchWorkTeam();
    fetchWokTeamEmployees({ variables: { id: workTeamId } });

    return () => {
      setDate(null);
    };
  }, []);

  const handleTeamAction = (action) => {
    setCurrentAction(action);
    setIsModalOpen(true);
  };

  const handleCheckAllEmployees = (e) => {
    const isChecked = e.target.checked;

    if (!isChecked) {
      setCheckedValues([]);
      return;
    }

    // setIsAllChecked(isChecked);

    setCheckedValues(
      activeWorkTeamEmployees?.map((employee) => employee?.user?.id)
    );
  };

  const handleCheckEmployee = (id) => {
    if (checkedValues.includes(id)) {
      return setCheckedValues(checkedValues.filter((item) => item !== id));
    }

    setCheckedValues([...checkedValues, id]);
  };

  const handlePaginate = (page) => {
    fetchWokTeamEmployees({
      variables: {
        page,
      },
    });
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);

    fetchWokTeamEmployees({
      variables: {
        page: 1,
      },
    });
  };

  const handleRemoveTeamMembers = () => {
    Swal.fire({
      icon: "warning",
      showConfirmButton: true,
      confirmButtonText: t("Yes"),
      confirmButtonColor: "#7cd1f9",
      showDenyButton: true,
      denyButtonText: t("No"),
      showCancelButton: false,
      html: `
      <div class="swal2-html-container">${t(
        "you are removing team members"
      )}:</div>

 
      <div class="employees_list">
      ${checkedValues
        .map((id) => {
          const employee = activeWorkTeamEmployees?.find(
            (employee) => employee?.user?.id === id
          );

          return `<li class="employee_member">${employee?.user?.name}</li>`;
        })
        .join("")}
      </div>  
      
      `,
      customClass: {
        popup: "swal-warning-style",
        container: "work_team_details_swal",
        icon: "swal-icon",
        denyButton: "order-2",
        confirmButton: "order-3",
      },
    }).then(async (values) => {
      if (values.isConfirmed) {
        const { error, serverError } = await handleActionMutation(
          removeWorkTeamMembers,
          {
            variables: {
              input: {
                workTeamId: workTeamId,
                allEmployees: isAllChecked ? true : false,
                employeesIds: isAllChecked ? undefined : checkedValues,
              },
            },
            isReturnUnion: true,
            refetchQuery: fetchWorkTeam,
          }
        );
        if (!error || !serverError) {
          fetchWokTeamEmployees();
          setCheckedValues([]);
        }
      }
    });
  };

  const handleDeleteWorkTeam = () => {
    Swal.fire({
      icon: "warning",
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      showConfirmButton: true,
      confirmButtonText: t("Yes"),
      confirmButtonColor: "#7cd1f9",
      showDenyButton: true,
      denyButtonText: t("No"),
      showCancelButton: false,
      customClass: {
        popup: "swal-warning-style",
        container: "work_team_details_swal",
        icon: "swal-icon",
        denyButton: "order-2",
        confirmButton: "order-3",
      },
    }).then(async (values) => {
      if (values.isConfirmed) {
        const { response, error } = await handleActionMutation(deleteWorkTeam, {
          variables: {
            id: workTeamId,
          },
        });

        if (!error) {
          history.push("/employees?tab=work-teams");
        }
      }
    });
  };

  return (
    <div className="content work_team_details">
      <div className="work_team_header">
        <Link to="/employees?tab=work-teams">
          <ArrowBackIosIcon className="back_icon" />
        </Link>

        {fetchWorkTeamLoading ? (
          <Skeleton variant="text" width={200} />
        ) : (
          <div className="team_info">
            <h1 className="team_name">{workTeamData?.workTeam?.name}</h1>
            <h2 className="team_group">
              {workTeamData?.workTeam?.workGroup?.name}
            </h2>
          </div>
        )}
        <HasPrivileges reqireMain={[Privilages.ADD_EDIT_TEAMS]} allowBP>
          <div className="action_btns">
            {workTeamData?.workTeam?.canEdit ? (
              <EditIcon
                className="edit_icon"
                onClick={() => handleTeamAction("edit")}
              />
            ) : null}
            {workTeamData?.workTeam?.canDelete ? (
              <ClearIcon
                className="delete_icon"
                onClick={handleDeleteWorkTeam}
              />
            ) : null}
          </div>
        </HasPrivileges>
      </div>

      <div className="work_team_search">
        <div className="team_stats">
          <span className="text-nowrap">{t("team members")} : </span>
          <span className="team_count">
            {activeWorkTeamEmployees?.length ?? 0}
          </span>
        </div>

        <div className="d-flex align-items-center w-90 gap-20">
          <div className="input-container-style-default search_container ">
            <InputForm
              name="name"
              type="text"
              placeholder={t("search")}
              rootStyle="w-100"
              containerStyle="w-100"
              onChange={handleSearch}
              icon="search"
            />

            <DateTimePickerForm
              name="date"
              placeholder={t("select date")}
              value={date}
              onChange={(date) => setDate(date)}
              rootStyle="ml-3 w-100"
              containerStyle="w-100"
              hasIcon
              isClearable
            />
          </div>
          {/* <HasPrivileges
            reqireMain={[Privilages.ADD_REMOVE_EMPLOYEES_FROM_TEAMS]}
            allowBP
          >
            <button
              className="btn add_new_btn_style"
              onClick={() => handleTeamAction("add")}
            >
              <AddIcon className="add_btn_icon" />
              {t("add members")}
            </button>
          </HasPrivileges> */}
        </div>
      </div>

      {/* <div className="work_team_filters mt-3">
        <HasPrivileges
          reqireMain={[Privilages.ADD_REMOVE_EMPLOYEES_FROM_TEAMS]}
          allowBP
        >
          {!employeeDataLoading ? (
            <div className="all_checkbox">
              <input
                id="all_employees"
                className="all_employee_checkbox"
                type="checkbox"
                name="all_employees"
                onChange={handleCheckAllEmployees}
                checked={isAllChecked}
              />
              <label htmlFor="all_employees">{t("all employees")}</label>
            </div>
          ) : null}
        </HasPrivileges>

        <HasPrivileges
          reqireMain={[Privilages.ADD_REMOVE_EMPLOYEES_FROM_TEAMS]}
          allowBP
        >
          <div className="employee_actions">
            <Tooltip
              title={t("select at least one employee")}
              disableHoverListener={checkedValues.length > 0}
            >
              <span>
                <button
                  className="remove_btn"
                  onClick={handleRemoveTeamMembers}
                  disabled={checkedValues.length === 0}
                >
                  {t("remove from team")}
                </button>
              </span>
            </Tooltip>

            <Tooltip
              title={t("select at least one employee")}
              disableHoverListener={checkedValues.length > 0}
            >
              <span>
                <button
                  className="move_btn"
                  onClick={() => handleTeamAction("move")}
                  disabled={checkedValues.length === 0}
                >
                  {t("move to another team")}
                </button>
              </span>
            </Tooltip>
          </div>
        </HasPrivileges>
      </div> */}

      {employeeDataLoading ? (
        <Loader />
      ) : (
        <>
          <div className="mt-3 work_team_employees">
            {activeWorkTeamEmployees?.map((employee) => {
              let employeeStartDateWithTeam = date
                ? employee?.workTeamEmployee?.find(
                    (workTeamEmp) =>
                      moment(workTeamEmp?.start, "YYYY-MM-DD") <=
                        moment(date) &&
                      (!workTeamEmp?.end ||
                        moment(workTeamEmp?.end) >= moment(date))
                  )?.start
                : employee?.workTeamEmployee[
                    employee?.workTeamEmployee?.length - 1
                  ]?.start;

              let employeeEndDateWithTeam = date
                ? employee?.workTeamEmployee?.find(
                    (workTeamEmp) =>
                      moment(workTeamEmp?.start, "YYYY-MM-DD") <=
                        moment(date) &&
                      (!workTeamEmp?.end ||
                        moment(workTeamEmp?.end) >= moment(date))
                  )?.end
                : employee?.workTeamEmployee[
                    employee?.workTeamEmployee?.length - 1
                  ]?.end;

              return (
                <div className="team_employee" key={employee?.user?.id}>
                  <Tooltip
                    classes={{
                      tooltip: "custom_tooltip_style p-2",
                      arrow: "custom_arrow_tooltip_style",
                    }}
                    arrow={true}
                    title={
                      <div>
                        <div className="d-flex align-items-center">
                          <p className="employee-team-start-end-date-text-label">
                            {t("start date")}:
                          </p>
                          <p className="employee-team-start-end-date-text">
                            {employeeStartDateWithTeam ?? "-----"}
                          </p>
                        </div>
                        <div className="d-flex align-items-center">
                          <p className="employee-team-start-end-date-text-label">
                            {t("end date")}:
                          </p>
                          <p className="employee-team-start-end-date-text">
                            {employeeEndDateWithTeam ?? "-----"}
                          </p>
                        </div>
                      </div>
                    }
                  >
                    <Info className="employee-work-team-info-icon" />
                  </Tooltip>
                  <HasPrivileges
                    allowBP
                    reqireMain={[Privilages.ADD_REMOVE_EMPLOYEES_FROM_TEAMS]}
                    altChildren={
                      <HrEmployeeCard
                        key={employee?.user?.id}
                        {...employee?.user}
                        isTeamView={true}
                        isDimmed={
                          new Date(employeeStartDateWithTeam).getTime() >
                          new Date().getTime()
                        }
                      />
                    }
                  >
                    <div
                      onClick={() => handleCheckEmployee(employee?.user?.id)}
                    >
                      {/* <input
                        id={employee?.user?.id}
                        type="checkbox"
                        onChange={() => handleCheckEmployee(employee?.user?.id)}
                        checked={checkedValues.includes(employee?.user?.id)}
                        className="employee_checkbox"
                      /> */}
                      <HrEmployeeCard
                        key={employee?.user?.id}
                        {...employee?.user}
                        isTeamView={true}
                        isDimmed={
                          new Date(employeeStartDateWithTeam).getTime() >
                          new Date().getTime()
                        }
                      />
                    </div>
                  </HasPrivileges>
                </div>
              );
            })}
          </div>
          <Pagination
            customPaginator={pagination}
            styleWraper=""
            onPaginate={handlePaginate}
          />
        </>
      )}

      {currentAction === "add" && isModalOpen ? (
        <AddWorkTeamMembersModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          workGroupId={workTeamData?.workTeam?.workGroup?.id}
          fetchWokTeamEmployees={fetchWokTeamEmployees}
        />
      ) : null}

      {currentAction === "edit" && isModalOpen ? (
        <EditWorkTeamModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          fetchWorkTeam={fetchWorkTeam}
          workGroupId={workTeamData?.workTeam?.workGroup?.id}
          workTeam={workTeamData?.workTeam}
        />
      ) : null}

      {currentAction === "move" && isModalOpen ? (
        <MoveWorkTeamMembersModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          fetchWokTeamEmployees={fetchWokTeamEmployees}
          checkedValues={checkedValues}
          isAllChecked={isAllChecked}
          workGroupId={workTeamData?.workTeam?.workGroup?.id}
        />
      ) : null}
    </div>
  );
};

export default WorkTeamDetails;

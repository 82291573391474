import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import HelperFns from "../../Helpers/HelperFns";

import RouteSidebar from "./RouteSidebar";
import { NavLink } from "react-router-dom";
import { SwipeableDrawer, Badge, SvgIcon } from "@mui/material";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AttendanceSidebar = ({
  route,
  setRoute,
  isMobile,
  isSidebarOpen,
  setIsSidebarOpen,
  Toggler,
  handleToggleSideBar,
  ...props
}) => {
  const { t } = useTranslation();

  // Reducer State
  const currentState = useSelector((state) => state);
  const isFoodicsUser = useSelector(
    (state) => state?.auth?.userProfile?.isFoodicsUser
  );

  /* ↓ State Effects ↓ */

  useEffect(() => {
    const resetRouteOnLeavingWindow = () => setRoute(null);
    document.addEventListener("mouseleave", resetRouteOnLeavingWindow);
    return () => {
      document.removeEventListener("mouseleave", resetRouteOnLeavingWindow);
    };
  }, []);

  /* ↓ Helpers ↓ */

  // check if tab is hoverable
  const checkIfHoverable = (tabsPrivilages, isHoverable) => {
    if (!isHoverable) return false;
    const numberOfAvailableTabs = tabsPrivilages?.filter((priv) =>
      HelperFns.checkPrivileges({
        privileges: priv,
        allowBP: true,
      })
    )?.length;
    return numberOfAvailableTabs > 1;
  };

  const onHover = (label, tabsPrivilages, isHoverable) => {
    if (checkIfHoverable(tabsPrivilages, isHoverable)) {
      setRoute(label);
    } else {
      setRoute(null);
    }
  };

  const handleCloseRouteBar = () => {
    handleToggleSideBar(null);
    setRoute(null);
  };

  return (
    <aside>
      <SwipeableDrawer
        open={isSidebarOpen}
        onOpen={handleToggleSideBar}
        onClose={handleCloseRouteBar}
        classes={{ paper: "sidebar pt-4" }}
        variant={!isMobile ? "permanent" : null}
        PaperProps={{ style: { zIndex: 1040 } }}
        sx={{ visibility: isMobile && !isSidebarOpen && "hidden" }}
      >
        <Toggler />

        {props.superLinks ? (
          <div className="sidebar-menu">
            <ul>
              {props?.superLinks?.map((link) => {
                return (
                  <HasPrivileges
                    reqireMain={link?.tabPrivileges?.flat()}
                    allowBP={link.allowBP}
                    key={link.path}
                  >
                    <li
                      onMouseOver={() =>
                        !isMobile
                          ? onHover(
                              link?.label,
                              link?.tabPrivileges,
                              link?.isHoverable
                            )
                          : undefined
                      }
                    >
                      <NavLink
                        {...(link.path === "/" ? { exact: true } : "")}
                        to={link.path}
                        activeClassName="active"
                      >
                        <div className="active-border-style"></div>
                        {link?.hasBadge ? (
                          <HasPrivileges
                            reqireMain={link?.badgePrivileges}
                            allowBP={link?.badgeAllowBP}
                            altChildren={
                              <FontAwesomeIcon
                                className="icon_style"
                                icon={Icons[link?.iconName]}
                              />
                            }
                          >
                            <div>
                              <Badge
                                className="sidebar-custome-badge"
                                badgeContent={
                                  currentState[link?.badgeReducer][
                                    link?.badgeDataNameInReducer
                                  ]
                                }
                                color="error"
                              >
                                <FontAwesomeIcon
                                  className="icon_style"
                                  icon={Icons[link?.iconName]}
                                />
                              </Badge>
                            </div>
                          </HasPrivileges>
                        ) : (
                          <FontAwesomeIcon
                            className="icon_style"
                            icon={Icons[link?.iconName]}
                          />
                        )}
                        <span className="nav-label">
                          {t(`sidebar.${link.label}`)}
                        </span>
                      </NavLink>
                    </li>
                  </HasPrivileges>
                );
              })}
              {isFoodicsUser ? (
                <li
                // onMouseOver={() =>
                //   !isMobile
                //     ? onHover(
                //         link?.label,
                //         link?.tabPrivileges,
                //         link?.isHoverable
                //       )
                //     : undefined
                // }
                >
                  <NavLink to="/foodics" activeClassName="active">
                    <div className="active-border-style" />
                    {/* prettier-ignore */}
                    <SvgIcon className="icon_style" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M64 32C28.7 32 0 60.7 0 96L0 256 0 448c0 17.7 14.3 32 32 32s32-14.3 32-32l0-160 160 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L64 224 64 96l224 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L64 32z"/></SvgIcon>
                    <span className="nav-label">foodics</span>
                  </NavLink>
                </li>
              ) : null}
            </ul>
          </div>
        ) : null}

        {route && !isMobile ? (
          <div
            className="routeSideBar"
            onMouseLeave={() => setRoute(null)}
            onClick={handleCloseRouteBar}
          >
            <RouteSidebar route={route} />
          </div>
        ) : null}
      </SwipeableDrawer>
    </aside>
  );
};

export default AttendanceSidebar;

import React from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  showToast,
  safeJsonParse,
  openSwalConfirm,
  getOrdinalTranslation,
} from "../../Helpers/HelperFns";
import moment from "moment";
import gql from "graphql-tag";
import Privileges from "../../Constants/Privilages";
import { toggleSignInBreaksModal } from "../../Store/Actions";

import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
} from "@mui/material";
import MainModal from "../../Components/MainModal";
import {
  EditIconButton,
  TimesIconButton,
  AcceptIconButton,
  RemoveIconButton,
} from "../../Components/IconButtonWithTooltip";
import { AddButton } from "../../Components/Buttons";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import DateTimePickerForm from "../../Builder/Form/DateTimePickerForm";

/**
 *
 * Mutations
 *
 */

const ADD_BREAK = gql`
  mutation addBreak($input: AddOrEditBreakInput) {
    add_break(input: $input) {
      ... on BreakInOut {
        id
      }
      ... on GeneralException {
        message
      }
    }
  }
`;
const DELETE_BREAK = gql`
  mutation deleteBreak($breaks_id: [ID]) {
    delete_break(breaks_id: $breaks_id) {
      ... on DeleteBreakResponse {
        success_message
        breaks_id
      }
      ... on GeneralException {
        message
      }
    }
  }
`;
const EDIT_BREAK = gql`
  mutation editBreak($input: AddOrEditBreakInput) {
    edit_break(input: $input) {
      ... on BreakInOut {
        id
      }
      ... on GeneralException {
        message
      }
    }
  }
`;

/**
 *
 * SignInBreaksModal
 *
 */

const SignInBreaksModal = ({ refetch }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Reducer State
  const modalData = useSelector((state) => state.super?.signInBreaksModal);

  // Local State
  const [isDirty, setIsDirty] = React.useState(false);
  const [breakInOuts, setBreakInOuts] = React.useState(
    modalData?.breakInOuts || []
  );

  /* ↓ State Effects ↓ */

  /* ↓ Helpers ↓ */

  const handleAddNew = () => {
    breakInOuts.every((b) => b?.id) &&
      setBreakInOuts((prev) => [
        ...prev,
        {
          id: null,
          logs: [],
          break_in_time: modalData?.date,
          break_out_time: modalData?.date,
        },
      ]);
  };

  const handleCancelNew = (i) => {
    setBreakInOuts((prev) =>
      prev.reduce((acc, curr, idx) => {
        if (i === idx) return acc;
        return [...acc, curr];
      }, [])
    );
  };

  const handleUpdateUI = (breakEntry, operation) => {
    setIsDirty(true);
    operation === "delete"
      ? setBreakInOuts((prev) => prev.filter((p) => p.id !== breakEntry.id))
      : setBreakInOuts((prev) =>
          prev.map((p) => {
            const bool =
              operation === "add" ? p.id === null : p.id === breakEntry.id;
            return bool ? breakEntry : p;
          })
        );
  };

  const handleCloseModal = () => {
    isDirty && refetch();
    dispatch(
      toggleSignInBreaksModal({
        isOpen: false,
        breakInOuts: [],
        sign_in_id: null,
        name: null,
        date: null,
      })
    );
  };

  return (
    <MainModal
      modalTitle={`${modalData?.name} - ${modalData?.date}`}
      toggle={handleCloseModal}
      isOpen={modalData?.isOpen}
      hasModalFooter={false}
      size="lg"
    >
      <Box
        sx={{
          rowGap: 1,
          display: "grid",
          ".head, .MuiAccordionSummary-content": {
            columnGap: 1,
            display: "grid",
            alignItems: "center",
            gridTemplateColumns: "1fr 1.5fr 1.5fr 1.3fr",
          },
        }}
      >
        <div className="head mb-2 pl-3">
          {breakInOuts?.length ? (
            <>
              <b>{t("breaks")}</b>
              <b>{t("from")}</b>
              <b>{t("to")}</b>
            </>
          ) : (
            <p className="mb-0" style={{ gridColumn: "1 / 4" }}>
              {t("no taken breaks")}
            </p>
          )}
          <HasPrivileges
            allowBP
            reqireMain={[Privileges.ADD_EDIT_DELETE_BREAKS]}
          >
            <AddButton
              onClick={handleAddNew}
              disabled={breakInOuts.some((b) => !b?.id)}
              sx={{ marginInlineStart: "auto" }}
            />
          </HasPrivileges>
        </div>

        {breakInOuts?.map((breakInOut, idx) => (
          <BreakCell
            key={idx}
            idx={idx}
            breakInOut={breakInOut}
            handleUpdateUI={handleUpdateUI}
            handleCancelNew={handleCancelNew}
          />
        ))}
      </Box>
    </MainModal>
  );
};

export default SignInBreaksModal;

/**
 *
 * BreakCell
 *
 */

const BreakCell = ({ breakInOut, idx, handleUpdateUI, handleCancelNew }) => {
  // Local State
  const [isEditMode, setIsEditMode] = React.useState(!breakInOut?.id);
  const [input, setInput] = React.useState({
    id: breakInOut?.id,
    logs: breakInOut?.logs,
    break_in_time: breakInOut?.break_in_time || null,
    break_out_time: breakInOut?.break_out_time || null,
  });

  // Reducer State
  const sign_in_id = useSelector(
    (state) => state.super?.signInBreaksModal?.sign_in_id
  );

  // Server State
  const [addBreak, { loading: addLoading }] = useMutation(ADD_BREAK, {
    onCompleted: ({ add_break }) => {
      const isError = add_break?.message;
      if (isError) {
        showToast("error", add_break?.message);
      } else {
        handleUpdateUI({ ...input, id: add_break?.[0]?.id }, "add");
        setIsEditMode(false);
        showToast("success");
      }
    },
  });
  const [editBreak, { loading: editLoading }] = useMutation(EDIT_BREAK, {
    onCompleted: ({ edit_break }) => {
      const isError = edit_break?.message;
      if (isError) {
        showToast("error", edit_break?.message);
      } else {
        setIsEditMode(false);
        showToast("success");
        handleUpdateUI(input, "edit");
      }
    },
  });
  const [deleteBreak, { loading: deleteLoading }] = useMutation(DELETE_BREAK, {
    variables: { breaks_id: [breakInOut?.id] },
    onCompleted: ({ delete_break }) => {
      if (delete_break?.success_message) {
        handleUpdateUI(breakInOut, "delete");
        showToast("success", delete_break?.success_message);
      } else {
        showToast("error", delete_break?.message);
      }
    },
    onError: (err) => {
      showToast(
        "error",
        err.graphQLErrors?.[0]?.extensions?.reason || err?.message
      );
    },
  });

  // Constants
  const isNewEntry = !breakInOut?.id;
  const isLoading = addLoading || editLoading || deleteLoading;

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    setIsEditMode(!breakInOut?.id);
    setInput((prev) => ({ ...prev, id: breakInOut?.id }));
  }, [breakInOut?.id]);

  /* ↓ Helpers ↓ */

  const handleChange = (date, name) => {
    setInput((prev) => ({
      ...prev,
      [name]: date.format("YYYY-MM-DD HH:mm:ss"),
    }));
  };

  const handleStartEditMode = (e) => {
    e.stopPropagation();
    setIsEditMode(true);
  };

  const handleCloseEditMode = (e) => {
    e.stopPropagation();
    !isNewEntry ? setIsEditMode(false) : handleCancelNew(idx);
  };

  const handleDeleteBreak = (e) => {
    e.stopPropagation();
    openSwalConfirm((isConfirmed) => isConfirmed && deleteBreak());
  };

  const handleAddEditBreak = (e) => {
    e.stopPropagation();
    if (!input?.break_in_time || !input?.break_out_time) return;
    const args = {
      variables: {
        input: {
          sign_in_id,
          breaks: [
            {
              id: input?.id,
              break_in_time: input?.break_in_time,
              break_out_time: input?.break_out_time,
            },
          ],
        },
      },
      onError: (err) => {
        const validation = err.graphQLErrors?.[0]?.extensions?.validation;
        showToast(
          "error",
          err.graphQLErrors?.[0]?.extensions?.reason ||
            validation?.["input.breaks"]?.[0] ||
            validation?.["input.breaks"]?.[1] ||
            err?.message
        );
      },
    };
    isNewEntry ? addBreak(args) : editBreak(args);
  };

  return (
    <Accordion
      expanded={breakInOut?.logs?.length ? undefined : false}
      sx={{
        "&::before, .MuiAccordionSummary-content::before": { display: "none" },
        "&.Mui-expanded": {
          boxShadow: (theme) => `${theme.shadows[1]} !important`,
          ".MuiAccordionSummary-root": { padding: "0px 16px !important" },
        },
      }}
    >
      {/* Break Details */}
      <AccordionSummary>
        <b>{getOrdinalTranslation(idx + 1, "Break", "f")}</b>
        {!isEditMode ? (
          <>
            <span>
              {moment(input?.break_in_time).format("YYYY-MM-DD hh:mm A")}
            </span>
            <span>
              {moment(input?.break_out_time).format("YYYY-MM-DD hh:mm A")}
            </span>
          </>
        ) : (
          <>
            <DateTimePickerForm
              dateTime
              disabled={isLoading}
              datePickerContainer=" "
              onClick={(e) => e.stopPropagation()}
              onSelect={(e) => handleChange(e, "break_in_time")}
              value={moment(input?.break_in_time)}
            />
            <DateTimePickerForm
              dateTime
              disabled={isLoading}
              datePickerContainer=" "
              onClick={(e) => e.stopPropagation()}
              onSelect={(e) => handleChange(e, "break_out_time")}
              value={moment(input?.break_out_time)}
            />
          </>
        )}

        {/* Break Actions */}
        <div className="d-flex justify-content-end">
          {isLoading ? (
            <CircularProgress size={20} />
          ) : !isEditMode ? (
            <HasPrivileges
              allowBP
              reqireMain={[Privileges.ADD_EDIT_DELETE_BREAKS]}
            >
              <EditIconButton onClick={handleStartEditMode} />
              <RemoveIconButton onClick={handleDeleteBreak} />
            </HasPrivileges>
          ) : (
            <>
              <AcceptIconButton onClick={handleAddEditBreak} />
              <TimesIconButton
                label="Cancel"
                sx={{ mx: 1 }}
                onClick={handleCloseEditMode}
              />
            </>
          )}
        </div>
      </AccordionSummary>

      {/* Break Logs */}
      {breakInOut?.logs?.length ? (
        <AccordionDetails>
          {breakInOut?.logs.map((log) => (
            <BreakLog key={log?.id} log={log} />
          ))}
        </AccordionDetails>
      ) : null}
    </Accordion>
  );
};

/**
 *
 * BreakLog
 *
 */

const BreakLog = ({ log }) => {
  const { t } = useTranslation();

  // Constants
  const desc = safeJsonParse(log?.description || {});
  const break_in_time =
    log?.event?.toLowerCase() === "created"
      ? desc?.attributes?.break_in_time
      : safeJsonParse(desc?.attributes?.break_in_time || {})?.to;
  const break_out_time =
    log?.event?.toLowerCase() === "created"
      ? desc?.attributes?.break_out_time
      : safeJsonParse(desc?.attributes?.break_out_time || {})?.to;

  return (
    <div className="border p-1 rounded mb-2 main-gray-color font-13">
      <div className="d-flex justify-content-between">
        <div className="d-flex gap-5">
          <b>{t("operation")}:</b>
          <span>{t(log.event.toLowerCase())}</span>
        </div>
        <div className="d-flex gap-5 pr-3">
          <b>{t("on")}:</b>
          <span>{log?.created_at}</span>
        </div>
      </div>

      <p className=" mb-0">{desc?.msg}</p>

      <ul className="p-0 d-flex justify-content-between mb-0">
        {break_in_time ? (
          <li className="d-flex gap-5">
            <b>{t("from")}:</b>
            {moment(break_in_time).format("hh:mm A")}
          </li>
        ) : null}
        {break_out_time ? (
          <li className="d-flex gap-5 pr-3">
            <b>{t("to")}:</b>
            {moment(break_out_time).format("hh:mm A")}
          </li>
        ) : null}
      </ul>
    </div>
  );
};

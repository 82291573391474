import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckboxForm } from "form-builder";
import { onInputChangeAction } from "../../../Store/Actions";
import { useTranslation } from "react-i18next";
import Masonry from "react-masonry-component";

const formName = "systemPlan";

const SystemPriviligesTree = (props) => {
  const { t } = useTranslation();
  const systemPlan = useSelector((state) => state?.admin[formName]);
  const dispatch = useDispatch();
  let allPrivileges = props?.parents
    ?.map((parent) => [
      parent?.id?.toString(),
      ...parent?.descendants?.map(String),
    ])
    ?.flat(Infinity);
  const handleSelcetAll = (e) => {
    if (e.target.checked) {
      let event = {
        target: {
          name: "selectedPrivileges",
          value: allPrivileges,
        },
      };

      // add all Privilages
      dispatch(onInputChangeAction(formName, event));
    } else {
      let event = {
        target: {
          name: "selectedPrivileges",
          value: [],
        },
      };

      // remove all desendant from main Privilages
      dispatch(onInputChangeAction(formName, event));
    }
  };
  return (
    <>
      <div className="flex-row d-flex align-items-baseline">
        <h4>{t("privileges")}</h4>

        <div
          style={{
            backgroundColor: "#23aaeb5e",
            borderRadius: "3px",
            border: "solid 1px #23aaeb5e",
          }}
          className="ml-2 p-1 row align-items-center"
        >
          <input
            type="checkbox"
            className="align-items-center"
            onChange={(e) => handleSelcetAll(e)}
            checked={
              allPrivileges?.length === systemPlan?.selectedPrivileges?.length
            }
            disabled={props.edit}
          />
          <label style={{ fontSize: "10px" }} className="ml-1">
            {t("select all")}
          </label>
        </div>
      </div>
      <Masonry>
        <OptionsList
          options={props?.parents}
          isFirst={true}
          edit={props.edit}
        />
      </Masonry>
    </>
  );
};

// Recursive component
const OptionsList = ({ options, isFirst, edit }) => {
  const systemPlan = useSelector((state) => state.admin[formName]);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const handleSelectAll = (e, option) => {
    if (e.target.checked) {
      let updatedPrivlegesWithAll = [
        ...new Set([
          ...systemPlan?.selectedPrivileges,
          ...option?.descendants?.map(String),
          option?.id?.toString(),
        ]),
      ];
      let event = {
        target: {
          name: "selectedPrivileges",
          value: updatedPrivlegesWithAll,
        },
      };

      // remove all desendant from main Privilages
      dispatch(onInputChangeAction(formName, event));
    } else {
      let updatedPrivlegesWithAll = [
        ...systemPlan.selectedPrivileges?.filter(
          // eslint-disable-next-line eqeqeq
          (item) => !option?.descendants?.includes(+item) && item != option.id
        ),
      ];
      let event = {
        target: {
          name: "selectedPrivileges",
          value: updatedPrivlegesWithAll,
        },
      };

      // remove all desendant from main Privilages
      dispatch(onInputChangeAction(formName, event));
    }
  };

  return (
    <>
      {options?.map((option) => (
        <div className={`${isFirst ? "col-12 col-xl-6" : ""}`}>
          <div
            className={`${isFirst ? "boxContainer py-2 my-2 pl-3 pr-2" : ""}`}
          >
            <ul className={`${isFirst ? "pl-0 mb-0" : "p-l-22"}`}>
              <div className="d-flex flex-row flex-wrap align-items-center">
                <Checkbox {...option} edit={edit} />
                {isFirst ? (
                  <div
                    style={{
                      backgroundColor: "#23aaeb5e",
                      borderRadius: "3px",
                      border: "solid 1px #23aaeb5e",
                    }}
                    className="ml-2 p-1 row align-items-center"
                  >
                    <input
                      type="checkbox"
                      className="align-items-center"
                      onChange={(e) => handleSelectAll(e, option)}
                      checked={option.descendants.every((child) =>
                        systemPlan?.selectedPrivileges.includes(`${child}`)
                      )}
                      disabled={edit}
                    />
                    <label style={{ fontSize: "10px" }} className="ml-1">
                      {t("select all")}
                    </label>
                  </div>
                ) : null}
              </div>

              {/* Base Case */}
              {option?.children?.length > 0 &&
                systemPlan?.selectedPrivileges?.includes(option?.id) && (
                  <OptionsList options={option.children} edit={edit} />
                )}
            </ul>
          </div>
        </div>
      ))}
    </>
  );
};

// checkbox component, completly controlled by parent
const Checkbox = ({ name, id, descendants, flag, privilege_type_id, edit }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const systemPlan = useSelector((state) => state.admin[formName]);
  const handleCheckboxChange = (e) => {
    //handle if un Checked and has descendants
    if (!e.target.checked) {
      let event = {
        target: {
          name: "selectedPrivileges",
          value: systemPlan?.selectedPrivileges?.filter(
            // eslint-disable-next-line eqeqeq
            (item) => !descendants?.includes(+item) && item != id
          ),
        },
      };

      // remove all desendant from main Privilages
      dispatch(onInputChangeAction(formName, event));
    } else {
      dispatch(onInputChangeAction(formName, e, "checkbox", id));
    }
  };
  return (
    <CheckboxForm
      options={[{ value: id, label: t(name) }]}
      name="selectedPrivileges"
      formName={formName}
      type="checkbox"
      reducer="admin"
      containerStyle=" "
      labelStyle="label-style"
      optionsContainerStyle="my-1"
      optionItemStyle=" "
      optionInputStyle=" "
      optionLabelStyle=" "
      optionLabelActiveStyle="optionLabelActiveStyle"
      onChange={handleCheckboxChange}
      disabled={edit}
    />
  );
};

export default SystemPriviligesTree;

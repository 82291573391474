import React from "react";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import {
  addLeadsTagsMutation,
  removeLeadsTagsMutation,
} from "../../Graphql/mutation";
import { showToast } from "../../Helpers/HelperFns";

import MainModal from "../MainModal";
import TagIcon from "@mui/icons-material/Tag";
import { RadioboxForm, BSelect } from "form-builder";

const operations = { ADD: "ADD", DELETE: "DELETE" };
const formNameValidation = "assignLeadsTagsClientValidation";

const SetLeadsTagsModal = ({
  data,
  onClose,
  onResetSelectedRows,
  refetchList,
}) => {
  const { t } = useTranslation();

  // Local State
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [options] = React.useState({
    tags: data?.tagsOptions || [],
    leads: data?.leadsOptions || [],
  });
  const [formData, setFormData] = React.useState({
    tags: [],
    leads: data?.leads || [],
    operation: operations.ADD,
  });

  // Reducer State
  const clientValidation = useSelector(
    (state) => state.crm?.[formNameValidation]
  );

  // Server Status
  const [assignLeadsTags, { loading }] = useMutation(
    formData.operation === operations.ADD
      ? addLeadsTagsMutation
      : removeLeadsTagsMutation
  );

  /* ↓ Helpers ↓ */

  const handleRadioChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSelectChange = (val, { name: key }) => {
    setFormData((prev) => ({ ...prev, [key]: val }));
  };

  const handleUpsert = () => {
    setFormSubmitting(true);
    if (clientValidation.length) return;

    const { leads, tags } = formData;
    assignLeadsTags({
      variables: {
        leads: leads.map((l) => +l.id),
        tags: tags.map((tag) => tag.id),
      },
      onCompleted: () => {
        onResetSelectedRows();
        refetchList();
        onClose();
        showToast("success");
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors[0]?.extensions?.reason ||
            err?.graphQLErrors[0]?.message ||
            err?.message
        );
      },
    });
  };

  return (
    <MainModal
      isOpen
      toggle={onClose}
      modalTitle="set tags"
      btnOnClick={handleUpsert}
      btnSubmitLoading={loading}
    >
      <RadioboxForm
        name="operation"
        label="operation"
        value={formData.operation}
        onChange={handleRadioChange}
        options={[
          {
            label: "add",
            value: operations.ADD,
          },
          {
            label: "delete",
            value: operations.DELETE,
          },
        ]}
        optionInputStyle=" "
        containerStyle="d-flex gap-20"
        optionItemStyle="d-inline-block mx-2"
        optionsContainerStyle="d-flex"
        rootStyle="mb-3"
      />

      <BSelect
        isMulti
        isDisabled
        name="leads"
        label="leads"
        icon="employee"
        rootStyle="mb-3"
        value={formData.leads}
        options={options?.leads || []}
        validateBy="textRequired"
        formSubmitting={formSubmitting}
        formNameValidation={formNameValidation}
      />
      <BSelect
        isMulti
        name="tags"
        label="tags"
        icon={<TagIcon />}
        value={formData.tags}
        options={options?.tags || []}
        validateBy="textRequired"
        onChange={handleSelectChange}
        formSubmitting={formSubmitting}
        formNameValidation={formNameValidation}
      />
    </MainModal>
  );
};

export default SetLeadsTagsModal;

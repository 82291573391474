import React from "react";
import { useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";

import Constants, {
  quartersOptions,
  crmYearsOptions,
  conditionalFooterRowStyles,
} from "../../../Constants";
import HelperFns, {
  showToast,
  getToDate,
  quarterToMonth,
} from "../../../Helpers/HelperFns";
import {
  GET_PIPELINE_CUSTOM_REPORT,
  GET_PIPELINE_MONTHLY_REPORT,
  GET_PIPELINE_QUARTERLY_REPORT,
} from "../../../Graphql/query";
import moment from "moment";
import Privileges from "../../../Constants/Privilages";
import { onFormResetAction } from "../../../Store/Actions";

import {
  ViewRelevantLeads,
  ViewRelevantCompanies,
} from "../../../Components/CRMFormModals";
import Loader from "../../../Components/Loader";
import DataTable from "react-data-table-component";
import { BSelect, DateTimePickerForm } from "form-builder";
import { CRMReportLinearChart } from "../../../Components/CRM";
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { BarsIconButton } from "../../../Components/IconButtonWithTooltip";

const modalInitState = { isOpen: false, data: null };
const filtersReducerKey = "pipelineReportFilters";
const modals = [
  { name: "viewRelevantLeads", Modal: ViewRelevantLeads },
  { name: "viewRelevantCompanies", Modal: ViewRelevantCompanies },
];
const formatData = (data) => {
  if (!data?.length) return data || [];

  const sum = data?.reduce(
    (acc, curr) => ({
      noExpand: true,
      client_manual: acc?.client_manual
        ? acc?.client_manual + +curr?.client_manual
        : +curr?.client_manual,
      Client: acc?.Client ? acc?.Client + +curr?.Client : +curr?.Client,
      Trial: acc?.Trial ? acc?.Trial + +curr?.Trial : +curr?.Trial,
      Demo: acc?.Demo ? acc?.Demo + +curr?.Demo : +curr?.Demo,
      new_users: acc?.new_users
        ? acc?.new_users + +curr?.new_users
        : +curr?.new_users,
      new_users_by_crm: acc?.new_users_by_crm
        ? acc?.new_users_by_crm + +curr?.new_users_by_crm
        : +curr?.new_users_by_crm,
      monthly_subscription_value: acc?.monthly_subscription_value
        ? acc?.monthly_subscription_value + +curr?.monthly_subscription_value
        : +curr?.monthly_subscription_value,
      yearly_subscription_value: acc?.yearly_subscription_value
        ? acc?.yearly_subscription_value + +curr?.yearly_subscription_value
        : +curr?.yearly_subscription_value,
      price_per_user: acc?.price_per_user
        ? acc?.price_per_user + +curr?.price_per_user
        : +curr?.price_per_user,
    }),
    {}
  );

  return [
    ...data,
    {
      name: "Total",
      monthYear: "",
      ...sum,
      price_per_user: sum?.monthly_subscription_value
        ? (
            sum?.monthly_subscription_value /
            (sum?.new_users_by_crm + sum?.new_users)
          ).toFixed(2)
        : 0,
    },
  ];
};

const PipelineReport = () => {
  const dispatch = useDispatch();

  // Local State
  const [showChart, setShowChart] = React.useState(false);
  const [modalsState, setModalsState] = React.useState(
    modals.map(({ name }) => ({ name, ...modalInitState }))
  );

  // Reducer State
  const filters = useSelector((state) => state.crm[filtersReducerKey]);

  /* ↓ Helpers ↓ */

  const renderFilters = () => {
    switch (filters.view) {
      case "monthly":
        return (
          <>
            <div className="d-flex align-items-center gap-10">
              <b>from</b>
              <BSelect
                name="fromMonth"
                value={filters.fromMonth}
                onChange={handleSelectFilters}
                inputContainerStyle="w-100"
                options={Constants.MonthsData}
                rootStyle="min-w-150"
                optionLabel="key"
                optionValue="value"
              />
              <BSelect
                name="fromYear"
                value={filters.fromYear}
                onChange={handleSelectFilters}
                inputContainerStyle="w-100"
                options={crmYearsOptions}
                rootStyle="min-w-150"
              />
            </div>
            <div className="d-flex align-items-center gap-10">
              <b>to</b>
              <BSelect
                name="toMonth"
                value={filters.toMonth}
                onChange={handleSelectFilters}
                inputContainerStyle="w-100"
                options={Constants.MonthsData}
                rootStyle="min-w-150"
                optionLabel="key"
                optionValue="value"
              />
              <BSelect
                name="toYear"
                value={filters.toYear}
                onChange={handleSelectFilters}
                inputContainerStyle="w-100"
                options={crmYearsOptions}
                rootStyle="min-w-150"
              />
            </div>
          </>
        );

      case "quarterly":
        return (
          <>
            <div className="d-flex align-items-center gap-10">
              <b>from</b>
              <BSelect
                name="fromQuarter"
                value={filters.fromQuarter}
                onChange={handleSelectFilters}
                inputContainerStyle="w-100"
                options={quartersOptions}
                rootStyle="min-w-150"
              />
              <BSelect
                name="fromYear"
                value={filters.fromYear}
                onChange={handleSelectFilters}
                inputContainerStyle="w-100"
                options={crmYearsOptions}
                rootStyle="min-w-150"
              />
            </div>
            <div className="d-flex align-items-center gap-10">
              <b>to</b>
              <BSelect
                name="toQuarter"
                value={filters.toQuarter}
                onChange={handleSelectFilters}
                inputContainerStyle="w-100"
                options={quartersOptions}
                rootStyle="min-w-150"
              />
              <BSelect
                name="toYear"
                value={filters.toYear}
                onChange={handleSelectFilters}
                inputContainerStyle="w-100"
                options={crmYearsOptions}
                rootStyle="min-w-150"
              />
            </div>
          </>
        );

      case "custom":
        return (
          <>
            <DateTimePickerForm
              hasIcon
              label="from"
              placeholder="from"
              value={filters.from}
              rootStyle="min-width-200"
              datePickerContainer="w-100"
              labelStyle="font-weight-bold"
              containerStyle="py-0 d-flex gap-10 align-items-center"
              onChange={(val) => handleDateFiltersChange("from", val)}
            />
            <DateTimePickerForm
              hasIcon
              label="to"
              placeholder="to"
              value={filters.to}
              rootStyle="min-width-200"
              datePickerContainer="w-100"
              labelStyle="font-weight-bold"
              containerStyle="py-0 d-flex gap-10 align-items-center"
              onChange={(val) => handleDateFiltersChange("to", val)}
            />
          </>
        );

      default:
        break;
    }
  };

  const renderReports = () => {
    switch (filters.view) {
      case "monthly":
        return (
          <MonthlyReport
            filters={filters}
            showChart={showChart}
            openRelevantLeadsModal={openRelevantLeadsModal}
            openRelevantCompaniesModal={openRelevantCompaniesModal}
          />
        );

      case "quarterly":
        return (
          <QuarterlyReport
            filters={filters}
            showChart={showChart}
            openRelevantLeadsModal={openRelevantLeadsModal}
            openRelevantCompaniesModal={openRelevantCompaniesModal}
          />
        );

      case "custom":
        return (
          <CustomReport
            filters={filters}
            showChart={showChart}
            openRelevantLeadsModal={openRelevantLeadsModal}
            openRelevantCompaniesModal={openRelevantCompaniesModal}
          />
        );

      default:
        break;
    }
  };

  const handleOpenModal = (name, data = null) => {
    const modalState = { name, data, isOpen: true };

    setModalsState((prev) =>
      prev.map((p) => (p.name === name ? modalState : p))
    );
  };

  const handleCloseModal = (name) => {
    setModalsState((prev) =>
      prev.map((p) => (p.name === name ? { name, ...modalInitState } : p))
    );
  };

  const openRelevantLeadsModal = (ids) => {
    const leadIDs = ids?.split(",");
    handleOpenModal("viewRelevantLeads", { leadIDs });
  };

  const openRelevantCompaniesModal = (ids) => {
    const companiesIDs = ids?.split(",");
    handleOpenModal("viewRelevantCompanies", { companiesIDs });
  };

  const handleSelectFilters = (val, { name }) => {
    dispatch(onFormResetAction(filtersReducerKey, { ...filters, [name]: val }));
  };

  const handleDateFiltersChange = (name, val) => {
    dispatch(onFormResetAction(filtersReducerKey, { ...filters, [name]: val }));
  };

  const handleViewChange = (_, view) => {
    dispatch(onFormResetAction(filtersReducerKey, { ...filters, view }));
  };

  return (
    <>
      {/* Filters */}
      <div className="d-flex flex-wrap align-items-center justify-content-between gap-10 my-3">
        <ToggleButtonGroup
          exclusive
          size="small"
          color="primary"
          value={filters.view}
          onChange={handleViewChange}
          aria-label="Select View Mode"
        >
          <ToggleButton value="custom">custom</ToggleButton>
          <ToggleButton value="monthly">monthly</ToggleButton>
          <ToggleButton value="quarterly">quarterly</ToggleButton>
        </ToggleButtonGroup>

        <div className="flex-1 d-flex gap-20 justify-content-end align-items-center">
          {renderFilters()}
          <BarsIconButton
            onClick={() => setShowChart((prev) => !prev)}
            label={showChart ? "show table" : "show chart"}
          />
        </div>
      </div>

      {/* Charts and Tables */}
      {renderReports()}

      {/* Modals */}
      {modals.map(({ name, Modal }, i) =>
        modalsState[i].isOpen ? (
          <Modal
            key={name}
            data={modalsState[i]?.data}
            onClose={() => handleCloseModal(name)}
          />
        ) : null
      )}
    </>
  );
};

export default PipelineReport;

/**
 *
 * End of `Pipeline Report`
 *
 * Start of `Monthly Report`
 *
 */

const getMonthlyChartData = (data) => {
  const formattedData = data.reduce(
    (acc, curr, idx) => {
      if (idx === data?.length - 1) return acc;
      acc.months.push(curr.monthYear);
      acc.trialData.push(curr.Trial?.toString());
      acc.demoData.push(curr.Demo?.toString());
      acc.clientData.push(curr.Client?.toString());
      acc.newUsersData.push(curr.new_users?.toString());
      acc.newUsersByCRMData.push(curr.new_users_by_crm?.toString());
      return acc;
    },
    {
      months: [],
      trialData: [],
      demoData: [],
      clientData: [],
      newUsersData: [],
      newUsersByCRMData: [],
    }
  );

  return {
    labels: formattedData.months,
    datasets: [
      { label: "Trial", data: formattedData.trialData },
      { label: "Demo", data: formattedData.demoData },
      { label: "Client", data: formattedData.clientData },
      { label: "New Users", data: formattedData.newUsersData },
      { label: "New Users by CRM", data: formattedData.newUsersByCRMData },
    ],
  };
};

const MonthlyReport = ({
  filters,
  showChart,
  openRelevantLeadsModal,
  openRelevantCompaniesModal,
  ...props
}) => {
  const isQuarterChild = Boolean(props?.data);

  // Local State
  const [data, setData] = React.useState(props?.data?.months || []);

  // Server State
  const { loading } = useQuery(GET_PIPELINE_MONTHLY_REPORT, {
    skip: isQuarterChild,
    variables: {
      from: `${filters?.fromYear?.id}-${filters?.fromMonth?.key}-01`,
      to: getToDate(`${filters?.toYear?.id}-${filters?.toMonth?.key}`),
    },
    onCompleted: (res) => {
      setData(formatData(res?.pipelineMonthlyReport));
    },
    onError: (error) => {
      showToast(
        "error",
        error?.graphQLErrors?.[0]?.extensions?.reason ||
          error?.graphQLErrors?.[0]?.message ||
          error?.message
      );
    },
  });

  // Constants
  const columns = React.useMemo(
    () => [
      ...(isQuarterChild ? [{ width: "50px" }] : []),
      {
        name: "Period",
        cell: (row) => {
          if (row?.Month || row?.monthYear)
            return isQuarterChild
              ? moment(row?.Month).format("MMMM")
              : moment(row?.monthYear).format("MMM, YYYY");
        },
      },
      {
        name: "New Clients",
        cell: (row) => (
          <ViewCell
            viewCompany
            num={row?.client_manual}
            perc={row?.client_manual_perc}
            isTotal={row?.noExpand}
            onClick={() =>
              openRelevantCompaniesModal(row?.client_manual_company)
            }
          />
        ),
      },
      {
        name: "New CRM Clients",
        cell: (row, i) => (
          <ViewCell
            num={row?.Client}
            perc={row?.client_perc}
            isTotal={row?.noExpand}
            onClick={() => openRelevantLeadsModal(row?.client_leads)}
          />
        ),
      },
      {
        name: "Trials",
        cell: (row, i) => (
          <ViewCell
            num={row?.Trial}
            perc={row?.trial_perc}
            isTotal={row?.noExpand}
            onClick={() => openRelevantLeadsModal(row?.trial_leads)}
          />
        ),
      },
      {
        name: "Demos",
        cell: (row, i) => (
          <ViewCell
            num={row?.Demo}
            perc={row?.demo_perc}
            isTotal={row?.noExpand}
            onClick={() => openRelevantLeadsModal(row?.demo_leads)}
          />
        ),
      },
      { name: "New Users", selector: "new_users", center: true },
      { name: "New CRM Users", selector: "new_users_by_crm", center: true },
      {
        name: "monthly subscription value",
        selector: "monthly_subscription_value",
        center: true,
      },
      {
        name: "yearly subscription value",
        selector: "yearly_subscription_value",
        center: true,
      },
      {
        name: "price per user per month",
        selector: "price_per_user",
        center: true,
      },
    ],
    []
  );

  return showChart ? (
    <CRMReportLinearChart
      loading={loading}
      chartData={getMonthlyChartData(data)}
    />
  ) : (
    <DataTable
      noHeader
      noTableHead={isQuarterChild}
      data={data}
      columns={columns}
      className="cards_table"
      progressPending={loading}
      progressComponent={<Loader />}
      conditionalRowStyles={conditionalFooterRowStyles}
      customStyles={
        isQuarterChild
          ? {
              rows: {
                style: {
                  minHeight: "35px  !important",
                  marginTop: "0 !important",
                  border: "none !important",
                  boxShadow: "none !important",
                },
              },
            }
          : {}
      }
    />
  );
};

/**
 *
 * End of `Monthly Report`
 *
 * Start of `Quarterly Report`
 *
 */

const getQuarterlyChartData = (data) => {
  const formattedData = data.reduce(
    (acc, curr, idx) => {
      if (idx === data?.length - 1) return acc;
      acc.quarters.push(curr.Quarter);
      acc.trialData.push(curr.Trial);
      acc.demoData.push(curr.Demo);
      acc.clientData.push(curr.Client);
      acc.newUsersData.push(curr.new_users);
      acc.newUsersByCRMData.push(curr.new_users_by_crm);
      return acc;
    },
    {
      quarters: [],
      trialData: [],
      demoData: [],
      clientData: [],
      newUsersData: [],
      newUsersByCRMData: [],
    }
  );

  return {
    labels: formattedData.quarters,
    datasets: [
      { label: "Trial", data: formattedData.trialData },
      { label: "Demo", data: formattedData.demoData },
      { label: "Client", data: formattedData.clientData },
      { label: "New Users", data: formattedData.newUsersData },
      { label: "New Users by CRM", data: formattedData.newUsersByCRMData },
    ],
  };
};

const QuarterlyReport = ({
  filters,
  showChart,
  openRelevantLeadsModal,
  openRelevantCompaniesModal,
}) => {
  // Local State
  const [data, setData] = React.useState([]);

  // Server State
  const { loading } = useQuery(GET_PIPELINE_QUARTERLY_REPORT, {
    variables: {
      from: `${filters?.fromYear?.id}-${quarterToMonth(
        filters?.fromQuarter?.id,
        "from"
      )}-01`,
      to: getToDate(
        `${filters?.toYear?.id}-${quarterToMonth(filters?.toQuarter?.id, "to")}`
      ),
    },
    onCompleted: (res) => {
      setData(formatData(res?.pipelineQuarterlyReport));
    },
    onError: (error) => {
      showToast(
        "error",
        error?.graphQLErrors?.[0]?.extensions?.reason ||
          error?.graphQLErrors?.[0]?.message ||
          error?.message
      );
    },
  });

  // Constants
  const columns = React.useMemo(
    () => [
      { name: "Period", selector: "Quarter" },
      {
        name: "New Clients",
        cell: (row) => (
          <ViewCell
            viewCompany
            num={row?.client_manual}
            perc={row?.client_manual_perc}
            isTotal={row?.noExpand}
            onClick={() =>
              openRelevantCompaniesModal(row?.client_manual_company)
            }
          />
        ),
      },
      {
        name: "New CRM Clients",
        cell: (row) => (
          <ViewCell
            num={row?.Client}
            perc={row?.client_perc}
            isTotal={row?.noExpand}
            onClick={() => openRelevantLeadsModal(row?.client_leads)}
          />
        ),
      },
      {
        name: "Trials",
        cell: (row) => (
          <ViewCell
            num={row?.Trial}
            perc={row?.trial_perc}
            isTotal={row?.noExpand}
            onClick={() => openRelevantLeadsModal(row?.trial_leads)}
          />
        ),
      },
      {
        name: "Demos",
        cell: (row) => (
          <ViewCell
            num={row?.Demo}
            perc={row?.demo_perc}
            isTotal={row?.noExpand}
            onClick={() => openRelevantLeadsModal(row?.demo_leads)}
          />
        ),
      },
      { name: "New Users", selector: "new_users", center: true },
      { name: "New CRM Users", selector: "new_users_by_crm", center: true },
      {
        name: "monthly subscription value",
        selector: "monthly_subscription_value",
        center: true,
      },
      {
        name: "yearly subscription value",
        selector: "yearly_subscription_value",
        center: true,
      },
      { name: "price per user", selector: "price_per_user", center: true },
    ],
    []
  );

  return showChart ? (
    <CRMReportLinearChart
      loading={loading}
      chartData={getQuarterlyChartData(data)}
    />
  ) : (
    <DataTable
      noHeader
      data={data}
      columns={columns}
      className="cards_table expandable"
      progressPending={loading}
      progressComponent={<Loader />}
      expandableRows
      expandableRowsComponent={
        <MonthlyReport
          openRelevantLeadsModal={openRelevantLeadsModal}
          openRelevantCompaniesModal={openRelevantCompaniesModal}
        />
      }
      expandableRowDisabled={(row) => row?.noExpand}
      conditionalRowStyles={conditionalFooterRowStyles}
      customStyles={{
        rows: {
          style: {
            minHeight: "50px !important",
          },
        },
      }}
    />
  );
};

/**
 *
 * End of `Quarterly Report`
 *
 * Start of `Custom Report`
 *
 */

const getCustomChartData = (data, filters) => {
  const formattedData = data?.[0];

  return {
    labels: [
      filters.from.format("YYYY-MM-DD"),
      filters?.to.format("YYYY-MM-DD"),
    ],
    datasets: [
      { label: "Trial", data: formattedData?.Trial?.toString() },
      { label: "Demo", data: formattedData?.Demo?.toString() },
      { label: "Client", data: formattedData?.Client?.toString() },
      { label: "New Users", data: formattedData?.new_users?.toString() },
      {
        label: "New Users by CRM",
        data: formattedData?.new_users_by_crm?.toString(),
      },
    ],
  };
};

const CustomReport = ({
  showChart,
  filters,
  openRelevantLeadsModal,
  openRelevantCompaniesModal,
}) => {
  // Local State
  const [data, setData] = React.useState([]);

  // Server State
  const { loading } = useQuery(GET_PIPELINE_CUSTOM_REPORT, {
    variables: {
      to: filters?.to.format("YYYY-MM-DD"),
      from: filters?.from.format("YYYY-MM-DD"),
    },
    onCompleted: (res) => {
      setData(res?.pipelineCustomReport || []);
    },
    onError: (error) => {
      showToast(
        "error",
        error?.graphQLErrors?.[0]?.extensions?.reason ||
          error?.graphQLErrors?.[0]?.message ||
          error?.message
      );
    },
  });

  // Constants
  const columns = React.useMemo(
    () => [
      {
        name: "New Clients",
        cell: (row) => (
          <ViewCell
            viewCompany
            num={row?.client_manual}
            isTotal={row?.noExpand}
            onClick={() =>
              openRelevantCompaniesModal(row?.client_manual_company)
            }
          />
        ),
      },
      {
        name: "New CRM Clients",
        cell: (row) => (
          <ViewCell
            num={row?.Client}
            isTotal={row?.noExpand}
            onClick={() => openRelevantLeadsModal(row.client_leads)}
          />
        ),
      },
      {
        name: "Trials",
        cell: (row) => (
          <ViewCell
            num={row?.Trial}
            isTotal={row?.noExpand}
            onClick={() => openRelevantLeadsModal(row.trial_leads)}
          />
        ),
      },
      {
        name: "Demos",
        cell: (row) => (
          <ViewCell
            num={row?.Demo}
            isTotal={row?.noExpand}
            onClick={() => openRelevantLeadsModal(row.demo_leads)}
          />
        ),
      },
      { name: "New Users", selector: "new_users", center: true },
      { name: "New CRM Users", selector: "new_users_by_crm", center: true },
      {
        name: "monthly subscription value",
        selector: "monthly_subscription_value",
        center: true,
      },
      {
        name: "yearly subscription value",
        selector: "yearly_subscription_value",
        center: true,
      },
      {
        name: "price per user per month",
        selector: "price_per_user",
        center: true,
      },
    ],
    []
  );

  return showChart ? (
    <CRMReportLinearChart
      loading={loading}
      chartData={getCustomChartData(data, filters)}
    />
  ) : (
    <DataTable
      noHeader
      data={data}
      columns={columns}
      className="cards_table"
      progressPending={loading}
      progressComponent={<Loader />}
    />
  );
};

/**
 *
 * End of `Custom Report`
 *
 * Start of `View Cell`
 *
 */

export const ViewCell = ({ num, perc, onClick, isTotal, ...props }) => {
  const canView = props?.viewCompany
    ? HelperFns.checkPrivileges({
        privileges: [Privileges.VIEW_COMPANIES, Privileges.SUPER_PRIVILEGE],
      })
    : HelperFns.checkPrivileges({
        privileges: [Privileges.VIEW_LEADS, Privileges.SUPER_PRIVILEGE],
      });

  const perc_num = perc?.slice(0, -1);
  const isClickable = canView && !isTotal && +num && onClick;

  return (
    <Box
      className="d-flex gap-10"
      {...(isClickable && {
        onClick,
        sx: {
          "&:hover": {
            cursor: "pointer",
            ".num": {
              color: "#23aaeb",
              textDecoration: "underline",
            },
          },
        },
      })}
    >
      <b className="num" style={{ width: 30, textAlign: "center" }}>
        {num}
      </b>
      {perc ? (
        <span
          className={perc_num > 0 ? "green-color" : "red-color"}
          style={{ minWidth: 60, textAlign: "center" }}
        >
          ({perc})
        </span>
      ) : null}
    </Box>
  );
};

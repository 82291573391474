import React, { useEffect, useState } from "react";
import { InputForm } from "form-builder";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  setDataTableSortingAction,
  fetchAttProfilesListAction,
  deleteAttProfileAction,
  cloneAttProfileAction,
  onFormResetAction,
} from "../../Store/Actions";
import { useHistory } from "react-router-dom";
import Store from "../../Store";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import Loader from "../../Components/Loader";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import Constants from "../../Constants";
import Helpers, { showToast } from "../../Helpers/HelperFns";
import IconButtonWithTooltip, {
  CopyIconButton,
  RemoveIconButton,
  EditIconButton,
} from "../../Components/IconButtonWithTooltip";
import ReactShowMoreText from "react-show-more-text";
import ArchiveIcon from "@mui/icons-material/Archive";
import { archiveAttendanceProfileMutation } from "../../Graphql/mutation";
import { useMutation } from "@apollo/client";
import { RadioboxForm } from "form-builder";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import useAllowCostCenter from "../../Helpers/Hooks/useAllowCostCenter";

const dataTableRef = "attendanceProfilesList";

const AttendanceProfileListPage = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const attProfilesLimit =
    props.authPlanLimits?.find((feature) => feature?.id === "6")?.pivot
      ?.limits ?? Infinity;

  const [
    archiveAttendanceProfile,
    { loading: archiveAttendanceProfileLoading },
  ] = useMutation(archiveAttendanceProfileMutation);

  const handleArchive = (id) => {
    swal({
      width: "auto",
      title: t("are you sure"),
      text: t("archive this attendance profile"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        archiveAttendanceProfile({
          variables: {
            id,
          },
          onCompleted: () => {
            showToast("success", t("done"));
            props.fetchAttProfilesListAction({
              ref: dataTableRef,
              [dataTableRef]: props.data,
            });
          },
          onError: (err) => {
            showToast(
              "error",
              err?.graphQLErrors[0]?.extensions?.reason ?? err?.message
            );
          },
        });
      }
    });
  };

  const { allowCostCenter } = useAllowCostCenter();

  const columns = [
    {
      name: t("name"),
      wrap: true,
      selector: "name",
      sortable: true,
      grow: 1.1,
      cell: (row) => (
        <div dir={Helpers.isStringRTL(row.name) ? "rtl" : "ltr"}>
          {row.name}
        </div>
      ),
    },
    {
      name: t("start of week"),
      center: true,
      grow: 0.6,
      cell: (row) => (
        <span>
          {t(
            Constants.WeekDays.find((day) => day?.value === row?.week_start_day)
              ?.label
          )}
        </span>
      ),
    },
    {
      name: t("Days Off"),
      grow: 0.75,
      center: true,
      cell: (row) =>
        row.weekends
          .split(",")
          .map((day) => t(day.toLowerCase()))
          .join(", "),
    },
    {
      name: t("Normal Work timing"),
      center: true,
      cell: (row) => (
        <div
          dir={Helpers.isStringRTL(row?.activeWorkTiming?.name) ? "rtl" : "ltr"}
        >
          {row?.activeWorkTiming?.name}
        </div>
      ),
    },
    {
      name: t("1st half work timing"),
      center: true,
      grow: 1.3,
      cell: ({ activeConfiguration }) =>
        activeConfiguration?.allow_half_day ? (
          <div
            dir={
              Helpers.isStringRTL(
                activeConfiguration?.first_half_work_timing?.name
              )
                ? "rtl"
                : "ltr"
            }
          >
            {activeConfiguration?.first_half_work_timing?.name}
          </div>
        ) : (
          <span className="w-100 text-center pr-4"> _______ </span>
        ),
    },
    {
      name: t("2nd half work timing"),
      center: true,
      wrap: true,
      grow: 1.3,
      style: { textAlign: "center" },
      cell: ({ activeConfiguration }) =>
        activeConfiguration?.allow_half_day ? (
          <div
            dir={
              Helpers.isStringRTL(
                activeConfiguration?.second_half_work_timing?.name
              )
                ? "rtl"
                : "ltr"
            }
          >
            {activeConfiguration?.second_half_work_timing?.name}
          </div>
        ) : (
          <span className="w-100 text-center pr-4"> _______ </span>
        ),
    },
    {
      name: t("holidays"),
      center: true,
      cell: ({ default_office_holiday_profile, holiday_profile }) =>
        default_office_holiday_profile ? (
          t("office holidays")
        ) : (
          <ReactShowMoreText>
            {holiday_profile?.holidays?.map((day) => day.name).join(", ")}
          </ReactShowMoreText>
        ),
    },
    ...(allowCostCenter
      ? [
          {
            name: t("cost center"),
            center: true,
            cell: ({ activeConfiguration }) => (
              <ReactShowMoreText>
                {activeConfiguration?.cost_center?.name}
              </ReactShowMoreText>
            ),
          },
        ]
      : []),
    {
      name: "",
      selector: "action",
      center: true,
      cell: (row) => (
        <div className="cards_table_actions">
          <HasPrivileges
            reqireMain={[Privilages.ADD_CLONE_PROFILES]}
            allowBP
            avalibleOnExpire={false}
            altExpireChildren={<CopyIconButton />}
          >
            <CopyIconButton
              onClick={() => Store.dispatch(cloneAttProfileAction(row.id))}
            />
          </HasPrivileges>

          <HasPrivileges
            reqireMain={[Privilages.EDIT_ATTENDANCE_PROFILES]}
            allowBP
            avalibleOnExpire={false}
            altExpireChildren={<IconButtonWithTooltip />}
          >
            <IconButtonWithTooltip
              onClick={() => handleArchive(row?.id)}
              label={row?.archive ? "uhnarchive" : "archive"}
              icon={row?.archive ? <UnarchiveIcon /> : <ArchiveIcon />}
            />
          </HasPrivileges>

          <HasPrivileges
            reqireMain={[Privilages.EDIT_ATTENDANCE_PROFILES]}
            altExpireChildren={<EditIconButton />}
            avalibleOnExpire={false}
            allowBP
          >
            <EditIconButton href={`att-profile/edit/${row.id}`} />
          </HasPrivileges>

          <HasPrivileges
            reqireMain={[Privilages.DELETE_ATTENDANCE_PROFILES]}
            allowBP
            avalibleOnExpire={false}
            altExpireChildren={<RemoveIconButton />}
          >
            <RemoveIconButton
              onClick={() => {
                swal({
                  title: t("are you sure"),
                  text: t("defaut_warning_messsage"),
                  icon: "warning",
                  className: "swal-warning-style",
                  dangerMode: true,
                  buttons: [t("Cancel"), t("OK")],
                }).then((willDelete) => {
                  if (willDelete) {
                    Store.dispatch(deleteAttProfileAction(row.id));
                  }
                });
              }}
            />
          </HasPrivileges>
        </div>
      ),
      ignoreRowClick: true,
    },
  ];

  useEffect(() => {
    props.fetchAttProfilesListAction({
      ref: dataTableRef,
      [dataTableRef]: props.data,
    });
    return () => {
      props.onFormResetAction(dataTableRef);
    };
  }, []);

  const handleSorting = (field, dir) => {
    props.fetchAttProfilesListAction({
      [dataTableRef]: {
        ...props.data,
        pagination: {
          ...props.data.pagination,
          currentPage: 1,
        },
        sorting: {
          dir,
          key: field.selector,
        },
      },
      ref: dataTableRef,
    });
    props.setDataTableSortingAction(dataTableRef, field.selector, dir);
  };

  const handleFilter = () => {
    props.fetchAttProfilesListAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data,
        pagination: {
          ...props.data.pagination,
          currentPage: 1,
        },
      },
    });
  };

  const useDidFilterUpdate = useDidUpdateEffect(handleFilter, [
    props.data.filter,
    props.data.only_archive,
  ]);

  const handlePaginate = (page = props.data.pagination.currentPage) => {
    props.fetchAttProfilesListAction({
      [dataTableRef]: {
        ...props.data,
        pagination: {
          ...props.data.pagination,
          currentPage: page,
        },
      },
      ref: dataTableRef,
    });
  };

  return (
    <>
      {archiveAttendanceProfileLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <div className="mt-3 content attendance-profile-list">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="col-9 px-0">
            <InputForm
              name="filter"
              formName={dataTableRef}
              placeholder={t("search")}
              containerStyle="w-100"
              inputStyle="search_input"
              inputContainerStyle="search_container"
              icon="search"
            />
          </div>
          <div className="my-3 row px-0 align-items-center w-auto mx-3 flex-nowrap ">
            <RadioboxForm
              formName={dataTableRef}
              options={[
                { value: false, label: "Unarchived" },
                { value: true, label: "Archived" },
              ]}
              name="only_archive"
              type="radio"
              containerStyle="my-2 my-lg-0 flex-nowrap"
              labelStyle="label-style"
              optionsContainerStyle="optionsContainerStyle  flex-nowrap"
              optionItemStyle="optionItemStyle"
              optionInputStyle=" "
              optionLabelStyle="optionLabelStyle"
            />
          </div>

          <HasPrivileges
            reqireMain={[Privilages.ADD_CLONE_PROFILES]}
            allowBP
            avalibleOnExpire={false}
            altExpireChildren={
              <div className="pl-3 text-right">
                <button
                  className="btn add_new_btn_style"
                  type="button"
                  disabled
                >
                  <FontAwesomeIcon className="mr-2" icon={faPlus} />
                  <span>{t("add new")}</span>
                </button>
              </div>
            }
          >
            <div className="pl-3 text-right">
              <Tooltip
                disableFocusListener
                disableTouchListener
                title={
                  props.data?.total >= attProfilesLimit
                    ? t(
                        "you have reached the maximum number of Attendance Profiles"
                      )
                    : ""
                }
              >
                <button
                  className="btn add_new_btn_style"
                  type="button"
                  onClick={() =>
                    props.data?.total >= attProfilesLimit
                      ? null
                      : history.push(`att-profile/new`)
                  }
                  disabled={props.data?.total >= attProfilesLimit}
                >
                  <FontAwesomeIcon className="mr-2" icon={faPlus} />
                  <span className="text-nowrap ">{t("add new")}</span>
                </button>
              </Tooltip>
            </div>
          </HasPrivileges>
        </div>

        <DataTable
          noDataComponent={<div className="p-4"> {t("no_records")} </div>}
          className="cards_table"
          columns={columns}
          data={props.data.data}
          noHeader
          persistTableHead
          sortServer
          responsive
          pointerOnHover
          highlightOnHover
          onSort={handleSorting}
          customStyles={{
            tableWrapper: { style: { width: "1650px", minWidth: "100%" } },
          }}
          defaultSortAsc={props.data.sorting.dir === "asc"}
          defaultSortField={props.data.sorting.key}
          paginationComponent={() => (
            <Pagination
              tableRef={dataTableRef}
              styleWraper=" "
              onPaginate={handlePaginate}
            />
          )}
          pagination={true}
          paginationServer={true}
          progressPending={props.data.isLoading}
          progressComponent={<Loader />}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.super[dataTableRef],
    authPlanLimits:
      state.auth?.userProfile?.company?.activeSubscription?.plan?.features,
  };
};

export default connect(mapStateToProps, {
  setDataTableSortingAction,
  fetchAttProfilesListAction,
  onFormResetAction,
})(AttendanceProfileListPage);

import React from "react";

import MainModal from "../MainModal";

const PreviewPaymentLogsModal = ({ data, onClose }) => {
  return (
    <MainModal
      isOpen
      modalTitle="Payment Logs"
      toggle={onClose}
      hasModalFooter={false}
    >
      {data?.logs?.length ? (
        data?.logs.map((log, i) => <p key={i}>{log}</p>)
      ) : (
        <p>no logs added yet</p>
      )}
    </MainModal>
  );
};

export default PreviewPaymentLogsModal;

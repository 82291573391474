import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";

import {
  subStatusOptions,
  leadStatusOptions,
  dateTimeListFormat,
  leadSourcesOptions,
} from "../../Constants/CRM";
import {
  removeLeadMutation,
  assignLeadsMutation,
  unassignLeadsMutation,
} from "../../Graphql/mutation";
import HelperFns, {
  showToast,
  buildOption,
  formatOptions,
  openSwalConfirm,
  formatSendingInputs,
} from "../../Helpers/HelperFns";
import {
  leadsListQuery,
  exportLeadsQuery,
  leadsListOptionsQuery,
} from "../../Graphql/query";
import moment from "moment";
import Privileges from "../../Constants/Privilages";

import {
  BSelect,
  InputForm,
  RadioboxForm,
  DateTimePickerForm,
} from "form-builder";
import {
  LeadModal,
  CommentModal,
  ActivityModal,
  ReminderModal,
  LeadsStatusModal,
  ImportLeadsModal,
  SetLeadsTagsModal,
  SetLeadsAgentModal,
  ColumnsVisibilityModal,
  ViewRelatedActivitiesModal,
} from "../../Components/CRMFormModals";
import IconButtonWithTooltip, {
  EditIconButton,
  BarsIconButton,
  RemoveIconButton,
  PreviewIconButton,
} from "../../Components/IconButtonWithTooltip";
import {
  CommentCell,
  LeadNameCell,
  FiltersChips,
  CRMTagsSelect,
  ContactTooltip,
  LeadAgentSelect,
} from "../../Components/CRM";
import {
  Tag,
  Event,
  Delete,
  Person,
  PersonAdd,
  PriorityHigh,
  NotificationAdd,
  InsertDriveFile,
} from "@mui/icons-material";
import {
  AddButton,
  ColorButton,
  ExportButton,
  FilterButton,
} from "../../Components/Buttons";
import Loader from "../../Components/Loader";
import { SvgIcon, Tooltip } from "@mui/material";
import DataTable from "react-data-table-component";
import ReactShowMoreText from "react-show-more-text";
import Pagination from "../../Components/Pagination";
import FiltersDrawer from "../../Components/FiltersDrawer";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const modalInitState = { isOpen: false, data: null };
const modals = [
  { name: "lead", Modal: LeadModal },
  { name: "comment", Modal: CommentModal },
  { name: "activity", Modal: ActivityModal },
  { name: "reminder", Modal: ReminderModal },
  { name: "setTags", Modal: SetLeadsTagsModal },
  { name: "setAgent", Modal: SetLeadsAgentModal },
  { name: "leadsStatus", Modal: LeadsStatusModal },
  { name: "importLeads", Modal: ImportLeadsModal },
  { name: "columnsVisibility", Modal: ColumnsVisibilityModal },
  { name: "viewRelatedActivities", Modal: ViewRelatedActivitiesModal },
];
const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};

const handleSendSourceCampaign = (source, campaign) => {
  if (!source && !campaign) return undefined;

  if (source === "Facebook") {
    return [source, ...(campaign ? [campaign] : [])];
  } else {
    return !source ? ["Facebook", ...(campaign ? [campaign] : [])] : [source];
  }
};
const sendFilters = ({ orderBy, ...filters }, others) => {
  return {
    page: 1,
    orderBy: orderBy || undefined,
    input: {
      ...formatSendingInputs({
        ...filters,
        campaign: undefined,
        assigned_to: others?.isAdminProfile
          ? [others?.adminID]
          : filters?.assigned_to,
      }),
      name: filters?.name,
      source: handleSendSourceCampaign(
        filters?.source?.id,
        filters?.campaign?.id
      ),
    },
  };
};
const formatFiltersChips = (filters, others) => {
  return {
    ...filters,
    type: undefined,
    page: undefined,
    first: undefined,
    lead_ids: undefined,
    assigned_to: others?.isAdminProfile ? undefined : filters.assigned_to,
  };
};

const LeadsList = ({ adminID, relevantLeadsIds }) => {
  const { t } = useTranslation();
  const isAdminProfile = Boolean(adminID);
  const filtersInitState = {
    to: "",
    from: "",
    name: "",
    source: [],
    tagIds: [],
    status: [],
    minNo: null,
    maxNo: null,
    type: "All",
    country: null,
    campaign: null,
    sub_status: null,
    orderBy: undefined,
    last_activity_at: "",
    lead_ids: relevantLeadsIds,
    assigned_to: isAdminProfile ? [adminID] : [],
  };

  // Local State
  const [leads, setLeads] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filtersChips, setFiltersChips] = useState({});
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState(filtersInitState);
  const [pagination, setPagination] = useState(paginationInitState);
  const [isClearSelectedRows, setIsClearSelectedRows] = useState(false);
  const [disableSubmitFilters, setDisableSubmitFilters] = useState(true);
  const [options, setOptions] = useState({
    tags: [],
    countries: [],
    campaigns: [],
  });
  const [modalsState, setModalsState] = useState(
    modals.map(({ name }) => ({ name, ...modalInitState }))
  );

  // Server State
  const [assign, { loading: assignLoading }] = useMutation(assignLeadsMutation);
  const [unassign, { loading: unassignLoading }] = useMutation(
    unassignLeadsMutation
  );
  const [removeLead, { loading: removeLoading }] =
    useMutation(removeLeadMutation);
  const [exportLeadsList, { loading: exportLoading }] = useLazyQuery(
    exportLeadsQuery,
    {
      fetchPolicy: "network-only",
      onCompleted: (response) => {
        if (
          response?.exportLeads &&
          response?.exportLeads?.status === "success"
        ) {
          HelperFns.downloadFile(response?.exportLeads?.file);
        } else {
          showToast("error", response?.exportLeads?.message);
        }
      },
      onError: (error) => {
        showToast("error", error?.message);
      },
    }
  );
  const { loading: isOptionsLoading } = useQuery(leadsListOptionsQuery, {
    onCompleted: ({ countries }) => {
      setOptions((prev) => ({
        ...prev,
        countries: countries?.length ? formatOptions(countries) : [],
      }));
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });
  const [getListData, { loading: isListLoading }] = useLazyQuery(
    leadsListQuery,
    {
      onCompleted: ({ leads, tags, campaigns }) => {
        setLeads(leads?.data || []);
        setFiltersChips(formatFiltersChips(filters, { isAdminProfile }));
        setPagination(leads?.paginatorInfo || paginationInitState);
        setOptions((prev) => ({
          ...prev,
          tags: tags?.data || [],
          campaigns: campaigns || [],
        }));
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message
        );
      },
    }
  );

  // Reducer State
  const user_id = useSelector((state) => state.user.userProfile.id);
  const columnsVisibility = useSelector(
    (state) => state?.dashboardUi?.crmColumnsVisibility
  );

  // Constants
  const columns = [
    {
      width: "60px",
      compact: true,
      cell: (row) => (
        <div>
          <HasPrivileges
            reqireMain={[
              Privileges.SUPER_PRIVILEGE,
              Privileges.ADD_EDIT_ACTIVITIES,
            ]}
          >
            <IconButtonWithTooltip
              label="add activity"
              icon={<Event className="text-primary" sx={{ fontSize: 16 }} />}
              onClick={() =>
                handleOpenModal("activity", {
                  id: row?.id,
                  name: row?.name,
                  contacts: row?.contacts,
                })
              }
            />
          </HasPrivileges>
          <HasPrivileges
            reqireMain={[
              Privileges.SUPER_PRIVILEGE,
              Privileges.ADD_EDIT_REMINDERS,
            ]}
          >
            <IconButtonWithTooltip
              label="add reminder"
              icon={
                <NotificationAdd className="blue-color" sx={{ fontSize: 16 }} />
              }
              onClick={() =>
                handleOpenModal("reminder", {
                  id: row.id,
                  name: row.name,
                  contacts: row?.contacts,
                })
              }
            />
          </HasPrivileges>
          <HasPrivileges
            reqireMain={[Privileges.ADD_EDIT_LEADS, Privileges.SUPER_PRIVILEGE]}
          >
            <EditIconButton
              onClick={() => handleOpenModal("lead", { id: row.id })}
              iconProps={{ sx: { fontSize: 16 } }}
            />
          </HasPrivileges>
          <HasPrivileges
            reqireMain={[Privileges.DELETE_LEADS, Privileges.SUPER_PRIVILEGE]}
          >
            <RemoveIconButton
              onClick={() => handleRemoveLead(row)}
              iconProps={{ sx: { fontSize: 16 } }}
            />
          </HasPrivileges>
        </div>
      ),
    },
    {
      grow: 2.5,
      compact: true,
      name: t("name"),
      sortable: true,
      sortField: "NAME",
      omit: columnsVisibility ? !columnsVisibility?.name : false,
      cell: (lead) => <LeadNameCell lead={lead} />,
    },
    {
      grow: 1.6,
      // compact: true,
      name: t("contacts"),
      omit: columnsVisibility ? !columnsVisibility?.contacts : false,
      cell: ({ contacts = [] }) => <div>{contacts.map(ContactTooltip)}</div>,
    },
    {
      grow: 1.4,
      compact: true,
      name: t("added on"),
      sortable: true,
      sortField: "CREATED_AT",
      omit: columnsVisibility ? !columnsVisibility?.["added on"] : false,
      cell: ({ date }) => moment(date).format(dateTimeListFormat),
    },
    {
      grow: 1.3,
      name: t("country"),
      selector: "country",
      omit: columnsVisibility ? !columnsVisibility?.country : false,
    },
    {
      name: t("comments"),
      compact: true,
      omit: columnsVisibility ? !columnsVisibility?.comments : false,
      cell: ({ id, comments = [] }) => (
        <HasPrivileges
          reqireMain={[
            Privileges.SUPER_PRIVILEGE,
            Privileges.VIEW_COMMENTS_ON_LEADS,
          ]}
        >
          <CommentCell
            comments={comments}
            onClick={() =>
              handleOpenModal("comment", { id, comments, type: "Lead" })
            }
          />
        </HasPrivileges>
      ),
    },
    {
      name: t("employees"),
      compact: true,
      omit: columnsVisibility ? !columnsVisibility?.employees : false,
      selector: "eps_no",
      center: true,
    },
    {
      name: t("industry"),
      compact: true,
      omit: columnsVisibility ? !columnsVisibility?.industry : false,
      cell: ({ industry }) => industry,
    },
    {
      name: t("source"),
      compact: true,
      omit: columnsVisibility ? !columnsVisibility?.source : false,
      cell: ({ source }) => {
        let src = "";
        if (source?.length) {
          src =
            source?.length > 1
              ? source?.[0] + " - " + source?.[1]
              : source?.[0];
        }

        return (
          <Tooltip title={src}>
            <span className="text-ellipsis">{src}</span>
          </Tooltip>
        );
      },
    },
    {
      name: t("assigned to"),
      compact: true,
      sortable: true,
      sortField: "LEAD_ASSIGN_DATE",
      omit:
        isAdminProfile ||
        (columnsVisibility ? !columnsVisibility?.["assigned to"] : false),
      cell: (row) =>
        row?.assignedTo?.name ? (
          <div>
            <div>
              <span className="d-block mb-1">{row?.assignedTo?.name}</span>
              {row?.lead_assign_date
                ? moment(row?.lead_assign_date).format(dateTimeListFormat)
                : null}
            </div>
            <HasPrivileges
              reqireMain={[Privileges.ASSIGN_LEADS, Privileges.SUPER_PRIVILEGE]}
            >
              <IconButtonWithTooltip
                label="unassign"
                onClick={() => handleUnassignLead(row)}
                icon={
                  <SvgIcon sx={{ fontSize: 18 }}>
                    <FontAwesomeIcon icon="fas fa-user-times" color="#dc3545" />
                  </SvgIcon>
                }
              />
            </HasPrivileges>
          </div>
        ) : (
          <HasPrivileges
            reqireMain={[
              Privileges.ASSIGN_LEADS,
              Privileges.ASSIGN_LEADS_TO_OTHER_AGENTS,
              Privileges.SUPER_PRIVILEGE,
            ]}
          >
            <div className="px-4">
              <IconButtonWithTooltip
                label="assign"
                icon={<PersonAdd fontSize="small" className="blue-color" />}
                onClick={() =>
                  !HelperFns.checkPrivileges({
                    privileges: [
                      Privileges.SUPER_PRIVILEGE,
                      Privileges.ASSIGN_LEADS_TO_OTHER_AGENTS,
                    ],
                  })
                    ? handleAssignToAuth(row)
                    : handleOpenModal("setAgent", {
                        selectedRows: [{ name: row.name, id: row.id }],
                      })
                }
              />
            </div>
          </HasPrivileges>
        ),
    },
    {
      name: t("tags"),
      compact: true,
      omit: columnsVisibility ? !columnsVisibility?.tags : false,
      cell: ({ tags = [] }) => (
        <div>
          {tags?.map((tag, i) => (
            <b style={{ color: tag?.color }}>
              {tag?.name}
              {i + 1 < tags.length ? ", " : null}
            </b>
          ))}
        </div>
      ),
    },
    {
      name: t("notes"),
      compact: true,
      omit: columnsVisibility ? !columnsVisibility?.notes : false,
      cell: ({ notes }) => (
        <ReactShowMoreText lines={2}>{notes}</ReactShowMoreText>
      ),
    },
    {
      grow: 1.4,
      compact: true,
      name: t("last activity date"),
      omit: columnsVisibility
        ? !columnsVisibility?.["last activity date"]
        : false,
      sortable: true,
      sortField: "latestActivityAt",
      cell: ({ latestActivity = {} }) =>
        latestActivity
          ? moment(latestActivity?.date).format(dateTimeListFormat)
          : null,
    },
    {
      name: t("related activities"),
      omit: columnsVisibility
        ? !columnsVisibility?.["view related activities"]
        : false,
      cell: ({ activities }) => (
        <PreviewIconButton
          onClick={() =>
            handleOpenModal("viewRelatedActivities", { activities })
          }
        />
      ),
    },
    {
      grow: 1.4,
      compact: true,
      name: t("next Reminder"),
      omit: columnsVisibility ? !columnsVisibility?.["next reminder"] : false,
      cell: ({ nextReminder = {} }) => {
        const type = JSON.parse(nextReminder?.type || "{}")?.name;
        return nextReminder ? (
          <div>
            <span className="d-block">{type}</span>
            <time dateTime={nextReminder?.due_date}>
              {moment(nextReminder?.due_date).format(dateTimeListFormat)}
            </time>
          </div>
        ) : (
          ""
        );
      },
    },
    {
      name: t("status"),
      compact: true,
      omit: columnsVisibility ? !columnsVisibility?.status : false,
      cell: ({ latestActivity = {} }) =>
        latestActivity ? (
          <div>
            <strong className="d-block">
              {JSON.parse(latestActivity?.status)[0]}
            </strong>
            {JSON.parse(latestActivity?.status)[1]}
          </div>
        ) : (
          <strong>Fresh Lead</strong>
        ),
    },
  ];

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    handleResetSelectedRows();
  }, [pagination.currentPage, JSON.stringify(filters)]);

  React.useEffect(() => {
    refetchList();
  }, []);

  /* ↓ Helpers ↓ */

  const refetchList = (vars, variables) => {
    getListData({
      variables: {
        ...sendFilters(variables || filters, { isAdminProfile, adminID }),
        first: pagination.perPage,
        lead_ids: relevantLeadsIds,
        ...vars,
      },
    });
  };

  const closeDrawer = () => () => {
    setShowFilters(() => false);
    setDisableSubmitFilters(() => true);
  };

  const handleSubmitFilter = () => {
    refetchList();
    closeDrawer()();
  };

  const handleResetFilter = () => {
    handleFilter(filtersInitState);
  };

  const handleDeleteFilter = (key) => {
    const newFilters = { ...filters, [key]: undefined };
    setFilters(newFilters);
    refetchList({}, newFilters);
    setFiltersChips((prev) => ({ ...prev, [key]: undefined }));
  };

  const handleFilter = (newFiltersState) => {
    setFilters(newFiltersState);
    setDisableSubmitFilters(false);
  };

  const handleSelectAllStatus = () => {
    handleFilter((prev) => ({
      ...prev,
      status: formatOptions(["Fresh Lead", ...leadStatusOptions]),
    }));
  };

  const handleExportLeadsList = () => {
    exportLeadsList({
      variables: {
        input: {
          filter_data: sendFilters(filters, { isAdminProfile, adminID })?.input,
        },
      },
    });
  };

  const handleSelectRows = ({ selectedRows = [] }) => {
    setSelectedRows(selectedRows.map((r) => ({ id: r?.id, name: r?.name })));
  };

  const handleResetSelectedRows = () => {
    setSelectedRows([]);
    setIsClearSelectedRows(true);
    setTimeout(() => {
      setIsClearSelectedRows(false);
    }, 1);
  };

  const handleSort = (row, order) => {
    handleFilter((prev) => ({
      ...prev,
      orderBy: [
        row?.sortField === "latestActivityAt"
          ? {
              order: order.toUpperCase(),
              activities: {
                column: "DATE",
                aggregate: "MAX",
              },
            }
          : { column: row?.sortField, order: order.toUpperCase() },
      ],
    }));
  };

  const handleOpenModal = (name, data = null) => {
    const modalState = { name, data, isOpen: true };

    setModalsState((prev) =>
      prev.map((p) => (p.name === name ? modalState : p))
    );
  };

  const handleCloseModal = (name) => {
    setModalsState((prev) =>
      prev.map((p) => (p.name === name ? { name, ...modalInitState } : p))
    );
  };

  const handleFilters = (e) => {
    handleFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSelectFilters = (val, { name }) => {
    handleFilter((prev) => ({
      ...prev,
      [name]: val,
      ...(name === "status" && { sub_status: null }),
    }));
  };

  const handleDateFiltersChange = (name, val) => {
    handleFilter((prev) => ({ ...prev, [name]: val }));
  };

  const handleSelectPerPage = (perPage) => {
    refetchList({ first: perPage });
  };

  const handlePaginate = (page = pagination.currentPage) => {
    refetchList({ page });
  };

  const handleAssignToAuth = (data) => {
    openSwalConfirm(
      (isConfirmed) =>
        isConfirmed &&
        assign({
          variables: { lead_ids: [data.id], user_id },
          onCompleted: () => {
            refetchList();
          },
          onError: (err) => {
            showToast(
              "error",
              err?.graphQLErrors?.[0]?.extensions?.reason ||
                err?.graphQLErrors?.[0]?.message ||
                err?.message
            );
          },
        }),
      {
        text: "You are going to assign this lead to yourself.",
      }
    );
  };

  const handleUnassignLead = (data) => {
    openSwalConfirm(
      (isConfirmed) =>
        isConfirmed &&
        unassign({
          variables: { lead_ids: [data.id] },
          onCompleted: () => {
            refetchList();
          },
          onError: (err) => {
            showToast(
              "error",
              err?.graphQLErrors?.[0]?.extensions?.reason ||
                err?.graphQLErrors?.[0]?.message ||
                err?.message
            );
          },
        })
    );
  };

  const handleRemoveLead = (data) => {
    openSwalConfirm((willDelete) => {
      if (willDelete) {
        removeLead({
          variables: { ids: [data.id] },
          onCompleted: () => {
            refetchList();
          },
          onError: (err) => {
            showToast(
              "error",
              err?.graphQLErrors?.[0]?.extensions?.reason ||
                err?.graphQLErrors?.[0]?.message ||
                err?.message
            );
          },
        });
      }
    });
  };

  const handleRemoveLeads = () => {
    openSwalConfirm((willDelete) => {
      if (willDelete) {
        removeLead({
          variables: { ids: selectedRows?.map((row) => row?.id) },
          onCompleted: () => {
            refetchList();
          },
          onError: (err) => {
            showToast(
              "error",
              err?.graphQLErrors?.[0]?.extensions?.reason ||
                err?.graphQLErrors?.[0]?.message ||
                err?.message
            );
          },
        });
      }
    });
  };

  return (
    <>
      {assignLoading || unassignLoading || removeLoading || exportLoading ? (
        <Loader fixed />
      ) : null}

      {/* Actions */}
      <div className="d-flex flex-wrap align-items-center justify-content-end gap-10 pt-4 mb-2">
        <FilterButton onClick={() => setShowFilters(true)} />
        {selectedRows.length ? (
          <>
            <HasPrivileges
              reqireMain={[
                Privileges.ADD_EDIT_LEADS,
                Privileges.SUPER_PRIVILEGE,
              ]}
            >
              <ColorButton
                icon={<Tag />}
                color="#2764ac"
                variant="outlined"
                label="set tags"
                onClick={() =>
                  handleOpenModal("setTags", {
                    leads: selectedRows,
                    leadsOptions: leads,
                    tagsOptions: options.tags,
                  })
                }
              />
            </HasPrivileges>
            <HasPrivileges
              reqireMain={[Privileges.DELETE_LEADS, Privileges.SUPER_PRIVILEGE]}
            >
              <ColorButton
                color="#ff6a6a"
                variant="outlined"
                label="Remove Leads"
                onClick={handleRemoveLeads}
                icon={<Delete />}
              />
            </HasPrivileges>
            <HasPrivileges
              reqireMain={[
                Privileges.SUPER_PRIVILEGE,
                Privileges.ASSIGN_LEADS_TO_OTHER_AGENTS,
              ]}
            >
              <ColorButton
                color="#009688"
                variant="outlined"
                label="Change Status"
                icon={<PriorityHigh />}
                onClick={() =>
                  handleOpenModal("leadsStatus", {
                    leads: selectedRows,
                    leadsOptions: leads,
                  })
                }
              />
            </HasPrivileges>
          </>
        ) : null}
        <HasPrivileges
          reqireMain={[
            Privileges.SUPER_PRIVILEGE,
            Privileges.ASSIGN_LEADS_TO_OTHER_AGENTS,
          ]}
        >
          <ColorButton
            color="#2764ac"
            label="set agent"
            variant="outlined"
            icon={<Person />}
            onClick={() => handleOpenModal("setAgent", { selectedRows })}
          />
        </HasPrivileges>
        <HasPrivileges
          reqireMain={[Privileges.ADD_EDIT_LEADS, Privileges.SUPER_PRIVILEGE]}
        >
          <AddButton label="lead" onClick={() => handleOpenModal("lead")} />
        </HasPrivileges>
        <HasPrivileges
          reqireMain={[Privileges.ADD_EDIT_LEADS, Privileges.SUPER_PRIVILEGE]}
        >
          <ColorButton
            color="#2764ac"
            label="import leads"
            icon={<InsertDriveFile />}
            onClick={() => handleOpenModal("importLeads")}
          />
          <ExportButton onClick={handleExportLeadsList} />
          <BarsIconButton
            sx={{ gridColumnStart: 6, justifySelf: "end" }}
            onClick={() => handleOpenModal("columnsVisibility")}
          />
        </HasPrivileges>
      </div>

      {/* Filters Chips */}
      <div className="d-flex flex-fill gap-10 flex-wrap mb-2">
        <FiltersChips
          filters={filtersChips}
          handleDeleteFilter={handleDeleteFilter}
        />
      </div>

      {/* Filters */}
      <FiltersDrawer
        skipCloseSubmit
        toggleDrawer={closeDrawer}
        isFilterDrawerOpen={showFilters}
        onFilterReset={handleResetFilter}
        onFilterSubmit={handleSubmitFilter}
        disableSubmitFilters={disableSubmitFilters}
        render={() => (
          <>
            <InputForm
              name="name"
              icon="search"
              value={filters.name}
              inputStyle="border-0"
              onChange={handleFilters}
              placeholder={t("Search")}
              inputContainerStyle="w-100"
              rootStyle="boxContainer shadow-sm flex-fill px-2 m-0 py-0"
            />
            <BSelect
              isClearable
              icon="city"
              name="country"
              label="country"
              placeholder={t("select country")}
              value={filters.country}
              onChange={handleSelectFilters}
              inputContainerStyle="w-100"
              isLoading={isOptionsLoading}
              options={options?.countries}
              containerStyle=" "
            />
            <div className="">
              <div className="d-flex justify-content-between">
                <span>status</span>
                <button className="link-btn" onClick={handleSelectAllStatus}>
                  Select All
                </button>
              </div>
              <BSelect
                isMulti
                isClearable
                name="status"
                icon="document"
                placeholder={t("select status")}
                value={filters?.status || []}
                onChange={handleSelectFilters}
                inputContainerStyle="w-100"
                options={formatOptions(["Fresh Lead", ...leadStatusOptions])}
                containerStyle=" "
              />
            </div>
            <BSelect
              isClearable
              name="sub_status"
              label="sub status"
              icon="document"
              placeholder={t("select sub status")}
              value={filters.sub_status}
              onChange={handleSelectFilters}
              inputContainerStyle="w-100"
              options={formatOptions(subStatusOptions[filters.status?.[0]?.id])}
              containerStyle=" "
              isDisabled={filters?.status?.length > 1}
            />
            {isAdminProfile ? null : (
              <LeadAgentSelect
                isMulti
                isClearable
                name="assigned_to"
                label="assigned to"
                placeholder={t("assigned to")}
                value={filters.assigned_to}
                onChange={handleSelectFilters}
                inputContainerStyle="w-100"
                containerStyle=" "
              />
            )}
            <DateTimePickerForm
              hasIcon
              isClearable
              label="from"
              labelStyle="w-100"
              value={filters.from}
              rootStyle="flex-fill"
              datePickerContainer="w-100"
              onChange={(val) => handleDateFiltersChange("from", val)}
            />
            <DateTimePickerForm
              hasIcon
              label="to"
              isClearable
              labelStyle="w-100"
              value={filters.to}
              rootStyle="flex-fill"
              datePickerContainer="w-100"
              onChange={(val) => handleDateFiltersChange("to", val)}
            />
            <BSelect
              isClearable
              icon="employee"
              name="source"
              label="source"
              placeholder={t("source")}
              value={filters.source}
              onChange={handleSelectFilters}
              inputContainerStyle="w-100"
              isLoading={isOptionsLoading}
              options={formatOptions(leadSourcesOptions)}
              containerStyle=" "
            />
            <BSelect
              isClearable
              name="campaign"
              label="campaign"
              placeholder={t("campaign")}
              value={
                filters.source && filters.source?.id !== "Facebook"
                  ? null
                  : filters.campaign
              }
              isLoading={isListLoading}
              onChange={handleSelectFilters}
              inputContainerStyle="w-100"
              options={formatOptions(options.campaigns)}
              isDisabled={filters.source && filters.source?.id !== "Facebook"}
              containerStyle=" "
            />
            <CRMTagsSelect
              isMulti
              name="tagIds"
              label="tags"
              rootStyle="w-100"
              value={filters.tagIds}
              onChange={handleSelectFilters}
              options={options?.tags}
              isLoading={isListLoading}
              inputContainerStyle="w-100"
              containerStyle=" "
            />
            <InputForm
              name="minNo"
              label="Min. No. of employees"
              containerStyle=" "
              value={filters.minNo}
              onChange={handleFilters}
              placeholder={t("min emp")}
            />
            <InputForm
              name="maxNo"
              label="Max. No. of employees"
              containerStyle=" "
              value={filters.maxNo}
              onChange={handleFilters}
              placeholder={t("max emp")}
            />
            <HasPrivileges
              reqireMain={[
                Privileges.SUPER_PRIVILEGE,
                Privileges.VIEW_OTHER_LEADS,
                Privileges.VIEW_UNASSIGNED_LEADS,
              ]}
            >
              <RadioboxForm
                name="type"
                value={filters.type}
                options={[
                  {
                    label: "all",
                    value: "All",
                    optProps: { onChange: handleFilters },
                  },
                  {
                    label: "Assigned",
                    value: "Assigned",
                    optProps: { onChange: handleFilters },
                  },
                  {
                    label: "Unassigned",
                    value: "Unassigned",
                    optProps: { onChange: handleFilters },
                  },
                ]}
                optionInputStyle=" "
                containerStyle="mb-0"
                optionItemStyle="d-inline-block mx-2"
                optionsContainerStyle="d-flex"
              />
            </HasPrivileges>
          </>
        )}
      />

      {/* List */}
      <DataTable
        noHeader
        sortServer
        data={leads}
        selectableRows
        columns={columns}
        onSort={handleSort}
        className="cards_table"
        progressComponent={<Loader />}
        progressPending={isListLoading}
        clearSelectedRows={isClearSelectedRows}
        onSelectedRowsChange={handleSelectRows}
        pagination
        paginationServer
        paginationComponent={() => (
          <div className="d-flex gap-10 justify-content-end">
            <BSelect
              value={buildOption(pagination.perPage)}
              onChange={(val) => handleSelectPerPage(val?.id)}
              inputContainerStyle="w-100"
              rootStyle="min-w-85"
              containerStyle=" "
              options={formatOptions([20, 50, 100, 150, 200])}
            />
            <Pagination
              styleWraper=""
              onPaginate={handlePaginate}
              customPaginator={pagination}
            />
          </div>
        )}
      />

      {/* Modals */}
      {modals.map(({ name, Modal }, i) =>
        modalsState[i].isOpen ? (
          <Modal
            key={name}
            isLeadList
            refetchList={refetchList}
            data={modalsState[i]?.data}
            onClose={() => handleCloseModal(name)}
            {...(["leadsStatus", "setTags", "setAgent"].includes(name) && {
              onResetSelectedRows: handleResetSelectedRows,
            })}
            {...(name === "comment" && {
              canCommentPrivileges: [
                Privileges.SUPER_PRIVILEGE,
                Privileges.COMMENT_ON_LEADS,
              ],
            })}
          />
        ) : null
      )}
    </>
  );
};

export default LeadsList;

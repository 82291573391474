import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { addNewWorkTimeConfigAction } from "../../../Store/Actions";

import Loader from "../../../Components/Loader";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NewAttWorkTimeSection from "../../../Components/AttProfileSections/NewAttWorkTimeSection";
import AttWorkTimeSectionPreview from "../../../Components/AttProfileSections/AttWorkTimeSectionPreview";

const EditAttSignInSection = ({ costCentersOptions = [], ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const serverValidation = useSelector(
    (state) => state?.super?.[props?.formProps?.formServerValidation]
  );

  // Local State
  const [editedSignInConfig, setEditedSignInConfig] = useState([]);

  // Reducer State
  const isViewWorkTimeLoading = useSelector(
    (state) => state.super.isViewWorkTimeLoading
  );
  const worktimeConfigs = useSelector(
    (state) => state.super.attendaceProfile.attendance_profile_work_timing_input
  );

  /* ↓ Helpers ↓ */

  const startEditSignInConfig = (id) => {
    setEditedSignInConfig([...editedSignInConfig, id]);
  };

  const endEditSignInConfig = (itemId) => {
    setEditedSignInConfig(editedSignInConfig.filter((id) => id !== itemId));
  };

  const addNewWorkTimeConfig = () => {
    dispatch(addNewWorkTimeConfigAction(props?.formProps?.formName));
  };

  return (
    <>
      {isViewWorkTimeLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <div style={{ display: props.show }} className="pt-lg-4 pt-1 pb-1">
        <div className="d-flex align-items-baseline">
          <h4 className="att_profile_header">{t("sign in configuration")}</h4>

          <button
            className="btn add_new_btn_style"
            type="button"
            onClick={addNewWorkTimeConfig}
            disabled={worktimeConfigs?.filter(({ id }) => !id).length}
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>

        {serverValidation?.["input.attendance_profile_work_timing_input"] ? (
          <div className="invalid-container-style">
            <p className="validity-msg-style">
              {serverValidation?.["input.attendance_profile_work_timing_input"]}
            </p>
          </div>
        ) : null}

        {worktimeConfigs?.map((workTimeConfig, i) =>
          editedSignInConfig?.includes(workTimeConfig?.id) ||
          workTimeConfig?.id == null ? (
            <NewAttWorkTimeSection
              key={`${i}-${workTimeConfig?.id}`}
              formProps={props.formProps}
              index={i}
              handleEndEdit={endEditSignInConfig}
              validationInputName="attendance_profile_work_timing_input"
              showRemoveButton={worktimeConfigs?.length > 1}
              costCentersOptions={costCentersOptions}
            />
          ) : (
            <AttWorkTimeSectionPreview
              key={`${i}`}
              {...workTimeConfig}
              handleEdit={startEditSignInConfig}
            />
          )
        )}
      </div>
    </>
  );
};

export default EditAttSignInSection;

import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";

import {
  deletePackageMutation,
  setPackageStatusMutation,
} from "../../Graphql/mutation/PaymentTracking";
import swal from "sweetalert";
import Privileges from "../../Constants/Privilages";
import { showToast } from "../../Helpers/HelperFns";
import { packagesListQuery } from "../../Graphql/query/PaymentTracking";
import { serializeFetchPackage } from "../../Helpers/HelperFns/PaymentTracking";

import {
  EditIconButton,
  RemoveIconButton,
} from "../../Components/IconButtonWithTooltip";
import { Link } from "react-router-dom";
import Loader from "../../Components/Loader";
import AntSwitch from "../../Components/AntSwitch";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import { AddButton } from "../../Components/Buttons";
import { RadioboxForm, InputForm } from "form-builder";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";

const reducer = "paymentTracking";
const filtersFormName = "packagesListFilters";
const FiltersFormProps = { reducer, formName: filtersFormName };
const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};
const sendFilters = (filters) => {
  return {
    page: 1,
    input: {
      page_flag: "list",
      name: filters?.name ?? "",
      type: filters?.type || undefined,
    },
  };
};

const PackagesList = () => {
  const { t } = useTranslation();

  // Local State
  const [data, setData] = React.useState([]);
  const [pagination, setPagination] = React.useState(paginationInitState);

  // Reducer State
  const filters = useSelector((state) => state?.[reducer]?.[filtersFormName]);

  // Server State
  const [deletePackage, { loading: deleteLoading }] = useMutation(
    deletePackageMutation
  );
  const [setPackageStatus, { loading: setStatusLoading }] = useMutation(
    setPackageStatusMutation
  );
  const { loading, refetch } = useQuery(packagesListQuery, {
    notifyOnNetworkStatusChange: true,
    variables: {
      first: pagination?.perPage,
      page: pagination?.currentPage,
      ...sendFilters(filters),
    },
    onCompleted: ({ packages }) => {
      setData(packages?.data ? packages?.data.map(serializeFetchPackage) : []);
      setPagination(packages?.pagination || paginationInitState);
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });

  // Constants
  const columns = [
    {
      name: t("name"),
      grow: 1,
      cell: (row) => (
        <HasPrivileges
          reqireMain={[
            Privileges.SUPER_PRIVILEGE,
            Privileges.VIEW_PACKAGES_PROFILE,
          ]}
          altChildren={<span>{row?.name}</span>}
        >
          <Link to={`/payment-tracking/package/${row.id}`}>{row?.name}</Link>
        </HasPrivileges>
      ),
    },
    {
      name: t("type"),
      center: true,
      cell: (row) => (row?.trial_value !== null ? t("Paid") : t("Free")),
    },
    {
      name: t("free trial period"),
      center: true,
      cell: (row) => (!!row?.trial_value ? row?.trial_value : "_________"),
    },
    {
      name: t("active subscribers"),
      selector: "activeSubscriptions",
      center: true,
    },
    {
      name: t("active"),
      center: true,
      cell: (row) => (
        <HasPrivileges
          reqireMain={[
            Privileges.SUPER_PRIVILEGE,
            Privileges.ACTIVATE_DEACTIVATE_PACKAGES,
          ]}
          altChildren={
            <AntSwitch
              checked={row?.status?.toLowerCase() === "active"}
              color="primary"
              disabled
            />
          }
        >
          <AntSwitch
            inputProps={{ "aria-label": "Switch demo" }}
            checked={row?.status?.toLowerCase() === "active"}
            onChange={(e, val) => handleTogglePackageStatus(row?.id, val)}
            color="primary"
          />
        </HasPrivileges>
      ),
    },
    {
      ignoreRowClick: true,
      cell: (row) => (
        <HasPrivileges
          reqireMain={[
            Privileges.SUPER_PRIVILEGE,
            Privileges.ADD_EDIT_DELETE_PACKAGES,
          ]}
        >
          <div className="cards_table_actions">
            <EditIconButton href={`/payment-tracking/edit-package/${row.id}`} />
            <RemoveIconButton onClick={() => handleDeletePackage(row)} />
          </div>
        </HasPrivileges>
      ),
    },
  ];

  /* ↓ Helpers ↓ */

  const handlePaginate = (page = pagination.currentPage) => {
    refetch({ page });
  };

  const handleDeletePackage = (row) => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((confirmed) => {
      if (confirmed) {
        deletePackage({
          variables: { id: row?.id },
          onCompleted: () => {
            refetch();
            showToast("success");
          },
          onError: (err) => {
            showToast(
              "error",
              err?.graphQLErrors?.[0]?.extensions?.reason ||
                err?.graphQLErrors?.[0]?.message ||
                err?.message
            );
          },
        });
      }
    });
  };

  const handleTogglePackageStatus = (id, active) => {
    setPackageStatus({
      variables: { id, status: active ? "active" : "inactive" },
      onCompleted: () => {
        refetch();
        showToast("success");
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message
        );
      },
    });
  };

  return (
    <>
      {deleteLoading || setStatusLoading ? <Loader fixed /> : null}

      {/* Filters */}
      <div className="d-flex justify-content-between align-items-center my-3 gap-20">
        <RadioboxForm
          {...FiltersFormProps}
          name="type"
          options={[
            { label: "active", value: "active" },
            { label: "inactive", value: "inactive" },
            { label: "all", value: null },
          ]}
          optionItemStyle=" "
          optionInputStyle=" "
          containerStyle="mb-0"
          optionsContainerStyle="d-flex gap-10"
        />
        <InputForm
          {...FiltersFormProps}
          name="name"
          labelStyle="focus-label"
          placeholder={t("search")}
          type="search"
          rootStyle="flex-1"
          containerStyle="w-100"
          inputStyle="search_input"
          inputContainerStyle="search_container"
          icon="search"
        />
        <HasPrivileges
          reqireMain={[
            Privileges.SUPER_PRIVILEGE,
            Privileges.ADD_EDIT_DELETE_PACKAGES,
          ]}
        >
          <AddButton href="/payment-tracking/new-package" />
        </HasPrivileges>
      </div>

      {/* Table */}
      <DataTable
        noHeader
        data={data}
        columns={columns}
        className="cards_table"
        progressPending={loading}
        progressComponent={<Loader inner />}
        pagination
        paginationServer
        paginationComponent={() => (
          <Pagination
            styleWraper=""
            onPaginate={handlePaginate}
            customPaginator={pagination}
          />
        )}
      />
    </>
  );
};

export default PackagesList;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import {
  subStatusOptions,
  leadStatusOptions,
  activityTypeOptions,
} from "../../Constants/CRM";
import {
  storeActivityMutation,
  changeActivityMutation,
} from "../../Graphql/mutation";
import {
  showToast,
  buildOption,
  formatOptions,
  formatSendingInputs,
} from "../../Helpers/HelperFns";
import moment from "moment";
import { clearCRMValidations, setCRMValidations } from "../../Store/Actions";
import { activityFormQuery, fetchLeadContactQuery } from "../../Graphql/query";

import Loader from "../Loader";
import Box from "@mui/material/Box";
import MainModal from "../MainModal";
import { AddButton } from "../Buttons";
import ContactModal from "./ContactModal";
import { LeadsServerRestSelect } from "../CRM";
import Dropzone from "react-dropzone-uploader";
import {
  InputForm,
  BSelect,
  DateTimePickerForm,
  CheckboxBooleanForm,
} from "form-builder";
import { EditIconButton, TimesIconButton } from "../IconButtonWithTooltip";

const formNameValidation = "activityClientValidation";
const formServerValidation = "activityServerValidation";
const contactModalInitState = { isOpen: false, isEdit: false, data: null };
const subscriptionTypes = [
  "2+1 Month Free",
  "50% Off",
  "Annual Plan",
  "Discounted Annual Plan",
];

const ActivityModal = ({
  data,
  isLeadList,
  isLeadProfile,
  leadProfileData,
  onClose,
  refetchList,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isEdit = Boolean(data) && !isLeadList;

  // Local State
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [contactModalState, setContactModalState] = useState(
    contactModalInitState
  );
  const [isFetchingInputDepend, setIsFetchingInputDepend] = useState(isEdit);
  const [formData, setFormData] = useState({
    lead: isLeadList ? data : leadProfileData || null,
    activity: { id: "Call", name: "Call" },
    date: moment(),
    meetingDate: moment(),
    generateMeeting: false,
    notes: "",
    status: null,
    contact: null,
    subStatus: null,
    videoLink: "",
    attachments: [],
    meetingTime: "",
    subscription: "",
    empNO: "",
    plans: [],
    setReminder: false,
    reminderType: null,
    reminderDueDate: moment(),
    reminderLeadContact: null,
    reminderLead: isLeadList ? data : leadProfileData || null,
    reminderNotes: "",
    reminderSavedAttachments: [],
  });
  const [options, setOptions] = useState({
    plans: [],
    leadsContacts: [],
    reminderLeadContacts: [],
  });

  // Reducer State
  const activityClientValidation = useSelector(
    (state) => state.crm[formNameValidation]
  );

  // Server State
  const [getLeadContact, { loading: isLeadContactLoading }] = useLazyQuery(
    fetchLeadContactQuery
  );
  const [storeActivity, { loading: isStoreLoading }] = useMutation(
    storeActivityMutation
  );
  const [changeActivity, { loading: isChangeLoading }] = useMutation(
    changeActivityMutation
  );
  const { loading: isDataLoading } = useQuery(activityFormQuery, {
    variables: {
      id: isLeadList ? undefined : data?.id,
      isActivitySkip: !isEdit,
    },
    onCompleted: ({ activity, plans }) => {
      const dateToMoment = (date) => (date ? moment(date) : "");

      // Data
      if (isEdit) {
        setFormData({
          id: activity.id,
          attachments: [],
          lead: activity.lead,
          contact: activity?.contact,
          notes: activity?.notes || "",
          savedAttachments: activity.attachments,
          empNO: JSON.parse(activity.type)?.empNO || "",
          plans: JSON.parse(activity.type)?.plans || [],
          videoLink: JSON.parse(activity.type)?.videoLink || "",
          status: buildOption(JSON.parse(activity?.status)?.[0]),
          activity: buildOption(JSON.parse(activity?.type)?.name),
          subStatus: buildOption(JSON.parse(activity?.status)?.[1]),
          date: moment(activity?.date),
          meetingTime: dateToMoment(JSON.parse(activity?.type)?.meetingTime),
          subscription: dateToMoment(JSON.parse(activity?.type)?.subscription),
        });
        setIsFetchingInputDepend(false);
      }

      // Options
      setOptions((prev) => ({
        ...prev,
        plans: plans?.data || [],
      }));
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors[0]?.extensions?.reason ||
          err?.graphQLErrors[0]?.message ||
          err?.message
      );
    },
  });

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    formData.setReminder &&
      formData?.reminderLead?.id &&
      handleFetchLeadContact(formData?.reminderLead?.id, "reminderLead");
  }, [formData.setReminder, formData?.reminderLead?.id]);

  React.useEffect(() => {
    formData?.lead?.id && handleFetchLeadContact(formData?.lead?.id, "lead");
    !isFetchingInputDepend &&
      setFormData((prev) => ({ ...prev, contact: null }));
  }, [formData.lead?.id]);

  React.useEffect(() => {
    !isFetchingInputDepend &&
      setFormData((prev) => ({ ...prev, subStatus: null }));
  }, [formData.status?.id]);

  React.useEffect(() => {
    return () => {
      dispatch(clearCRMValidations(formServerValidation));
    };
  }, []);

  /* ↓ Helpers ↓ */

  const handleCheckBoxChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: !prevState[e.target.name],
    }));
  };

  const getType = () => {
    let type = { name: formData.activity?.name };

    switch (formData.activity?.id) {
      case "Meeting":
        type = { ...type, meetingTime: formData.meetingTime };
        break;
      case "Demo":
        type = { ...type, videoLink: formData.videoLink };
        break;
      case "Proposal":
        type = {
          ...type,
          empNO: formData.empNO,
          plans: formData.plans,
          subscription: formData.subscription,
        };
        break;
      default:
        break;
    }

    return JSON.stringify(type);
  };

  const handleAddContactModal = () => {
    setContactModalState({
      isOpen: true,
      isEdit: false,
      data: { lead_id: formData.lead?.id },
    });
  };

  const handleEditContactModal = () => {
    setContactModalState({
      isOpen: true,
      isEdit: true,
      data: { lead_id: formData.lead?.id, ...formData.contact },
    });
  };

  const onUpsertContact = () => {
    handleFetchLeadContact(formData?.lead?.id, "lead");
    formData.setReminder &&
      handleFetchLeadContact(formData?.reminderLead?.id, "reminderLead");
  };

  const handleTextChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSelectChange = (val, { name: key }) => {
    if (key === "lead" && !formData.reminderLead) {
      setFormData((prevState) => ({
        ...prevState,
        reminderLead: val,
        reminderLeadContact: null,
      }));
    }
    if (key === "reminderLead") {
      setFormData((prevState) => ({
        ...prevState,
        reminderLead: val,
        reminderLeadContact: null,
      }));
    }
    if (key === "contact" && !formData.reminderLeadContact) {
      setFormData((prevState) => ({
        ...prevState,
        reminderLeadContact: val,
      }));
    }
    setFormData((prev) => ({ ...prev, [key]: val }));

    // fetch contact
    if (["lead", "reminderLead"].includes(key))
      handleFetchLeadContact(val.id, key);
  };

  const handleDateChange = (name, val) => {
    setFormData((prev) => ({
      ...prev,
      [name]: val,
    }));
  };

  const handleAttachmentChange = ({ file }, status) => {
    if (status === "done") {
      setFormData((prev) => ({
        ...prev,
        attachments: [...prev?.attachments, file],
      }));
    }
  };

  const handleReminderAttachmentChange = ({ file }, status) => {
    if (status === "done") {
      setFormData((prev) => ({
        ...prev,
        reminderSavedAttachments: [...prev?.reminderSavedAttachments, file],
      }));
    }
  };

  const handleSavedAttachmentRemove = (idx) => {
    setFormData((prev) => ({
      ...prev,
      savedAttachments: prev.savedAttachments.filter((_, i) => i !== idx),
    }));
  };

  const handleFetchLeadContact = (id, key) => {
    id &&
      getLeadContact({
        variables: { id },
        onCompleted: ({ lead = {} }) => {
          setOptions((prev) => ({
            ...prev,
            ...(key === "lead" && { leadsContacts: lead?.contacts || [] }),
            ...(key === "reminderLead" && {
              reminderLeadContacts: lead?.contacts || [],
            }),
          }));

          setFormData((prev) => ({
            ...prev,
            ...(key === "lead" && {
              contact: prev?.contact
                ? lead?.contacts.find(
                    (contact) => contact.id === prev.contact.id
                  )
                : null,
            }),
            ...(key === "reminderLead" && {
              reminderLeadContact: prev?.reminderLeadContact
                ? lead?.contacts.find(
                    (contact) => contact.id === prev.reminderLeadContact.id
                  )
                : null,
            }),
          }));
        },
      });
  };

  const handleUpsert = () => {
    setFormSubmitting(true);
    if (activityClientValidation.length) return;

    const upsert = isEdit ? changeActivity : storeActivity;
    const {
      lead,
      empNO,
      notes,
      plans,
      status,
      contact,
      activity,
      subStatus,
      videoLink,
      meetingTime,
      attachments,
      subscription,
      savedAttachments = [],
      date,
      setReminder,
      reminderType,
      reminderDueDate,
      reminderLead,
      reminderLeadContact,
      reminderAttachments,
      reminderNotes,
      reminderSavedAttachments,
      generateMeeting,
      meetingDate,
      ...rest
    } = formData;
    upsert({
      variables: {
        notes,
        attachments,
        type: getType(),
        lead_id: lead?.id || undefined,
        ...formatSendingInputs({ ...rest }),
        contact_id: contact?.id || undefined,
        status: [status?.name, subStatus?.name],
        date: date.format("YYYY-MM-D HH:mm:ss"),
        ...(isEdit && {
          attachmentIds: savedAttachments.map((a) => +a.id),
        }),
        ...(!isEdit && generateMeeting && { meetingDate: moment(meetingDate).format("YYYY-MM-D HH:mm") }),
        setReminder,
        reminderInput: setReminder
          ? {
              reminderType: JSON.stringify({ name: reminderType?.id }),
              reminderDueDate: reminderDueDate.format("YYYY-MM-D HH:mm:ss"),
              reminderLeadId: reminderLead?.id,
              reminderLeadContactId: reminderLeadContact?.id,
              reminderNotes,
              reminderAttachments: reminderSavedAttachments,
            }
          : null,
      },
      onCompleted: () => {
        refetchList();
        onClose();
        showToast("success");
      },
      onError: (err) => {
        err?.graphQLErrors?.[0]?.extensions?.validation &&
          dispatch(
            setCRMValidations({
              ref: formServerValidation,
              validations: err?.graphQLErrors?.[0]?.extensions?.validation,
            })
          );

        (err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message) &&
          showToast(
            "error",
            err?.graphQLErrors?.[0]?.extensions?.reason ||
              err?.graphQLErrors?.[0]?.message ||
              err?.message
          );
      },
    });
  };

  const reminderForm = (
    <div>
      <div className="">
        {isLeadList || isLeadProfile ? (
          <>
            <strong>{t("lead")}</strong>
            <span className="d-block">{formData.lead.name}</span>
          </>
        ) : (
          <LeadsServerRestSelect
            type="Activities"
            name="reminderLead"
            label="lead"
            icon="employee"
            value={formData.reminderLead}
            onChange={handleSelectChange}
            validateBy="textRequired"
            rootStyle="flex-1"
          />
        )}
      </div>
      <div className="d-flex gap-10">
        <BSelect
          label="lead Contact"
          name="reminderLeadContact"
          icon="employees"
          isLoading={isLeadContactLoading}
          value={formData.reminderLeadContact}
          onChange={handleSelectChange}
          options={options?.reminderLeadContacts || []}
          isDisabled={!Boolean(formData.reminderLead?.name)}
          validateBy="textRequired"
          rootStyle="flex-1"
        />
      </div>
      <div className="d-flex gap-10">
        <BSelect
          label="reminder type"
          name="reminderType"
          icon="employee"
          keepDefaultStyle
          labelStyle="w-100"
          value={formData.reminderType}
          onChange={handleSelectChange}
          options={formatOptions(activityTypeOptions)}
          validateBy="textRequired"
          formSubmitting={formSubmitting}
          formNameValidation={formNameValidation}
          rootStyle="flex-1"
        />
        <DateTimePickerForm
          hasIcon
          dateTime
          label="due date"
          labelStyle="w-100"
          value={formData.reminderDueDate}
          datePickerContainer="w-100"
          onSelect={(val) => handleDateChange("reminderDueDate", val)}
          validateBy="textRequired"
          formSubmitting={formSubmitting}
          formNameValidation={formNameValidation}
          formServerValidation={formServerValidation}
          validationName="reminderInput.reminderDueDate"
          reducer="crm"
          rootStyle="flex-1"
        />
      </div>
      <div className="d-flex gap-10">
        <label className="w-100 mt-2">
          <span>{t("Notes")}</span>
          <textarea
            name="reminderNotes"
            value={formData.reminderNotes}
            onChange={handleTextChange}
            style={{ resize: "none" }}
          />
        </label>
      </div>
      <div className="d-flex gap-10">
        <div className="flex-1">
          <span>{t("Attachments")}</span>
          <Box
            className="documents_dropzone_style"
            sx={{
              ".dzu-dropzone .dzu-previewContainer": {
                height: 60,
                justifyContent: "center",
                minHeight: "initial !important",
                ".dzu-previewStatusContainer": { position: "static" },
              },
            }}
          >
            <Dropzone onChangeStatus={handleReminderAttachmentChange} />
          </Box>
        </div>
      </div>
    </div>
  );

  return (
    <MainModal
      isOpen
      toggle={onClose}
      btnOnClick={handleUpsert}
      modalTitle={t(`${isEdit ? "edit" : "new"} activity`)}
      btnSubmitLoading={isStoreLoading || isChangeLoading}
    >
      {(isEdit && isDataLoading) || isLeadContactLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <div className="d-flex flex-column gap-10">
        {isLeadList || isLeadProfile ? (
          <>
            <strong>{t("lead")}</strong>
            <span>{formData.lead.name}</span>
          </>
        ) : (
          <LeadsServerRestSelect
            type="Activities"
            name="lead"
            label="lead"
            icon="employee"
            keepDefaultStyle
            isDisabled={isEdit}
            value={formData.lead}
            onChange={handleSelectChange}
            validateBy="textRequired"
            formSubmitting={formSubmitting}
            formNameValidation={formNameValidation}
          />
        )}
        <BSelect
          label="activity"
          name="activity"
          icon="employee"
          keepDefaultStyle
          labelStyle="w-100"
          value={formData.activity}
          onChange={handleSelectChange}
          options={formatOptions(activityTypeOptions)}
          validateBy="textRequired"
          formSubmitting={formSubmitting}
          formNameValidation={formNameValidation}
        />
        <div>
          <div className="d-flex align-items-center gap-20 mb-2">
            {t("lead contact")}
            <AddButton
              isIconOnly
              onClick={handleAddContactModal}
              disabled={!Boolean(formData.lead?.id) ? true : undefined}
            />
            <EditIconButton
              onClick={handleEditContactModal}
              disabled={!Boolean(formData.contact?.id)}
              tooltipProps={{ isDisable: !Boolean(formData.contact?.id) }}
            />
          </div>
          <BSelect
            name="contact"
            icon="employees"
            keepDefaultStyle
            value={formData.contact}
            onChange={handleSelectChange}
            isLoading={isLeadContactLoading}
            options={options?.leadsContacts || []}
            isDisabled={!Boolean(formData.lead?.name)}
            validateBy="textRequired"
            formSubmitting={formSubmitting}
            formNameValidation={formNameValidation}
          />
        </div>
        <DateTimePickerForm
          hasIcon
          dateTime
          label="date"
          labelStyle="w-100"
          value={formData.date}
          datePickerContainer="w-100"
          onSelect={(val) => handleDateChange("date", val)}
          validateBy="textRequired"
          formSubmitting={formSubmitting}
          formNameValidation={formNameValidation}
          formServerValidation={formServerValidation}
          validationName="date"
          reducer="crm"
        />

        {!isEdit ? (
          <div className="d-flex gap-20 align-items-center">
            <CheckboxBooleanForm
              name="generateMeeting"
              options={[t("generate meeting")]}
              checked={formData.generateMeeting}
              type="checkbox"
              containerStyle=""
              onChange={handleCheckBoxChange}
            />
            {formData?.generateMeeting ? (
              <DateTimePickerForm
                hasIcon
                dateTime
                rootStyle="flex-1"
                value={formData.meetingDate}
                datePickerContainer="w-100"
                onSelect={(val) => handleDateChange("meetingDate", val)}
              />
            ) : null}
          </div>
        ) : null}

        {formData.activity?.name === "Meeting" ? (
          <DateTimePickerForm
            hasIcon
            dateTime
            labelStyle="w-100"
            label="meeting time"
            value={formData.meetingTime}
            datePickerContainer="w-100"
            onSelect={(val) => handleDateChange("meetingTime", val)}
          />
        ) : null}
        {formData.activity?.name === "Demo" ? (
          <InputForm
            name="videoLink"
            label="video link"
            labelStyle="w-100"
            onChange={handleTextChange}
            inputContainerStyle="w-100"
            value={formData.videoLink}
          />
        ) : null}
        {formData.activity?.name === "Proposal" ? (
          <>
            <div className="d-flex align-items-end gap-10">
              <BSelect
                name="subscription"
                icon="employees"
                keepDefaultStyle
                label="subscription type"
                rootStyle="flex-fill"
                value={formData.subscription}
                onChange={handleSelectChange}
                options={formatOptions(subscriptionTypes)}
                classNamePrefix="b-select-style"
              />
              <InputForm
                type="number"
                name="empNO"
                containerStyle=" "
                label="number Of employees"
                rootStyle="flex-fill"
                value={formData.empNO}
                onChange={handleTextChange}
              />
            </div>
            <BSelect
              isMulti
              name="plans"
              label="plans"
              icon="employees"
              keepDefaultStyle
              value={formData.plans}
              options={options.plans}
              isLoading={!options.plans.length}
              classNamePrefix="b-select-style"
              onChange={handleSelectChange}
            />
          </>
        ) : null}

        <label className="w-100 mt-2">
          <span>{t("Notes")}</span>
          <textarea
            name="notes"
            value={formData.notes}
            onChange={handleTextChange}
            style={{ resize: "none" }}
          />
        </label>
        <span>{t("Attachments")}</span>
        {isEdit ? (
          <div>
            {formData?.savedAttachments?.map((attachment, i) => (
              <div key={i} className="d-flex gap-20 align-items-center py-2">
                {attachment?.name}
                <TimesIconButton
                  onClick={() => handleSavedAttachmentRemove(i)}
                />
              </div>
            ))}
          </div>
        ) : null}
        <Box
          className="documents_dropzone_style"
          sx={{
            ".dzu-dropzone .dzu-previewContainer": {
              height: 60,
              justifyContent: "center",
              minHeight: "initial !important",
              ".dzu-previewStatusContainer": { position: "static" },
            },
          }}
        >
          <Dropzone onChangeStatus={handleAttachmentChange} />
        </Box>

        <div className="d-flex gap-10">
          <BSelect
            name="status"
            icon="employee"
            label="lead status"
            keepDefaultStyle
            rootStyle="flex-fill"
            value={formData.status}
            onChange={handleSelectChange}
            options={formatOptions(leadStatusOptions)}
            validateBy="textRequired"
            formSubmitting={formSubmitting}
            formNameValidation={formNameValidation}
          />
          {Object.keys(subStatusOptions).includes(formData.status?.name) ? (
            <BSelect
              name="subStatus"
              icon="employee"
              label="lead sub status"
              keepDefaultStyle
              rootStyle="flex-fill"
              onChange={handleSelectChange}
              value={formData.subStatus}
              classNamePrefix="b-select-style"
              options={formatOptions(subStatusOptions[formData.status.name])}
              validateBy="textRequired"
              formSubmitting={formSubmitting}
              formNameValidation={formNameValidation}
            />
          ) : null}
        </div>
        <CheckboxBooleanForm
          setWithValue
          name="setReminder"
          options={[t("set Reminder")]}
          checked={formData.setReminder}
          type="checkbox"
          containerStyle="pt-4 mt-2"
          onChange={handleCheckBoxChange}
        />
        {formData.setReminder && reminderForm}
      </div>

      {contactModalState.isOpen ? (
        <ContactModal
          state={contactModalState}
          onUpsertContact={onUpsertContact}
          onClose={() => setContactModalState(contactModalInitState)}
        />
      ) : null}
    </MainModal>
  );
};

export default ActivityModal;

import gql from "graphql-tag";

export const UPSERT_ASSET_TYPE = gql`
  mutation upsertAssetType($input: UpdateOrCreateAssetTypeInput) {
    updateOrCreateAssetType(input: $input) {
      __typename

      ... on AssetType {
        id
      }

      ... on GeneralException {
        message
      }
    }
  }
`;

export const DELETE_ASSET_TYPE = gql`
  mutation deleteAssetType($id: ID) {
    deleteAssetType(id: $id) {
      __typename

      ... on AssetType {
        id
      }

      ... on GeneralException {
        message
      }
    }
  }
`;

export const UPSERT_WAREHOUSE = gql`
  mutation upsertWarehouse($input: UpdateOrCreateWarehouseInput) {
    updateOrCreateWarehouse(input: $input) {
      __typename

      ... on Warehouse {
        id
      }

      ... on GeneralException {
        message
      }
    }
  }
`;

export const DELETE_WAREHOUSE = gql`
  mutation deleteWarehouse($id: ID) {
    deleteWarehouse(id: $id) {
      __typename

      ... on Warehouse {
        id
      }

      ... on GeneralException {
        message
      }
    }
  }
`;

// handle upsert company asset mutation
export const upsertCompanyAssetMutation = gql`
  mutation upsertCompanyAsset($input: UpdateOrCreateAssetInput) {
    updateOrCreateAsset(input: $input) {
      __typename

      ... on Asset {
        id
      }

      ... on GeneralException {
        message
      }
    }
  }
`;

// handle add new unit mutation
export const addNewUnitMutation = gql`
  mutation storeItems($input: StoreItemsInput) {
    storeItems(input: $input) {
      __typename

      ... on ItemsList {
        items {
          id
        }
      }

      ... on GeneralException {
        message
      }
    }
  }
`;

// handle assign asset mutation
export const assignAssetMutation = gql`
  mutation assignAsset($input: StoreAssignOrderInput) {
    storeAssignOrder(input: $input) {
      __typename

      ... on AssignOrder {
        id
      }

      ... on GeneralException {
        message
      }
    }
  }
`;

// handle retrieve asset mutation
export const retrieveAssetMutation = gql`
  mutation retrieveAsset($input: RetrieveAssignOrderInput) {
    retrieveAssignOrder(input: $input) {
      __typename

      ... on ItemsList {
        items {
          id
          serial_number
        }
      }

      ... on GeneralException {
        message
      }
    }
  }
`;

export const DELETE_ITEM = gql`
  mutation deleteItem($id: ID) {
    deleteItem(id: $id) {
      __typename

      ... on Item {
        id
      }

      ... on GeneralException {
        message
      }
    }
  }
`;

// handle delete company asset mutation
export const deleteCompanyAssetMutation = gql`
  mutation deleteCompanyAsset($id: ID) {
    deleteAsset(id: $id) {
      __typename

      ... on Asset {
        id
      }

      ... on GeneralException {
        message
      }
    }
  }
`;

export const UNSCRAPP_ITEM = gql`
  mutation unscrappItem($input: UnscrappedItemInput) {
    unscrappedItem(input: $input) {
      __typename

      ... on Item {
        id
      }

      ... on GeneralException {
        message
      }
    }
  }
`;

// handle scrappe item mutation
export const scrapItemMutation = gql`
  mutation scrappedItem($input: ScrappedItemInput) {
    scrappedItem(input: $input) {
      __typename

      ... on Item {
        id
      }

      ... on GeneralException {
        message
      }
    }
  }
`;

export const TRANSFER_ASSETS = gql`
  mutation transferAssets($input: ChangeItemsWarehouseInput) {
    changeItemsWarehouse(input: $input) {
      __typename

      ... on ItemsList {
        items {
          id
        }
      }

      ... on GeneralException {
        message
      }
    }
  }
`;

export const REQUEST_ASSETS = gql`
  mutation requestAssets($input: StoreAssetRequestInput) {
    storeAssetRequest(input: $input) {
      __typename

      ... on AssetRequest {
        id
      }

      ... on GeneralException {
        message
      }
    }
  }
`;

export const ACCEPT_ASSET_REQUEST = gql`
  mutation acceptAssetRequest($input: AcceptAssetRequestInput) {
    acceptAssetRequest(input: $input) {
      __typename

      ... on AssetRequest {
        id
      }

      ... on GeneralException {
        message
      }
    }
  }
`;

export const REJECT_ASSET_REQUEST = gql`
  mutation rejectAssetRequest($id: ID!) {
    rejectAssetRequest(asset_request_id: $id) {
      __typename

      ... on AssetRequest {
        id
      }

      ... on GeneralException {
        message
      }
    }
  }
`;

export const CANCEL_ASSET_REQUEST = gql`
  mutation cancelAssetRequest($id: ID!) {
    CancelAssetRequest(asset_request_id: $id) {
      __typename

      ... on AssetRequest {
        id
      }

      ... on GeneralException {
        message
      }
    }
  }
`;

export const RETRIEVE_ASSET = gql`
  mutation retriveAsset($input: RetrieveAssignOrderInput) {
    retrieveAssignOrder(input: $input) {
      __typename

      ... on ItemsList {
        items {
          id
        }
      }

      ... on GeneralException {
        message
      }
    }
  }
`;

export const EXCHANGE_ITEM = gql`
  mutation exchangeItem($input: ChangeAssignOrderInput) {
    changeAssignOrder(input: $input) {
      __typename

      ... on Item {
        id
      }

      ... on GeneralException {
        message
      }
    }
  }
`;

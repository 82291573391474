import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { showToast } from "../../../Helpers/HelperFns";
import MainModal from "../../MainModal";
import {
  UPSERT_REMINDER,
  upsertCompanyActivityMutation,
} from "../../../Graphql/mutation";
import CompanyReminderForm from "./forms/CompanyReminderForm";
import CheckboxBooleanForm from "../../../Builder/Form/CheckboxBooleanForm";
import CompanyActivityForm from "./forms/CompanyActivityForm";
import {
  fetchCompanyCompanyContactsAction,
  onFormResetAction,
  updateValueAction,
} from "../../../Store/Actions";
import HasPrivileges from "../../../Helpers/HOC/HasPrivileges";
import Privileges from "../../../Constants/Privilages";

const CompanyActivityAndReminderModal = ({
  onClose,
  refetchList,
  isAddReminder,
  isOpen,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [hasActivitiesOrReminder, setHasActivitiesOrReminder] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);

  const companyReminderInput = useSelector(
    (state) => state?.super?.comapnyReminderForm
  );

  const companyActivityInput = useSelector(
    (state) => state?.super?.comapnyActivityForm
  );

  const reminderClientValidation = useSelector(
    (state) => state.super?.comapnyReminderFormValidation
  );

  const activityClientValidation = useSelector(
    (state) => state.super?.comapnyActivityFormValidation
  );

  const companyID = useSelector(
    (state) => state.super?.selectedCompanyProfile?.id
  );

  const isEdit = Boolean(
    isAddReminder ? companyReminderInput?.id : companyActivityInput?.id
  );

  useEffect(() => {
    dispatch(fetchCompanyCompanyContactsAction(companyID));
  }, []);

  const [upsertReminder, { loading: isUpsertLoading }] =
    useMutation(UPSERT_REMINDER);

  const [upsertCompanyActivity, { loading: upsertCompanyActivityIsLoading }] =
    useMutation(upsertCompanyActivityMutation);

  const handleUpsertCompanyReminder = () => {
    const {
      savedAttachments: reminderSavedAttachments,
      ...serilaizedCompanyReminderInput
    } = companyReminderInput;
    const {
      savedAttachments: activitySavedAttachments,
      ...serilaizedCompanyActivityInput
    } = companyActivityInput;
    upsertReminder({
      variables: {
        input: {
          company_id: companyID,
          ...serilaizedCompanyReminderInput,
          setActivites: hasActivitiesOrReminder,
          ...(hasActivitiesOrReminder
            ? {
                activitiesInput: {
                  ...serilaizedCompanyActivityInput,
                  company_id: companyID,
                },
              }
            : {}),
        },
      },
      onCompleted: (res) => {
        if (res?.upsert_company_reminder?.id) {
          showToast("success");
          refetchList();
          onClose();
        }
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ?? err.message
        );
      },
    });
  };

  const handleUpsertCompanyActivity = () => {
    const {
      savedAttachments: reminderSavedAttachments,
      ...serilaizedCompanyReminderInput
    } = companyReminderInput;
    const {
      savedAttachments: activitySavedAttachments,
      ...serilaizedCompanyActivityInput
    } = companyActivityInput;
    upsertCompanyActivity({
      variables: {
        input: {
          company_id: companyID,
          ...serilaizedCompanyActivityInput,
          setReminder: hasActivitiesOrReminder,
          ...(hasActivitiesOrReminder
            ? {
                reminderInput: {
                  ...serilaizedCompanyReminderInput,
                  company_id: companyID,
                },
              }
            : {}),
        },
      },
      onCompleted: (res) => {
        if (res?.upsert_company_activities?.id) {
          showToast("success");
          refetchList();
          onClose();
        }
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ?? err.message
        );
      },
    });
  };

  const handleUpsert = () => {
    setFormSubmitting(true);

    if (isAddReminder) {
      if (reminderClientValidation?.length) return;
      if (hasActivitiesOrReminder && activityClientValidation?.length) return;
      handleUpsertCompanyReminder();
      return;
    }

    if (activityClientValidation?.length) return;
    if (hasActivitiesOrReminder && reminderClientValidation?.length) return;
    handleUpsertCompanyActivity();
    return;
  };

  const handelHasActivitiesOrReminder = (val) => {
    if (val) {
      if (isAddReminder) {
        dispatch(
          updateValueAction(
            "comapnyActivityForm",
            "contact_id",
            companyReminderInput?.contact_id
          )
        );
      } else {
        dispatch(
          updateValueAction(
            "comapnyReminderForm",
            "contact_id",
            companyActivityInput?.contact_id
          )
        );
      }
    }
    setHasActivitiesOrReminder(val);
  };

  useEffect(() => {
    return () => {
      dispatch(onFormResetAction("comapnyReminderForm"));
      dispatch(onFormResetAction("comapnyReminderFormValidation"));
      dispatch(onFormResetAction("comapnyReminderFormServerValidation"));
      dispatch(onFormResetAction("comapnyActivityForm"));
      dispatch(onFormResetAction("comapnyActivityFormValidation"));
      dispatch(onFormResetAction("comapnyActivityFormServerValidation"));
    };
  }, []);

  return (
    <MainModal
      isOpen={isOpen}
      toggle={onClose}
      btnOnClick={handleUpsert}
      btnSubmitLoading={isUpsertLoading || upsertCompanyActivityIsLoading}
      modalTitle={t(
        `${isEdit ? "edit" : "new"} ${isAddReminder ? "reminder" : "activity"}`
      )}
    >
      {isAddReminder ? (
        <CompanyReminderForm
          formSubmitting={formSubmitting}
          hasActivity={hasActivitiesOrReminder}
        />
      ) : (
        <CompanyActivityForm
          formSubmitting={formSubmitting}
          hasReminder={hasActivitiesOrReminder}
        />
      )}

      {
        <HasPrivileges
          reqireMain={[
            Privileges.SUPER_PRIVILEGE,
            ...(isAddReminder
              ? [Privileges.ADD_EDIT_COMPANY_ACTIVITIES]
              : [Privileges.ADD_EDIT_COMPANY_REMINDERS]),
          ]}
        >
          <CheckboxBooleanForm
            options={[isAddReminder ? t("set activity") : t("set reminder")]}
            type="checkbox"
            checked={hasActivitiesOrReminder}
            onChange={() =>
              handelHasActivitiesOrReminder(!hasActivitiesOrReminder)
            }
          />
        </HasPrivileges>
      }

      {hasActivitiesOrReminder ? (
        isAddReminder ? (
          <HasPrivileges
            reqireMain={[
              Privileges.SUPER_PRIVILEGE,
              Privileges.ADD_EDIT_COMPANY_ACTIVITIES,
            ]}
          >
            <CompanyActivityForm formSubmitting={formSubmitting} />
          </HasPrivileges>
        ) : (
          <HasPrivileges
            reqireMain={[
              Privileges.SUPER_PRIVILEGE,
              Privileges.ADD_EDIT_COMPANY_REMINDERS,
            ]}
          >
            <CompanyReminderForm formSubmitting={formSubmitting} />
          </HasPrivileges>
        )
      ) : null}
    </MainModal>
  );
};

export default CompanyActivityAndReminderModal;

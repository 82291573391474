import React from "react";

import Privileges from "../../../Constants/Privilages";

import {
  Reports,
  Calendar,
  Dashboard,
  LeadsList,
  TrialsList,
  AgentsList,
  MeetingsList,
  RemindersList,
  ActivitiesList,
} from "../../../Containers/CRMLists";
import FoodicsLeads from "./FoodicsLeads";
import {
  Event,
  Groups,
  Assessment,
  SupportAgent,
  Notifications,
  LocalActivity,
  Dashboard as DashboardIcon,
} from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const crmTabs = [
  {
    label: "Reports",
    icon: <Assessment fontSize="small" />,
    value: "reports",
    privileges: [Privileges.VIEW_CRM_REPORTS],
    allowBP: false,
    panel: <Reports />,
  },
  {
    label: "Dashboard",
    icon: <DashboardIcon fontSize="small" />,
    value: "dashboard",
    privileges: [Privileges.VIEW_CRM_DASHBOARD, Privileges.SUPER_PRIVILEGE],
    allowBP: false,
    panel: <Dashboard />,
  },
  {
    label: "Calendar",
    icon: <Event fontSize="small" />,
    value: "calendar",
    privileges: [Privileges.VIEW_CRM_CALENDAR, Privileges.SUPER_PRIVILEGE],
    allowBP: false,
    panel: <Calendar />,
  },
  {
    label: "Meetings",
    icon: <Groups fontSize="small" />,
    value: "meetings",
    privileges: [Privileges.VIEW_CRM_MEETINGS, Privileges.SUPER_PRIVILEGE],
    allowBP: false,
    panel: <MeetingsList />,
  },
  {
    label: "Leads",
    icon: <FontAwesomeIcon icon="fa fa-mail-bulk" />,
    value: "leads",
    privileges: [Privileges.VIEW_LEADS, Privileges.SUPER_PRIVILEGE],
    allowBP: false,
    panel: <LeadsList />,
  },
  {
    label: "Foodics Leads",
    icon: <FontAwesomeIcon icon="fa fa-mail-bulk" />,
    value: "foodics_leads",
    privileges: [Privileges.VIEW_FOODICS_LEADS, Privileges.SUPER_PRIVILEGE],
    allowBP: false,
    panel: <FoodicsLeads />,
  },
  {
    label: "Activities",
    icon: <LocalActivity fontSize="small" />,
    value: "activities",
    privileges: [Privileges.VIEW_ACTIVITIES, Privileges.SUPER_PRIVILEGE],
    allowBP: false,
    panel: <ActivitiesList />,
  },
  {
    label: "Reminders",
    icon: <Notifications fontSize="small" />,
    value: "reminders",
    privileges: [Privileges.VIEW_REMINDERS, Privileges.SUPER_PRIVILEGE],
    allowBP: false,
    panel: <RemindersList />,
  },
  {
    label: "Trials",
    icon: <FontAwesomeIcon icon="fa fa-mail-bulk" />,
    value: "trials",
    privileges: [Privileges.VIEW_TRIALS, Privileges.SUPER_PRIVILEGE],
    allowBP: false,
    panel: <TrialsList />,
  },
  {
    label: "Agents",
    icon: <SupportAgent fontSize="small" />,
    value: "agents",
    privileges: [Privileges.VIEW_CRM_AGENTS, Privileges.SUPER_PRIVILEGE],
    allowBP: false,
    panel: <AgentsList />,
  },
];

export default crmTabs;

import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";

import Constants from "../../../Constants";
import { makeStyles } from "tss-react/mui";

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import GroupIcon from "@mui/icons-material/Group";
import StoreIcon from "@mui/icons-material/Store";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const verticalNavbarStyles = makeStyles()(() => Constants.verticalNavbarStyles);
const horizontalNavbarStyles = makeStyles()(
  () => Constants.horizontalNavbarStyles
);

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const FoodicsTabs = ({ isRouteSidebar }) => {
  const query = useQuery();
  const history = useHistory();
  const { t } = useTranslation();

  // Constants
  const activeValue = query.get("tab");
  const { classes } = isRouteSidebar
    ? verticalNavbarStyles()
    : horizontalNavbarStyles();

  React.useEffect(() => {
    if (!activeValue) renderfirstTab();
  }, [activeValue]);

  /* ↓ Helpers ↓ */

  const renderfirstTab = () => {
    history.push(`/foodics?tab=users`);

    return null;
  };

  const handleChange = (_, newValue) => {
    history.push(`/foodics?tab=${newValue}`);
  };

  return (
    <Tabs
      variant="scrollable"
      scrollButtons="auto"
      onChange={handleChange}
      orientation={isRouteSidebar ? "vertical" : "horizontal"}
      value={
        ["users", "branches", "shifts"].includes(activeValue)
          ? activeValue
          : false
      }
      classes={{
        root: classes.root,
        indicator: classes.indicator,
        flexContainer: classes.flexContainer,
        scrollButtons: classes.scrollButtons,
      }}
    >
      <Tab
        value="users"
        classes={{
          root: classes.tabRoot,
          wrapper: "top-bar-label",
          selected: classes.selected,
        }}
        iconPosition="start"
        icon={<GroupIcon fontSize="small" />}
        label={t("users")}
      />
      <Tab
        value="branches"
        classes={{
          root: classes.tabRoot,
          wrapper: "top-bar-label",
          selected: classes.selected,
        }}
        iconPosition="start"
        icon={<StoreIcon fontSize="small" />}
        label={t("branches")}
      />
      <Tab
        value="shifts"
        classes={{
          root: classes.tabRoot,
          wrapper: "top-bar-label",
          selected: classes.selected,
        }}
        iconPosition="start"
        icon={<AccessTimeIcon fontSize="small" />}
        label={t("shifts")}
      />
    </Tabs>
  );
};

export default FoodicsTabs;

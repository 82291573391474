import React from "react";
import { useTranslation } from "react-i18next";
import { BSelect, InputForm } from "form-builder";
import { useDispatch } from "react-redux";
import { DIAL_CODES } from "../../Constants/DialCodes";

const formName = "paymentMethodForm";
const PAYMENT_METHOD_OPTIONS = [
  { name: "cash", id: "cash" },
  { name: "bank transfer", id: "bank transfer" },
  { name: "prepaid card", id: "prepaid card" },
  { name: "phone wallet", id: "phone wallet" },
];

const SinglePaymentForm = ({
  payment,
  index,
  optionsData,
  optionsLoading,
  formSubmitting,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const formProps = {
    formNameValidation: "paymentMethodFormValidation",
    formSubmitting,
  };

  const handelSelect = (opt, key) => {
    if (key === "payment_method" && opt === null) {
      dispatch({
        type: "UPDATE_FORM_LIST",
        payload: {
          formName,
          listName: "payments",
          key,
          value: null,
          index,
        },
      });
      return true;
    }
    if (key === "payment_method") handelPhoneWallet(opt);
    dispatch({
      type: "UPDATE_FORM_LIST",
      payload: {
        formName,
        listName: "payments",
        key,
        value: opt?.id,
        index,
      },
    });
    return true;
  };

  const handleInputChange = (e) => {
    dispatch({
      type: "UPDATE_FORM_LIST",
      payload: {
        formName,
        listName: "payments",
        key: e?.target?.name,
        value: e?.target?.value,
        index,
      },
    });
  };

  const handelPhoneWallet = (opt) => {
    if (opt.id === "phone wallet") {
      dispatch({
        type: "UPDATE_FORM_LIST",
        payload: {
          formName,
          listName: "payments",
          key: "code",
          value: DIAL_CODES[optionsData?.country?.short_name?.toLowerCase()],
          index,
        },
      });
      dispatch({
        type: "UPDATE_FORM_LIST",
        payload: {
          formName,
          listName: "payments",
          key: "country_short_name",
          value: optionsData?.country?.short_name,
          index,
        },
      });
    }
  };

  return (
    <form className="col m-0 p-0">
      <div className="col-12 m-0 p-0">
        <BSelect
          {...formProps}
          name="payment_method"
          label={t("payment method")}
          keepDefaultStyle
          placeholder={t("select option")}
          options={PAYMENT_METHOD_OPTIONS}
          validateBy="textRequired"
          validationName="input.payment_method"
          labelStyle={"font-weight-bold"}
          containerStyle="mt-4"
          onInterceptInputOnChange={(_, opt) =>
            handelSelect(opt, "payment_method")
          }
          bValue={payment.payment_method}
          isClearable
        />
      </div>

      {payment?.payment_method === "bank transfer" ? (
        <>
          <div className="row col-12 m-0 p-0">
            <div className="col-6 m-0 p-0">
              <BSelect
                {...formProps}
                name="bank_id"
                label={t("Bank")}
                labelStyle={"font-weight-bold"}
                keepDefaultStyle
                placeholder={t("select option")}
                options={optionsData?.country?.banks}
                validateBy="textRequired"
                validationName="input.bank_id"
                skipLocalization
                containerStyle="mt-3 mb-2"
                loading={optionsLoading}
                onInterceptInputOnChange={(_, opt) =>
                  handelSelect(opt, "bank_id")
                }
                bValue={payment.bank_id}
              />
            </div>
            <div className="col-6">
              <InputForm
                {...formProps}
                label={t("account number")}
                labelStyle={"font-weight-bold"}
                validateBy="textRequired"
                name="account_number"
                type="text"
                placeholder={t("account number")}
                containerStyle="mt-3 mb-2"
                inputContainerStyle=" "
                onChange={handleInputChange}
                value={payment?.account_number}
              />
            </div>
          </div>
          <div className="row col-12 m-0 p-0">
            <div className="col-6 m-0 p-0">
              <InputForm
                {...formProps}
                label={t("name of account")}
                labelStyle={"font-weight-bold"}
                validateBy="textRequired"
                name="account_name"
                type="text"
                placeholder={t("name of account")}
                containerStyle="mt-3 mb-2"
                inputContainerStyle=" "
                onChange={handleInputChange}
                value={payment?.account_name}
              />
            </div>
            <div className="col-6">
              <InputForm
                {...formProps}
                label={t("IBAN")}
                labelStyle={"font-weight-bold"}
                validateBy="textRequired"
                name="iban"
                type="text"
                placeholder={t("IBAN")}
                containerStyle="mt-3 mb-2"
                inputContainerStyle=" "
                onChange={handleInputChange}
                value={payment?.iban}
              />
            </div>
          </div>{" "}
        </>
      ) : null}

      {payment?.payment_method === "prepaid card" ? (
        <>
          <div className="row col-12 m-0 p-0">
            <div className="col-6 m-0 p-0">
              <BSelect
                {...formProps}
                name="prepaid_card_id"
                label={t("card")}
                labelStyle={"font-weight-bold"}
                keepDefaultStyle
                placeholder={t("select option")}
                options={optionsData?.country?.prepaidCards}
                validateBy="textRequired"
                validationName="input.prepaid_card_id"
                skipLocalization
                containerStyle="mt-3 mb-2"
                loading={optionsLoading}
                onInterceptInputOnChange={(_, opt) =>
                  handelSelect(opt, "prepaid_card_id")
                }
                bValue={payment.prepaid_card_id}
              />
            </div>
            <div className="col-6">
              <InputForm
                {...formProps}
                label={t("card number")}
                labelStyle={"font-weight-bold"}
                validateBy="textRequired"
                name="card_number"
                type="text"
                placeholder={t("card number")}
                containerStyle="mt-3 mb-2"
                inputContainerStyle=" "
                onChange={handleInputChange}
                value={payment?.card_number}
              />
            </div>
          </div>
        </>
      ) : null}

      {payment?.payment_method === "phone wallet" ? (
        <>
          <div className="row col-12 m-0 p-0">
            <div className="col-6 m-0 p-0">
              <BSelect
                {...formProps}
                name="phone_wallet_id"
                label={t("wallet")}
                labelStyle={"font-weight-bold"}
                keepDefaultStyle
                placeholder={t("select option")}
                options={optionsData?.country?.phoneWallets}
                validateBy="textRequired"
                validationName="input.phone_wallet_id"
                skipLocalization
                containerStyle="mt-3 mb-2"
                loading={optionsLoading}
                onInterceptInputOnChange={(_, opt) =>
                  handelSelect(opt, "phone_wallet_id")
                }
                bValue={payment.phone_wallet_id}
              />
            </div>
            <div className="col-6">
              <InputForm
                {...formProps}
                label={t("phone number")}
                labelStyle={"font-weight-bold"}
                validateBy="textRequired"
                name="number"
                type="text"
                placeholder={
                  (optionsData?.country?.short_name ? "  " : "") +
                  t("phone number")
                }
                containerStyle="mt-3 mb-2"
                inputContainerStyle=" "
                onChange={handleInputChange}
                value={payment?.number}
                icon={
                  <div className="pr-2">
                    {
                      DIAL_CODES[
                        optionsData?.country?.short_name?.toLowerCase()
                      ]
                    }
                  </div>
                }
              />
            </div>
          </div>
        </>
      ) : null}
    </form>
  );
};

export default SinglePaymentForm;

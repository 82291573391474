import React from "react";
import { useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";

import HelperFns, {
  showToast,
  formatOptions,
} from "../../../Helpers/HelperFns";
import Privileges from "../../../Constants/Privilages";
import { onFormResetAction } from "../../../Store/Actions";
import { conditionalFooterRowStyles } from "../../../Constants";
import { GET_AGENT_OVERDUE_ACTIVITIES_REPORT } from "../../../Graphql/query";

import Loader from "../../../Components/Loader";
import DataTable from "react-data-table-component";
import { LeadAgentSelect } from "../../../Components/CRM";
import { DateTimePickerForm, BSelect } from "form-builder";
import { ViewRelevantLeads } from "../../../Components/CRMFormModals";

const filtersReducerKey = "agentOverdueActivitiesReport";
const modalInitState = { isOpen: false, data: null };
const setFilters = (filters) => {
  return {
    ex_days: filters?.ex_days?.id,
    to: filters?.to?.format("YYYY-MM-DD"),
    from: filters?.from?.format("YYYY-MM-DD"),
    agents: filters?.agents ? filters?.agents?.map((agent) => agent?.id) : [],
  };
};

const AgentOverdueActivitiesReport = () => {
  const dispatch = useDispatch();

  // Local State
  const [data, setData] = React.useState([]);
  const [modalState, setModalState] = React.useState(modalInitState);

  // Reducer State
  const filters = useSelector((state) => state.crm[filtersReducerKey]);

  // Server State
  const { loading } = useQuery(GET_AGENT_OVERDUE_ACTIVITIES_REPORT, {
    variables: setFilters(filters),
    onCompleted: (res) => {
      setData(res?.agentOverdueActivitiesReport || []);
    },
    onError: (error) => {
      showToast(
        "error",
        error?.graphQLErrors?.[0]?.extensions?.reason ||
          error?.graphQLErrors?.[0]?.message ||
          error?.message
      );
    },
  });

  // Constants
  const canViewAll = HelperFns.checkPrivileges({
    privileges: [Privileges.VIEW_ALL_CRM_REPORTS],
  });
  const columns = React.useMemo(
    () => [
      { name: "Agent name", selector: "Agent", ignoreRowClick: true },
      {
        name: "No. of Overdue Activities",
        selector: "leadsCount",
        center: true,
        style: {
          "&:hover": {
            color: "#23aaeb",
            cursor: "pointer",
            textDecoration: "underline",
          },
        },
      },
      {
        name: "average days left Overdue Activities",
        selector: "avg_time",
        ignoreRowClick: true,
        center: true,
      },
    ],
    []
  );

  /* ↓ Helpers ↓ */

  const onOpenRelevantLeadsModal = (ids) => {
    const leadIDs = ids?.split(",");
    setModalState({ isOpen: true, data: { leadIDs } });
  };

  const handleSelectFilters = (val, { name }) => {
    dispatch(onFormResetAction(filtersReducerKey, { ...filters, [name]: val }));
  };
  const handleDateFiltersChange = (name, val) => {
    dispatch(onFormResetAction(filtersReducerKey, { ...filters, [name]: val }));
  };

  return (
    <>
      {/* Filters */}
      <div className="d-flex gap-60 justify-content-between align-items-end mb-3 mt-2">
        {canViewAll ? (
          <LeadAgentSelect
            isMulti
            isClearable
            name="agents"
            label="agent"
            value={filters.agents}
            onChange={handleSelectFilters}
            inputContainerStyle="w-100"
            containerStyle=" "
            rootStyle="flex-1"
          />
        ) : null}
        <div className="d-flex gap-20 align-items-end">
          <BSelect
            name="ex_days"
            label="X days"
            value={filters.ex_days}
            onChange={handleSelectFilters}
            inputContainerStyle="w-100"
            containerStyle="my-0"
            options={formatOptions([1, 2, 3, 4, 5])}
            rootStyle="min-w-150"
          />
          <DateTimePickerForm
            hasIcon
            isClearable
            label="from"
            value={filters.from}
            rootStyle="min-width-200"
            containerStyle="py-0"
            labelStyle="w-100 mb-2"
            datePickerContainer="w-100"
            onChange={(val) => handleDateFiltersChange("from", val)}
          />
          <DateTimePickerForm
            hasIcon
            isClearable
            label="to"
            value={filters.to}
            rootStyle="min-width-200"
            containerStyle="py-0"
            labelStyle="w-100 mb-2"
            datePickerContainer="w-100"
            onChange={(val) => handleDateFiltersChange("to", val)}
          />
        </div>
      </div>

      {/* Table */}
      <DataTable
        noHeader
        data={data}
        columns={columns}
        className="cards_table"
        progressPending={loading}
        progressComponent={<Loader />}
        conditionalRowStyles={conditionalFooterRowStyles}
        onRowClicked={(row) => onOpenRelevantLeadsModal(row?.leads_id)}
      />

      {/* Modals */}
      {modalState?.isOpen ? (
        <ViewRelevantLeads
          data={modalState.data}
          onClose={() => setModalState(modalInitState)}
        />
      ) : null}
    </>
  );
};

export default AgentOverdueActivitiesReport;

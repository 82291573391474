import gql from "graphql-tag";

import {
  announcementFragment,
  paginatorFragment,
  presetFragment,
} from "../fragments";

/*
  Offices
*/

/*
  Departments
*/

/*
  Positions
*/

/*
  Work Timings
*/

/*
  Time Range Profile Settings
*/

export const FETCH_TIME_RANGES_PROFILE_LIST = gql`
  query fetchTimeRangesProfileList(
    $first: Int! = 20
    $page: Int = 1
    $name: String
    $type: String
  ) {
    getTimeRangeProfilePresets(
      first: $first
      page: $page
      name: $name
      type: $type
    ) {
      data {
        id
        name
        type
        allow_overtime
        overtime_limit
        overtime_cutoff
        min_overtime_cutoff
        max_overtime_limit
        min_work_hours
        overtime_factor
      }
      paginatorInfo {
        ...paginator
      }
    }
  }
  ${paginatorFragment}
`;
export const FETCH_EMPLOYEE_GRACE_MINUTES_PROFILE_SETTINGS = gql`
  query fetchEmployeeGraceMinutesProfileList($employee_id: ID) {
    getGraceMinutes(emp_id: $employee_id) {
      id
      emp_id
      allow_grace_minutes
      sign_in_grace_minutes
      start
      canDelete
    }
  }
`;
export const FETCH_TIME_RANGES_PRESETS = gql`
  query fetchTimeRangesPresets {
    getTimeRangeProfilePresets(first: 999, page: 1) {
      data {
        presetId: id
        name
        type
        allow_overtime
        overtime_limit
        overtime_cutoff
        min_overtime_cutoff
        max_overtime_limit
        min_work_hours
        overtime_factor
      }
    }
  }
`;

/*
  Leave & Breaks
*/

/*
  Attendance Profiles
*/

/*
  Salary Configurations Presets
*/

export const salaryConfigurationPresetsQuery = gql`
  query salaryConfigurationPresets {
    presets {
      ...preset
    }
  }
  ${presetFragment}
`;

/*
  Allowances & Deductibles
*/

export const allowancesListQuery = gql`
  query allowancesList {
    allowances: Allowances {
      id
      name
      created_at
      updated_at
    }
  }
`;
export const deductiblesListQuery = gql`
  query deductiblesList {
    deductibles: Deductibles {
      id
      name
      created_at
      updated_at
    }
  }
`;

/*
  Holidays
*/

/*
  Documents
*/

/*
  Expense Categories
*/

/*
  Access levels
*/

/*
  Announcements
*/

export const announcementsListQuery = gql`
  query announcementList(
    $page: Int
    $first: Int!
    $type: String!
    $title: String
    $status: String
  ) {
    announcements(
      page: $page
      type: $type
      first: $first
      title: $title
      status: $status
    ) {
      data {
        ...announcement
        description
        published_at
        created_at
        user {
          name
        }
        file {
          id
          path
        }
      }
      paginatorInfo {
        ...paginator
      }

      # Options
    }
  }
  ${announcementFragment}
  ${paginatorFragment}
`;

export const announcementFormQuery = gql`
  query announcementForm(
    $id: ID
    $isAdminPanel: Boolean!
    $isIncludeAnnouncement: Boolean!
  ) {
    announcement(id: $id) @include(if: $isIncludeAnnouncement) {
      ...announcement
      send_to
    }

    #Options
    companies(first: 9999, input: { company_status: "active" })
      @include(if: $isAdminPanel) {
      data {
        id
        name
      }
    }
    offices: company_offices(first: 9999) @skip(if: $isAdminPanel) {
      data {
        id
        name
      }
    }
    positions: company_positions(first: 9999) @skip(if: $isAdminPanel) {
      data {
        id
        name
      }
    }
    departments: company_departments(first: 9999) @skip(if: $isAdminPanel) {
      data {
        id
        name
      }
    }
    employees: company_users(input: { page_flag: null, status: ["Active"] })
      @skip(if: $isAdminPanel) {
      data {
        id
        name
      }
    }
    managers: activeManagersAndCopiedManagers @skip(if: $isAdminPanel) {
      id
      name
    }
  }
  ${announcementFragment}
`;

// handle fetch system users query
export const systemUsersListQuery = gql`
  query fetchUserSystemList($page: Int, $first: Int, $input: UserFilterInput) {
    system_users(page: $page, first: $first, input: $input) {
      data {
        id
        name
        active
        has_temporary_password
        phone
        phone_country_code
        phone_verified
        company {
          id
          name
        }
        phone_verified_by {
          id
          name
        }
      }
      paginatorInfo {
        ...paginator
      }
    }
  }
  ${paginatorFragment}
`;

import React from "react";

import chroma from "chroma-js";

import { BSelect } from "form-builder";

const tagSelectStyle = {
  control: (styles) => ({ ...styles, backgroundColor: "white !important" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = data?.color ? chroma(data?.color) : "";
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? `${data.color} !important`
        : isFocused
        ? color ? `${color?.alpha(0.1)?.css()} !important` : "" // prettier-ignore
        : undefined,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? chroma.contrast(color, "white") > 2 ? "white !important" : "black !important" // prettier-ignore
        : `${data.color} !important`,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? `${data.color} !important`
            : color ? `${color?.alpha(0.3)?.css()} !important` : "" // prettier-ignore
          : undefined,
      },
    };
  },
  multiValue: (styles, { data }) => {
    const color = data?.color ? chroma(data?.color) : "";
    return {
      ...styles,
      backgroundColor: color ? `${color?.alpha(0.1)?.css()} !important` : "", // prettier-ignore
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: `${data.color} !important`,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: `${data.color} !important`,
    ":hover": {
      color: "white !important",
      backgroundColor: `${data.color} !important`,
    },
  }),
};

const CRMTagsSelect = (props) => {
  return <BSelect styles={tagSelectStyle} {...props} />;
};

export default CRMTagsSelect;

import React from "react";

import { Box, CircularProgress } from "@mui/material";

const BlockUi = ({ children, blocking, tag: Tag, ...props }) => {
  return (
    <Tag {...props}>
      {blocking ? (
        <Box
          sx={{
            inset: 0,
            zIndex: 1010,
            cursor: "wait",
            minHight: "2em",
            overflow: "hidden",
            textAlign: "center",
            position: "absolute",
          }}
        >
          <Box
            sx={{
              opacity: 0.5,
              width: "100%",
              height: "100%",
              backgroundColor: "white",
              filter: "alpha(opacity = 50)",
            }}
          />
          <CircularProgress
            sx={{
              top: "50%",
              left: "50%",
              zIndex: 10001,
              position: "absolute",
              transform: "translateY(-50%)",
            }}
          />
        </Box>
      ) : null}
      {children}
    </Tag>
  );
};

export default BlockUi;

import React, { useEffect } from "react";
import MainModal from "../../Components/MainModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import HelperFns, { showToast } from "../../Helpers/HelperFns";
import { Alert, AlertTitle, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Constants from "../../Constants";
import { addWorkRemotelySettingsForEmployeesMutation } from "../../Graphql/mutation";
import { useMutation } from "@apollo/client";
import InputForm from "../../Builder/Form/InputForm";

const WorkRemotelyModal = (props) => {
  const { t } = useTranslation();

  const initState = {
    canWorkRemote: false,
    employee_can_request: false,
    allow_work_remotely_cutoff: false,
    flexibleRemote: true,
    add_multiple: true,
    remoteDays: [],
    canExchangeDays: false,
    maxRemoteDaysPerWeek: 1,
    work_remotely_cutoff: 0,
  };

  const [input, setInput] = useState(initState);

  // add Work Remotely Settings For Employees
  const [
    addWorkRemotelySettingsForEmployees,
    { loading: submitLoading, submitError },
  ] = useMutation(addWorkRemotelySettingsForEmployeesMutation, {
    onError: (err) => {
      console.log({ err });
      if (err?.graphQLErrors[0]?.extensions?.validation) {
        let validation = err?.graphQLErrors[0]?.extensions?.validation;
        setValid({ isValid: false, msg: Object.values(validation).toString() });
      } else
        showToast(
          "error",
          err?.graphQLErrors[0]?.extensions?.reason || err.message
        );
    },
    onCompleted: (res) => {
      if (res?.addWorkRemotelySettingsForEmployees?.status === "success") {
        props.resetSelection();
        props.refetch();
        props.closeWorkRemotelyModal();
      }
      showToast(
        res?.addWorkRemotelySettingsForEmployees?.status,
        res?.addWorkRemotelySettingsForEmployees.message
      );
    },
  });

  // validate state
  const [valid, setValid] = useState(true);

  const validate = () => {
    setValid({
      isValid: Boolean(
        input.maxRemoteDaysPerWeek || input.work_remotely_cutoff
      ),
      msg: "max days cannot be embty",
    });
    return Boolean(input.maxRemoteDaysPerWeek || input.work_remotely_cutoff);
  };

  const handleSave = () => {
    if (!validate()) return;
    HelperFns.checkPassword("defaut_warning_messsage", "", "Confirm", "", () =>
      onsubmit()
    );
  };

  const onsubmit = () => {
    addWorkRemotelySettingsForEmployees({
      variables: {
        input: {
          ...input,
          work_remotely_cutoff: input?.work_remotely_cutoff
            ? HelperFns.hoursToTime(input?.work_remotely_cutoff)
            : null,
          employees_ids: props.selectedEmployees,
        },
      },
    });
  };

  useEffect(() => {
    return () => {
      setInput(initState);
      setValid({
        isValid: true,
        msg: null,
      });
    };
  }, [props.workRemotelyModal.isOpen]);

  useEffect(() => {
    return () => {
      setInput((prev) => ({
        ...prev,
        employee_can_request: false,
        allow_work_remotely_cutoff: false,
        maxRemoteDaysPerWeek: 1,
        work_remotely_cutoff: 0,
      }));
    };
  }, [input.canWorkRemote]);

  const handleCancle = () => {
    props.closeWorkRemotelyModal();
  };

  const handleInputChange = (e) => {
    if (e.target.type == "checkbox") {
      setInput((prev) => ({ ...prev, [e.target.name]: e.target.checked }));
    } else {
      setInput((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };

  return (
    <MainModal
      isOpen={props.workRemotelyModal.isOpen}
      size="lg"
      modalTitle={t("Bulk Actions")}
      btnSubmitLoading={submitLoading}
      btnOnClick={handleSave}
      toggle={handleCancle}
      className="p-5 "
    >
      <div className="p-2">
        <Alert severity="info">
          <AlertTitle>{t("Change The Remote work settings")}</AlertTitle>
          <strong>
            {t("Employees will be effected", {
              count: props.selectedEmployees.length,
            })}
          </strong>
        </Alert>
      </div>

      <div className={"p-2 " + (valid ? "" : "invalid-container-style")}>
        <strong className="d-block mt-4 mb-2 blue-color">
          {t("Remote work settings")}
        </strong>

        <label className={"checkbox"}>
          <input
            type="checkbox"
            name={"canWorkRemote"}
            value={input.canWorkRemote}
            onChange={(e) => handleInputChange(e)}
            checked={input.canWorkRemote}
            {...props}
          />
          <span></span>

          <div className={"booleanInputLabelStyle lightActiveColor"}>
            {t("Allow Employee To Work From Home")}
          </div>
        </label>

        {input.canWorkRemote && (
          <div>
            <label className={"checkbox"}>
              <input
                type="checkbox"
                name={"allow_work_remotely_cutoff"}
                value={input.allow_work_remotely_cutoff}
                onChange={(e) => handleInputChange(e)}
                checked={input.allow_work_remotely_cutoff}
                {...props}
              />
              <span></span>

              <div className={"booleanInputLabelStyle lightActiveColor"}>
                {t(
                  "Employee must submit work remotely request before the start of work day"
                )}
              </div>
            </label>

            {input.allow_work_remotely_cutoff && (
              <InputForm
                label="Employee must submit work remotely request before the start of work day by"
                name="work_remotely_cutoff"
                type="text"
                hasSuffix
                suffixTitle="Hours"
                containerStyle="leaves_field_container my-2 d-flex justify-content-between"
                inputContainerStyle="d-flex align-items-center"
                labelStyle="text-13px"
                onChange={handleInputChange}
                value={input.work_remotely_cutoff}
              />
            )}

            <label className={"checkbox"}>
              <input
                type="checkbox"
                name={"employee_can_request"}
                value={input.employee_can_request}
                onChange={(e) => handleInputChange(e)}
                checked={input.employee_can_request}
                {...props}
              />
              <span></span>

              <div className={"booleanInputLabelStyle lightActiveColor"}>
                {t("Allow Employee To Request Work From Home")}
              </div>
            </label>

            <div className="input-container-style-default maxRemoteDaysPerWeek-days-style my-3">
              <label
                className={"label-style-default validity-label-style mr-3"}
              >
                {t("Max. Days Per Week")}
              </label>
              <div
                className={"input-container-style-default step-input-container"}
              >
                <IconButton
                  color="primary"
                  aria-label="add to shopping cart"
                  className="buttonRoot"
                  onClick={() => {
                    setInput((prev) => ({
                      ...prev,
                      maxRemoteDaysPerWeek:
                        Number(prev.maxRemoteDaysPerWeek) - 1,
                    }));
                  }}
                  disabled={input.maxRemoteDaysPerWeek === 1}
                >
                  <RemoveIcon classes={{ root: "iconRoot" }}></RemoveIcon>
                </IconButton>
                <input
                  dir="auto"
                  className={"stepInputRoot"}
                  name={"maxRemoteDaysPerWeek"}
                  value={input.maxRemoteDaysPerWeek}
                  onChange={handleInputChange}
                  onBlur={() => {}}
                  type="number"
                />
                <IconButton
                  color="primary"
                  aria-label="add to shopping cart"
                  className="buttonRoot"
                  onClick={() => {
                    setInput((prev) => ({
                      ...prev,
                      maxRemoteDaysPerWeek:
                        Number(prev.maxRemoteDaysPerWeek) + 1,
                    }));
                  }}
                  disabled={input.maxRemoteDaysPerWeek === 7}
                >
                  <AddIcon classes={{ root: "iconRoot" }}></AddIcon>
                </IconButton>
              </div>
            </div>
          </div>
        )}
        <div
          className={"pt-3 " + (valid.isValid ? "" : "invalid-container-style")}
        >
          <div className="validity-msg-style text-left">{t(valid.msg)}</div>
        </div>
      </div>
    </MainModal>
  );
};

export default WorkRemotelyModal;

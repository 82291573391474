import React, { useEffect, useState } from "react";
import MainModal from "../../../Components/MainModal";
import { useDispatch, useSelector } from "react-redux";
import {
  onFormResetAction,
  toggleAcceptAssetRequestModal,
  toggleEmployeeAssetRequestModal,
} from "../../../Store/Actions";
import { useTranslation } from "react-i18next";
import { InputForm, BSelect } from "form-builder";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  GET_ACCEPT_ASSET_REQUEST_DATA,
  GET_ASSET_ITEMS_ACCORDING_TO_WAREHOUSE,
  GET_ASSET_WAREHOUSES,
  GET_ASSETS_MENU,
  GET_WAREHOUSES_MENU,
} from "../../../Graphql/query";
import {
  ACCEPT_ASSET_REQUEST,
  REQUEST_ASSETS,
} from "../../../Graphql/mutation";
import { showToast } from "../../../Helpers/HelperFns";
import Dropzone from "react-dropzone-uploader";

const formName = "acceptAssetRequestForm";
const formNameValidation = "acceptAssetRequestClientValidations";

const AcceptAssetRequestModal = ({ handleRefetch = () => undefined }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [requestFiles, setRequestFiles] = useState([]);

  const serializedAttachemnts = requestFiles?.map(({ file, extension }) => ({
    file,
    extension,
  }));

  const [attemptGetAcceptRequestData, { data, loading }] = useLazyQuery(
    GET_ACCEPT_ASSET_REQUEST_DATA
  );

  const request = useSelector(
    (state) => state?.assets?.acceptAssetRequestModal?.request
  );
  useEffect(() => {
    if (request?.assetType) {
      attemptGetAcceptRequestData({
        variables: {
          input: {
            filter: {
              asseTypeId: request?.assetType?.id,
            },
          },
        },
      });
    }
  }, [request?.assetType]);

  const [attemptGetItems, { loading: itemsLoading, data: itemsQueryData }] =
    useLazyQuery(GET_ASSET_ITEMS_ACCORDING_TO_WAREHOUSE);

  const [
    attemptGetAssetWarehouses,
    { loading: warehousesLoading, data: warehousesQueryData },
  ] = useLazyQuery(GET_ASSET_WAREHOUSES);

  const [formSubmitting, setFormSubmitting] = useState(false);

  const isOpen = useSelector(
    (state) => state?.assets?.acceptAssetRequestModal?.isOpen
  );

  const formData = useSelector((state) => state?.assets?.[formName]);

  const isSerializedAsset =
    data?.companyAssets?.data
      ?.find((asset) => asset?.id == formData?.assetId)
      ?.product_type?.toLowerCase() == "serialized";

  useEffect(() => {
    if (!!formData?.warehouseId && isSerializedAsset) {
      attemptGetItems({
        variables: {
          assetId: formData?.assetId,
          warehouseId: formData?.warehouseId,
        },
      });
    }
  }, [formData?.warehouseId]);

  useEffect(() => {
    if (formData?.assetId && isSerializedAsset) {
      attemptGetAssetWarehouses({
        variables: {
          assetId: formData?.assetId,
        },
      });
    }
  }, [formData?.assetId]);

  const clientValidations = useSelector(
    (state) => state?.assets?.[formNameValidation]
  );

  const [attemptAcceptAssetRequest, { loading: acceptRequestLoading }] =
    useMutation(ACCEPT_ASSET_REQUEST, {
      onCompleted: (data) => {
        if (data?.acceptAssetRequest?.__typename == "GeneralException") {
          showToast("error", data?.acceptAssetRequest?.message);

          return;
        }
        dispatch(
          toggleAcceptAssetRequestModal({ isOpen: false, request: null })
        );
        handleRefetch();
      },
      onError(err) {
        showToast(
          "error",
          err?.graphQLErrors?.[0].extensions?.reason ?? err?.message
        );
      },
    });

  useEffect(() => {
    return () => {
      dispatch(onFormResetAction(formName));
      dispatch(onFormResetAction(formNameValidation));
    };
  }, []);

  const dismiss = () => {
    dispatch(toggleAcceptAssetRequestModal({ isOpen: false, request: null }));
  };

  const handleSubmit = () => {
    setFormSubmitting(true);

    if (!clientValidations?.length) {
      attemptAcceptAssetRequest({
        variables: {
          input: {
            asset_request_id: request?.id,
            asset_id: formData?.assetId,
            ...(isSerializedAsset
              ? {
                  items: formData?.itemIds?.map((id) => ({ id })),
                }
              : {
                  number_of_items: +formData?.numberOfUnits,
                }),

            ...(!!serializedAttachemnts[0]
              ? {
                  file: { ...serializedAttachemnts[0] },
                }
              : {}),
          },
        },
      });
    }
  };

  // function to add object to requestFiles array
  const addRequestFileObject = (file) => {
    setRequestFiles((requestFiles) => [...requestFiles, file]);
  };

  // function to remove an object from an requestFiles array
  const removeRequestFileObject = (id) => {
    setRequestFiles((requestFiles) =>
      requestFiles.filter((file) => file?.id !== id)
    );
  };

  const handleImageChange = ({ file, meta }, status) => {
    // to convert image to base64 string
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      let base64 = reader.result?.split(",");
      let fileType = file?.type?.split("/");

      if (status == "done") {
        let target = {
          file: base64[1],
          extension: fileType[1],
          id: meta?.id,
        };
        addRequestFileObject(target);
      }
    };

    if (status == "removed") {
      removeRequestFileObject(meta?.id);
    }
    return;
  };

  const removeAllSelectedFiles = (_, allFiles) => {
    allFiles?.forEach((f) => f?.remove());
  };

  return (
    <MainModal
      isOpen={isOpen}
      toggle={dismiss}
      modalTitle={t("accept asset request")}
      btnLabel={t("save")}
      btnOnClick={handleSubmit}
      btnSubmitLoading={acceptRequestLoading}
    >
      <div className="mb-3">
        <p className="m-0 font-weight-bold">{t("asset type")}</p>
        <p className="m-0">{request?.assetType?.name}</p>
      </div>

      <div className="mb-3">
        <p className="m-0 font-weight-bold">{t("description")}</p>
        <p className="m-0">{request?.description}</p>
      </div>

      <BSelect
        formName={formName}
        formNameValidation={formNameValidation}
        formSubmitting={formSubmitting}
        reducer="assets"
        label="asset"
        name="assetId"
        labelStyle="mb-2"
        keepDefaultStyle
        isLoading={loading}
        options={
          data?.companyAssets?.data?.map((asset) => ({
            id: asset?.id,
            name: `${asset?.name} - ${asset?.brand} - ${asset?.model}`,
          })) ?? []
        }
        inputContainerStyle=""
        placeholder={t("select asset")}
        icon="faWarehouse"
        rootStyle="mb-3"
        validateBy="textRequired"
      />

      {formData?.assetId ? (
        isSerializedAsset ? (
          <>
            <BSelect
              formName={formName}
              formNameValidation={formNameValidation}
              formSubmitting={formSubmitting}
              reducer="assets"
              label="storage location"
              name="warehouseId"
              labelStyle="mb-2"
              keepDefaultStyle
              isLoading={loading}
              options={warehousesQueryData?.fetchAsset?.warehouses ?? []}
              inputContainerStyle=""
              placeholder={t("select storage location")}
              icon="faWarehouse"
              rootStyle="mb-3"
              validateBy="textRequired"
            />

            <BSelect
              formName={formName}
              formNameValidation={formNameValidation}
              formSubmitting={formSubmitting}
              reducer="assets"
              label="serial numbers"
              name="itemIds"
              labelStyle="mb-2"
              keepDefaultStyle
              isLoading={itemsLoading}
              options={
                itemsQueryData?.fetchAsset?.items_according_to_warhouse ?? []
              }
              inputContainerStyle=""
              placeholder={t("select storage location")}
              optionLabel="serial_number"
              optionValue="id"
              isMulti
              validateBy="arrayRequired"
            />
          </>
        ) : (
          <InputForm
            formName={formName}
            formNameValidation={formNameValidation}
            formSubmitting={formSubmitting}
            reducer="assets"
            validateBy="positiveNumber"
            name="numberOfUnits"
            placeholder={t("No. of units")}
            label="No. of units"
            labelStyle="mb-2"
            containerStyle="mt-0 mb-3"
          />
        )
      ) : null}

      <div className="row">
        <div className="col-12">
          <label className="mb-2">{t("attachements")}</label>

          <div className="documents_dropzone_style">
            <Dropzone
              onChangeStatus={handleImageChange}
              canRemove
              accept="image/*"
              name="file"
              multiple={false}
              maxFiles={1}
              inputContent={t("face_placeholder")}
              onSubmit={removeAllSelectedFiles}
            />
          </div>
        </div>
      </div>
    </MainModal>
  );
};

export default AcceptAssetRequestModal;

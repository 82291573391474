import React from "react";
import { useSelector } from "react-redux";

import axios from "axios";
import { Config } from "../../Config";
import { debounce } from "lodash";
import { showToast } from "../../Helpers/HelperFns";

import { BSelect } from "form-builder";

const LeadsServerRestSelect = ({ type, ...props }) => {
  const token = useSelector((state) => state?.auth?.token);

  const fetchOptions = (name, callback) => {
    if (name.length < 2) {
      callback([]);
      return;
    }

    axios
      .get(`${Config.API_URL}/api/lead-names?type=${type}&name=${name}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        callback(res?.data?.data || []);
      })
      .catch((error) => {
        callback([]);
        showToast("error", error?.response?.data?.message);
      });
  };

  const debouncedFetchOptions = React.useCallback(
    debounce((inputValue, callback) => fetchOptions(inputValue, callback), 500),
    []
  );

  const loadOptions = (inputValue, callback) => {
    debouncedFetchOptions(inputValue, callback);
  };

  return <BSelect {...props} async loadOptions={loadOptions} />;
};

export default LeadsServerRestSelect;

import React, { useEffect, useState } from "react";
import MainModal from "../../MainModal";
import { InputForm } from "form-builder";
import { connect } from "react-redux";
import {
  dismissEditCompanyNameModalAction,
  updateCompanyNameAction,
} from "../../../Store/Actions";
import { useTranslation } from "react-i18next";

const formName = "editCompanyNameModal";
const formNameValidation = "editCompanyNameModalValidation";
const formServerValidation = "editCompanyNameModalServerValidation";

const EditCompanyNameModal = (props) => {
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const FormProps = {
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting: isSubmitting,
  };

  const handleSubmitBtn = () => {
    setIsSubmitting(true);
    if (props.modalValidation.length) {
      return;
    }

    props.updateCompanyNameAction({
      data: props.editCompanyNameModal,
      ref: "editCompanyNameModalActions",
    });
  };

  const isModalVissible = props.modalData.isVissible;
  const modalMessage = props.modalData.modalMessage;
  const isLoading = props.modalData.isLoading;

  const toggleModal = () => {
    props.dismissEditCompanyNameModalAction({
      ref: "editCompanyNameModalActions",
      formName,
    });
  };

  useEffect(() => {
    setIsSubmitting(false);
  }, [isModalVissible]);

  return (
    <MainModal
      isOpen={isModalVissible}
      toggle={toggleModal}
      modalTitle={t("Edit Company Name")}
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={isLoading}
      className="company_form_wrapper_style"
    >
      <>
        <div className="row employee-form px-3">
          <div className="col-12 my-0">
            <h4 className="sub-title-style mb-1">{t("Company Info")}</h4>
            <hr />

            <div className="row mt-3 mb-2">
              <div className="col-12 mb-2">
                <InputForm
                  {...FormProps}
                  validateBy="textRequired"
                  name="company_name"
                  validationName={`input.company_input.name`}
                  placeholder={t("name")}
                  label="Company Name"
                  labelStyle="mb-2"
                  containerStyle="mt-0"
                  inputContainerStyle=" "
                  icon="person"
                />
              </div>
            </div>
          </div>

          {/* (Start) Error Message */}
          {modalMessage && isSubmitting && (
            <div className="warnig-msg-style">{modalMessage}</div>
          )}
          {/* (End) Error Message */}
        </div>
      </>
    </MainModal>
  );
};

const mapStateToProps = (state) => ({
  modalData: state.super.editCompanyNameModalActions,
  modalValidation: state.super[formNameValidation],
  editCompanyNameModal: state.super[formName],
  serverVaildation: state.super[formServerValidation],
});

export default connect(mapStateToProps, {
  dismissEditCompanyNameModalAction,
  updateCompanyNameAction,
})(EditCompanyNameModal);

import React from "react";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import gql from "graphql-tag";
import { connect } from "react-redux";
import Privilages from "../../Constants/Privilages";

import { Link } from "react-router-dom";
import { Skeleton } from "@mui/material";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";

const GET_DASHBOARD_DOCS = gql`
  query getDashboardDocs($company_id: ID!) {
    company_profile_missing_documents(
      input: { company_id: $company_id, page_flag: "dashboard" }
    ) {
      number_of_documents
    }
    company_profile_expired_user_documents(
      input: { company_id: $company_id, page_flag: "dashboard" }
    ) {
      number_of_documents
    }
  }
`;

const Documents = (props) => {
  const { t } = useTranslation();

  // Server State
  const { data, loading } = useQuery(GET_DASHBOARD_DOCS, {
    variables: { company_id: props?.company_id },
  });

  // Constants
  const missingDocuments = data?.company_profile_missing_documents;
  const expiredDocuments = data?.company_profile_expired_user_documents;

  return (
    <div className="mb-4">
      <div className="side-title">
        {loading ? (
          <Skeleton variant="text" width={160} height={30} />
        ) : (
          t("documents")
        )}
      </div>
      <div className="row justify-content-between">
        {loading ? (
          <div className="col">
            <Skeleton variant="rectangular" width="100%" height={90} />
          </div>
        ) : (
          <div className="col d-flex align-items-center justify-content-between documents-block">
            <div>
              <div className="side-title mb-0">{t("missing documents")}</div>
              <HasPrivileges
                reqireMain={[Privilages.VIEW_MISSING_EXPIRED_DOCUMENT]}
                allowBP
              >
                <Link className="mt-2" to="/employees?tab=employee-documents">
                  {t("show_all")}
                </Link>
              </HasPrivileges>
            </div>

            <HasPrivileges
              reqireMain={[Privilages.VIEW_MISSING_EXPIRED_DOCUMENT]}
              allowBP
              altChildren={<p>{missingDocuments?.number_of_documents}</p>}
            >
              <Link to="/employees?tab=employee-documents">
                <p>{missingDocuments?.number_of_documents}</p>
              </Link>
            </HasPrivileges>
          </div>
        )}
        {loading ? (
          <div className="col">
            <Skeleton variant="rectangular" width="100%" height={90} />
          </div>
        ) : (
          <div className="col d-flex align-items-center justify-content-between documents-block">
            <div>
              <div className="side-title mb-0">{t("expired documents")}</div>
              <HasPrivileges
                reqireMain={[Privilages.VIEW_MISSING_EXPIRED_DOCUMENT]}
                allowBP
              >
                <Link className="mt-2" to="/employees?tab=employee-documents">
                  {t("show_all")}
                </Link>
              </HasPrivileges>
            </div>
            <HasPrivileges
              reqireMain={[Privilages.VIEW_MISSING_EXPIRED_DOCUMENT]}
              allowBP
              altChildren={<p>{expiredDocuments?.number_of_documents}</p>}
            >
              <Link to="/employees?tab=employee-documents">
                <p>{expiredDocuments?.number_of_documents}</p>
              </Link>
            </HasPrivileges>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    company_id: state.auth?.userProfile?.company?.id,
  };
};

export default connect(mapStateToProps)(Documents);

import React, { useEffect } from "react";
import { InputForm, DateTimePickerForm, BSelect } from "form-builder";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import { faTimes, faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  fetchAdminHolidaysAction,
  onInputResetAction,
  showAdminHolidaysModalAction,
  deleteAdminHolidayAction,
} from "../../Store/Actions";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import AdminHolidaysModal from "../../Components/AdminPanelModals/AdminHolidaysModal";
import swal from "sweetalert";
import Loader from "../../Components/Loader";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import moment from "moment";
import { useTranslation } from "react-i18next";
import HelperFns from "../../Helpers/HelperFns";
import {
  EditIconButton,
  RemoveIconButton,
} from "../../Components/IconButtonWithTooltip";

const dataTableRef = "adminHolidaysList";

const AdminHolidaysList = (props) => {
  const { t } = useTranslation();

  const columns = [
    {
      name: t("name"),
      wrap: true,
      selector: "name",
      sortable: false,
      grow: 1,
      cell: (row) => (
        <div dir={HelperFns.isStringRTL(row.name) ? "rtl" : "ltr"}>
          {row.name}
        </div>
      ),
    },
    {
      name: t("country"),
      wrap: true,
      sortable: false,
      grow: 1.5,
      cell: (row) => (
        <>
          {row?.countries?.map(
            (country, i) =>
              `${i !== 0 && country?.name ? " , " : ""}${country?.name}`
          )}
        </>
      ),
    },
    {
      name: t("from"),
      wrap: true,
      selector: "start_date",
      sortable: false,
      grow: 1,
      cell: (row) => (
        <>
          {row?.start_date != null
            ? moment(row?.start_date).format("DD - MM - yyyy")
            : ""}
        </>
      ),
    },
    {
      name: t("to"),
      wrap: true,
      selector: "end_date",
      sortable: false,
      grow: 1,
      cell: (row) => (
        <>
          {row?.end_date != null
            ? moment(row?.end_date).format("DD - MM - yyyy")
            : ""}
        </>
      ),
    },
    {
      name: t("actions"),
      wrap: true,
      selector: "action",
      grow: 0.5,
      cell: (row) => (
        <div className="cards_table_actions">
          <HasPrivileges
            reqireMain={[
              Privilages.SUPER_PRIVILEGE,
              Privilages.ADD_EDIT_DELETE_ADMIN_HOLIDAYS,
            ]}
          >
            <EditIconButton onClick={() => handleEdit(row)} />
          </HasPrivileges>

          <HasPrivileges
            reqireMain={[
              Privilages.SUPER_PRIVILEGE,
              Privilages.ADD_EDIT_DELETE_ADMIN_HOLIDAYS,
            ]}
          >
            <RemoveIconButton onClick={() => handleDelete(row)} />
          </HasPrivileges>
        </div>
      ),
      ignoreRowClick: true,
    },
  ];

  // handle edit admin holiday modal
  const handleEdit = (row) => {
    let data = {
      id: row?.id,
      name: row?.name,
      start_date: row?.start_date,
      end_date: row?.end_date,
      countries_ids: row?.countries?.map((country) => country?.id),
    };
    props.showAdminHolidaysModalAction({
      data,
      ref: "adminHolidaysModalActions",
      formName: "adminHolidaysModal",
    });
  };

  // handle add admin holiday modal
  const handleAdd = () => {
    let data = {
      id: null,
      name: "",
      start_date: "",
      end_date: "",
      countries_ids: [],
    };
    props.showAdminHolidaysModalAction({
      data,
      ref: "adminHolidaysModalActions",
      formName: "adminHolidaysModal",
    });
  };

  // handle delete admin holiday
  const handleDelete = (row) => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        props.deleteAdminHolidayAction(row?.id);
      }
    });
  };

  useEffect(() => {
    props.fetchAdminHolidaysAction({
      ref: dataTableRef,
      [dataTableRef]: props.data[dataTableRef],
    });
    return () => {
      props.onInputResetAction(dataTableRef, "name");
      props.onInputResetAction(dataTableRef, "year");
      props.onInputResetAction(dataTableRef, "country_id");
    };
  }, []);

  const handleFilter = () => {
    props.fetchAdminHolidaysAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: 1,
        },
      },
    });
  };

  const useDidFilterUpdate = useDidUpdateEffect(handleFilter, [
    props.data[dataTableRef].name,
    props.data[dataTableRef].year,
    props.data[dataTableRef].country_id,
  ]);

  const handlePaginate = (
    page = props.data[dataTableRef].pagination.currentPage
  ) => {
    console.log("@pagination effect");
    props.fetchAdminHolidaysAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: page,
        },
      },
    });
  };

  return (
    <>
      <div className="admin_holidays_wrapper_style">
        {props?.isDeleteAdminHolidayLoading ? (
          <div className="loader_wrapper_style">
            <Loader />
          </div>
        ) : null}

        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mb-2">
          <div className="my-2 col flex-row px-0">
            <InputForm
              reducer="admin"
              name="name"
              formName={dataTableRef}
              placeholder={t("search")}
              inputContainerStyle=" "
              containerStyle=" "
            />
          </div>

          <div className="col my-2">
            <BSelect
              name="country_id"
              optionLabel="name"
              optionValue="id"
              reducer="admin"
              options={props?.data?.holidaysCountriesFilterOptions}
              formName={dataTableRef}
              keepDefaultStyle
              containerStyle=""
              isClearable
              placeholder={t("select country")}
            />
          </div>

          <div className="mx-2">
            <DateTimePickerForm
              name="year"
              formName={dataTableRef}
              label={t("year")}
              hasIcon
              reducer="admin"
              labelStyle="label-style mb-0"
              containerStyle="container-style-default flex-nowrap"
              rootStyle="att-datepicker-style"
              placeholder={t("select year")}
              allowClear
              picker="year"
              requestFormat="yyyy"
              pickerFormat="yyyy"
              format="yyyy"
            />
          </div>

          <HasPrivileges
            reqireMain={[
              Privilages.SUPER_PRIVILEGE,
              Privilages.ADD_EDIT_DELETE_ADMIN_HOLIDAYS,
            ]}
          >
            <div className="my-2 pl-3 text-right">
              <button
                className="btn add_new_btn_style"
                type="button"
                onClick={handleAdd}
              >
                <FontAwesomeIcon className="mr-2" icon={faPlus} />
                {t("add new")}
              </button>
            </div>
          </HasPrivileges>
        </div>

        <DataTable
          noDataComponent={<div className="p-4"> {t("no_records")} </div>}
          className="cards_table"
          columns={columns}
          data={props.data[dataTableRef].data}
          noHeader
          persistTableHead
          paginationComponent={() => (
            <Pagination
              tableRef={dataTableRef}
              styleWraper=""
              onPaginate={handlePaginate}
              reducer="admin"
            />
          )}
          pagination={true}
          paginationServer={true}
          progressPending={props.data[dataTableRef].isLoading}
          progressComponent={<Loader />}
        />
      </div>

      {/* Start of Admin Hoildays Form Modal */}
      <AdminHolidaysModal />
      {/* End of Admin Hoildays Form Modal */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.admin,
    isDeleteAdminHolidayLoading: state.admin.deleteAdminHolidaysLoading,
  };
};

export default connect(mapStateToProps, {
  fetchAdminHolidaysAction,
  onInputResetAction,
  showAdminHolidaysModalAction,
  deleteAdminHolidayAction,
})(AdminHolidaysList);

import { put, call, takeLatest, takeEvery, select } from "redux-saga/effects";
import Types from "../Actions/types";
import Helper from "../../Helpers/HelperFns";

import * as yup from "yup";

import {
  fetchProfileSuccess,
  fetchAttendanceRecordsSuccess,
  fetchAttendanceRecordsFailed,
  attendanceActionModalSuccess,
  attendanceActionModalFailed,
  cancelRequestSuccess,
  cancelRequestFailed,
  dismissEditLeavesModalAction,
  fetchHistoryListSuccess,
  fetchHistoryListFailed,
  createSignInAction,
  createSignInSuccess,
  createSignInFailed,
  showDismissHomeRequestAlert,
  hideDismissHomeRequestAlert,
  createSignOutSuccess,
  createSignOutFailed,
  showForceSignOutAlert,
  hideForceSignOutAlert,
  refreshSignInOutAction,
  refreshSignInOutSuccess,
  forgotPasswordFinish,
  resetPasswordSuccess,
  resetPasswordFailed,
  loginAction,
  createSignOutAction,
  upsertUserLocaleSuccess,
  upsertUserLocaleFailed,
  upsertClaimsRequestSuccess,
  upsertClaimsRequestFailed,
  cancelClaimRequestSuccess,
  cancelClaimRequestFailed,
  fetchPayrollReportListFailed,
  fetchMyPayrollSuccess,
  editAttRequestModalFailed,
  editAttRequestModalSuccess,
  fetchHistoryListAttempt,
  viewAttRequestModalSuccess,
  viewAttRequestModalFailed,
  hideViewAttRequestModal,
  upsertSickRequestSuccess,
  fetchTotalEmployeeRequests,
  fetchUserPettyCashListSuccess,
  fetchUserPettyCashListFailed,
  fetchUserProfileCurrentBalanceSuccess,
  fetchUserProfileCurrentBalanceFailed,
  fetchCompanyExpensesCategoriesAction,
  upsertCustomRequestSuccess,
  checkAllNotificationToReadSuccess,
  checkAllNotificationToReadFailed,
  fetchCurrenciesInExpenseFormSuccess,
  showSuccessToast,
  showErrorToast,
  showToast,
  refetchFromSagaToHook,
  resetRefetchFromSagaToHook,
} from "../Actions";

import { fetchAttendanceRecords } from "../../Services/UserService";

import {
  cancelRqeuest,
  postNormalRequest,
  postUnpaidRequest,
  postPermissionRequest,
  postHalfdayRequest,
  postEmergencyRequest,
  postHomeExchangeRequest,
  postHomeFlexRequest,
  fetchHistoryList,
  craeteSignIn,
  dismissHomeSignIn,
  createSignOut,
  refreshSignInOut,
  forgotPasswordRequest,
  updateForgottenPassword,
  getSignInInfo,
  getSignOutInfo,
  upsertUserLocale,
  upsertClaimRequest,
  cancelClaimRqeuest,
  fetchMyPayroll,
  editAttRequestModal,
  viewAttRequestModal,
  upsertSickLeavesRequest,
  fetchUserPettyCashList,
  fetchUserProfileCurrentBalance,
  upsertCustomLeavesRequest,
  checkAllNotificationToRead,
} from "../../Services/Client";
import history from "../../Helpers/Navigations/history";

export const getUserReducer = (state) => state.user;
export const getAuthReducer = (state) => state.auth;
export const getSuperReducer = (state) => state.super;

export function* handleCancelUserRequestSaga({ payload }) {
  try {
    const response = yield call(cancelRqeuest, payload);
    if (!response.errors) {
      if (
        response?.data?.cancel_request?.__typename === "Payroll" ||
        response?.data?.cancel_request?.__typename === "GeneralException"
      ) {
        yield put(cancelRequestFailed(payload.id));
        yield put(
          showErrorToast(response?.data?.cancel_request?.message)
        );
        return;
      }
      yield put(cancelRequestSuccess(payload.id));
      yield put(hideViewAttRequestModal());
      yield put(showSuccessToast());

      // to close edit leave request
      yield put(
        dismissEditLeavesModalAction({
          ref: "editLeavesModalActions",
          formName: "editLeavesModal",
        })
      );
    } else {
      yield put(cancelRequestFailed(payload.id));
      yield put(showErrorToast(response.errors[0]?.extensions?.reason));
    }
    
    if (["emp_profile_requests_history", "history_logs"].includes(payload?.pageFlag)) yield put(refetchFromSagaToHook());
    
    // Case Edit Attendance Request - History Tab
    if (payload.pageFlag === "my_requests_history") {
      let userReducer = yield select(getUserReducer); // <-- get the my requests
      yield put(
        fetchHistoryListAttempt({
          ref: "historyList",
          rows: userReducer?.historyList?.pagination.perPage,
          status: userReducer?.historyList?.status,
          from: userReducer?.historyList?.from,
          to: userReducer?.historyList?.to,
          page: userReducer?.historyList?.pagination.currentPage,
        })
      );
    }
  } catch (error) {
    yield put(cancelRequestFailed(payload.id));
    yield put(showErrorToast(error?.message));
    yield put(resetRefetchFromSagaToHook());
  }
}

export function* handleFetchAttendanceRecordsSaga({ payload }) {
  try {
    const userHistory = yield call(fetchAttendanceRecords, payload);
    yield put(fetchAttendanceRecordsSuccess(userHistory.data));
  } catch (error) {
    yield put(fetchAttendanceRecordsFailed());
    console.log("err", error.response);
  }
}

function* onCancelUserRequest() {
  yield takeEvery(Types.CANCEL_REQUEST_ATTEMPT, handleCancelUserRequestSaga);
}

function* onFetchingAttendaceRecords() {
  yield takeEvery(
    Types.FETCH_ATTENDANCE_RECORDS_ATTEMPT,
    handleFetchAttendanceRecordsSaga
  );
}

// *********** // (Start) Attendance Action Modal // ***************

// (Start) Normal Leave Request

export function* handleNormalRequestSaga({ payload }) {
  try {
    const response = yield call(postNormalRequest, payload);
    if (!response.errors) {
      if (
        response.data?.normal_request?.__typename === "GeneralException" ||
        response.data?.normal_request?.__typename === "PaidPayrollException"
      ) {
        yield put(
          attendanceActionModalFailed(
            response.data?.normal_request?.message ?? "Something went wrong!"
          )
        );
        return;
      }

      yield put(attendanceActionModalSuccess());
      yield put(showSuccessToast());
    } else {
      if (response.errors?.extensions?.validation) {
        attendanceActionModalFailed(
          Object.values(response.errors?.extensions?.validation)?.toString() ??
          "Something went wrong!"
        );
        return;
      }
      yield put(
        attendanceActionModalFailed(
          response.errors[0].extensions.reason
            ? response.errors[0].extensions.reason
            : "Something went wrong!"
        )
      );
    }
  } catch (error) {
    yield put(
      attendanceActionModalFailed(
        error.message ? error.message : "Something went wrong!"
      )
    );
  }
}

function* OnHandleNormalRequestSaga() {
  yield takeEvery(Types.SEND_NORMAL_REQUEST_ATTEMPT, handleNormalRequestSaga);
}

export function* handleUnpaidRequestSaga({ payload }) {
  try {
    const response = yield call(postUnpaidRequest, payload);
    if (!response.errors) {
      if (
        response.data?.unpaid_request?.__typename === "GeneralException" ||
        response.data?.unpaid_request?.__typename === "PaidPayrollException"
      ) {
        yield put(
          attendanceActionModalFailed(
            response.data?.unpaid_request?.message ?? "Something went wrong!"
          )
        );
        return;
      }

      yield put(attendanceActionModalSuccess());
      yield put(showSuccessToast());
    } else {
      if (response.errors?.extensions?.validation) {
        attendanceActionModalFailed(
          Object.values(response.errors?.extensions?.validation)?.toString() ??
          "Something went wrong!"
        );
        return;
      }
      yield put(
        attendanceActionModalFailed(
          response.errors[0].extensions.reason
            ? response.errors[0].extensions.reason
            : "Something went wrong!"
        )
      );
    }
  } catch (error) {
    yield put(
      attendanceActionModalFailed(
        error?.message ? error?.message : "Something went wrong!"
      )
    );
  }
}

function* OnHandleUnpaidRequestSaga() {
  yield takeEvery(Types.SEND_UNPAID_REQUEST_ATTEMPT, handleUnpaidRequestSaga);
}
// (End) Normal Leave Request

// (Start) Emergency Leave Request
export function* handleEmergencyRequestSaga({ payload }) {
  try {
    const response = yield call(postEmergencyRequest, payload);
    if (!response.errors) {
      yield put(attendanceActionModalSuccess());
      yield put(showSuccessToast());
    } else {
      yield put(
        attendanceActionModalFailed(
          response.errors[0].extensions.reason
            ? response.errors[0].extensions.reason
            : "Something went wrong!"
        )
      );
    }
  } catch (error) {
    yield put(
      attendanceActionModalFailed(
        error.message ? error.message : "Something went wrong!"
      )
    );
  }
}

function* OnHandleEmergencyRequestSaga() {
  yield takeEvery(
    Types.SEND_EMERGENCY_REQUEST_ATTEMPT,
    handleEmergencyRequestSaga
  );
}
// (End) Emergency Leave Request

// (Start) permission Request
export function* handlePermissionRequestSaga({ payload }) {
  try {
    const response = yield call(postPermissionRequest, payload);
    if (!response.errors) {
      if (
        response.data?.permission_request?.__typename === "GeneralException" ||
        response.data?.permission_request?.__typename === "PaidPayrollException"
      ) {
        yield put(
          attendanceActionModalFailed(
            response.data?.permission_request?.message ??
            "Something went wrong!"
          )
        );
        return;
      }
      yield put(attendanceActionModalSuccess());
      yield put(showSuccessToast());
    } else {
      if (response.errors?.extensions?.validation) {
        attendanceActionModalFailed(
          Object.values(response.errors?.extensions?.validation)?.toString() ??
          "Something went wrong!"
        );
        return;
      }
      yield put(
        attendanceActionModalFailed(
          response.errors[0].extensions.reason
            ? response.errors[0].extensions.reason
            : "Something went wrong!"
        )
      );
    }
  } catch (error) {
    yield put(
      attendanceActionModalFailed(
        error.message ? error.message : "Something went wrong!"
      )
    );
  }
}

function* OnHandlePermissionRequestSaga() {
  yield takeEvery(
    Types.SEND_PERMISSION_REQUEST_ATTEMPT,
    handlePermissionRequestSaga
  );
}
// (End) permission Request

// (Start) Halfday Request
export function* handleHalfdayRequestSaga({ payload }) {
  try {
    const response = yield call(postHalfdayRequest, payload);
    if (!response.errors) {
      if (
        response.data?.halfday_request?.__typename === "GeneralException" ||
        response.data?.halfday_request?.__typename === "PaidPayrollException"
      ) {
        yield put(
          attendanceActionModalFailed(
            response.data?.halfday_request?.message ?? "Something went wrong!"
          )
        );
        return;
      }
      yield put(attendanceActionModalSuccess());
      yield put(showSuccessToast());
    } else {
      if (response.errors?.extensions?.validation) {
        attendanceActionModalFailed(
          Object.values(response.errors?.extensions?.validation)?.toString() ??
          "Something went wrong!"
        );
        return;
      }
      yield put(
        attendanceActionModalFailed(
          response.errors[0].extensions.reason
            ? response.errors[0].extensions.reason
            : "Something went wrong!"
        )
      );
    }
  } catch (error) {
    yield put(
      attendanceActionModalFailed(
        error.message ? error.message : "Something went wrong!"
      )
    );
  }
}

function* OnHandleHalfdayRequestSaga() {
  yield takeEvery(Types.SEND_HALFDAY_REQUEST_ATTEMPT, handleHalfdayRequestSaga);
}
// (End) Halfday Request

// (Start) Home Exchange Request
export function* handleHomeExchangeRequestSaga({ payload }) {
  try {
    const response = yield call(postHomeExchangeRequest, payload);
    if (!response.errors) {
      if (
        response.data?.home_exchange_request?.__typename ===
        "GeneralException" ||
        response.data?.home_exchange_request?.__typename ===
        "PaidPayrollException"
      ) {
        yield put(
          attendanceActionModalFailed(
            response.data?.home_exchange_request?.message ??
            "Something went wrong!"
          )
        );
        return;
      }
      yield put(attendanceActionModalSuccess());
      yield put(showSuccessToast());
    } else {
      if (response.errors?.extensions?.validation) {
        attendanceActionModalFailed(
          Object.values(response.errors?.extensions?.validation)?.toString() ??
          "Something went wrong!"
        );
        return;
      }
      yield put(
        attendanceActionModalFailed(
          response.errors[0].extensions.reason
            ? response.errors[0].extensions.reason
            : "Something went wrong!"
        )
      );
    }
  } catch (error) {
    yield put(
      attendanceActionModalFailed(
        error.message ? error.message : "Something went wrong!"
      )
    );
  }
}

function* OnHandleHomeExchangeRequestSaga() {
  yield takeEvery(
    Types.SEND_HOME_EXCHANGE_REQUEST_ATTEMPT,
    handleHomeExchangeRequestSaga
  );
}
// (End) Home Exchange Request

// (Start) Home Flex Request
export function* handleHomeFlexRequestSaga({ payload }) {
  try {
    const response = yield call(postHomeFlexRequest, payload);
    if (!response.errors) {
      if (
        response.data?.home_flex_request?.__typename === "GeneralException" ||
        response.data?.home_flex_request?.__typename === "PaidPayrollException"
      ) {
        yield put(
          attendanceActionModalFailed(
            response.data?.home_flex_request?.message ?? "Something went wrong!"
          )
        );
        return;
      }
      yield put(attendanceActionModalSuccess());
      yield put(showSuccessToast());
    } else {
      if (response.errors?.extensions?.validation) {
        attendanceActionModalFailed(
          Object.values(response.errors?.extensions?.validation)?.toString() ??
          "Something went wrong!"
        );
        return;
      }
      yield put(
        attendanceActionModalFailed(
          response.errors[0].extensions.reason
            ? response.errors[0].extensions.reason
            : "Something went wrong!"
        )
      );
    }
  } catch (error) {
    yield put(
      attendanceActionModalFailed(
        error.message ? error.message : "Something went wrong!"
      )
    );
  }
}

function* OnHandleHomeFlexRequestSaga() {
  yield takeEvery(
    Types.SEND_HOME_FLEX_REQUEST_ATTEMPT,
    handleHomeFlexRequestSaga
  );
}
// (End) Home Flex Request

// *********** // (End) Attendance Action Modal // ***************

export function* handleFetchHistoryListSaga({ payload }) {
  try {
    const response = yield call(fetchHistoryList, payload);
    if (!response.errors) {
      yield put(
        fetchHistoryListSuccess(payload.ref, response?.data?.sign_in_outs)
      );
    } else {
      yield put(fetchHistoryListFailed(payload.ref));
    }
  } catch (error) {
    console.log("err", { error });
    yield put(fetchHistoryListFailed(payload.ref));
  }
}

function* onHandleFetchHistoryListSaga() {
  yield takeLatest(
    Types.FETCH_HISTORY_LIST_ATTEMPT,
    handleFetchHistoryListSaga
  );
}

export function* handleGetSignInInfoSaga({ payload }) {
  try {
    const response = yield call(getSignInInfo);
    if (response.data.signInInfo?.from_home) {
      yield put(
        showDismissHomeRequestAlert({ message: "Are you working Remotely?" })
      );
    } else {
      yield put(createSignInAction(0));
    }
    if (response?.errors) {
      yield put(createSignInFailed());
      yield put(refreshSignInOutAction());
      yield put(showErrorToast("Sign In Failed"));
      return;
    }
  } catch (error) {
    yield put(createSignInFailed());
    yield put(refreshSignInOutAction());
    yield put(showErrorToast("Sign In Failed"));
  }
}

export function* handleGetSignOutInfoSaga({ payload }) {
  try {
    const response = yield call(getSignOutInfo);
    if (!response.data.signOutInfo?.work_hours_exceeded) {
      yield put(createSignOutFailed());
      yield put(
        showForceSignOutAlert({
          message:
            "You are signing out early are you sure you want to sign out ?",
        })
      );

      return;
    } else {
      yield put(createSignOutAction());
    }
    if (response?.errors) {
      yield put(createSignOutFailed());
      yield put(refreshSignInOutAction());
      yield put(showErrorToast("Sign Out Failed"));
      return;
    }
  } catch (error) {
    yield put(createSignOutFailed());
    yield put(refreshSignInOutAction());
    yield put(showErrorToast("Sign Out Failed"));
  }
}

export function* handleSignInRequestSaga({ payload }) {
  yield put(hideDismissHomeRequestAlert());
  try {
    const response = yield call(craeteSignIn, payload);
    if (response?.errors) {
      console.log(response);
      yield put(createSignInFailed());
      yield put(refreshSignInOutAction());
      yield put(showErrorToast("Sign In Failed"));
    } else {
      yield put(createSignInSuccess(response.data.sign_in));
      yield put(refreshSignInOutAction());
      return;
    }
  } catch (error) {
    yield put(createSignInFailed());
    yield put(refreshSignInOutAction());
  }
}

export function* handleDismissHomeSignInSaga({ payload }) {
  try {
    const response = yield call(dismissHomeSignIn, payload);
    console.log("response", response);
    if (!response.errors) {
      yield put(createSignInSuccess(response.data.dismiss_work_from_home));
      yield put(hideDismissHomeRequestAlert());
      return;
    } else {
      yield put(createSignInFailed());
      yield put(refreshSignInOutAction());
      yield put(hideDismissHomeRequestAlert());
      yield put(showErrorToast(response.errors[0]?.extensions?.reason));
    }
  } catch (error) {
    yield put(createSignInFailed());
    yield put(refreshSignInOutAction());
  }
}

export function* handleCreateSignOutSaga({ payload }) {
  yield put(hideForceSignOutAlert());
  try {
    const response = yield call(createSignOut, payload);
    console.log("response", response);
    if (!response.errors) {
      yield put(createSignOutSuccess(response.data.sign_out));
      yield put(refreshSignInOutAction());
      yield put(hideForceSignOutAlert());

      return;
    } else {
      yield put(createSignOutFailed());
      yield put(refreshSignInOutAction());
      yield put(
        showErrorToast(response.errors?.[0]?.extensions?.reason)
      );
    }
  } catch (error) {
    yield put(createSignInFailed());
    yield put(refreshSignInOutAction());
  }
}

export function* handleRefreshSignInOutSaga({ payload }) {
  try {
    let authReducer = yield select(getAuthReducer);
    const response = yield call(refreshSignInOut);

    if (response.data?.status) {
      yield put(refreshSignInOutSuccess(response.data?.status));
    }

    if (
      response.data?.users_requests_by_role ||
      response.data?.claims
    ) {
      yield put(
        fetchTotalEmployeeRequests({
          requests: response.data?.users_requests_by_role?.paginatorInfo?.total
            ? response.data?.users_requests_by_role?.paginatorInfo?.total
            : 0,
          claims: response.data?.claims?.paginatorInfo?.total
            ? response.data?.claims?.paginatorInfo?.total
            : 0,
        })
      );
    }

    if (response.data?.profile) {
      yield put(fetchProfileSuccess(response.data?.profile));
    }

    return;
  } catch (error) {
    console.log("error", { error });
  }
}

function* onCreatetSignInSaga() {
  yield takeEvery(Types.SIGN_IN_ATTEMPT, handleSignInRequestSaga);
}

function* onDismissHomeSignInSaga() {
  yield takeEvery(
    Types.DISMISS_HOME_SIGN_IN_ATTEMPT,
    handleDismissHomeSignInSaga
  );
}

function* onCreateSignOutSaga() {
  yield takeEvery(Types.SIGN_OUT_ATTEMPT, handleCreateSignOutSaga);
}

function* onRefreshSignInOutSaga() {
  yield takeEvery(
    Types.REFRESH_SIGN_IN_OUT_ATTEMPT,
    handleRefreshSignInOutSaga
  );
}

// (Start) Forgot Password
export function* handleForgotPasswordSaga({ payload }) {
  try {
    const response = yield call(forgotPasswordRequest, payload);
    if (!response.errors) {
      yield put(
        showSuccessToast(response?.data?.forgotPassword?.message)
      );
      yield put(forgotPasswordFinish());
      history.push("login");
    } else {
      yield put(
        showErrorToast(response.errors?.[0]?.extensions?.reason)
      );
      yield put(forgotPasswordFinish());
    }
  } catch (error) {
    yield put(showErrorToast(error?.message));
    yield put(forgotPasswordFinish());
  }
}

function* onForgotPasswordSaga() {
  yield takeEvery(Types.FORGOT_PASSWORD_ATTEMPT, handleForgotPasswordSaga);
}
// (End) Forgot Password

// (Start) Reset Password
export function* handleResetPasswordSaga({ payload }) {
  try {
    const response = yield call(updateForgottenPassword, payload);
    console.log("res", response);
    if (!response.errors) {
      yield put(resetPasswordSuccess());
      let authForm = {
        username: payload.email,
        password: payload.password,
        device_token: "desktop",
        device_type: "pc",
      };
      let emailType = yup.string().email();
      if (!emailType.isValidSync(payload.email?.toString())) {
        history.push("/");
      } else {
        yield put(loginAction(authForm));
      }

      yield put(
        showSuccessToast(
          response?.data?.updateForgottenPassword?.message
        )
      );
    } else {
      yield put(resetPasswordFailed());
      yield put(
        showErrorToast(response.errors?.[0]?.extensions?.reason)
      );
    }
  } catch (error) {
    console.log(
      "err",
      { error },
      Boolean(error.graphQLErrors[0].extensions.errors)
    );
    yield put(resetPasswordFailed());

    if (error.graphQLErrors.length) {
      if (Boolean(error.graphQLErrors[0].extensions?.validation)) {
        yield put(
          showErrorToast(
            error.graphQLErrors[0].extensions?.validation?.[
            "input.password"
            ]?.[0]
          )
        );
        return;
      }
      if (Boolean(error.graphQLErrors[0].extensions.errors)) {
        yield put(
          showErrorToast(
            error?.graphQLErrors?.[0]?.extensions?.errors?.token
          )
        );
        return;
      }
      yield put(showErrorToast(error?.message));
      return;
    }
    yield put(showErrorToast(error?.message));
  }
}

function* onResetPasswordSaga() {
  yield takeEvery(Types.RESET_PASSWORD_ATTEMPT, handleResetPasswordSaga);
}

function* onGetSignInInfoSaga() {
  yield takeEvery(Types.SIGN_IN_INFO_ATTEMPT, handleGetSignInInfoSaga);
}

function* onGetSignOutInfoSaga() {
  yield takeEvery(Types.SIGN_OUT_INFO_ATTEMPT, handleGetSignOutInfoSaga);
}
// (End) Reset Password

export function* handleFetchMyPayrollSaga({ payload }) {
  try {
    const response = yield call(fetchMyPayroll, payload);
    if (!response?.errors) {
      yield put(
        fetchMyPayrollSuccess(payload.ref, {
          data: response.data?.payrollReport,
        })
      );
    } else {
      yield put(fetchPayrollReportListFailed(payload.ref));
      yield put(
        showErrorToast(
          response.errors?.[0]?.extensions.reason ||
          response.errors?.[0]?.message
        )
      );
    }
  } catch (error) {
    console.log("err", { error });
    yield put(fetchPayrollReportListFailed(payload.ref));
    yield put(showErrorToast(error?.message));
  }
}

function* onFetchMyPayroll() {
  yield takeLatest(Types.FETCH_MY_PAYROLL_ATTEMPT, handleFetchMyPayrollSaga);
}

export function* handleUpsertClaimsRequestSaga({ payload }) {
  try {
    const response = yield call(upsertClaimRequest, payload);

    if (
      !response?.errors &&
      response.data.upsert_claim.__typename === "Claim"
    ) {
      yield put(showSuccessToast());
      let userReducer = yield select(getUserReducer);
      yield put(
        upsertClaimsRequestSuccess({
          ref: payload.ref,
          formName: payload.formName,
        })
      );
    }

    if (
      !response?.errors &&
      response.data.upsert_claim.__typename === "GeneralException"
    ) {
      yield put(
        upsertClaimsRequestFailed({
          ref: payload.ref,
          serverRef: "requestFormServerValidation",
          message: response?.data?.upsert_claim?.message,
        })
      );
      return;
    }

    if (response?.errors) {
      yield put(
        upsertClaimsRequestFailed({
          ref: payload.ref,
          serverRef: "requestFormServerValidation",
          errors: Helper.parseValidatorErrors(
            response?.errors[0]?.extensions?.validation
          ),
        })
      );
      if (response?.errors[0]?.extensions?.reason) {
        yield put(
          showErrorToast(response.errors?.[0]?.extensions?.reason)
        );
      }
    }
  } catch (error) {
    yield put(
      upsertClaimsRequestFailed({
        ref: payload.ref,
        serverRef: "requestFormServerValidation",
        errors: {},
      })
    );
    yield put(showErrorToast(error?.message));
  }
}

function* onUpsertClaimsRequest() {
  yield takeLatest(
    Types.UPSERT_CLAIM_REQUEST_ATTEMPT,
    handleUpsertClaimsRequestSaga
  );
}

export function* handleCancelCalimRequestSaga({ payload }) {
  try {
    const response = yield call(cancelClaimRqeuest, payload);

    if (!response.errors) {
      yield put(cancelClaimRequestSuccess(payload.id));
      yield put(showSuccessToast());
      return;
    }

    yield put(showErrorToast(response.errors[0]?.extensions?.reason));
  } catch (error) {
    yield put(cancelClaimRequestFailed(payload.id));
    yield put(showErrorToast(error?.message));
  }
}

function* onCancelClaimRequest() {
  yield takeEvery(
    Types.CANCEL_CLAIM_REQUEST_ATTEMPT,
    handleCancelCalimRequestSaga
  );
}

// upsert user locale

export function* handleUpsertUserLocaleSaga({ payload }) {
  try {
    const response = yield call(upsertUserLocale, payload);
    if (!response.errors) {
      yield put(upsertUserLocaleSuccess(response.data.upsert_locale));
    } else {
      yield put(
        showErrorToast(response.errors?.[0]?.extensions?.reason)
      );
      yield put(upsertUserLocaleFailed());
    }
  } catch (error) {
    yield put(showErrorToast(error?.message));
    yield put(upsertUserLocaleFailed());
  }
}

function* onUpsertUserLocaleSaga() {
  yield takeEvery(Types.UPSERT_USER_LOCALE_ATTEMPT, handleUpsertUserLocaleSaga);
}

// (Start) Edit Att Request Modal
export function* handleEditAttRequestModalSaga({ payload }) {
  const { ref, ...data } = payload?.data || {};
  try {
    const response = yield call(editAttRequestModal, data);
    if (!response.errors) {
      if (
        response.data?.edit_attendance_request?.__typename ===
        "GeneralException" ||
        response.data?.edit_attendance_request?.__typename ===
        "PaidPayrollException"
      ) {
        yield put(
          editAttRequestModalFailed(
            response.data?.edit_attendance_request?.message ??
            "Something went wrong!"
          )
        );
        return;
      }
      let userReducer = yield select(getUserReducer);

      yield put(editAttRequestModalSuccess());

      yield put(showSuccessToast());

      if (ref === "myHistory") {
        yield put(
          fetchHistoryListAttempt({
              ref: "historyList",
              rows: userReducer.historyList.pagination.perPage,
              status: userReducer.historyList.status,
              from: userReducer.historyList.from,
              to: userReducer.historyList.to,
              page: userReducer.historyList.pagination.currentPage,
          })
        );
      }

      if (["empHistory", "empLogs"].includes(ref)) {
        yield put(refetchFromSagaToHook());
      }
    } else {
      yield put(
        editAttRequestModalFailed(
          response.errors[0]?.extensions?.reason
            ? response.errors[0]?.extensions?.reason
            : "Something went wrong!"
        )
      );
      yield put(resetRefetchFromSagaToHook());
    }
  } catch (error) {
    yield put(
      editAttRequestModalFailed(
        error.message ? error.message : "Something went wrong!"
      )
    );
    yield put(resetRefetchFromSagaToHook());
  }
}

function* onEditAttRequestModal() {
  yield takeEvery(
    Types.EDIT_ATT_REQUEST_MODAL_ATTEMPT,
    handleEditAttRequestModalSaga
  );
}

// (Start) View Att Request Modal
export function* handleViewAttRequestModalSaga({ payload }) {
  try {
    const response = yield call(viewAttRequestModal, payload);
    if (!response.errors) {
      if (!!response?.data?.view_request) {
        yield put(viewAttRequestModalSuccess(response?.data?.view_request));
      } else {
        yield put(hideViewAttRequestModal());
        yield put(viewAttRequestModalFailed(""));
        yield put(
          showToast({ type: "info", msg: "request has been closed" })
        );
        let userReducer = yield select(getUserReducer); // <-- get the my requests
        yield put(
          fetchHistoryListAttempt({
            ref: "historyList",
            rows: userReducer?.historyList?.pagination.perPage,
            status: userReducer?.historyList?.status,
            from: userReducer?.historyList?.from,
            to: userReducer?.historyList?.to,
            page: userReducer?.historyList?.pagination.currentPage,
          })
        );
      }
    } else {
      yield put(
        viewAttRequestModalFailed(
          response.errors[0]?.extensions?.reason
            ? response.errors[0]?.extensions?.reason
            : "Something went wrong!"
        )
      );
    }
  } catch (error) {
    yield put(
      viewAttRequestModalFailed(
        error.message ? error.message : "Something went wrong!"
      )
    );
  }
}

function* onViewAttRequestModal() {
  yield takeEvery(
    Types.VIEW_ATT_REQUEST_MODAL_ATTEMPT,
    handleViewAttRequestModalSaga
  );
}

export function* handleUpsertSickRequestSaga({ payload }) {
  try {
    const response = yield call(upsertSickLeavesRequest, payload);

    if (!response?.errors) {
      if (
        response.data?.sick_request?.__typename === "GeneralException" ||
        response.data?.sick_request?.__typename === "PaidPayrollException"
      ) {
        yield put(
          attendanceActionModalFailed(
            response.data?.sick_request?.message ?? "Something went wrong!"
          )
        );
        return;
      }

      yield put(showSuccessToast());
      yield put(
        upsertSickRequestSuccess({
          ref: payload.ref,
          formName: payload.formName,
        })
      );

      yield put(showSuccessToast());
    } else {
      if (response.errors?.extensions?.validation) {
        attendanceActionModalFailed(
          Object.values(response.errors?.extensions?.validation)?.toString() ??
          "Something went wrong!"
        );
        return;
      }
      yield put(
        attendanceActionModalFailed(
          response.errors[0].extensions.reason
            ? response.errors[0].extensions.reason
            : "Something went wrong!"
        )
      );
    }
  } catch (error) {
    yield put(
      attendanceActionModalFailed(
        error.message ? error.message : "Something went wrong!"
      )
    );
  }
}

function* onUpsertSickRequest() {
  yield takeLatest(
    Types.UPSERT_SICK_REQUEST_ATTEMPT,
    handleUpsertSickRequestSaga
  );
}
export function* handleUpsertCustomRequestSaga({ payload }) {
  try {
    const response = yield call(upsertCustomLeavesRequest, payload);

    if (!response?.errors) {
      if (
        response.data?.custom_request?.__typename === "GeneralException" ||
        response.data?.custom_request?.__typename === "PaidPayrollException"
      ) {
        yield put(
          attendanceActionModalFailed(
            response.data?.custom_request?.message ?? "Something went wrong!"
          )
        );
        return;
      }
      yield put(showSuccessToast());
      yield put(
        upsertCustomRequestSuccess({
          ref: payload.ref,
          formName: payload.formName,
        })
      );

      yield put(showSuccessToast());
    } else {
      if (response.errors?.extensions?.validation) {
        attendanceActionModalFailed(
          Object.values(response.errors?.extensions?.validation)?.toString() ??
          "Something went wrong!"
        );
        return;
      }
      yield put(
        attendanceActionModalFailed(
          response.errors[0].extensions.reason
            ? response.errors[0].extensions.reason
            : "Something went wrong!"
        )
      );
    }
  } catch (error) {
    yield put(
      attendanceActionModalFailed(
        error.message ? error.message : "Something went wrong!"
      )
    );
  }
}

function* onUpsertCustomRequest() {
  yield takeLatest(
    Types.UPSERT_CUSTOM_REQUEST_ATTEMPT,
    handleUpsertCustomRequestSaga
  );
}

export function* handleFetchUserPettyCashListSaga({ payload }) {
  try {
    const response = yield call(fetchUserPettyCashList, payload);
    if (!response?.errors) {
      yield put(
        fetchUserPettyCashListSuccess(payload.ref, response.data?.petty_cashes)
      );
      yield put(
        fetchCompanyExpensesCategoriesAction(response?.data?.categories)
      );

      yield put(
        fetchCurrenciesInExpenseFormSuccess(response?.data?.currencies)
      );
    } else {
      yield put(fetchUserPettyCashListFailed(payload.ref));
      yield put(
        showErrorToast(
          response.errors?.[0]?.extensions?.reason ||
          response?.errors?.[0]?.message
        )
      );
    }
  } catch (error) {
    yield put(fetchUserPettyCashListFailed(payload.ref));
    yield put(showErrorToast(error?.message));
  }
}

function* onFetchUserPettyCashList() {
  yield takeLatest(
    Types.FETCH_USER_PETTY_CASH_ATTEMPT,
    handleFetchUserPettyCashListSaga
  );
}

export function* handleFetchUserProfileCurrentBalanceSaga({ payload }) {
  try {
    const response = yield call(fetchUserProfileCurrentBalance, payload);
    yield put(fetchUserProfileCurrentBalanceSuccess(response?.data?.employee));
  } catch (error) {
    yield put(fetchUserProfileCurrentBalanceFailed());
    console.log("err", error);
  }
}

function* onFetchUserProfileCurrentBalance() {
  yield takeEvery(
    Types.FETCH_USER_PROFILE_CURRENT_BALANCE_ATTEMPT,
    handleFetchUserProfileCurrentBalanceSaga
  );
}

export function* handleCheckAllNotificationsSaga() {
  try {
    const response = yield call(checkAllNotificationToRead);
    if (
      response?.data?.updateNotificationMessagesStatus?.status === "Success"
    ) {
      yield put(checkAllNotificationToReadSuccess());
    }
  } catch (error) {
    yield put(checkAllNotificationToReadFailed());
    console.log("err", error);
  }
}

function* onCheckAllNotificationsSaga() {
  yield takeEvery(
    Types.CHECK_NOTIFICATION_READ_ATTEMPT,
    handleCheckAllNotificationsSaga
  );
}

const sagaWatchers = {
  onCancelUserRequest,
  onFetchingAttendaceRecords,
  OnHandleNormalRequestSaga,
  OnHandleUnpaidRequestSaga,
  OnHandleEmergencyRequestSaga,
  OnHandlePermissionRequestSaga,
  OnHandleHalfdayRequestSaga,
  OnHandleHomeExchangeRequestSaga,
  OnHandleHomeFlexRequestSaga,
  onHandleFetchHistoryListSaga,
  onCreatetSignInSaga,
  onDismissHomeSignInSaga,
  onCreateSignOutSaga,
  onRefreshSignInOutSaga,

  onForgotPasswordSaga,
  onResetPasswordSaga,
  onGetSignInInfoSaga,
  onGetSignOutInfoSaga,
  onUpsertUserLocaleSaga,
  onUpsertClaimsRequest,
  onCancelClaimRequest,
  onFetchMyPayroll,
  onEditAttRequestModal,
  onViewAttRequestModal,
  onUpsertSickRequest,
  onFetchUserPettyCashList,
  onFetchUserProfileCurrentBalance,
  onUpsertCustomRequest,
  onCheckAllNotificationsSaga,
};

export default sagaWatchers;

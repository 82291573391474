import React from "react";
import { useQuery } from "@apollo/client";

import { gql } from "@apollo/client";
import { showToast } from "../../Helpers/HelperFns";

import { BSelect } from "form-builder";
import { components } from "react-select";
import IconButtonWithTooltip from "../IconButtonWithTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUserSlash } from "@fortawesome/free-solid-svg-icons";

const GET_LEAD_AGENTS = gql`
  query getLeadAgents($haveLeadsActiveOnly: Int = 1) {
    agents: saas_names(
      first: 9999
      haveLeads: 1
      haveLeadsActiveOnly: $haveLeadsActiveOnly
    ) {
      data {
        id
        name
      }
    }
  }
`;

const LeadAgentSelect = (props) => {
  const [agents, setAgents] = React.useState([]);
  const [haveLeadsActiveOnly, setHaveLeadsActiveOnly] = React.useState(1);
  const { loading } = useQuery(GET_LEAD_AGENTS, {
    variables: { haveLeadsActiveOnly },
    onCompleted: ({ agents }) => {
      setAgents(agents?.data || []);
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });

  return (
    <BSelect
      {...props}
      isLoading={loading}
      isActive={haveLeadsActiveOnly}
      options={loading ? [] : agents}
      onToggle={() => setHaveLeadsActiveOnly((prev) => +!prev)}
      components={{ Control: DataControl }}
    />
  );
};

export default LeadAgentSelect;

const DataControl = ({ children, ...props }) => {
  const { isActive, onToggle } = props.selectProps;

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onToggle();
  };

  return (
    <components.Control {...props}>
      <span onMouseDown={handleClick}>
        <IconButtonWithTooltip
          onClick={() => undefined}
          label={`show ${isActive ? "all" : "active"} employees`}
          icon={
            isActive ? (
              <FontAwesomeIcon icon={faUser} size="sm" color="#90979e" />
            ) : (
              <FontAwesomeIcon icon={faUserSlash} size="sm" color="#90979e" />
            )
          }
        />
      </span>

      {children}
    </components.Control>
  );
};

import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  updatePositionLocationAction,
  onInputChangeAction,
} from "../../Store/Actions";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Circle,
} from "react-google-maps";
import { Config } from "../../Config";
import { Skeleton } from "@mui/material";
// google maps init values function

const MarkerLoc = (props) => {
  const mapCircle = useRef(null);
  const locationForm = useSelector((state) => state.super.locationForm);
  useEffect(() => {
    if (!!!locationForm.lat && !!!locationForm.lng) {
      dispatch(
        updatePositionLocationAction(
          "locationForm",
          "31.159437542039704",
          "29.951770466796894"
        )
      );
    }
    return () => {};
  }, []);

  const dispatch = useDispatch();
  const onMarkerDragEnd = (e) => {
    dispatch(
      updatePositionLocationAction(
        "locationForm",
        e.latLng.lng().toString(),
        e.latLng.lat().toString()
      )
    );
  };

  const onRadiusChanged = () => {
    if (mapCircle?.current) {
      let e = {
        target: {
          name: "max_distance",
          value: mapCircle?.current?.getRadius()?.toString(),
        },
      };
      dispatch(onInputChangeAction("locationForm", e));
    }
  };

  return (
    <GoogleMap
      defaultZoom={18}
      center={{
        lat: +locationForm.lat,
        lng: +locationForm.lng,
      }}
      defaultCenter={{
        lat: +locationForm.lat,
        lng: +locationForm.lng,
      }}
      onClick={onMarkerDragEnd}
    >
      <Marker
        position={{
          lat: +locationForm.lat,
          lng: +locationForm.lng,
        }}
        draggable={true}
        onDragEnd={onMarkerDragEnd}
      />
      <Circle
        ref={mapCircle}
        defaultCenter={{
          lat: +locationForm.lat,
          lng: +locationForm.lng,
        }}
        onRadiusChanged={onRadiusChanged}
        editable
        center={{
          lat: +locationForm.lat,
          lng: +locationForm.lng,
        }}
        radius={+locationForm.max_distance}
        options={{ strokeColor: "#ff0000" }}
      />
    </GoogleMap>
  );
};

const MapWithAMarker = withScriptjs(withGoogleMap(MarkerLoc));

const LocationMarker = () => {
  return (
    <MapWithAMarker
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${Config.googleMapKey}&libraries=places`}
      loadingElement={<Skeleton variant="rectangular" width="100%" height={300} />}
      containerElement={<div style={{ height: `300px`, width: `100%` }} />}
      mapElement={<div style={{ height: `100%` }} />}
    />
  );
};

export default LocationMarker;

// Hooks
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Helpers
import { SuperNavList, SettingsPrivilages } from "./Constants/Nav";
import {
  logoutAction,
  refreshSignInOutAction,
  setShouldExitCalendarPage,
} from "./Store/Actions";
import moment from "moment";
import swal from "sweetalert";
import i18next from "i18next";
import cookies from "js-cookie";
import HelperFns from "./Helpers/HelperFns";
import Privilages from "./Constants/Privilages";
import history from "./Helpers/Navigations/history";
import { assignmentTabPrivileges } from "./Constants";

// Components
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Loader from "./Components/Loader";
import Layout from "./Components/Layout";
import MuiProvider from "./Components/Layout/MuiProvider";
import ProtectedRouter from "./Helpers/HOC/ProtectedRouter";

// Routes
import AdminPanelRoutes from "./Routes/AdminPanel";
import PaymentTrackingRoutes from "./Routes/PaymentTracking";
import UnsubscribeNotificationsPage from "./Routes/UnsubscribeNotificationsPage";
import CreateEmployeeProfilePage from "./Routes/CreateEmployeeProfilePage";
import AttendanceProfilePage from "./Routes/AttendanceProfilePage";
import ViewEmployeeProfile from "./Routes/ViewEmployeeProfile";
import ViewManagerDashboard from "./Routes/ViewManagerDashboard";
import ViewOfficeForm from "./Routes/ViewOfficeForm";
import SettingsPage from "./Routes/SettingsPage";
import MyProfilePage from "./Routes/MyProfilePage";
import UnauthorizedPage from "./Routes/UnauthorizedPage";
import LoginPage from "./Routes/LoginPage";
import LogsPage from "./Routes/LogsPage";
import AttendanceHistory from "./Routes/AttendanceHistory";
import ForgotPasswordPage from "./Routes/ForgotPasswordPage";
import ResetPasswordPage from "./Routes/ResetPasswordPage";
import ViewDocuments from "./Routes/ViewDocuments";
import ViewPayroll from "./Routes/ViewPayroll";
import ViewExpenses from "./Routes/ViewExpenses";
import RequestsPage from "./Routes/ViewRequests";
import ViewFinancials from "./Routes/ViewFinancials";
import ViewLoans from "./Routes/ViewLoans";
import VerifyPhonePage from "./Routes/VerifyPhonePage";
import VerifyEmailPage from "./Routes/VerifyEmailPage";
import JobPostApplication from "./Routes/JobPostApplication";
import EmailNotifications from "./Routes/EmailNotifications";
import SetupWizardPage from "./Routes/SetupWizardPage";
import PrivacyPolicyPage from "./Routes/PrivacyPolicyPage/indes";
import ViewRecruitment from "./Routes/ViewRecruitment";
import ApplicationsList from "./Containers/ApplicationsList";
import ApplicantProfile from "./Containers/ApplicantProfile";
import RecruitmentProcessPage from "./Routes/RecruitmentProcessPage";
import EditRecruitmentProcessPage from "./Routes/EditRecruitmentProcessPage";
import IntakeFormPage from "./Routes/IntakeFormPage";
import EditIntakeFormPage from "./Routes/EditIntakeFormPage";
import ViewCalendar from "./Routes/ViewCalendar/ShiftsCalendar";
import JobApplicationSuccess from "./Routes/JobApplicationSuccess/JobApplicationSuccess";
import ProcessedJobs from "./Routes/ProcessedJobs";
import { EmployeesPage } from "./Routes/EmployeesPage";
import NotFoundPage from "./Routes/NotFoundPage";
import { WorkTeamDetails } from "./Routes/WorkTeamDetails";
import { DraftSchedulesPage } from "./Routes/DraftSchedulesPage";
import { CreateWorkSchedulePage } from "./Routes/CreateWorkSchedulePage";
import DownPage from "./Routes/DownPage";
import LogsModal from "./Containers/LogsModal";
import * as Sentry from "@sentry/browser";
import ProcessedQueries from "./Routes/ProcessedQueries";
import BlankPage from "./Routes/LoginPage/BlankPage";
import SetTemporaryPasswordModal from "./Containers/TemporaryPassword/SetTemporaryPasswordModal";
import CRMRoutes from "./Routes/CRM";
import FoodicsRoutes from "./Routes/Foodics";
import AssetsPage from "./Containers/Assets/AssetsPage";
let timer;

const MainApp = () => {
  const dispatch = useDispatch();

  // Reducer State
  const token = useSelector((state) => state?.auth?.token);
  const user = useSelector((state) => state?.auth?.userProfile);
  const isCheckPasswordLoading = useSelector(
    (state) => state?.super?.checkPasswordLoading
  );

  /* ↓ State Effects ↓ */

  useEffect(() => {
    if (token) {
      Sentry.setUser(user);
    } else {
      Sentry.setUser(null);
    }
    clearInterval(timer);
    if (Boolean(token) && user?.user_type?.toLowerCase() !== "super") {
      dispatch(refreshSignInOutAction());
      timer = setInterval(() => {
        dispatch(refreshSignInOutAction());
      }, 300000);
    }
    return () => {
      if (token) {
        clearInterval(timer);
      }
    };
  }, [token]);

  useEffect(() => {
    const handleInvalidToken = (e) => {
      let persistData =
        JSON.parse(localStorage.getItem("persist:attendance_root")) ?? null;
      const mainStorage = persistData ? JSON.parse(persistData?.auth) : null;

      if (
        mainStorage?.shouldExitCalendarPage &&
        window?.location?.href?.toLowerCase()?.includes("work-schedules")
      ) {
        dispatch(setShouldExitCalendarPage(false));
        swal({
          title: HelperFns.localizeFns("success"),
          text: HelperFns.localizeFns("schedule has been published"),
          icon: "success",
          className: "swal-success-style",
          closeOnEsc: false,
          closeOnClickOutside: false,
          showConfirmButton: true,
          showCancelButton: false,
        }).then((confirm) => {
          if (confirm) {
            window.location.href = `${window.location.href.slice(
              0,
              window.location.href?.indexOf("/work-schedules")
            )}/attendance?tab=work-calendar`;
          }
        });
      }

      if (
        !token &&
        (!window?.location?.href
          ?.toLowerCase()
          ?.includes("jobpostapplication") ||
          !window?.location?.href?.toLowerCase()?.includes("login"))
      ) {
        dispatch(logoutAction());
      } else {
        if (persistData) {
          let updateState = {};
          Object?.keys(persistData)?.forEach((key) => {
            updateState[key] = JSON.parse(persistData[key]);
          });
          dispatch({
            type: "persist/REHYDRATE",
            payload: updateState,
            key: "attendance_root",
          });
        }
      }
    };
    window.addEventListener("storage", handleInvalidToken);
    return function cleanup() {
      window.removeEventListener("storage", handleInvalidToken);
    };
  }, []);

  useEffect(() => {
    if (window.location.href?.toLowerCase()?.indexOf("unauthorized") > -1) {
      window.location.href = "/";
    }
  }, [window.location.href]);

  useEffect(() => {
    const handleStorageEvent = (event) => {
      if (event.key === "should-refresh") {
        window.location.href = "/";
      }
    };

    window.addEventListener("storage", handleStorageEvent);

    return () => {
      window.removeEventListener("storage", handleStorageEvent);
    };
  }, []);

  useEffect(() => {
    const currentLanguage = user?.lng ?? (cookies.get("i18next") || "en-US");

    document.body.dir = currentLanguage?.includes("ar") ? "rtl" : "ltr";

    if (currentLanguage !== user?.lng) {
      i18next.changeLanguage(user?.lng);
      document.documentElement.lang = user?.lng ?? "en-US";

      if (currentLanguage.includes("ar")) {
        moment.defineLocale("ar-custome", {
          parentLocale: "ar-sa",
          preparse: function (string) {
            return string;
          },
          postformat: function (string) {
            return string;
          },
        });
      } else {
        moment.locale("en");
      }
    } else {
      i18next.changeLanguage(user?.lng);
      document.documentElement.lang = user?.lng ?? "en";
      if (currentLanguage?.includes("ar")) {
        moment.defineLocale("ar-custome", {
          parentLocale: "ar-sa",
          preparse: function (string) {
            return string;
          },
          postformat: function (string) {
            return string;
          },
        });
      } else {
        moment.locale("en");
      }
    }
  }, [user?.lng]);

  useEffect(() => {
    if (!user?.lng?.includes("ar")) {
      moment.locale("en");
      i18next.changeLanguage(user?.lng);
    } else {
      moment.defineLocale("ar-custome", {
        parentLocale: "ar-sa",
        preparse: function (string) {
          return string;
        },
        postformat: function (string) {
          return string;
        },
      });
      i18next.changeLanguage(user?.lng);
    }
  });

  const isSetTemporaryModalOpen = useSelector(
    (state) => state?.super?.setTemporaryPasswordModal?.isOpen
  );

  return (
    <MuiProvider>
      <Router history={history}>
        {isCheckPasswordLoading ? (
          <div className="loader_wrapper_style">
            <Loader />
          </div>
        ) : null}

        <LogsModal />

        {isSetTemporaryModalOpen ? <SetTemporaryPasswordModal /> : null}

        {token && !user?.has_temporary_password ? (
          <Switch>
            <Route
              path="/unsubscribe/notification/:token"
              component={UnsubscribeNotificationsPage}
            />
            <Route exact path="/down">
              <DownPage />
            </Route>
            <Route exact path="/redirecting">
              <BlankPage />
            </Route>
            <Route exact path="/privacy-policy">
              <PrivacyPolicyPage />
            </Route>
            <Route exact path="/jobPostApplication">
              <JobPostApplication />
            </Route>
            <Route exact path="/JobApplicationSuccess">
              <JobApplicationSuccess />
            </Route>
            <Layout superLinks={SuperNavList}>
              <Switch>
                <Route exact path="/">
                  {HelperFns.checkPrivileges({
                    privileges: [
                      Privilages.VIEW_EMPLOYEE_OVERTIME_CHART,
                      Privilages.VIEW_EMPLOYEES_PER_DEPARTMENT_CHART,
                      Privilages.VIEW_EMPLOYEE_ATTENDANCE_HISTORY_CHART,
                      Privilages.VIEW_EMPLOYEE_DAILY_STATUSES,
                      // Privilages.VIEW_EMPLOYEE_REQUESTS,
                      Privilages.VIEW_THE_EXPENSES_CHART_OF_MY_OFFICE,
                      Privilages.VIEW_THE_EXPENSES_CHART_OF_ALL_OFFICES,
                      Privilages.VIEW_OPEN_PAYROLL_MONTHS,
                      Privilages.VIEW_MISSING_EXPIRED_DOCUMENT,
                      Privilages.VIEW_TURNOVER_CHART,
                    ],
                    allowBP: true,
                  }) ? (
                    <ViewManagerDashboard />
                  ) : HelperFns.checkPrivileges({
                      privileges: [Privilages.VIEW_PROFILE],
                    }) ? (
                    <Redirect to="/employee-profile" />
                  ) : user?.user_type?.toLowerCase() !== "super" ? (
                    <Redirect to="/employees" />
                  ) : (
                    <Redirect to="/admin-panel" />
                  )}
                </Route>
                <Route exact path="/password/reset/:token">
                  <Redirect to="/" />
                </Route>
                <Route exact path="/login">
                  <Redirect to="/" />
                </Route>
                <Route path="/unauthorized">
                  <UnauthorizedPage />
                </Route>
                <ProtectedRouter
                  privileges={[Privilages.VIEW_PROFILE]}
                  allowBP={false}
                  exact
                  path="/employee-profile"
                >
                  <MyProfilePage />
                </ProtectedRouter>
                <ProtectedRouter allowBP={true} exact path="/setup-wizard">
                  <SetupWizardPage />
                </ProtectedRouter>
                <ProtectedRouter
                  privileges={[
                    Privilages.VIEW_EMPLOYEE_PROFILES,
                    Privilages.VIEW_EMPLOYEE_SALARY_CONFIGURATION,
                    Privilages.VIEW_EMPLOYEE_PETTY_CASH_BALANCE_IN_EMPLOYEE_PROFILE,
                    Privilages.VIEW_EMPLOYEE_DOCUMENTS,
                    Privilages.VIEW_EMPLOYEE_SCHEDULES,
                    Privilages.VIEW_EMPLOYEE_ATTENDANCE_TYPE_CONFIGURATIONS,
                  ]}
                  allowBP={true}
                  exact
                  path="/employees/employee-profile/:userId"
                >
                  <ViewEmployeeProfile />
                </ProtectedRouter>
                {/* <ProtectedRouter privileges={["1", "2"]} path="/commission">
                  <CommissionForm />
                </ProtectedRouter>
                <ProtectedRouter privileges={["1", "2"]} path="/commission-lists">
                  <ViewCommissionLists />
                </ProtectedRouter> */}
                {/* Start of settings tab routes */}
                <ProtectedRouter
                  privileges={SettingsPrivilages}
                  allowBP={true}
                  exact={true}
                  path="/settings"
                >
                  <SettingsPage />
                </ProtectedRouter>
                <Route path="/crm" component={CRMRoutes} />
                {user?.isFoodicsUser ? (
                  <Route path="/foodics" component={FoodicsRoutes} />
                ) : null}
                <ProtectedRouter
                  privileges={[Privilages.ADD_EDIT_DELETE_OFFICES]}
                  allowBP={true}
                  exact={true}
                  path="/offices/new"
                >
                  <ViewOfficeForm />
                </ProtectedRouter>
                <ProtectedRouter
                  privileges={[Privilages.ADD_EDIT_DELETE_OFFICES]}
                  allowBP={true}
                  exact={true}
                  path="/offices/edit/:officeId"
                >
                  <ViewOfficeForm />
                </ProtectedRouter>
                <ProtectedRouter
                  privileges={[Privilages.EDIT_ATTENDANCE_PROFILES]}
                  allowBP={true}
                  exact
                  path={`/att-profile/edit/:attId`}
                >
                  <AttendanceProfilePage />
                </ProtectedRouter>
                <ProtectedRouter
                  privileges={[Privilages.ADD_CLONE_PROFILES]}
                  allowBP={true}
                  exact
                  path={`/att-profile/new`}
                >
                  <AttendanceProfilePage />
                </ProtectedRouter>
                {/* End of settings tab routes */}
                <ProtectedRouter
                  privileges={[Privilages.ADD_EDIT_DELETE_EMPLOYEES]}
                  allowBP={true}
                  exact
                  path="/employees/new"
                >
                  <CreateEmployeeProfilePage />
                </ProtectedRouter>
                <ProtectedRouter
                  privileges={[
                    Privilages.VIEW_EMPLOYEES,
                    Privilages.VIEW_EMPLOYEE_SALARY_CONFIGURATION,
                    Privilages.VIEW_EMPLOYEE_ATTENDANCE_TYPE_CONFIGURATIONS,
                    Privilages.VIEW_EMPLOYEE_PETTY_CASH_BALANCE_IN_EMPLOYEE_PROFILE,
                    Privilages.VIEW_EMPLOYEE_DOCUMENTS,
                    Privilages.VIEW_EMPLOYEE_SCHEDULES,
                    Privilages.VIEW_MISSING_EXPIRED_DOCUMENT,
                  ]}
                  allowBP={true}
                  exact
                  path="/employees"
                >
                  <EmployeesPage />
                </ProtectedRouter>
                <ProtectedRouter
                  privileges={[Privilages.VIEW_WORK_TEAMS]}
                  allowBP={true}
                  exact
                  path="/employees/work-teams/:workTeamId"
                >
                  <WorkTeamDetails />
                </ProtectedRouter>
                {/* <ProtectedRouter
                  privileges={[Privilages.VIEW_EMPLOYEE_SCHEDULES]}
                  allowBP={true}
                  exact
                  path="/attendance?tab=work-calendar"
                > */}
                {/* <ViewCalendar /> */}
                {/* </ProtectedRouter> */}
                {/* <ProtectedRouter
                  privileges={[Privilages.VIEW_DRAFTED_SCHEDULES]}
                  allowBP={true}
                  exact
                  path="/attendance?tab=work-calendar/drafts"
                >
                  <DraftSchedulesPage />
                </ProtectedRouter> */}
                <ProtectedRouter
                  privileges={[
                    Privilages.PLAN_NEW_SCHEDULES_FOR_MANAGED_WORKGROUPS,
                  ]}
                  allowBP={true}
                  exact
                  path="/work-schedules/form"
                >
                  <CreateWorkSchedulePage />
                </ProtectedRouter>
                <ProtectedRouter
                  privileges={[
                    Privilages.VIEW_LEAVE_BALANCE_HISTORY,
                    Privilages.VIEW_EMPLOYEE_BALANCE_ADJUSTMENT,
                    Privilages.VIEW_ATTENDANCE_MONTHLY_REPORT,
                    Privilages.VIEW_ATTENDANCE_HISTORY_LOGS,
                    Privilages.MANAGE_EMPLOYEE_ATTENDANCE,
                    Privilages.VIEW_EMPLOYEE_PENALTIES,
                    Privilages.VIEW_FACIAL_RECOGNITION_REPORT,
                    Privilages.VIEW_EMPLOYEE_SCHEDULES,
                    Privilages.PLAN_NEW_SCHEDULES_FOR_MANAGED_WORKGROUPS,
                    Privilages.VIEW_DRAFTED_SCHEDULES,
                    ...assignmentTabPrivileges,
                  ]}
                  allowBP={true}
                  exact
                  path="/attendance"
                >
                  <LogsPage />
                </ProtectedRouter>
                {/* <ProtectedRouter privileges={["1"]} path="/office">
                  <ViewOfficeForm />
                </ProtectedRouter> */}
                <ProtectedRouter
                  privileges={[
                    Privilages.VIEW_EMPLOYEE_REQUESTS,
                    Privilages.VIEW_EMPLOYEE_CLAIMS,
                    Privilages.VIEW_REQUEST_RULES_TO_EMPLOYEES_IN_ALL_DEPARTMENTS,
                    Privilages.VIEW_REQUEST_RULES_TO_EMPLOYEES_IN_MY_DEPARTMENT,
                    Privilages.VIEW_APPROVAL_LAYERS,
                    Privilages.VIEW_LOAN_REQUEST,
                  ]}
                  allowBP={true}
                  exact
                  path="/requests"
                >
                  <RequestsPage />
                </ProtectedRouter>
                <ProtectedRouter
                  privileges={[
                    Privilages.VIEW_EMPLOYEE_BONUSES_DEDUCTIONS,
                    Privilages.VIEW_THE_EXPENSES_OF_MY_OFFICE,
                    Privilages.VIEW_PETTY_CASH_BALANCE_ADJUSTMENT_LIST,
                    Privilages.VIEW_LOANS_LIST,
                    Privilages.VIEW_PAYROLL_LIST,
                    Privilages.VIEW_COST_CENTERS,
                    Privilages.VIEW_COST_CENTERS_REPORT,
                  ]}
                  allowBP={true}
                  exact
                  path="/financials"
                >
                  <ViewFinancials />
                </ProtectedRouter>
              
                {/* <ProtectedRouter privileges={["1", "2", "3"]} exact path="/suspension">
                  <ViewSuspension />
                </ProtectedRouter> */}
                <ProtectedRouter
                  privileges={[Privilages.VIEW_ATTENDANCE_HISTORY]}
                  allowBP={true}
                  exact
                  path="/employee-profile/history"
                >
                  <AttendanceHistory />
                </ProtectedRouter>
                <ProtectedRouter
                  privileges={[Privilages.CUSTOMIZE_NOTIFICATIONS]}
                  allowBP={true}
                  exact
                  path="/email-notifications"
                >
                  <EmailNotifications />
                </ProtectedRouter>
                {/* <ProtectedRouter
                  privileges={[Privilages.VIEW_MISSING_EXPIRED_DOCUMENT]}
                  allowBP={true}
                  exact
                  path="/employees?tab=employee-documents"
                >
                  <ViewDocuments />
                </ProtectedRouter> */}
                <ProtectedRouter
                  privileges={[
                    Privilages.VIEW_RECRUITMENT_PROCESSES,
                    Privilages.VIEW_INTAKE_FORMS,
                    Privilages.VIEW_JOB_POSTS,
                  ]}
                  allowBP={true}
                  exact
                  path="/recruitment"
                >
                  <ViewRecruitment />
                </ProtectedRouter>
                <ProtectedRouter
                  privileges={[Privilages.ADD_EDIT_DELETE_INTAKE_FORMS]}
                  allowBP={true}
                  exact
                  path="/recruitment/new-intake"
                >
                  <IntakeFormPage />
                </ProtectedRouter>
                <ProtectedRouter
                  privileges={[Privilages.ADD_EDIT_DELETE_INTAKE_FORMS]}
                  allowBP={true}
                  exact
                  path="/recruitment/intake/:intakeId"
                >
                  <EditIntakeFormPage />
                </ProtectedRouter>
                <ProtectedRouter
                  privileges={[
                    Privilages.ADD_EDIT_DELETE_RECRUITMENT_PROCESSES,
                  ]}
                  allowBP={true}
                  exact
                  path="/recruitment/new-process"
                >
                  <RecruitmentProcessPage />
                </ProtectedRouter>
                <ProtectedRouter
                  privileges={[
                    Privilages.ADD_EDIT_DELETE_RECRUITMENT_PROCESSES,
                  ]}
                  allowBP={true}
                  exact
                  path="/recruitment/process/:processId"
                >
                  <EditRecruitmentProcessPage />
                </ProtectedRouter>
                <ProtectedRouter
                  privileges={[Privilages.VIEW_JOB_POST_APPLICANTS]}
                  allowBP={true}
                  exact
                  path="/recruitment/job-posts/:jobPostId"
                >
                  <ApplicationsList />
                </ProtectedRouter>
                <ProtectedRouter
                  privileges={[Privilages.VIEW_APPLICANT_PROFILES]}
                  allowBP={true}
                  exact
                  path="/recruitment/applicant-profile/:applicantId"
                >
                  <ApplicantProfile />
                </ProtectedRouter>

                <Route path="/admin-panel" component={AdminPanelRoutes} />
                <Route path="/payment-tracking" component={PaymentTrackingRoutes} />

                <Route exact path="/dev/processed-jobs">
                  <ProcessedJobs />
                </Route>
                <Route exact path="/dev/processed-queries">
                  <ProcessedQueries />
                </Route>
                <Route exact path="/password/reset/">
                  <Redirect to="/" />
                </Route>

                <ProtectedRouter
                  privileges={[
                    Privilages.VIEW_COMPANY_ASSETS,
                    Privilages.VIEW_ASSET_REQUEST,
                    Privilages.VIEW_ASSET_TYPES,
                    Privilages.VIEW_STORAGE_LOCATIONS,
                    Privilages.VIEW_SCRAPPED_ASSETS,
                  ]}
                  allowBP={true}
                  exact
                  path="/assets"
                >
                  <AssetsPage />
                </ProtectedRouter>

                <Route component={NotFoundPage} />
              </Switch>
            </Layout>
          </Switch>
        ) : token && user?.has_temporary_password ? (
          <Switch>
            <Route exact path="/password/reset/">
              <ResetPasswordPage updateTempPassword user={user} />
            </Route>
            <Route path="*">
              <Redirect to="/password/reset" />
            </Route>
          </Switch>
        ) : (
          <Switch>
            <Route
              path="/unsubscribe/notification/:token"
              component={UnsubscribeNotificationsPage}
            />
            <Route exact path="/down">
              <DownPage />
            </Route>
            <Route exact path="/redirecting">
              <BlankPage />
            </Route>
            <Route exact path="/privacy-policy">
              <PrivacyPolicyPage />
            </Route>
            <Route exact path="/login">
              <LoginPage />
            </Route>
            <Route exact path="/forgot-password">
              <ForgotPasswordPage />
            </Route>
            <Route exact path="/password/reset/:token">
              <ResetPasswordPage />
            </Route>
            <Route exact path="/password/reset/wassage/:token">
              <ResetPasswordPage wassagePlatform />
            </Route>
            <Route exact path="/verify-phone-number">
              <VerifyPhonePage />
            </Route>
            <Route exact path="/verifyEmailAddress">
              <VerifyEmailPage />
            </Route>
            <Route exact path="/jobPostApplication">
              <JobPostApplication />
            </Route>
            <Route exact path="/JobApplicationSuccess">
              <JobApplicationSuccess />
            </Route>
            <Route exact path="*">
              <Redirect to="/login" />
            </Route>
          </Switch>
        )}
      </Router>
    </MuiProvider>
  );
};

export default MainApp;

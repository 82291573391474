import React from "react";
import { useDispatch } from "react-redux";

import Privileges from "../Constants/Privilages";
import { EDIT_ATTENDANCE } from "../Constants/Requests";

import HasPrivileges from "../Helpers/HOC/HasPrivileges";
import { PreviewIconButton } from "./IconButtonWithTooltip";
import { showViewAttRequestModal } from "../Store/Actions";

/**
 *
 * Start of Edit Attendance Request Actions
 *
 */

export const EditAttReqActions = ({ request, isMyProfile }) => {
  const dispatch = useDispatch();

  /* ↓ Helpers ↓ */

  const handleShowAttRequestInfo = () => {
    dispatch(showViewAttRequestModal(request));
  };

  if (
    request?.status?.toLowerCase() === "pending" &&
    request?.requestType?.id === EDIT_ATTENDANCE
  ) {
    if (!isMyProfile) {
      return (
        <HasPrivileges
          allowBP
          reqireMain={[
            Privileges.VIEW_EMPLOYEE_REQUESTS,
            Privileges.MANAGE_EMPLOYEE_REQUESTS,
            // Privileges.APPLY_FOR_EDIT_ATTENDANCE_REQUESTS_ON_BEHALF,
          ]}
          altExpireChildren={<PreviewIconButton />}
        >
          <PreviewIconButton
            label="show request"
            onClick={handleShowAttRequestInfo}
          />
        </HasPrivileges>
      );
    } else {
      return (
        <HasPrivileges
          allowBP
          reqireMain={[Privileges.APPLY_FOR_EDIT_ATTENDANCE_REQUESTS]}
          altExpireChildren={<PreviewIconButton />}
        >
          <PreviewIconButton
            label="show request"
            onClick={handleShowAttRequestInfo}
          />
        </HasPrivileges>
      );
    }
  }
  return null;
};

/**
 *
 * End of Edit Attendance Request Actions
 *
 * Start of
 *
 */

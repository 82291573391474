import { faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import SinglePaymentForm from "./SinglePaymentForm";

const GET_OPTIONS = gql`
  query getCountryOptions($countryId: ID!) {
    country(id: $countryId) {
      name
      short_name

      banks {
        id
        name
      }
      phoneWallets {
        id
        name
      }
      prepaidCards {
        id
        name
      }
    }
  }
`;

const UpdateOrCreateUserPayment = ({
  isEmployeeForm = true,
  formSubmitting,
  countryId,
}) => {
  const { t } = useTranslation();

  const payments = useSelector(
    (state) => state?.super?.paymentMethodForm?.payments
  );

  const { data: optionsData, loading: optionsLoading } = useQuery(GET_OPTIONS, {
    variables: {
      countryId: countryId,
    },
    skip: !Boolean(countryId),
  });

  return (
    <div className={`${isEmployeeForm ? "boxContainer" : ""} mb-3`}>
      {isEmployeeForm ? (
        <>
          <div className="name_container">
            <FontAwesomeIcon
              className=""
              icon={faMoneyBill}
              color="#23aaeb"
              size="2x"
            />
            <h4
              className={
                isEmployeeForm ? "sub-title-style mb-0" : "secondary_title my-3"
              }
            >
              {t("Payment Info")}
            </h4>
          </div>
          <hr />
        </>
      ) : null}
      {payments?.map((payment, index) => (
        <SinglePaymentForm
          payment={payment}
          optionsData={optionsData}
          optionsLoading={optionsLoading}
          index={index}
          formSubmitting={formSubmitting}
        />
      ))}
    </div>
  );
};

export default UpdateOrCreateUserPayment;

import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Box, Tooltip } from "@mui/material";
import { CheckboxBooleanForm, RadioboxForm } from "form-builder";

const options = [{ value: "both" }, { value: "manager" }];

const AbilitySubmitRequestsSection = ({ formProps }) => {
  const { t } = useTranslation();

  // Reducer State
  const formData = useSelector((state) => state?.super?.[formProps?.formName]);

  // Constants
  const requests = [
    {
      name: "annual_emergency_leave",
      label: t("Annual and emergency leaves"),
      show: formData?.allow_annual_leaves,
    },
    {
      name: "annual_emergency_half_day",
      label: t("Annual and emergency Half-days"),
      show: formData?.allow_annual_leaves,
    },
    {
      name: "unpaid_leave",
      label: t("Unpaid Leaves"),
      show: formData?.allow_unpaid_leaves,
    },
    {
      name: "unpaid_half_day",
      label: t("Unpaid Half-days"),
      show: formData?.allow_unpaid_leaves,
    },
    {
      name: "sick_leave",
      label: t("Sick Leaves"),
      show: formData?.allow_sick_leaves,
    },
    {
      name: "permission",
      label: t("Paid Permissions"),
      show: formData?.allow_paid_permissions,
    },
    {
      name: "unpaid_permission",
      label: t("unpaid permissions"),
      show: formData?.allow_unpaid_permissions,
    },
  ];
  const checked = requests.some((req) => req.show);

  return (
    <>
      <Tooltip
        title={checked ? "" : "Allow any type of leaves to enable this section"}
      >
        <span>
          <CheckboxBooleanForm
            disabled
            checked={checked}
            options={["Allow ability to submit requests"]}
            containerStyle="leaves_checkbox_container mb-2"
          />
        </span>
      </Tooltip>

      {checked ? (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 50px 100px",
            ".center": { justifySelf: "center" },
          }}
        >
          <b>{t("Requests")}</b>
          <b className="center pr-3">{t("Both")}</b>
          <b className="center">{t("Manager Only")}</b>

          {requests.map((request) =>
            request?.show ? (
              <React.Fragment key={request?.name}>
                <span>{request?.label}</span>

                {options.map((opt) => (
                  <RadioboxForm
                    {...formProps}
                    key={opt?.value}
                    name={request?.name}
                    options={[opt]}
                    rootStyle="center"
                  />
                ))}
              </React.Fragment>
            ) : null
          )}
        </Box>
      ) : null}
    </>
  );
};

export default AbilitySubmitRequestsSection;

import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";

import { showToast } from "../../Helpers/HelperFns";
import { conflictModalQuery } from "../../Graphql/query";
import { conflictModalMutation } from "../../Graphql/mutation";

import Loader from "../Loader";
import { Alert, AlertTitle, Button } from "@mui/material";

const formatData = (data) =>
  data.map((d) => ({
    id: d?.id,
    date: d?.date,
    WT: d?.oldWorkTiming?.name,
    newWT: d?.newWorkTiming?.name,
    attProfile: d?.attendanceProfile?.name,
  }));

const ConflictAlert = () => {
  const { t } = useTranslation();

  // Local State
  const [data, setData] = React.useState([]);

  // Server State
  const [dismiss, { loading: dismissLoading }] = useMutation(
    conflictModalMutation
  );
  useQuery(conflictModalQuery, {
    onCompleted: ({ failed_process = [] }) => {
      if (failed_process.length) {
        setData(formatData(failed_process));
      }
    },
  });

  /* ↓ Helpers ↓ */

  const handleDismiss = (id) => {
    dismiss({
      variables: { id },
      onCompleted: () => {
        setData((prev) => prev.filter((p) => p?.id !== id));
        showToast("success");
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors[0]?.extensions?.reason ||
            err?.graphQLErrors[0]?.message ||
            err?.message
        );
      },
    });
  };

  return data?.length
    ? data.map(({ id, date, attProfile, WT, newWT }) => (
        <>
          {dismissLoading ? <Loader fixed /> : null}
          <Alert
            severity="warning"
            sx={{
              mb: 2,
              alignItems: "center",
              ".MuiAlert-message": { display: "flex", gap: 1, pb: 0 },
            }}
            action={
              <Button
                size="small"
                sx={{ color: "#9B0606" }}
                onClick={() => handleDismiss(id)}
              >
                {t("Dismiss")}
              </Button>
            }
          >
            <AlertTitle>{t("warning")}: </AlertTitle>
            <p className="flex-1 mb-0">
              {t("conflict_msg", { WT, newWT, attProfile, date })}
            </p>
          </Alert>
        </>
      ))
    : null;
};

export default ConflictAlert;

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { CheckboxBooleanForm, BSelect } from "form-builder";
import { useTranslation } from "react-i18next";
import { showErrorToast } from "../../Store/Actions";
import { fetchAllowedWorkTimingOptionsQuery } from "../../Graphql/query";
import { useQuery } from "@apollo/client";

const LimitWorkTimingsSection = (props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  // states from store
  const workTimingsData = useSelector((state) => state.super.workTimings);

  // Start of fetch limit work timing allowed employees/postions/departments/offices options query function
  const {
    data: allowedWorkTimingOptionsResponseData,
    loading: allowedWorkTimingOptionsLoading,
  } = useQuery(fetchAllowedWorkTimingOptionsQuery, {
    onError(err) {
      // error toaster
      dispatch(
        showErrorToast(
          err?.graphQLErrors[0]?.extensions?.reason ||
            err?.graphQLErrors[0]?.message ||
            err?.message
        )
      );
    },
    onCompleted: (data) => {
      // console completed data
      console.log("onCompleted", data);
    },
  });
  // End of fetch limit work timing allowed employees/postions/departments/offices options query function

  return (
    <>
      <h5 className="content-header">{t("work timing assignment")}</h5>

      <CheckboxBooleanForm
        {...props?.formProps}
        options={[
          "Anyone can assign this work timing to a schedule/assignment/sign-in-configuration",
        ]}
        name="allow_work_timing_assigment"
        type="checkbox"
        checked={workTimingsData?.allow_work_timing_assigment}
      />

      {!workTimingsData?.allow_work_timing_assigment ? (
        <>
          <BSelect
            {...props?.formProps}
            isMulti
            name="offices"
            label={t("allowed offices")}
            keepDefaultStyle
            options={
              allowedWorkTimingOptionsResponseData?.company_offices?.data
            }
            isLoading={allowedWorkTimingOptionsLoading}
            isDisabled={allowedWorkTimingOptionsLoading}
            labelStyle="my-2"
            containerStyle="containerStyle flex-column align-items-start py-0"
            inputContainerStyle="flex-grow-1 mr-0 w-100"
            skipLocalization
            icon="office"
          />

          <BSelect
            {...props?.formProps}
            isMulti
            name="departments"
            label={t("allowed departments")}
            keepDefaultStyle
            options={
              allowedWorkTimingOptionsResponseData?.company_departments?.data
            }
            isLoading={allowedWorkTimingOptionsLoading}
            isDisabled={allowedWorkTimingOptionsLoading}
            labelStyle="my-2"
            containerStyle="containerStyle flex-column align-items-start py-0"
            inputContainerStyle="flex-grow-1 mr-0 w-100"
            skipLocalization
            icon="department"
          />

          <BSelect
            {...props?.formProps}
            isMulti
            name="positions"
            label={t("allowed positions")}
            keepDefaultStyle
            options={
              allowedWorkTimingOptionsResponseData?.company_positions?.data
            }
            isLoading={allowedWorkTimingOptionsLoading}
            isDisabled={allowedWorkTimingOptionsLoading}
            labelStyle="my-2"
            containerStyle="containerStyle flex-column align-items-start py-0"
            inputContainerStyle="flex-grow-1 mr-0 w-100"
            skipLocalization
            icon="person"
          />

          <BSelect
            {...props?.formProps}
            isMulti
            name="employees"
            label={t("allowed employees")}
            keepDefaultStyle
            options={allowedWorkTimingOptionsResponseData?.employees?.data}
            isLoading={allowedWorkTimingOptionsLoading}
            isDisabled={allowedWorkTimingOptionsLoading}
            labelStyle="my-2"
            containerStyle="containerStyle flex-column align-items-start py-0"
            inputContainerStyle="flex-grow-1 mr-0 w-100"
            skipLocalization
            icon="employees"
          />
        </>
      ) : null}
    </>
  );
};

export default LimitWorkTimingsSection;

import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import moment from "moment";
import Chart from "chart.js";
import gql from "graphql-tag";

import { DateTimePickerForm } from "../../../Builder/Form";
import { PiechartPlaceholder } from "../../../LoadingSection/Dashboard";

const departmentsChartQuery = gql`
  query departmentsChart($from: String, $to: String) {
    departments_users_chart(from: $from, to: $to) {
      data
      names
    }
  }
`;

let DoughnutChart;
const DepartmentChart = () => {
  const { t } = useTranslation();

  // Local State
  const [mQuery, setMQuery] = useState(window.innerWidth < 768);
  const [filter, setFilter] = useState({
    from: moment().startOf("month"),
    to: moment().endOf("month"),
  });

  // Server State
  const { data, loading } = useQuery(departmentsChartQuery, {
    variables: {
      to: filter.to ? filter.to.format("YYYY-MM-DD") : null,
      from: filter.from ? filter.from.format("YYYY-MM-DD") : null,
    },
  });

  // Constants
  const chartData = data?.departments_users_chart;

  /* ↓ Helpers ↓ */

  const handleFromTo = (date, name) => {
    setFilter((prev) => ({ ...prev, [name]: date }));
  };

  /* ↓ State Effects ↓ */

  useEffect(() => {
    window.addEventListener("resize", (e) => {
      setMQuery(e.currentTarget.innerWidth < 768);
    });
    // this is the cleanup function to remove the listener
    return () =>
      window.removeEventListener("resize", (e) =>
        setMQuery(e.currentTarget.innerWidth < 768)
      );
  }, []);

  useEffect(() => {
    // intiliazing Line Chart and its options
    var theHelp = Chart.helpers;
    if (!loading) {
      let ctx = document.getElementById("DoughnutChartID").getContext("2d");

      if (typeof DoughnutChart !== "undefined") DoughnutChart.destroy();

      DoughnutChart = new Chart(ctx, {
        type: "doughnut",
        data: {
          labels: chartData?.names,
          datasets: [
            {
              data: chartData?.data,
              backgroundColor: [
                "#23aaeb",
                "#23aaebc2",
                "#23aaeba6",
                "#23aaeb87",
                "#23aaeb66",
                "#1398d7",
                "#00adff",
                "#007db8",
              ],
            },
          ],
        },
        options: {
          plugins: {
            labels: {
              render: (args) => `${args.percentage}%`,
              fontColor: "#fff",
            },
          },
          // responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: true,
            position: mQuery ? "top" : "right",
            labels: {
              generateLabels: function (chart) {
                var data = chart.data;
                if (data.labels.length && data.datasets.length) {
                  return data.labels.map(function (label, i) {
                    var meta = chart.getDatasetMeta(0);
                    var ds = data.datasets[0];
                    var arc = meta.data[i];
                    var custom = (arc && arc.custom) || {};
                    var getValueAtIndexOrDefault =
                      theHelp.getValueAtIndexOrDefault;
                    var arcOpts = chart.options.elements.arc;
                    var fill = custom.backgroundColor
                      ? custom.backgroundColor
                      : getValueAtIndexOrDefault(
                          ds.backgroundColor,
                          i,
                          arcOpts.backgroundColor
                        );
                    var stroke = custom.borderColor
                      ? custom.borderColor
                      : getValueAtIndexOrDefault(
                          ds.borderColor,
                          i,
                          arcOpts.borderColor
                        );
                    var bw = custom.borderWidth
                      ? custom.borderWidth
                      : getValueAtIndexOrDefault(
                          ds.borderWidth,
                          i,
                          arcOpts.borderWidth
                        );
                    return {
                      // And finally :
                      text: `${label} ${Math.round(
                        (ds.data[i] / ds.data.reduce((a, b) => +a + +b, 0)) *
                          100
                      )}%`,
                      fillStyle: fill,
                      strokeStyle: stroke,
                      lineWidth: bw,
                      hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                      index: i,
                    };
                  });
                }
                return [];
              },
            },
          },
        },
      });
    }
  }, [loading, mQuery]);

  return loading ? (
    <PiechartPlaceholder />
  ) : (
    <>
      <div className="d-flex flex-column flex-md-row justify-content-between chart-title">
        <h4 className="ml-0 mb-0 pt-1">{t("department_chart")}</h4>
        <div className="d-flex gap-10">
          <DateTimePickerForm
            placeholder="from"
            value={filter.from}
            onChange={(val) => handleFromTo(val, "from")}
          />
          <DateTimePickerForm
            placeholder="to"
            value={filter.to}
            onChange={(val) => handleFromTo(val, "to")}
          />
        </div>
      </div>

      <div className="DoughnutChart_wrapper_style">
        {loading ? null : <canvas height="100vh" id="DoughnutChartID"></canvas>}
      </div>
    </>
  );
};

export default DepartmentChart;

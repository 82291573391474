import gql from "graphql-tag";

/* Leads */

export const tagFragment = gql`
  fragment tag on Tag {
    id
    name
    color: colour
  }
`;

export const leadFragment = gql`
  fragment lead on Lead {
    id
    name
    city
    eps_no
    source
    country
    industry
    phones
    website
    date
    notes
    assignedTo {
      id
      name
    }
    user {
      id
      name
    }
    tags {
      ...tag
    }
  }
  ${tagFragment}
`;

/* Activities */

export const activityFragment = gql`
  fragment activity on Activity {
    id
    type
    date
    notes
    status
    lead {
      id
      name
      contacts {
        id
        name
      }
    }
    attachments {
      id
      name
      file {
        id
        path
      }
    }
  }
`;

/* Reminders */

export const reminderFragment = gql`
  fragment reminder on Reminder {
    id
    type
    notes
    status
    due_date
    attachments {
      id
      name
      file {
        id
        path
      }
    }
    lead {
      id
      name
      contacts {
        id
        name
      }
    }
  }
`;

/* Trials */

export const trialFragment = gql`
  fragment trial on Trial {
    id
    status
    period
    user {
      id
      name
    }
    subscription {
      id
      limit: employees_limit
      end_date
      start_date
      plan {
        id
        name
      }
      company {
        id
        name
        numberOfEmployees
        allow_am_access
        primarySAAS {
          user {
            id
            name
          }
        }
        secondarySAAS {
          user {
            id
            name
          }
        }
        businessPartners {
          user {
            id
            name
            email
            phone
            phone_country_code
            country_short_name
          }
        }
      }
    }
  }
`;

/* Others */

export const contactFragment = gql`
  fragment contact on Contact {
    id
    name
    email
    phones
    position
  }
`;

export const commentFragment = gql`
  fragment comment on Comment {
    id
    comment
    created_at
    user {
      id
      name
    }
  }
`;

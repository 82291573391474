import React from "react";

import {
  CheckboxBooleanForm,
  DateTimePickerForm,
  InputForm,
} from "form-builder";

import { useTranslation } from "react-i18next";

import AttSignInSettings from "../../../Components/AttProfileSections/AttSignInSettings";

const WorkTimingSignInSettings = ({ onNext, onBack, ...commonFormProps }) => {
  //   const { commonFormProps } = props;

  const { t } = useTranslation();

  //   console.log(props);
  return (
    <div className="pt-lg-4 pt-1 pb-1">
      <h4 className="my-2 sub-title-style">{t("sign in settings")}</h4>

      <AttSignInSettings formProps={commonFormProps} />

      <div className="mt-4 mb-1 text-right">
        <button
          type="submit"
          onClick={onBack}
          // onClick={()=>console.log(props.data.attendaceProfile)}

          className="btn btn-secondray pervious_btn_style mr-2"
        >
          {t("previous")}
        </button>
        <button
          className="btn btn-primary submit_btn_style invisible"
        // onClick={onNext}
        >
          {t("next")}
        </button>
      </div>
    </div>
  );
};

export default WorkTimingSignInSettings;

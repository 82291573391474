import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import {
  GET_EMPLOYEE_ASSIGNED_ASSETS,
  GET_MY_ASSIGNED_ASSETS,
} from "../../../Graphql/query";
import DataTable from "react-data-table-component";
import Loader from "../../../Components/Loader";
import Pagination from "../../../Components/Pagination";
import { useTranslation } from "react-i18next";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import { IconButton } from "@mui/material";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import { useDispatch, useSelector } from "react-redux";
import RetriveAssetModal from "./RetriveAssetModal";
import {
  toggleExchangeItemModal,
  toggleRetriveAssetModal,
} from "../../../Store/Actions";
import ExchangeItemModal from "./ExchangeItemModal";
import AssignRetriveAssetsModal from "../CompanyAssets/AssignRetriveAssetsModal";
import HasPrivileges from "../../../Helpers/HOC/HasPrivileges";
import Privilages from "../../../Constants/Privilages";
import EmployeeAssetRequestModal from "./EmployeeAssetRequestModal";

const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};

const EmployeeAssignedAssets = ({ employeeId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [pagination, setPagination] = useState(paginationInitState);
  const isAssignRetrieveAssetModalOpen = useSelector(
    (state) => state?.assets?.assignRetriveAssetModal?.isOpen
  );

  const authId = useSelector((state) => state?.auth?.userProfile?.id);

  const [attemptGetAssingedAssets, { loading, data }] = useLazyQuery(
    GET_EMPLOYEE_ASSIGNED_ASSETS,
    {
      variables: {
        page: pagination?.currentPage,
        employeeId: employeeId ?? authId,
      },
      onCompleted: (data) => {
        setPagination(data?.employeeAssignItems?.paginatorInfo ?? {});
      },
    }
  );

  const [
    attemptGetMyAssignedAssets,
    { loading: myAssetsLoading, data: myAssignedData },
  ] = useLazyQuery(GET_MY_ASSIGNED_ASSETS, {
    variables: {
      page: pagination?.currentPage,
    },
    onCompleted: (data) => {
      setPagination(data?.viewMyAssignItems?.paginatorInfo ?? {});
    },
  });

  const handleRefetch = () => {
    if (!!employeeId) {
      attemptGetAssingedAssets();
    } else {
      attemptGetMyAssignedAssets();
    }
  };

  const handlePaginate = (page = pagination.currentPage) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
  };

  useEffect(() => {
    if (!!employeeId) {
      attemptGetAssingedAssets();
    } else {
      attemptGetMyAssignedAssets();
    }
  }, [employeeId]);

  const isRetriveModalOpen = useSelector(
    (state) => state?.assets?.retriveAssetModal?.isOpen
  );

  const isRequestModalOpen = useSelector(
    (state) => state?.assets?.employeeAssetsRequestModal?.isOpen
  );

  const handleTransferItemToAnotherEmployee = (item) => {
    dispatch(toggleExchangeItemModal({ isOpen: true, itemId: item?.id }));
  };

  const handleUnAssignItem = (item) => {
    dispatch(toggleRetriveAssetModal({ isOpen: true, itemId: item?.id }));
  };

  const isExchangeItemModalOpen = useSelector(
    (state) => state?.assets?.exchangeItemModal?.isOpen
  );

  const columns = [
    {
      name: t("name"),
      cell: (item) => <p className="m-0">{item?.asset?.name}</p>,
    },
    {
      name: t("type"),
      cell: (item) => <p className="m-0">{item?.asset?.assetType?.name}</p>,
    },
    {
      name: t("brand"),
      cell: (item) => <p className="m-0">{item?.asset?.brand}</p>,
    },
    {
      name: t("model"),
      cell: (item) => <p className="m-0">{item?.asset?.model}</p>,
    },
    {
      name: t("serial number"),
      selector: "serial_number",
    },
    {
      name: t("description"),
      selector: "note",
    },
    {
      width: "100px",
      cell: (item) => (
        <div className="d-flex align-items-center gap-5">
          {employeeId ? (
            <>
              <HasPrivileges
                allowBP
                reqireMain={[Privilages.TRANSFER_ASSIGNED_ASSET]}
              >
                <IconButton
                  onClick={() => handleTransferItemToAnotherEmployee(item)}
                  size="small"
                >
                  <SyncAltIcon color="primary" fontSize="small" />
                </IconButton>
              </HasPrivileges>

              <HasPrivileges
                allowBP
                reqireMain={[Privilages.ASSIGN_RETRIEVE_ASSET_UNITS]}
              >
                <IconButton
                  onClick={() => handleUnAssignItem(item)}
                  size="small"
                >
                  <LinkOffIcon fontSize="small" color="error" />
                </IconButton>
              </HasPrivileges>
            </>
          ) : null}
        </div>
      ),
    },
  ];

  return (
    <div>
      <DataTable
        noHeader
        data={
          !!employeeId
            ? data?.employeeAssignItems?.data ?? []
            : myAssignedData?.viewMyAssignItems?.data ?? []
        }
        columns={columns}
        className="cards_table my-3"
        progressPending={loading || myAssetsLoading}
        progressComponent={<Loader />}
        pagination={true}
        paginationComponent={() => (
          <Pagination
            styleWraper=""
            onPaginate={handlePaginate}
            customPaginator={pagination}
          />
        )}
      />

      {isRetriveModalOpen ? (
        <RetriveAssetModal handleRefetch={handleRefetch} />
      ) : null}

      {isExchangeItemModalOpen ? (
        <ExchangeItemModal handleRefetch={handleRefetch} />
      ) : null}

      {isRequestModalOpen ? <EmployeeAssetRequestModal /> : null}
      {isAssignRetrieveAssetModalOpen ? (
        <AssignRetriveAssetsModal
          handleRefetch={handleRefetch}
          employeeId={employeeId}
        />
      ) : null}
    </div>
  );
};

export default EmployeeAssignedAssets;

import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import AttendanceLogsList from "../../Containers/AttendanceLogsList";

import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import HelperFns from "../../Helpers/HelperFns";
import Penalties from "../../Containers/Penalties";
import MonthlyReport from "../../Containers/MonthlyReport";
import YearlyReport from "../../Containers/YearlyReport";
import Assignments from "../../Containers/Assignments";
import FacialRecognitionReport from "../../Containers/FacialRecognitionReport";
import LogsTabs from "./LogsTabs";
import { useSelector } from "react-redux";
import { Config } from "../../Config";
import { assignmentTabPrivileges } from "../../Constants";
import ConflictAlert from "../../Components/Feedback/ConflictAlert";
import BalanceLogsList from "../../Containers/BalanceLogsList";
import ViewCalendar from "../ViewCalendar/ShiftsCalendar";
import { DraftSchedulesPage } from "../DraftSchedulesPage";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const LogsPage = (props) => {
  const companyId = useSelector(
    (state) => state?.user?.userProfile?.company?.id
  );
  const history = useHistory();
  let query = useQuery();
  const activeValue = query.get("tab");

  useEffect(() => {
    if (!activeValue) {
      renderfirstTab();
    }
  }, [activeValue]);

  const renderfirstTab = () => {
    if (
      HelperFns.checkPrivileges({
        privileges: [
          Privilages.VIEW_EMPLOYEE_SCHEDULES,
          Privilages.PLAN_NEW_SCHEDULES_FOR_MANAGED_WORKGROUPS,
        ],
        allowBP: true,
      })
    ) {
      return history.push(`/attendance?tab=work-calendar`);
    }
    if (
      HelperFns.checkPrivileges({
        privileges: [
          Privilages.VIEW_ATTENDANCE_HISTORY_LOGS,
          Privilages.MANAGE_EMPLOYEE_ATTENDANCE,
        ],
        allowBP: true,
      })
    ) {
      return history.push(`/attendance?tab=attendance-history`);
    }
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_EMPLOYEE_PENALTIES],
        allowBP: true,
      })
    ) {
      return history.push(`/attendance?tab=penalties`);
    }
    if (
      HelperFns.checkPrivileges({
        privileges: assignmentTabPrivileges,
        allowBP: true,
      })
    ) {
      return history.push(`/attendance?tab=assignments`);
    }
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_ATTENDANCE_MONTHLY_REPORT],
        allowBP: true,
      })
    ) {
      return history.push(`/attendance?tab=monthly-report`);
    }
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_ATTENDANCE_MONTHLY_REPORT],
        allowBP: true,
      })
    ) {
      return history.push(`/attendance?tab=custom-report`);
    }
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_ATTENDANCE_MONTHLY_REPORT],
        allowBP: true,
      })
    ) {
      return history.push(`/attendance?tab=yearly-report`);
    }
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_FACIAL_RECOGNITION_REPORT],
        allowBP: true,
      })
    ) {
      return history.push(`/attendance?tab=facial-recognition-report`);
    }
    return null;
  };

  // const handleChange = (event, newValue) => {
  //   history.push(`/attendance?tab=${newValue}`);
  // };

  const renderSelectedTab = () => {
    switch (activeValue?.toLowerCase()) {
      case "work-calendar":
        return (
          <HasPrivileges
            reqireMain={[
              Privilages.VIEW_EMPLOYEE_SCHEDULES,
              Privilages.PLAN_NEW_SCHEDULES_FOR_MANAGED_WORKGROUPS,
            ]}
            allowBP
          >
            <ViewCalendar />
          </HasPrivileges>
        );
        case "work-calendar/drafts":
          return (
            <HasPrivileges
              reqireMain={[Privilages.VIEW_DRAFTED_SCHEDULES]}
              allowBP
            >
              <DraftSchedulesPage />
            </HasPrivileges>
          );
      case "attendance-history":
        return (
          <HasPrivileges
            reqireMain={[
              Privilages.VIEW_ATTENDANCE_HISTORY_LOGS,
              Privilages.MANAGE_EMPLOYEE_ATTENDANCE,
            ]}
            allowBP
          >
            <ConflictAlert />
            <AttendanceLogsList />
          </HasPrivileges>
        );
      case "penalties":
        return (
          <HasPrivileges
            reqireMain={[Privilages.VIEW_EMPLOYEE_PENALTIES]}
            allowBP
          >
            <Penalties />
          </HasPrivileges>
        );

      case "assignments":
        return <Assignments />;

      case "monthly-report":
        return (
          <HasPrivileges
            reqireMain={[Privilages.VIEW_ATTENDANCE_MONTHLY_REPORT]}
            allowBP
          >
            <MonthlyReport />
          </HasPrivileges>
        );
      case "custom-report":
        return (
          <HasPrivileges
            reqireMain={[Privilages.VIEW_ATTENDANCE_MONTHLY_REPORT]}
            allowBP
          >
            <MonthlyReport customReport />
          </HasPrivileges>
        );
      case "yearly-report":
        return (
          <HasPrivileges
            reqireMain={[Privilages.VIEW_ATTENDANCE_MONTHLY_REPORT]}
            allowBP
          >
            <YearlyReport />
          </HasPrivileges>
        );
      case "facial-recognition-report":
        return (
          <HasPrivileges
            reqireMain={[Privilages.VIEW_FACIAL_RECOGNITION_REPORT]}
            allowBP
          >
            <FacialRecognitionReport />
          </HasPrivileges>
        );
        case "leave-adjustments":
        return (
          <HasPrivileges
            reqireMain={[Privilages.VIEW_EMPLOYEE_BALANCE_ADJUSTMENT]}
            allowBP
          >
            <BalanceLogsList />
          </HasPrivileges>
        );
      default:
        break;
    }
  };

  return (
    <div className="content pt-3">
      <LogsTabs />
      <div className="pt-4">{renderSelectedTab()}</div>
    </div>
  );
};

export default LogsPage;

import React, { useEffect } from "react";
import MainModal from "../../MainModal";
import { connect } from "react-redux";
import moment from "moment";
import {
  dismissAttChartEmployeeNameModalAction,
  fetchEmployeeNameOnClickAction,
} from "../../../Store/Actions";
import Loader from "../../Loader";
import Avatar from "@mui/material/Avatar";
import Helper from "../../../Helpers/HelperFns";
import { useTranslation } from "react-i18next";
import { Home } from "@mui/icons-material";

const formName = "attendanceChartEmployeeNamesModal";

const ShowEmployeesNameModal = (props) => {
  const { t } = useTranslation();

  const toggleModal = () => {
    props.dismissAttChartEmployeeNameModalAction({
      ref: "attendanceChartEmployeeNamesModalActions",
      formName,
    });
  };

  useEffect(() => {
    if (props?.isModalVissible) {
      props?.fetchEmployeeNameOnClickAction(
        props?.attendanceChartEmployeeNamesModal?.date,
        props?.attendanceChartEmployeeNamesModal?.status,
        props?.attendanceChartEmployeeNamesModal?.office_id
      );
    }
  }, [props?.isModalVissible]);

  if (props?.isEmployeeNamesLoading) {
    return (
      <div className="loader_wrapper_style">
        <Loader />
      </div>
    );
  }

  return (
    <MainModal
      isOpen={props?.isModalVissible}
      toggle={toggleModal}
      modalTitle={`${moment(
        props?.attendanceChartEmployeeNamesModal?.date
      ).format("DD/MM/yyyy")} - ${t(
        props?.attendanceChartEmployeeNamesModal?.label
      )} - ${props?.attendanceChartEmployeeNamesModal?.value}`}
      className="show_all_modal_wrapper_style"
    >
      <div className="row">
        {props?.employeeNames?.map((emp) => (
          <div className="col-12 col-md-6 my-2">
            <div className="d-flex align-items-center show_all_modal_avatar_style user-img">
              <Avatar className="rounded-circle blackish-avatar">
                {Helper.getIntials(emp?.user?.name)}
              </Avatar>

              <span className="align-middle pl-2">{emp?.user?.name}</span>
              {emp?.user?.from_home ? (
                <Home className="ml-2 dashboard-home-icon" />
              ) : (
                ""
              )}
            </div>
          </div>
        ))}
      </div>
    </MainModal>
  );
};

const mapStateToProps = (state) => ({
  modalData: state.super.attendanceChartEmployeeNamesModalActions,
  isModalVissible:
    state.super.attendanceChartEmployeeNamesModalActions.isVissible,
  attendanceChartEmployeeNamesModal: state.super[formName],
  isEmployeeNamesLoading: state.super.attendanceChartEmployeeNamesLoading,
  employeeNames: state.super.attendanceChartEmployeeNames,
});

export default connect(mapStateToProps, {
  dismissAttChartEmployeeNameModalAction,
  fetchEmployeeNameOnClickAction,
})(ShowEmployeesNameModal);

// import { useQuery } from '@apollo/client'
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ViewFromBuilder from "../../Containers/ViewFormBuilder";
import {
  fetchIntakeFormAction,
  upsertApplicationAction,
} from "../../Store/Actions";

import {
  Button,
  Spinner,
} from "reactstrap";

import Loader from "../../Components/Loader";
import { useTranslation } from "react-i18next";
// import { CardActionArea } from '@mui/material';

const useQuery = () => {
  return new URLSearchParams(useLocation().search?.toLowerCase());
};

export const OverlayHeader = () => (
  <div className="parent">
    <div className="headerOverlay" />
  </div>
);

const JobPostApplication = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let query = useQuery();

  const Job_post_id = query.get("job_post_id");

  const intakeFormFields = useSelector(
    (state) => state.recruitment.jobPostIntakeFormFields
  );

  const applicationServerValidation = useSelector(
    (state) => state.recruitment.applicationModalServerValidation
  );
  const jobPostIntakeForm = useSelector(
    (state) => state.recruitment.jobPostIntakeForm
  );
  const applicationJobPost = useSelector(
    (state) => state.recruitment.applicationModal
  );
  const applicationModalActions = useSelector(
    (state) => state.recruitment.applicationModalActions
  );

  useEffect(() => {
    if (Job_post_id) {
      fetchIntakeForm();
    }

    return () => {};
  }, [Job_post_id]);

  const handleSubmitBtn = (e) => {
    // to send file and extenstion only without id key in the array
    e.preventDefault();
    let answers = intakeFormFields
      ?.sort((a, b) => a.order - b.order)
      ?.map((field) => ({
        field_id: field.id,
        type: field.type,
        suggested_field_type: field.suggested_field_type,
        value:
          !field?.options?.length && field?.type?.toLowerCase() !== "attachment"
            ? jobPostIntakeForm?.[field.id] ?? null
            : null,
        options_id:
          field?.options?.length &&
          field?.type?.toLowerCase() !== "attachment" &&
          jobPostIntakeForm?.[field.id]
            ? [jobPostIntakeForm?.[field.id]]?.flat(Infinity)
            : [],
        attached_file:
          field?.type?.toLowerCase() === "attachment" &&
          typeof jobPostIntakeForm?.[field.id] !== "string" &&
          jobPostIntakeForm?.[field.id]
            ? jobPostIntakeForm?.[field.id]
            : null,
      }));

    dispatch(
      upsertApplicationAction({
        data: {
          job_post_id: Job_post_id,
          intake_form_id: applicationJobPost?.intake_form_id,
          answers,
        },
        ref: "applicationModalActions",
        external: true,
      })
    );
  };

  const isFetchJobPostIntakeFormLoading = useSelector(
    (state) => state.recruitment.isFetchJobPostIntakeFormLoading
  );

  const fetchIntakeForm = () => {
    dispatch(fetchIntakeFormAction(Job_post_id, true));
  };
  return (
    <>
      <OverlayHeader />
      {isFetchJobPostIntakeFormLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : (
        <div className="content-wraper">
          <div
            className="p-5"
            style={{
              width: "100%",
              height: "auto",
              alignSelf: "center",
              boxShadow: "0 2px 4px 0 rgba(152, 151, 151, 0.16)",
              border: "solid 1px #f5f4f4",
              backgroundColor: "#fff",
              zIndex: 999,
            }}
          >
            <div className="justify-content-between d-flex flex-wrap mb-5">
              <div
                style={{
                  width: "280px",
                  height: "280px",
                  // margin: "0 62px 70.5px 0",
                  borderRadius: "50%",
                  boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.1)",
                  border: "solid 1px #eff2f4",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <img
                  src="assets/img/logo.svg"
                  width="225"
                  height="auto"
                  alt="Mawared Logo"
                />
              </div>
              <div
                className="d-flex flex-column pt-3"
                style={{ width: "50vw" }}
              >
                <h2 className="h3 flex-wrap">
                  {applicationJobPost?.company?.name}
                </h2>
                <h1 style={{ color: "#2764ac", fontWeight: "bold" }}>
                  {applicationJobPost.title}
                </h1>
                <div
                  style={{ color: "#2764ac" }}
                  dangerouslySetInnerHTML={{
                    __html: applicationJobPost?.description,
                  }}
                ></div>
              </div>
            </div>
            <ViewFromBuilder
              formServerValidation={"applicationModalServerValidation"}
              formName="jobPostIntakeForm"
              formFields={intakeFormFields}
            />
            {applicationServerValidation?.["input.intake_form_id"] ? (
              <div className="errors-wrapper">
                <span className="error-color">
                  {applicationServerValidation?.[
                    "input.intake_form_id"
                  ]?.toString()}
                </span>
              </div>
            ) : null}
            {applicationServerValidation?.["input.job_post_id"] ? (
              <div className="errors-wrapper">
                <span className="error-color">
                  {applicationServerValidation?.[
                    "input.job_post_id"
                  ]?.toString()}
                </span>
              </div>
            ) : null}

            <div className="text-right">
              <Button
                disabled={applicationModalActions?.isLoading}
                color="primary"
                // type="submit"
                onClick={handleSubmitBtn}
              >
                {applicationModalActions?.isLoading ? (
                  <Spinner
                    style={{ width: "1rem", height: "1rem", color: "#fff" }}
                  />
                ) : (
                  t("apply")
                )}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default JobPostApplication;

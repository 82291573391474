import gql from "graphql-tag";

/* Leads */

export const storeLeadMutation = gql`
  mutation storeLead(
    $eps_no: Int
    $city: String
    $name: String
    $notes: String
    $tagIds: [Int]
    $source: [String]
    $assigned_to: ID
    $website: String
    $country: String!
    $industry: String
    $phones: [[String]]
    $contacts: [ContactInput]!
  ) {
    store_lead(
      city: $city
      name: $name
      notes: $notes
      tagIds: $tagIds
      source: $source
      eps_no: $eps_no
      phones: $phones
      website: $website
      country: $country
      industry: $industry
      contacts: $contacts
      assigned_to: $assigned_to
    ) {
      id
    }
  }
`;

export const changeLeadMutation = gql`
  mutation changeLead(
    $id: ID!
    $eps_no: Int
    $city: String
    $tagIds: [Int]
    $name: String!
    $notes: String
    $source: [String]
    $assigned_to: ID
    $website: String
    $country: String!
    $industry: String
    $phones: [[String]]
    $contacts: [ContactInput]!
  ) {
    change_lead(
      id: $id
      city: $city
      name: $name
      notes: $notes
      source: $source
      eps_no: $eps_no
      tagIds: $tagIds
      phones: $phones
      website: $website
      country: $country
      industry: $industry
      contacts: $contacts
      assigned_to: $assigned_to
    ) {
      id
    }
  }
`;

export const removeLeadMutation = gql`
  mutation removeLead($ids: [ID]!) {
    remove_lead(ids: $ids) {
      status
      message
    }
  }
`;

export const assignLeadsMutation = gql`
  mutation assignLeads($lead_ids: [ID]!, $user_id: ID!) {
    assign(lead_ids: $lead_ids, user_id: $user_id) {
      id
    }
  }
`;

export const unassignLeadsMutation = gql`
  mutation unassignLeads($lead_ids: [ID]!) {
    unassign_lead(leads_id: $lead_ids) {
      status
      message
    }
  }
`;

export const changeLeadsStatusMutation = gql`
  mutation changeLeadsStatus($leads: [Int], $status: [String]) {
    set_lead(ids: $leads, status: $status)
  }
`;

export const addLeadsTagsMutation = gql`
  mutation addLeadsTags($leads: [ID], $tags: [ID]) {
    assign_tag(lead_ids: $leads, tag_ids: $tags) {
      id
    }
  }
`;

export const removeLeadsTagsMutation = gql`
  mutation removeLeadsTags($leads: [ID], $tags: [ID]) {
    remove_tag_from_lead(lead_ids: $leads, tag_ids: $tags) {
      id
    }
  }
`;

export const downloadCRMTemplateMutation = gql`
  mutation downloadCRMTemplate {
    download_crm_template
  }
`;

export const importLeadsMutation = gql`
  mutation importLead($file: Upload) {
    import_lead(file: $file)
  }
`;

/* Activities */

export const storeActivityMutation = gql`
  mutation storeActivity(
    $type: JSON!
    $lead_id: ID!
    $notes: String
    $date: DateTime!
    $contact_id: ID!
    $status: [String]!
    $meetingDate: String
    $attachments: [Upload]
    $setReminder: Boolean
    $reminderInput: reminderInput
  ) {
    store_activity(
      type: $type
      date: $date
      notes: $notes
      status: $status
      lead_id: $lead_id
      contact_id: $contact_id
      attachments: $attachments
      setReminder: $setReminder
      meetingDate: $meetingDate
      reminderInput: $reminderInput
    ) {
      id
    }
  }
`;

export const changeActivityMutation = gql`
  mutation changeActivity(
    $id: ID!
    $type: JSON!
    $notes: String
    $date: DateTime!
    $contact_id: ID!
    $status: [String]!
    $attachmentIds: [Int]
    $attachments: [Upload]
    $setReminder: Boolean
    $reminderInput: reminderInput
  ) {
    change_activity(
      id: $id
      type: $type
      date: $date
      notes: $notes
      status: $status
      contact_id: $contact_id
      attachments: $attachments
      attachmentIds: $attachmentIds
      setReminder: $setReminder
      reminderInput: $reminderInput
    ) {
      id
    }
  }
`;

export const removeActivityMutation = gql`
  mutation removeActivity($id: ID!) {
    remove_activity(id: $id) {
      id
    }
  }
`;

/* Reminders */

export const storeReminderMutation = gql`
  mutation storeReminder(
    $user_id: ID
    $type: JSON!
    $lead_id: ID!
    $notes: String
    $contact_id: ID!
    $due_date: DateTime!
    $attachments: [Upload]
    $setActivity: Boolean = false
    $activityInput: ActivityInput = null
  ) {
    store_reminder(
      type: $type
      notes: $notes
      user_id: $user_id
      lead_id: $lead_id
      due_date: $due_date
      contact_id: $contact_id
      attachments: $attachments
      setActivity: $setActivity
      activityInput: $activityInput
    ) {
      id
    }
  }
`;

export const changeReminderMutation = gql`
  mutation changeReminder(
    $id: ID!
    $type: JSON
    $user_id: ID
    $lead_id: ID
    $notes: String
    $contact_id: ID
    $due_date: DateTime
    $attachmentIds: [Int]
    $attachments: [Upload]
    $setActivity: Boolean = false
    $activityInput: ActivityInput = null
  ) {
    change_reminder(
      id: $id
      type: $type
      notes: $notes
      user_id: $user_id
      lead_id: $lead_id
      due_date: $due_date
      contact_id: $contact_id
      attachments: $attachments
      attachmentIds: $attachmentIds
      setActivity: $setActivity
      activityInput: $activityInput
    ) {
      id
    }
  }
`;

export const removeReminderMutation = gql`
  mutation removeReminder($id: ID!) {
    remove_reminder(id: $id) {
      id
    }
  }
`;

export const completeReminderMutation = gql`
  mutation changeReminder($id: ID!) {
    set_reminder(id: $id, status: "Completed") {
      id
    }
  }
`;

export const revertReminderMutation = gql`
  mutation changeReminder($id: ID!) {
    set_reminder(id: $id, status: "Revert") {
      id
    }
  }
`;

/* Trials */

export const storeTrialMutation = gql`
  mutation storeTrial(
    $limit: Int
    $from: Date
    $lead_id: ID
    $plan_id: ID
    $name: String
    $period: String
    $primaryId: ID
    $secondaryIds: [ID]
    $business_partners: [BusinessPartnerInput]
    $month_start_day: Int
    $week_day: WeekStartDay
    $year_start_month: Int
    $allow_am_access: Int
    $payment_method: JSON
  ) {
    store_trial(
      name: $name
      from: $from
      limit: $limit
      period: $period
      lead_id: $lead_id
      plan_id: $plan_id
      primaryId: $primaryId
      secondaryIds: $secondaryIds
      business_partners: $business_partners
      first_of_week: $week_day
      first_of_year: $year_start_month
      first_of_month: $month_start_day
      allow_am_access: $allow_am_access
      payment_method: $payment_method
    ) {
      id
    }
  }
`;

export const changeTrialMutation = gql`
  mutation changeTrial(
    $id: ID!
    $limit: Int
    $lead_id: ID
    $plan_id: ID
    $name: String
    $primaryId: ID
    $secondaryIds: [ID]
    $business_partners: [BusinessPartnerInput]
    $allow_am_access: Int
  ) {
    change_trial(
      id: $id
      name: $name
      limit: $limit
      lead_id: $lead_id
      plan_id: $plan_id
      primaryId: $primaryId
      secondaryIds: $secondaryIds
      business_partners: $business_partners
      allow_am_access: $allow_am_access
    ) {
      id
    }
  }
`;

export const removeTrialMutation = gql`
  mutation removeTrial($id: ID!) {
    remove_trial(id: $id) {
      id
    }
  }
`;

/* Comment */

export const storeCommentMutation = gql`
  mutation storeComment($comment: String!, $id: ID, $type: CommentType!) {
    store_comment(comment: $comment, id: $id, type: $type) {
      id
    }
  }
`;

export const changeCommentMutation = gql`
  mutation changeComment($comment: String!, $id: ID) {
    change_comment(comment: $comment, id: $id) {
      id
    }
  }
`;

export const removeCommentMutation = gql`
  mutation removeComment($id: ID!) {
    remove_comment(id: $id) {
      id
    }
  }
`;

/* Contact */

export const storeContactMutation = gql`
  mutation storeContact($input: ContactInput) {
    store_contact(i: $input) {
      id
    }
  }
`;

export const changeContactMutation = gql`
  mutation changeContact($input: ContactInput!) {
    change_contact(i: $input) {
      id
    }
  }
`;

export const removeContactMutation = gql`
  mutation removeContact($id: ID!) {
    remove_contact(id: $id) {
      id
    }
  }
`;

/* Tags */

export const storeLeadTagsMutation = gql`
  mutation storeLeadTags($name: String, $color: String) {
    store_tag(name: $name, colour: $color) {
      id
      name
      color: colour
    }
  }
`;

export const changeLeadTagMutation = gql`
  mutation changeLeadTag($id: Int, $name: String, $color: String) {
    change_tag(id: $id, name: $name, colour: $color)
  }
`;

export const removeLeadTagMutation = gql`
  mutation removeLeadTag($id: Int) {
    remove_tag(id: $id)
  }
`;

/* Meetings */

export const saveMeetingMutation = gql`
  mutation saveMeeting(
    $date: String
    $lead_id: Int
    $user_id: Int
    $company_id: Int
  ) {
    store_meeting(
      date: $date
      lead_id: $lead_id
      user_id: $user_id
      company_id: $company_id
    ) {
      id
    }
  }
`;

export const changeMeetingMutation = gql`
  mutation changeMeeting(
    $id: Int
    $date: String
    $lead_id: Int
    $user_id: Int
    $company_id: Int
  ) {
    change_meeting(
      id: $id
      date: $date
      lead_id: $lead_id
      user_id: $user_id
      company_id: $company_id
    ) {
      id
    }
  }
`;

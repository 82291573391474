import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";

import swal from "sweetalert";
import { connect } from "react-redux";
import { showToast } from "../../Helpers/HelperFns";
import { showSystemRolePlanModal } from "../../Store/Actions";
import { packageRolesQuery } from "../../Graphql/query/PaymentTracking";

import { Link } from "react-router-dom";
import Loader from "../../Components/Loader";
import Privileges from "../../Constants/Privilages";
import { AddButton } from "../../Components/Buttons";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RemoveIconButton } from "../../Components/IconButtonWithTooltip";
import { AddPackageRoleModal } from "../../Components/PaymentTrackingModals";
import { deletePackageRoleMutation } from "../../Graphql/mutation/PaymentTracking";

const ViewPackageRoles = (props) => {
  const { id } = useParams();
  const { t } = useTranslation();

  // Local State
  const [data, setData] = React.useState({});

  // Server State
  const [deletePackageRole, { loading: deleteRoleLoading }] = useMutation(
    deletePackageRoleMutation
  );
  const { loading, refetch } = useQuery(packageRolesQuery, {
    notifyOnNetworkStatusChange: true,
    variables: { id },
    onCompleted: (res) => {
      setData(res?.package);
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });

  const handleAddRole = () => {
    props.showSystemRolePlanModal();
  };

  const handleDeleteRole = (roleId) => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((confirmed) => {
      if (confirmed) {
        deletePackageRole({
          variables: { roleId },
          onCompleted: () => {
            refetch();
            showToast("success");
          },
          onError: (err) => {
            showToast(
              "error",
              err?.graphQLErrors?.[0]?.extensions?.reason ||
                err?.graphQLErrors?.[0]?.message ||
                err?.message
            );
          },
        });
      }
    });
  };

  return (
    <section className="content pt-3">
      {loading || deleteRoleLoading ? <Loader fixed /> : null}

      <div className="px-2 pb-2">
        <h1 className="page-title mb-3">{t("new package")}</h1>

        <div className="d-flex align-items-center gap-20">
          <b className="sub-title-style">{t("Roles")}</b>

          <HasPrivileges
            reqireMain={[
              Privileges.ADD_EDIT_DELETE_PACKAGES,
              Privileges.SUPER_PRIVILEGE,
            ]}
          >
            <AddButton isIconOnly onClick={handleAddRole} />
          </HasPrivileges>
        </div>
        <HasPrivileges
          reqireMain={[
            Privileges.ADD_EDIT_DELETE_PACKAGES,
            Privileges.SUPER_PRIVILEGE,
          ]}
        >
          <div className="mt-3">
            {data?.roles?.map((role, i) => (
              <div key={i} className="d-flex align-items-center justify-content-between max-w-308 mb-2">
                <div className="d-flex align-items-center gap-10">
                  <FontAwesomeIcon size="xs" color="#23aaeb" icon={faCircle} />
                  <span>{role.name}</span>
                </div>
                <HasPrivileges
                  reqireMain={[
                    Privileges.ADD_EDIT_DELETE_PACKAGES,
                    Privileges.SUPER_PRIVILEGE,
                  ]}
                >
                  <RemoveIconButton
                    onClick={() => handleDeleteRole(role?.id)}
                  />
                </HasPrivileges>
              </div>
            ))}
          </div>
        </HasPrivileges>
      </div>

      <div className="px-2">
        <Link
          to="/payment-tracking?tab=packages"
          className="add_new_btn_style bg-success btn float-right m-2 text-capitalize"
        >
          {t("finish")}
        </Link>
      </div>

      <AddPackageRoleModal isNewPlan={true} />
    </section>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {
  showSystemRolePlanModal,
})(ViewPackageRoles);

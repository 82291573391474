import MainModal from "../../Components/MainModal";
import Select from "react-select";
import {
  CheckboxBooleanForm,
  DateTimePickerForm,
  InputForm,
  RadioboxForm,
  BSelect,
} from "form-builder";
import HelperFns, { showToast } from "../../Helpers/HelperFns";
import {
  onFormResetAction,
  showPreviewRolePrivilagesModalAction,
  viewWorkTimeAction,
} from "../../Store/Actions";
import PreviewRolePrivilagesModal from "../../Components/PreviewRolePrivilagesModal";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Alert, AlertTitle } from "@mui/material";
import IconButtonWithTooltip, {
  PreviewIconButton,
} from "../../Components/IconButtonWithTooltip";
import moment from "moment";
import Privilages from "../../Constants/Privilages";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { GET_COST_CENTERS_MENU } from "../../Graphql/query";
import useAllowCostCenter from "../../Helpers/Hooks/useAllowCostCenter";

const bulkEditInitState = {
  from: null,
  to: null,
  status: null,
  workTimingId: null,
  cost_center_id: null,
};

const formServerValidation = "bulkEditAttServerValidation";

const FETCH_WORK_TIMING_LIST = gql`
  query fetchWorkTiming {
    work_timings_menu(new_versions_only: true) {
      id
      name
    }
  }
`;

const UPDATE_BULK_EDIT_ATTENDANCE = gql`
  mutation updateBulkEditAttendance($input: BulkEditAttendanceInput) {
    bulkEditAttendance(input: $input) {
      status
      message
    }
  }
`;

const EditBulkAttendance = (props) => {
  const [formInput, setFormInput] = useState(bulkEditInitState);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { allowCostCenter } = useAllowCostCenter();

  const { loading, error, data } = useQuery(FETCH_WORK_TIMING_LIST, {
    skip: !props?.isOpen,
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      showToast(
        "error",
        error?.graphQLErrors?.[0]?.extensions?.reason ?? error?.message
      );
    },
  });

  const { data: costCentersQueryData, loading: costCentersLoading } = useQuery(
    GET_COST_CENTERS_MENU
  );

  const [
    updateBulkEditAttendance,
    { loading: submitLoading, data: submitData, error: submitError, reset },
  ] = useMutation(UPDATE_BULK_EDIT_ATTENDANCE, {
    onCompleted(res) {
      if (res?.bulkEditAttendance?.status === "success") {
        props?.resetSelection();
        toggle();
      }
      showToast(
        res?.bulkEditAttendance?.status,
        res?.bulkEditAttendance?.message
      );
    },
    onError(error) {
      // do something
      console.log({ error });
      showToast("error", error?.graphQLErrors?.[0]?.extensions?.reason);
    },
  });

  useEffect(() => {
    if (submitError) {
      if (submitError?.graphQLErrors?.[0]?.extensions?.validation) {
        dispatch(
          onFormResetAction(
            formServerValidation,
            submitError?.graphQLErrors?.[0]?.extensions?.validation
          )
        );
      }
    } else {
      dispatch(onFormResetAction(formServerValidation));
    }
  }, [submitError]);

  const handleSubmit = () => {
    reset();
    HelperFns.checkPassword(
      "defaut_warning_messsage",
      "",
      t(`Confirm, bulk edit attendance for`, {
        count: props?.selectedEmployees?.length,
      }),
      "",
      () =>
        updateBulkEditAttendance({
          variables: {
            input: {
              ...formInput,
              employees_id: props?.selectedEmployees,
            },
          },
        })
    );
  };

  const handleDateChange = (value, name, args) => {
    setFormInput((prevState) => ({
      ...prevState,
      [name]: moment(value).format("YYYY-MM-DD"),
    }));
  };
  const handleStatusChange = (e, value) => {
    setFormInput((prevState) => ({ ...prevState, status: value }));
  };

  const handleWtChange = (_, value, args) => {
    setFormInput((prevState) => ({
      ...prevState,
      workTimingId: value?.id ?? null,
    }));
  };

  const handleCostCenterChange = (value) => {
    setFormInput((prevState) => ({
      ...prevState,
      cost_center_id: value?.id ?? null,
    }));
  };

  const handleViewWorkTime = (id) => {
    if (id) {
      dispatch(viewWorkTimeAction(id));
    }
  };

  const toggle = () => {
    props?.dismiss();
    reset();
    dispatch(onFormResetAction(formServerValidation));
    setFormInput(bulkEditInitState);
  };

  return (
    <MainModal
      isOpen={props?.isOpen}
      toggle={toggle}
      className="leaves_breaks_modal"
      btnOnClick={handleSubmit}
      btnSubmitLoading={submitLoading || loading}
      btnOnCancelClick={toggle}
      modalTitle={`${t("Bulk Actions")} - ${t("Edit Attendance")}`}
    >
      <Alert severity="info">
        <AlertTitle>{t("Bulk Edit Attendance")}</AlertTitle>
        <strong>
          {t("Employees will be effected", {
            count: props?.selectedEmployees?.length,
          })}
        </strong>
      </Alert>

      <div className="d-flex px-0  my-1">
        <div className="col-md-6 px-0 my-1">
          <DateTimePickerForm
            formServerValidation={formServerValidation}
            datePickerContainer="ml-0"
            containerStyle="d-flex flex-column flex-md-row align-items-center"
            labelStyle="mr-3"
            label="from"
            name="from"
            validationName="input.from"
            onIntercept={handleDateChange}
            value={
              Boolean(formInput?.from)
                ? moment(formInput?.from, "YYYY-MM-DD")
                : null
            }
          />
        </div>
        <div className="col-md-6 px-0 my-1">
          <DateTimePickerForm
            formServerValidation={formServerValidation}
            containerStyle="d-flex flex-column flex-md-row align-items-center"
            datePickerContainer="ml-0"
            labelStyle="mr-3"
            label="to"
            name="to"
            validationName="input.to"
            onIntercept={handleDateChange}
            value={
              Boolean(formInput?.to)
                ? moment(formInput?.to, "YYYY-MM-DD")
                : null
            }
          />
        </div>
      </div>

      <RadioboxForm
        formServerValidation={formServerValidation}
        name="status"
        label="status"
        validationName="input.status"
        labelStyle="mb-2"
        options={[
          { label: "attended", value: "attended" },
          { label: "absent", value: "Absent" },
        ]}
        optionsContainerStyle="row sign_in_days"
        optionLabelStyle
        optionItemStyle="col-md-6 col-xl-4 attendance_sign_in_item"
        optionInputStyle="edit_attendance_checkbox"
        interceptChange={handleStatusChange}
        value={formInput?.status}
      />

      <div className="align-items-end d-flex flex-row justify-content-between mb-2">
        <div className="flex-1">
          <BSelect
            formServerValidation={formServerValidation}
            containerStyle="mt-3"
            name="workTimingId"
            label="applicable work timing"
            validationName="input.workTimingId"
            keepDefaultStyle
            placeholder={t("select option")}
            isDisabled={loading || submitLoading}
            isLoading={loading || submitLoading}
            options={data?.work_timings_menu || []}
            value={
              data?.work_timings_menu?.find(
                (wt) => wt?.id == formInput?.workTimingId
              ) ?? null
            }
            optionLabel="name"
            optionValue="id"
            labelStyle="mb-2"
            inputContainerStyle="b-select-style attendance_work_timing_select"
            skipLocalization
            isClearable
            icon="calendar"
            onInterceptInputOnChange={handleWtChange}
          />
        </div>
        {formInput?.workTimingId ? (
          <HasPrivileges allowBP>
            <IconButtonWithTooltip
              label={t("View Work Timing")}
              onClick={() => handleViewWorkTime(formInput?.workTimingId)}
              icon={
                <FontAwesomeIcon
                  size="md"
                  color="#5c6974"
                  icon={faEye}
                  className="ml-2"
                />
              }
            />
          </HasPrivileges>
        ) : null}
      </div>

      {allowCostCenter ? (
        <BSelect
          formServerValidation={formServerValidation}
          containerStyle="mt-3"
          name="cost_center_id"
          validationName="input.cost_center_id"
          label="cost center"
          keepDefaultStyle
          placeholder={t("select cost center")}
          options={costCentersQueryData?.cost_centers?.data ?? []}
          value={costCentersQueryData?.cost_centers?.data?.find(
            (costCenter) => costCenter?.id == formInput?.id
          )}
          onChange={handleCostCenterChange}
          labelStyle="mb-2"
          inputContainerStyle="b-select-style attendance_work_timing_select"
          icon="money"
        />
      ) : null}
    </MainModal>
  );
};

export default EditBulkAttendance;

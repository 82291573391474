import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { connect } from "react-redux";
import Privilages from "../../Constants/Privilages";

import {
  Documents,
  ExpensesChart,
  OvertimeChart,
  DepartmentChart,
  DashboardWidgets,
  EmployeesTurnover,
  OpenPayrollMonths,
  AttendanceHistoryChart,
} from "../../Components/DashboardCharts";
import { Box } from "@mui/material";
import ToggleModal from "./ToggleModal";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import ExpiredDashboardComponent from "./ExpiredDashboardComponent";
import { BarsIconButton } from "../../Components/IconButtonWithTooltip";
import ManagerDashboardAlertContinueSetupWizard from "./ManagerDashboardAlertContinueSetupWizard";

const ManagerDashboard = (props) => {
  const { t } = useTranslation();

  // use state for toggle modal
  const [isToggleModalVissible, setIsToggleModalVissible] = useState(false);

  if (
    props.profile?.company &&
    !props.profile?.company?.hasActiveSubscription
  ) {
    return <ExpiredDashboardComponent />;
  }

  if (props?.setupWizardRemainingSteps > 0 && !props.firstLoad) {
    return <ManagerDashboardAlertContinueSetupWizard />;
  }

  return (
    <div className="content dashboard_wrapper_style">
      <Box
        sx={{ borderBottom: "1px solid #313030", pb: 2, mb: 2.5 }}
        className="d-flex justify-content-between align-items-center"
      >
        <h4 style={{ fontSize: 20 }} className="page-title border-0 mb-0 pb-0">
          {t("dashboard")}
        </h4>
        <BarsIconButton onClick={() => setIsToggleModalVissible(true)} />
      </Box>

      {/* (Start) Employee Status Block */}
      <HasPrivileges
        reqireMain={[Privilages.VIEW_EMPLOYEE_DAILY_STATUSES]}
        allowBP
      >
        {props?.dashboardChartsColumns?.view_employee_daily_statuses ? (
          <DashboardWidgets />
        ) : (
          ""
        )}
      </HasPrivileges>
      {/* (End) Employee Status Block */}

      {/* (Start) Attendance Chart Block */}
      <HasPrivileges
        reqireMain={[Privilages.VIEW_EMPLOYEE_ATTENDANCE_HISTORY_CHART]}
        allowBP
        avalibleOnExpire={false}
      >
        {props?.dashboardChartsColumns
          ?.view_employee_attendance_history_chart ? (
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <AttendanceHistoryChart />
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </HasPrivileges>
      {/* (End) Attendance Chart Block */}

      <Box
        className="d-flex gap-20 flex-wrap"
        sx={{ ".item": { width: { sm: 1, md: "calc(50% - 20px)" } } }}
      >
        {/* (Start) OverTime and Departments Charts Blocks */}
        <HasPrivileges
          reqireMain={[Privilages.VIEW_EMPLOYEE_OVERTIME_CHART]}
          allowBP
          avalibleOnExpire={false}
        >
          {props?.dashboardChartsColumns?.view_employee_overtime_chart ? (
            <div className="item">
              <div className="card">
                <div className="card-body">
                  <OvertimeChart />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </HasPrivileges>

        <HasPrivileges
          reqireMain={[Privilages.VIEW_EMPLOYEES_PER_DEPARTMENT_CHART]}
          allowBP
          avalibleOnExpire={false}
        >
          {props?.dashboardChartsColumns
            ?.view_employees_per_department_chart ? (
            <div className="item">
              <div className="card">
                <div className="card-body chart_card_body_style">
                  <DepartmentChart />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </HasPrivileges>
        {/* (End) OverTime and Departments Charts Blocks */}

        {/* (Start) Expense and Turn Over Charts Blocks */}
        <HasPrivileges
          reqireMain={[Privilages.VIEW_THE_EXPENSES_CHART_OF_MY_OFFICE]}
          allowBP
          avalibleOnExpire={false}
        >
          {props?.dashboardChartsColumns
            ?.view_the_expenses_chart_of_my_office ? (
            <div className="item">
              <ExpensesChart />
            </div>
          ) : (
            ""
          )}
        </HasPrivileges>

        <HasPrivileges
          reqireMain={[Privilages.VIEW_TURNOVER_CHART]}
          allowBP
          avalibleOnExpire={false}
        >
          {props?.dashboardChartsColumns?.view_turnover_chart ? (
            <div className="item">
              <EmployeesTurnover />
            </div>
          ) : (
            ""
          )}
        </HasPrivileges>
        {/* (End) Expense and Turn Over Charts Blocks */}
      </Box>

      {/* (Start) Open payroll Months Block */}
      <HasPrivileges
        reqireMain={[Privilages.VIEW_OPEN_PAYROLL_MONTHS]}
        allowBP
        avalibleOnExpire={false}
      >
        {props?.dashboardChartsColumns?.view_open_payroll_months ? (
          <div className="row">
            <div className="col-sm-12 col">
              <OpenPayrollMonths />
            </div>
          </div>
        ) : (
          ""
        )}
      </HasPrivileges>
      {/* (End) Open payroll Months Block */}

      {/* (Start) Documents Block */}
      <HasPrivileges
        reqireMain={[Privilages.VIEW_MISSING_EXPIRED_DOCUMENT]}
        allowBP
        avalibleOnExpire={false}
      >
        {props?.dashboardChartsColumns?.VIEW_MISSING_EXPIRED_DOCUMENT ? (
          <Documents />
        ) : (
          ""
        )}
      </HasPrivileges>
      {/* (End) Documents Block */}

      {/* Start of Toggle Modal */}
      <ToggleModal
        isModalVissible={isToggleModalVissible}
        toggleModal={() => setIsToggleModalVissible(!isToggleModalVissible)}
      />
      {/* End of Toggle Modal  */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.user.userProfile,
    firstLoad: state.super.dashboardViewLoading,
    setupWizardRemainingSteps: state.wizard.setupWizardRemainingSteps,
    dashboardChartsColumns: state.dashboardUi.dashboardChartsColumns,
  };
};

export default connect(mapStateToProps)(ManagerDashboard);

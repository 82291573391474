import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  onFormResetAction,
  setDataTableSortingAction,
  updateValueAction,
} from "../../Store/Actions";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import InboundRequests from "../../Containers/InboundRequests";
import { gql, useQuery } from "@apollo/client";
import FiltersDrawer, { FilterCheckBox } from "../../Components/FiltersDrawer";
import NewMultiSelect from "../../Components/FiltersDrawer/NewMultiSelect";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { InboundRequestTypeOptions, OVERTIME } from "../../Constants/Requests";
import Constants from "../../Helpers/Constants";
import FilterBadge from "../../Components/FilterBadge";
import { userRequestsFragment } from "../../Graphql/fragments";
import { InputForm } from "form-builder";

const inboundRequestsRef = "inboundRequestsList";
const inboundRequestsOptions = gql`
  query inboundRequestsOptions {
    totalPendingRequests: users_requests_by_role(
      first: 1
      page: 1
      input: { status: [2], page: "requests_list" }
    ) {
      paginatorInfo {
        total
      }
    }
    employeesOptions: users_by_role(
      first: 9999
      input: { page_flag: "requests_list" }
    ) {
      data {
        id
        name
      }
    }
    officesOptions: company_offices(first: 9999) {
      data {
        id
        name
      }
    }
    positionsOptions: company_positions(first: 9999) {
      data {
        id
        name
      }
    }
    departmentsOptions: company_departments(first: 9999) {
      data {
        id
        name
      }
    }
  }
`;
const inboundRequests = gql`
  query inboundRequests(
    $rows: Int!
    $page: Int!
    $field: String!
    $order: SortOrder!
    $status: [Int]
    $from: String
    $payable_month: String
    $to: String
    $emp_id: [ID]
    $request_type_id: [ID]
    $office_id: [ID]
    $position_id: [ID]
    $department_id: [ID]
    $directManaged: Boolean
    $employee_search: String
  ) {
    users_requests: users_requests_by_role(
      first: $rows
      page: $page
      input: {
        directManaged: $directManaged
        orderBy: { column: $field, order: $order }
        status: $status
        dateRange: { from: $from, to: $to }
        emp_id: $emp_id
        request_type_id: $request_type_id
        office_id: $office_id
        position_id: $position_id
        department_id: $department_id
        page: "requests_list"
        payable_month: $payable_month
        employee_search: $employee_search
      }
    ) {
      data {
        ...userRequests
        __typename
        employee {
          user {
            name
            id
            manager {
              id
            }
            copied_managers {
              id
            }
            attendance_profile {
              week_start_day
            }
          }
        }
        created_at
      }
      paginatorInfo {
        lastItem
        total
        perPage
        currentPage
        hasMorePages
        firstItem
        lastPage
      }
    }
  }
  ${userRequestsFragment}
`;

const ViewRequests = (props) => {
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsFilterDrawerOpen(open);
  };
  const onFilterReset = () => {
    dispatch(onFormResetAction(inboundRequestsRef));
  };

  const onFilterSubmit = () => {
    const isDifferent =
      JSON.stringify({ ...variables, page: 1 }) !==
      JSON.stringify({ ...fetchVariables, page: 1 });
    if (isDifferent) handleRequestsFilter({ page: 1 });
    setIsFilterDrawerOpen(false);
  };

  const inboundRequestsList = useSelector(
    (state) => state.super.inboundRequestsList
  );

  const paginationInitState = {
    total: 20,
    perPage: 20,
    lastPage: 1,
    lastItem: 20,
    firstItem: 1,
    currentPage: 1,
    hasMorePages: false,
  };

  const [pagination, setPagination] = useState(paginationInitState);
  const [optionsData, setOptionsData] = useState(null);

  useEffect(() => {
    return () => {
      setPagination(paginationInitState);
    };
  }, []);

  const fetchVariables = {
    rows: pagination?.perPage,
    page: 1,
    field: inboundRequestsList.sorting.key,
    employee_search: inboundRequestsList?.filterByEmployeeSearch
      ? inboundRequestsList?.filterByEmployeeSearch
      : null,
    directManaged: Boolean(inboundRequestsList?.directManaged),
    order: inboundRequestsList.sorting.dir.toUpperCase(),
    status: !!inboundRequestsList.statusFilter
      ? inboundRequestsList.statusFilter
      : null,
    from: inboundRequestsList?.from
      ? moment(inboundRequestsList?.from, "YYYY/MM/DD").format("YYYY-MM-DD")
      : null,
    payable_month:
      inboundRequestsList?.type?.includes(OVERTIME) ||
      !inboundRequestsList.payable_month
        ? null
        : inboundRequestsList.payable_month,
    to: inboundRequestsList?.to
      ? moment(inboundRequestsList?.to, "YYYY/MM/DD").format("YYYY-MM-DD")
      : null,
    office_id: props.tableData.offices?.length ? props.tableData.offices : null,
    position_id: props.tableData.positions?.length
      ? props.tableData.positions
      : null,
    department_id: props.tableData.departments?.length
      ? props.tableData.departments
      : null,
    emp_id: inboundRequestsList?.employees?.length
      ? inboundRequestsList?.employees
      : null,
    request_type_id: !!inboundRequestsList?.type
      ? inboundRequestsList?.type
      : null,
  };

  const refetchQueries = [
    {
      query: inboundRequests,
      variables: fetchVariables,
    },
  ];

  const { data: options, loading: optionsLoading } = useQuery(
    inboundRequestsOptions,
    {
      onCompleted: (data) => {
        if (
          data?.employeesOptions?.data &&
          data?.officesOptions?.data &&
          data?.positionsOptions?.data &&
          data?.departmentsOptions?.data
        ) {
          setOptionsData({
            employeesOptions: data?.employeesOptions?.data,
            officesOptions: data?.officesOptions?.data,
            positionsOptions: data?.positionsOptions?.data,
            departmentsOptions: data?.departmentsOptions?.data,
          });
        }
      },
    }
  );
  const {
    data: inboundRequestsData,
    loading: inboundRequestsLoading,
    refetch,
    variables,
  } = useQuery(inboundRequests, {
    variables: {
      rows: 20,
      page: 1,
      field: "status",
      directManaged: false,
      order: "ASC",
      status: [2],
      from: "2024-09-01",
      payable_month: null,
      to: "2024-09-30",
      office_id: null,
      position_id: null,
      department_id: null,
      emp_id: null,
      request_type_id: [],
      employee_search: null,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setPagination(data?.users_requests?.paginatorInfo);
    },
  });

  const inboundRequestsQueryData = { ...inboundRequestsData, ...options };

  const handleRequestsFilter = (filters = {}) => {
    refetch({
      office_id: props.tableData.offices?.length
        ? props.tableData.offices
        : null,
      position_id: props.tableData.positions?.length
        ? props.tableData.positions
        : null,
      department_id: props.tableData.departments?.length
        ? props.tableData.departments
        : null,
      status: !!inboundRequestsList.statusFilter
        ? inboundRequestsList.statusFilter
        : null,
      from: inboundRequestsList?.from,
      directManaged: inboundRequestsList.directManaged,
      payable_month: !inboundRequestsList.payable_month
        ? null
        : inboundRequestsList.payable_month,
      to: inboundRequestsList?.to,
      request_type_id: !!inboundRequestsList?.type
        ? inboundRequestsList?.type
        : null,
      emp_id: inboundRequestsList?.employees?.length
        ? inboundRequestsList?.employees
        : null,
      employee_search: inboundRequestsList?.filterByEmployeeSearch
        ? inboundRequestsList?.filterByEmployeeSearch
        : null,
      ...filters,
    });
  };

  const handleRequestsSorting = (field, dir) => {
    refetch({
      page: pagination?.currentPage,
      field: field?.selector,
      order: dir?.toUpperCase(),
    });
    props.setDataTableSortingAction(inboundRequestsRef, field.selector, dir);
  };

  const handleRequestsPaginate = (page = pagination.currentPage) => {
    setPagination({ ...pagination, currentPage: page });
    refetch({ page });
  };

  const renderAppliedFilters = () => {
    const selectedEmployees = optionsData?.employeesOptions?.filter((emp) =>
      variables?.emp_id?.includes(emp.id)
    );
    const selectedDepartments = optionsData?.departmentsOptions?.filter((dep) =>
      variables?.department_id?.includes(dep.id)
    );
    const selectedPositions = optionsData?.positionsOptions?.filter((pos) =>
      variables?.position_id?.includes(pos.id)
    );
    const selectedOffices = optionsData?.officesOptions?.filter((off) =>
      variables?.office_id?.includes(off.id)
    );
    const selectedType = InboundRequestTypeOptions?.filter((t) =>
      variables?.request_type_id?.includes(t.value)
    );

    const directManaged = variables?.directManaged ? [{}] : [];

    const employeeSearch = variables?.employee_search
      ? [{ name: variables?.employee_search }]
      : [];

    const onCancelFilter = (key, name, emptyVal = null) => {
      dispatch(updateValueAction(inboundRequestsRef, name, null));
      handleRequestsFilter({
        [key]: emptyVal,
        page: 1,
      });
    };

    return (
      <div className="d-flex gap-1 flex-wrap w-75">
        <FilterBadge
          label={t("employee search")}
          selectedItems={employeeSearch}
          onCancelFilter={() =>
            onCancelFilter("employee_search", "filterByEmployeeSearch")
          }
        />
        <FilterBadge
          label={t("office")}
          selectedItems={selectedOffices}
          onCancelFilter={() => onCancelFilter("office_id", "offices")}
        />
        <FilterBadge
          label={t("Positions")}
          selectedItems={selectedPositions}
          onCancelFilter={() => onCancelFilter("position_id", "positions")}
        />
        <FilterBadge
          label={t("departments")}
          selectedItems={selectedDepartments}
          onCancelFilter={() => onCancelFilter("department_id", "departments")}
        />
        <FilterBadge
          label={t("employees")}
          selectedItems={selectedEmployees}
          onCancelFilter={() => onCancelFilter("emp_id", "employees")}
        />
        <FilterBadge
          label={t("type")}
          selectedItems={selectedType}
          onCancelFilter={() => onCancelFilter("request_type_id", "type", [])}
          optLabel="label"
        />

        <FilterBadge
          label={t("directy managed")}
          selectedItems={directManaged}
          onCancelFilter={() =>
            onCancelFilter("directManaged", "directManaged")
          }
          optLabel="label"
        />
        <FilterBadge
          label={t("from")}
          hideCancel
          selectedItems={
            Boolean(variables?.from)
              ? [
                  {
                    name: moment(variables?.from, "YYYY-MM-DD").format(
                      "DD/MM/YYYY"
                    ),
                  },
                ]
              : []
          }
          onCancelFilter={() => onCancelFilter("from", "from")}
        />

        <FilterBadge
          label={t("to")}
          hideCancel
          selectedItems={
            Boolean(variables?.to)
              ? [
                  {
                    name: moment(variables?.to, "YYYY-MM-DD").format(
                      "DD/MM/YYYY"
                    ),
                  },
                ]
              : []
          }
          onCancelFilter={() => onCancelFilter("to", "to")}
        />

        <FilterBadge
          label={t("payable month")}
          selectedItems={
            Boolean(variables?.payable_month)
              ? [
                  {
                    name: moment(variables?.payable_month, "MM").format("MMMM"),
                  },
                ]
              : []
          }
          onCancelFilter={() =>
            onCancelFilter("payable_month", "payable_month")
          }
        />
      </div>
    );
  };

  const handelClearFilters = () => {
    onFilterReset();
    handleRequestsFilter({
      position_id: null,
      office_id: null,
      department_id: null,
      emp_id: null,
      directManaged: false,
      request_type_id: null,
      employee_search: null,
      page: 1,
    });
  };

  const appliedFiltersLength =
    (variables?.emp_id?.length ?? 0) +
    (variables?.office_id?.length ?? 0) +
    (variables?.department_id?.length ?? 0) +
    (variables?.position_id?.length ?? 0) +
    (variables?.request_type_id?.length ? 1 : 0) +
    (variables?.payable_month ? 1 : 0) +
    (variables?.directManaged ? 1 : 0) +
    (variables?.employee_search ? 1 : 0);

  return (
    <>
      <div className="content requests_view_wrapper_style ml-0 pt-2">
        <InboundRequests
          dataTableRef={inboundRequestsRef}
          title=""
          onSorting={handleRequestsSorting}
          onFilter={handleRequestsFilter}
          onPaginate={handleRequestsPaginate}
          pageFlag="requests_list"
          inboundRequestsData={
            inboundRequestsQueryData?.users_requests?.data
              ? inboundRequestsQueryData?.users_requests?.data
              : []
          }
          officesFilterOptions={inboundRequestsQueryData?.offices?.data}
          employeesFilterOptions={inboundRequestsQueryData?.employees?.data}
          inboundRequestsLoading={inboundRequestsLoading}
          refetchQueries={refetchQueries}
          pagination={pagination}
          refetchRequests={refetch}
          toggleDrawer={toggleDrawer}
          renderAppliedFilters={renderAppliedFilters}
          appliedFiltersLength={appliedFiltersLength}
          handelClearFilters={handelClearFilters}
        />
      </div>

      <FiltersDrawer
        isFilterDrawerOpen={isFilterDrawerOpen}
        toggleDrawer={toggleDrawer}
        onFilterReset={onFilterReset}
        onFilterSubmit={onFilterSubmit}
        dataTableRef={inboundRequestsRef}
        isLoading={inboundRequestsLoading}
        formData={{
          ...inboundRequestsList,
          isLoading: optionsLoading,
        }}
        employeesOptions={optionsData?.employeesOptions}
        officesOptions={optionsData?.officesOptions}
        positionsOptions={optionsData?.positionsOptions}
        departmentsOptions={optionsData?.departmentsOptions}
      >
        <InputForm
          formName={inboundRequestsRef}
          labelStyle="mb-2"
          label={t("employee search")}
          name="filterByEmployeeSearch"
          icon="search"
          type="search"
          placeholder={t("employee search")}
          value={inboundRequestsList?.filterByEmployeeSearch?.trimStart()}
          inputContainerStyle="w-100"
        />

        <NewMultiSelect
          name="type"
          optionLabel="label"
          optionValue="value"
          options={InboundRequestTypeOptions.map((it) => ({
            ...it,
            label: t(it.label),
          }))}
          dataTableRef={inboundRequestsRef}
          label={t("request type")}
          icon="list"
          placeHolder={t("type")}
          value={inboundRequestsList?.type}
          isLoading={optionsLoading}
        />
        {inboundRequestsList?.type?.includes(OVERTIME) ? (
          <NewMultiSelect
            name="payable_month"
            optionLabel="value"
            optionValue="key"
            options={Constants.MonthData}
            dataTableRef={inboundRequestsRef}
            label={t("payable at")}
            icon="list"
            placeHolder={t("month")}
            value={inboundRequestsList?.payable_month}
            isSingle
            isLoading={inboundRequestsLoading && !Boolean(optionsData)}
          />
        ) : null}

        <FilterCheckBox
          label={t("Only show employees I manage directly")}
          formName={inboundRequestsRef}
          name="directManaged"
          value={inboundRequestsList?.directManaged}
        />
      </FiltersDrawer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    tableData: state?.super[inboundRequestsRef],
  };
};

export default connect(mapStateToProps, {
  setDataTableSortingAction,
})(ViewRequests);

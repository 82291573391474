import React from "react";

import L from "leaflet";
import PropTypes from "prop-types";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import markerIconRetina from "leaflet/dist/images/marker-icon-2x.png";

import {
  Popup,
  Marker,
  TileLayer,
  FeatureGroup,
  MapContainer,
  LayersControl,
} from "react-leaflet";

const customIcon = new L.Icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
  iconRetinaUrl: markerIconRetina,
  iconSize: [25, 41], // Default size for Leaflet
  iconAnchor: [12, 41], // To ensure the marker points to the right spot
  popupAnchor: [1, -34], // Where the popup should open relative to the marker
  shadowSize: [41, 41],
});

const getMidMarkers = (markers) => {
  const sum = markers.reduce(
    (acc, val) => [
      acc[0] + parseFloat(val.position[0]),
      acc[1] + parseFloat(val.position[1]),
    ],
    [0, 0]
  );

  return [sum[0] / markers.length, sum[1] / markers.length];
};

/**
 * @component MultiMarkersMap
 * @param {Object} props
 * @param {Array.<Object>} props.markers

 * @param {string} props.markers[].name
 * @param {(string|element)} props.markers[].label
 * @param {Array} props.markers[].position - [ lat, lng ]
 */

const MultiMarkersMap = ({ markers, height }) => {
  const center =
    markers.length > 1 ? getMidMarkers(markers) : markers[0].position;

  return (
    <MapContainer zoom={16} center={center} style={{ minHeight: height }}>
    <TileLayer
      maxNativeZoom={18}
      maxZoom={22}
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      // url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      // url="https://tile.osmand.net/hd/{z}/{x}/{y}.png"
      // url="https://www.google.com/maps/d/viewer?mid=1hfgYNdK6xXt9Snc1obOyJy09am-xbms&ll={x},{y}&z={z}"
    />
      <LayersControl collapsed={false} position="topright">
        {markers.map((marker) => (
          <LayersControl.Overlay checked name={marker.name} key={marker?.label}>
            <FeatureGroup>
              <Marker position={marker.position} icon={customIcon}>
                {marker?.label ? <Popup>{marker.label}</Popup> : null}
              </Marker>
            </FeatureGroup>
          </LayersControl.Overlay>
        ))}
      </LayersControl>
    </MapContainer>
  );
};

MultiMarkersMap.propTypes = {
  height: PropTypes.string,
  markers: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    position: PropTypes.shape([
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    ]).isRequired,
  }).isRequired,
};

MultiMarkersMap.defaultProps = {
  height: "500px",
};

export default MultiMarkersMap;

import React from "react";
import { CheckboxBooleanForm, RadioboxForm, BSelect, InputForm } from "form-builder";
import Constants from "../../../Constants";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

// not in reducer
const SickLeavesSection = ({ formProps }) => {
	const is_deductible = useSelector((state) => state?.super?.leavesBreaksForm?.is_deductible);

	const { t } = useTranslation();
	return (
		<>
			<CheckboxBooleanForm
				{...formProps}
				options={["Allow Employees To Request Sick Leaves"]}
				name="allow_sick_leaves"
				validationName="input.sick_leave_setting.sick_leaves"
				type="checkbox"
				containerStyle="leaves_checkbox_container mb-2"
			/>

			<CheckboxBooleanForm
				{...formProps}
				options={["Employee must submit leave request before the start of work day"]}
				name="allow_sick_leave_cutoff"
				validationName="input.sick_leave_setting.sick_leaves"
				type="checkbox"
				containerStyle="leaves_checkbox_container mb-2"
				dependOn="allow_sick_leaves"
				dependancyType="equal"
				dependancyValue={[1]}
				setWithValue
			/>

			<InputForm
				{...formProps}
				validateBy="textRequired"
				label="Employee must submit leave request before the start of work day by"
				name="sick_leave_cutoff"
				validationName="input.sick_leave_setting.sick_leave_cutoff"
				type="text"
				hasSuffix
				suffixTitle="Hours"
				containerStyle="leaves_field_container my-2"
				inputContainerStyle="d-flex align-items-center"
				dependOn="allow_sick_leave_cutoff"
				dependancyType="equal"
				dependancyValue={[true]}
			/>

			<InputForm
				{...formProps}
				validateBy="textRequired"
				label="Number Of Sick Leaves Per Year"
				name="allowed_sick_leaves"
				validationName="input.sick_leave_setting.allowed_sick_leaves"
				type="text"
				dependOn="allow_sick_leaves"
				dependancyType="equal"
				dependancyValue={[1]}
				hasSuffix
				suffixTitle="Days"
				containerStyle="leaves_field_container"
				inputContainerStyle="d-flex align-items-center"
			/>

			<CheckboxBooleanForm
				{...formProps}
				options={["Include Holidays and Weekends When Calculating Sick Leave Durations"]}
				name="include_weekends_and_holidays_in_sick"
				validationName="input.sick_leave_setting.include_weekends_and_holidays_in_sick"
				type="checkbox"
				dependOn="allow_sick_leaves"
				dependancyType="equal"
				dependancyValue={[1]}
				containerStyle="leaves_checkbox_container mt-2"
			/>

			<CheckboxBooleanForm
				{...formProps}
				options={["Apply Deduction when employee requests sick leave"]}
				name="is_deductible"
				validationName="input.sick_leave_setting.is_deductible"
				type="checkbox"
				dependOn="allow_sick_leaves"
				dependancyType="equal"
				dependancyValue={[1]}
				containerStyle="leaves_checkbox_container mt-2"
				setWithValue
			/>

			<div className="align-items-center d-flex mt-2 w-100">
				<InputForm
					{...formProps}
					validateBy="textRequired"
					label="Deduct"
					name="deduction_amount"
					validationName="input.sick_leave_setting.deduction_amount"
					type="text"
					dependOn="is_deductible"
					dependancyType="equal"
					dependancyValue={[true]}
					containerStyle="leaves_field_container gap-10 justify-content-start"
					inputContainerStyle="w-25"
					hasSuffix
					suffixTitle={"day"}
				/>
				{is_deductible ? <label>{t("for each sick leave day requested")}</label> : null}
			</div>
			<RadioboxForm
				{...formProps}
				name="deduction_type"
				validationName="input.sick_leave_setting.deduction_type"
				options={[
					{
						label: "salary",
						value: "salary",
					},
					{
						label: "annual leaves",
						value: "annual_leaves",
					},
				]}
				type="radio"
				containerStyle="my-2"
				labelStyle="mb-2"
				label="from"
				optionsContainerStyle="optionsContainerStyle"
				optionItemStyle="optionItemStyle"
				optionInputStyle=" "
				optionLabelStyle="optionLabelStyle"
				dependOn="is_deductible"
				dependancyType="equal"
				dependancyValue={[true]}
			/>
			<RadioboxForm
				{...formProps}
				name="negative_balance_action"
				validationName="input.sick_leave_setting.negative_balance_action"
				options={[
					{
						value: "salary",
						label: "Deduct the remaining amount from salary",
						optProps: {
							disabled: true,
						},
					},
					{
						value: "prevent",
						label: "Prevent the employee from requesting sick leaves",
						optProps: {
							disabled: true,
							checked: true,
						},
					},
				]}
				type="radio"
				containerStyle="my-2"
				labelStyle="mb-2"
				label="In case the employee doesn't have enough annual leave balance"
				optionsContainerStyle="optionsContainerStyle"
				optionItemStyle="optionItemStyle"
				optionInputStyle=" "
				optionLabelStyle="optionLabelStyle"
				dependOn="deduction_type"
				dependancyType="equal"
				dependancyValue={["annual_leaves"]}
			/>
		</>
	);
};

export default SickLeavesSection;

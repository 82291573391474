import React from "react";
import { useDispatch } from "react-redux";
import { useMutation } from "@apollo/client";
import {
  deleteLeavesBreaksSettingMutation,
  cloneLeavesBreaksSettingMutation,
} from "../../Graphql/mutation";
import { showLeavesBreaksModal } from "../../Store/Actions";
import { useTranslation } from "react-i18next";
import HelperFns from "../../Helpers/HelperFns";

// Privileges
import Privilages from "../../Constants/Privilages";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import { handleActionMutation } from "../../Helpers/HelperFns";

// Ui
import Swal from "sweetalert2";

// Icons
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import {
  RemoveIconButton,
  CopyIconButton,
  EditIconButton,
} from "../IconButtonWithTooltip";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const LeavesBreaksCard = ({ data, setIsModalOpen, fetchLeavesBreaks }) => {
  const { t } = useTranslation();

  const [deleteLeavesBreaksSetting] = useMutation(
    deleteLeavesBreaksSettingMutation
  );
  const [cloneLeavesBreaksSetting] = useMutation(
    cloneLeavesBreaksSettingMutation
  );

  const dispatch = useDispatch();

  const handleEdit = () => {
    dispatch(showLeavesBreaksModal(data));

    setIsModalOpen(true);
  };

  const handleClone = () => {
    handleActionMutation(cloneLeavesBreaksSetting, {
      variables: {
        id: data.id,
      },
      refetchQuery: fetchLeavesBreaks,
    });
  };

  const handleDelete = () => {
    Swal.fire({ 
      icon: "warning",
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      showConfirmButton: true,
      confirmButtonText: t("Yes"),
      confirmButtonColor: "#7cd1f9",
      showDenyButton: true,
      denyButtonText: t("No"),
      showCancelButton: false,
      customClass: {
        popup: "swal-warning-style",
        icon: "swal-icon",
        denyButton: "order-2",
        confirmButton: "order-3",
      },
    }).then((values) => {
      if (values.isConfirmed) {
        handleActionMutation(deleteLeavesBreaksSetting, {
          variables: {
            id: data.id,
          },
          refetchQuery: fetchLeavesBreaks,
        });
      }
    });
  };

  if (data?.children?.length == 0) {
    return (
      <div className="leaves_breaks_card m-0 row align-items-center mb-1 w-100">
        <h1 className="leaves_breaks_card_name col-md-2">
          <PersonOutlineIcon className="name_icon" />
          <span dir={HelperFns.isStringRTL(data?.name) ? "rtl" : "ltr"}>
            {data?.name}
          </span>
        </h1>

        {/* Leaves */}
        <div className="leaves_breaks_card_leaves col-md-3">
          <div className="row">
            <span className="col-md-3">
              {data?.allow_annual_leaves
                ? data?.allowed_annual_leaves
                : t("N/A")}
            </span>
            <span className="col-md-3">
              {data?.allowed_emergency_leaves ?? t("N/A")}
            </span>
            <span className="col-md-3">
              {data?.allow_sick_leaves ? data?.allowed_sick_leaves : t("N/A")}
            </span>
            <span className="col-md-3">
              {data?.allow_unpaid_leaves ? t("Allowed") : t("N/A")}
            </span>
          </div>
        </div>

        {/* Permissions */}
        <div className="leaves_breaks_card_permissions col-md-3">
          <div className="row">
            <span className="col-md-4">
              {data?.allow_permissions ? data?.min_permission_unit : t("N/A")}
            </span>
            <span className="col-md-4">
              {data?.allow_permissions ? data?.max_units_per_day : t("N/A")}
            </span>
            <span className="col-md-4">
              {data?.allow_permissions ? data?.max_units_per_month : t("N/A")}
            </span>
          </div>
        </div>

        {/* Breaks */}
        <div className="leaves_breaks_card_breaks col-md-3">
          <div className="row align-items-center">
            <span className="col-md-3">
              {data.allow_break
                ? data?.deduct_from_work_hours
                  ? t("Yes")
                  : t("No")
                : t("N/A")}
            </span>
            <span className="col-md-3">
              {data.allow_break
                ? data?.number_of_allowed_break_sessions_per_day
                : t("N/A")}
            </span>
            <span className="col-md-3">
              {data.allow_break
                ? data?.maximum_break_session_duration
                : t("N/A")}
            </span>
            <span className="col-md-3">
              {data.allow_break ? data?.maximum_break_duration : t("N/A")}
            </span>
          </div>
        </div>

        <div
          style={{ gap: "5px" }}
          className="leaves_breaks_card_actions gap-5 col-md-1"
        >
          <HasPrivileges
            reqireMain={[Privilages.ADD_EDIT_CLONE_LEAVE_BREAK_PROFILES]}
            allowBP
            avalibleOnExpire={false}
          >
            <EditIconButton onClick={handleEdit} />
            <CopyIconButton onClick={handleClone} />
          </HasPrivileges>

          <HasPrivileges
            reqireMain={[Privilages.DELETE_LEAVE_BREAK_PROFILES]}
            allowBP
            avalibleOnExpire={false}
          >
            <RemoveIconButton onClick={handleDelete} />
          </HasPrivileges>
          {data.children?.length ? (
            <ExpandMoreIcon
              sx={{
                fontSize: 25,
                color: "#7cd1f9",
              }}
            />
          ) : null}
        </div>
      </div>
    );
  } else {
    return (
      <Accordion
        className="m-0 p-0"
        elevation={0}
        disableGutters
        square
        slotProps={{ heading: { component: "div" } }}
        disabled={data?.children?.length == 0}
      >
        <AccordionSummary className="border-0 p-0">
          <div className="leaves_breaks_card m-0 row align-items-center mb-1 w-100">
            <h1 className="leaves_breaks_card_name col-md-2">
              <PersonOutlineIcon className="name_icon" />
              <span dir={HelperFns.isStringRTL(data?.name) ? "rtl" : "ltr"}>
                {data?.name}
              </span>
            </h1>

            {/* Leaves */}
            <div className="leaves_breaks_card_leaves col-md-3">
              <div className="row">
                <span className="col-md-3">
                  {data?.allow_annual_leaves
                    ? data?.allowed_annual_leaves
                    : t("N/A")}
                </span>
                <span className="col-md-3">
                  {data?.allowed_emergency_leaves ?? t("N/A")}
                </span>
                <span className="col-md-3">
                  {data?.allow_sick_leaves
                    ? data?.allowed_sick_leaves
                    : t("N/A")}
                </span>
                <span className="col-md-3">
                  {data?.allow_unpaid_leaves ? t("Allowed") : t("N/A")}
                </span>
              </div>
            </div>

            {/* Permissions */}
            <div className="leaves_breaks_card_permissions col-md-3">
              <div className="row">
                <span className="col-md-4">
                  {data?.allow_permissions
                    ? data?.min_permission_unit
                    : t("N/A")}
                </span>
                <span className="col-md-4">
                  {data?.allow_permissions ? data?.max_units_per_day : t("N/A")}
                </span>
                <span className="col-md-4">
                  {data?.allow_permissions
                    ? data?.max_units_per_month
                    : t("N/A")}
                </span>
              </div>
            </div>

            {/* Breaks */}
            <div className="leaves_breaks_card_breaks col-md-3">
              <div className="row align-items-center">
                <span className="col-md-3">
                  {data.allow_break
                    ? data?.deduct_from_work_hours
                      ? t("Yes")
                      : t("No")
                    : t("N/A")}
                </span>
                <span className="col-md-3">
                  {data.allow_break
                    ? data?.number_of_allowed_break_sessions_per_day
                    : t("N/A")}
                </span>
                <span className="col-md-3">
                  {data.allow_break
                    ? data?.maximum_break_session_duration
                    : t("N/A")}
                </span>
                <span className="col-md-3">
                  {data.allow_break ? data?.maximum_break_duration : t("N/A")}
                </span>
              </div>
            </div>

            <div
              style={{ gap: "5px" }}
              className="leaves_breaks_card_actions gap-5 col-md-1"
            >
              <HasPrivileges
                reqireMain={[Privilages.ADD_EDIT_CLONE_LEAVE_BREAK_PROFILES]}
                allowBP
                avalibleOnExpire={false}
              >
                <EditIconButton onClick={handleEdit} />
                <CopyIconButton onClick={handleClone} />
              </HasPrivileges>

              <HasPrivileges
                reqireMain={[Privilages.DELETE_LEAVE_BREAK_PROFILES]}
                allowBP
                avalibleOnExpire={false}
              >
                <RemoveIconButton onClick={handleDelete} />
              </HasPrivileges>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {data?.children?.map((child) => (
            <div
              key={child?.id}
              className="leaves_breaks_card row align-items-center"
            >
              <h1 className="leaves_breaks_card_name col-md-2">
                <PersonOutlineIcon className="name_icon" />
                <span dir={HelperFns.isStringRTL(child?.name) ? "rtl" : "ltr"}>
                  {child?.name}
                </span>
              </h1>

              {/* Leaves */}
              <div className="leaves_breaks_card_leaves col-md-3">
                <div className="row">
                  <span className="col-md-3">
                    {child?.allow_annual_leaves
                      ? child?.allowed_annual_leaves
                      : t("N/A")}
                  </span>
                  <span className="col-md-3">
                    {child?.allowed_emergency_leaves ?? t("N/A")}
                  </span>
                  <span className="col-md-3">
                    {child?.allow_sick_leaves
                      ? child?.allowed_sick_leaves
                      : t("N/A")}
                  </span>
                  <span className="col-md-3">
                    {child?.allow_unpaid_leaves ? t("Allowed") : t("N/A")}
                  </span>
                </div>
              </div>

              {/* Permissions */}
              <div className="leaves_breaks_card_permissions col-md-3">
                <div className="row">
                  <span className="col-md-4">
                    {child?.allow_permissions
                      ? child?.min_permission_unit
                      : t("N/A")}
                  </span>
                  <span className="col-md-4">
                    {child?.allow_permissions
                      ? child?.max_units_per_day
                      : t("N/A")}
                  </span>
                  <span className="col-md-4">
                    {child?.allow_permissions
                      ? child?.max_units_per_month
                      : t("N/A")}
                  </span>
                </div>
              </div>

              {/* Breaks */}
              <div className="leaves_breaks_card_breaks col-md-3">
                <div className="row align-items-center">
                  <span className="col-md-3">
                    {child.allow_break
                      ? child?.deduct_from_work_hours
                        ? t("Yes")
                        : t("No")
                      : t("N/A")}
                  </span>
                  <span className="col-md-3">
                    {child.allow_break
                      ? child?.number_of_allowed_break_sessions_per_day
                      : t("N/A")}
                  </span>
                  <span className="col-md-3">
                    {child.allow_break
                      ? child?.maximum_break_session_duration
                      : t("N/A")}
                  </span>
                  <span className="col-md-3">
                    {child.allow_break ? child?.maximum_break_duration : t("N/A")}
                  </span>
                </div>
              </div>

              {/* <div
                style={{ gap: "5px" }}
                className="leaves_breaks_card_actions col-md-1"
              >
                <HasPrivileges
                  reqireMain={[Privilages.ADD_EDIT_CLONE_LEAVE_BREAK_PROFILES]}
                  allowBP
                  avalibleOnExpire={false}
                >
                  <EditIconButton onClick={handleEdit} />
                  <CopyIconButton onClick={handleClone} />
                </HasPrivileges>

                <HasPrivileges
                  reqireMain={[Privilages.DELETE_LEAVE_BREAK_PROFILES]}
                  allowBP
                  avalibleOnExpire={false}
                >
                  <RemoveIconButton onClick={handleDelete} />
                </HasPrivileges>
              </div> */}
            </div>
          ))}
        </AccordionDetails>
      </Accordion>
    );
  }
};

export default LeavesBreaksCard;

import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import BalanceModal from "../../Containers/BalanceLogsList/BalanceModal";
import EditEmployeeModal from "../../Components/HrEmployeeCard/EditEmployeeModal";
import SuspensionModal from "../../Components/HrEmployeeCard/SuspensionModal";
import ActivationModal from "../../Components/HrEmployeeCard/ActivationModal";
import EmployeeProfileTabs from "../../Containers/EmployeeProfile/EmployeeProfileTabs";
import GeneralTab from "../../Containers/EmployeeProfile/GeneralTab";
import SalaryConfigTab from "../../Containers/EmployeeProfile/SalaryConfigTab";
import PettyCashTab from "../../Containers/EmployeeProfile/PettyCashTab";
import DocumentsTab from "../../Containers/EmployeeProfile/DocumentsTab";
import HistoryTab from "../../Containers/EmployeeProfile/HistoryTab";
import ManagePrivilagesModal from "../../Components/HrEmployeeCard/ManagePrivilagesModal";
import ConfirmDeleteManagerModal from "../../Components/ConfirmDeleteManagerModal";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import Loader from "../../Components/Loader";
import HelperFns, { showToast } from "../../Helpers/HelperFns";
import { useParams } from "react-router-dom";

import {
  fetchEmployeeProfileSuccess,
  resetEmployeeProfileViewAction,
} from "../../Store/Actions";
import LoansTab from "../../Containers/EmployeeProfile/LoansTab";
import EditWorkTimePreview from "../../Components/AttProfileSections/EditWorkTimePreview";
import AttendanceTypeTab from "../../Containers/EmployeeProfile/AttendanceTypeTab";
import EmployeeGraceMinutesProfileSetting from "../../Containers/EmployeeProfile/EmployeeGraceMinutesProfileSetting";
import WorkCalendarTab from "../../Containers/EmployeeProfile/WorkCalendarTab";
import ProfileCard from "../../Components/ProfileCard";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  employeeBalanceQuery,
  employeeProfileQuery,
} from "../../Graphql/query";
import { Alert } from "@mui/material";
import { TimesIconButton } from "../../Components/IconButtonWithTooltip";
import { useTranslation } from "react-i18next";
import ActivaitionSuspensionRecordsModal from "../../Components/ActivaitionSuspensionRecordsModal";
import EmployeeLeaveAndBreakProfiles from "./EmployeeLeaveAndBreakProfiles";
import EmployeeAssets from "../../Containers/Assets/EmployeeAssets/EmployeeAssets";

const CANCEL_SUSPENSION = gql`
  mutation cancelSuspension($userId: ID) {
    cancel_suspension(user_id: $userId) {
      status
      message
    }
  }
`;

const ViewEmployeeProfile = (props) => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);

  const hasActiveSubscription = useSelector(
    (state) => state?.auth?.userProfile?.company?.hasActiveSubscription
  );

  const employeeBalanceList = useSelector(
    (state) => state.super.employeeBalanceList
  );

	// Redux state
	const activiationSuspensionModalIsOpen = useSelector(
		(state) => state?.super?.activiationSuspensionModal?.isOpen
	);

  const refetchQueries = [
    {
      query: employeeProfileQuery,
      variables: {
        id: userId,
        isExpired: !hasActiveSubscription,
      },
    },
    ...(HelperFns.checkPrivileges({
      privileges: [Privilages.VIEW_EMPLOYEE_BALANCE_ADJUSTMENT],
      allowBP: true,
    })
      ? [
          {
            query: employeeBalanceQuery,
            variables: {
              id: userId,
              rows: 10,
              page: 1,
              field: employeeBalanceList?.sorting.key,
              order: employeeBalanceList?.sorting.dir.toUpperCase(),
              type: employeeBalanceList?.type,
              year: employeeBalanceList?.yearFilter,
            },
          },
        ]
      : []),
  ];

  const shouldRenderComponent = (reqirePrivileges) => {
    if (
      (HelperFns.checkPrivileges({
        allowBP: false,
        scope: "managed",
        privileges: reqirePrivileges,
      }) &&
        HelperFns.isManagedByAuth(
          props.profile?.manager?.id,
          props.profile?.copied_managers?.map((user) => user?.id)
        )) ||
      HelperFns.checkPrivileges({
        allowBP: true,
        scope: "all",
        privileges: reqirePrivileges,
      })
    ) {
      return true;
    }
    return false;
  };

  const handleFirstRender = () => {
    if (
      shouldRenderComponent([
        Privilages.VIEW_EMPLOYEE_REQUESTS,
        Privilages.VIEW_EMPLOYEE_BALANCE_ADJUSTMENT,
        Privilages.VIEW_EMPLOYEE_CLAIMS,
        Privilages.VIEW_ATTENDANCE_HISTORY_LOGS,
        Privilages.MANAGE_EMPLOYEE_ATTENDANCE,
      ])
    ) {
      return setSelected(1);
    }
    if (
      shouldRenderComponent([Privilages.VIEW_EMPLOYEE_SALARY_CONFIGURATION])
    ) {
      return setSelected(2);
    }

    if (shouldRenderComponent([Privilages.VIEW_EMPLOYEE_DOCUMENTS])) {
      return setSelected(3);
    }

    if (
      shouldRenderComponent([
        Privilages.VIEW_EMPLOYEE_PETTY_CASH_BALANCE_IN_EMPLOYEE_PROFILE,
      ]) &&
      props.profile?.userPettyCash?.length > 0
    ) {
      return setSelected(4);
    }

    if (
      shouldRenderComponent([Privilages.VIEW_LOANS_LIST]) &&
      props?.profile?.loans?.length > 0
    ) {
      return setSelected(5);
    }

    if (
      shouldRenderComponent([
        Privilages.VIEW_EMPLOYEE_ATTENDANCE_TYPE_CONFIGURATIONS,
      ])
    ) {
      return setSelected(6);
    }
    if (shouldRenderComponent([Privilages.VIEW_EMPLOYEE_GRACE_MINUTES])) {
      return setSelected(9);
    }

    // this should be for work schedule tab with correct privilieges
    if (shouldRenderComponent([Privilages.VIEW_EMPLOYEE_SCHEDULES])) {
      return setSelected(7);
    }

    if (shouldRenderComponent([Privilages.VIEW_EMPLOYEE_LEAVE_BREAK_PROFILE])) {
      return setSelected(11);
    }
  };

  useEffect(() => {
    if (!selected && props.profile?.id) {
      handleFirstRender();
    }
  }, [selected, props?.profile?.id]);

  const { loading: employeeProfileLoading } = useQuery(employeeProfileQuery, {
    variables: {
      id: userId,
      isExpired: !hasActiveSubscription,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      console.log("hook", data);
      dispatch(fetchEmployeeProfileSuccess(data?.user));
    },
  });

  useEffect(() => {
    return () => {
      props.resetEmployeeProfileViewAction();
      setSelected(null);
    };
  }, []);

  const handleChange = (event, newValue) => {
    setSelected(newValue);
  };

  const renderSelectedTab = () => {
    switch (selected) {
      case 1:
        if (
          shouldRenderComponent([
            Privilages.VIEW_EMPLOYEE_REQUESTS,
            Privilages.VIEW_EMPLOYEE_BALANCE_ADJUSTMENT,
            Privilages.VIEW_EMPLOYEE_CLAIMS,
          ])
        ) {
          return <GeneralTab shouldRenderComponent={shouldRenderComponent} />;
        }
        break;
      case 2:
        if (
          shouldRenderComponent([Privilages.VIEW_EMPLOYEE_SALARY_CONFIGURATION])
        ) {
          return <SalaryConfigTab />;
        }
        break;
      case 3:
        if (shouldRenderComponent([Privilages.VIEW_EMPLOYEE_DOCUMENTS])) {
          return <DocumentsTab />;
        }
        break;
      case 4:
        if (
          shouldRenderComponent([
            Privilages.VIEW_EMPLOYEE_PETTY_CASH_BALANCE_IN_EMPLOYEE_PROFILE,
          ])
        ) {
          return <PettyCashTab />;
        }
        break;

      case 5:
        if (shouldRenderComponent([Privilages.VIEW_LOANS_LIST])) {
          return <LoansTab />;
        }
        break;

      case 6:
        if (
          shouldRenderComponent([
            Privilages.VIEW_EMPLOYEE_ATTENDANCE_TYPE_CONFIGURATIONS,
          ])
        ) {
          return <AttendanceTypeTab />;
        }

        break;

      case 9:
        if (shouldRenderComponent([Privilages.VIEW_EMPLOYEE_GRACE_MINUTES])) {
          return <EmployeeGraceMinutesProfileSetting />;
        }

        break;
      case 7:
        if (shouldRenderComponent([Privilages.VIEW_EMPLOYEE_SCHEDULES])) {
          return (
            <WorkCalendarTab
              userId={userId}
              pageFlag="employee_profile_work_calendar"
            />
          );
        }
        break;

      case 8:
        if (
          shouldRenderComponent([
            Privilages.VIEW_ATTENDANCE_HISTORY_LOGS,
            Privilages.MANAGE_EMPLOYEE_ATTENDANCE,
          ])
        ) {
          return <HistoryTab />;
        }
        break;

      case 11:
        if (
          shouldRenderComponent([Privilages.VIEW_EMPLOYEE_LEAVE_BREAK_PROFILE])
        ) {
          return <EmployeeLeaveAndBreakProfiles />;
        }
        break;

      case 10:
        return (
          <HasPrivileges
            allowBP
            reqireMain={[
              Privilages.VIEW_ASSIGNED_ASSETS,
              Privilages.VIEW_ASSET_REQUEST,
            ]}
          >
            <EmployeeAssets />
          </HasPrivileges>
        );

      default:
        break;
    }
  };

  const { t } = useTranslation();
  const [cancelSuspension, { loading: cancelSuspensionLoading }] = useMutation(
    CANCEL_SUSPENSION,
    {
      variables: { userId },
      isShowErrorModal: true,
      onCompleted: ({ cancel_suspension = {} }) => {
        cancel_suspension.toLowerCase()?.status === "success"
          ? window.location.reload()
          : showToast("error", cancel_suspension?.message);
      },
      onError: (err) => {
        showToast(
          "error",
          err.graphQLErrors?.[0]?.extensions?.reason || err?.message
        );
      },
    }
  );
  const handleCancelSuspension = () => {
    HelperFns.checkPassword(
      "cancel_suspension_confirm_msg",
      "",
      "Confirm",
      "",
      cancelSuspension
    );
  };

  return (
    <div className="employee_profile_content_wrapper content">
      {props.sendPhoneVerificationLoading ||
      props.sendEmailVerificationLoading ||
      props?.isResendCredentailsLoading ||
      props?.isInitDeleteManagerLoading ||
      props?.isViewWorkTimeLoading ||
      props?.isInitSuspendManagerLoading ||
      cancelSuspensionLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}
      {props?.profile?.employee?.dateOfSuspensionInFuture ? (
        <HasPrivileges
          allowBP
          reqireMain={[Privilages.ACTIVATE_SUSPEND_EMPLOYEES]}
        >
          <Alert
            icon={false}
            severity="warning"
            sx={{
              mb: 1,
              mt: -3,
              width: 300,
              color: "#ffb700",
              bgcolor: "#fff4d7",
              marginInlineStart: "auto",
            }}
            action={
              <TimesIconButton
                label="Cancel Suspension"
                onClick={handleCancelSuspension}
              />
            }
          >
            {t("Suspended Starting From", {
              date: props?.profile?.employee?.dateOfSuspensionInFuture,
            })}
          </Alert>
        </HasPrivileges>
      ) : null}

      <ProfileCard
        shouldRenderComponent={shouldRenderComponent}
        {...props.profile}
        isEmployee={false}
        isLoading={employeeProfileLoading}
      />

      {!!props.profile?.id && (
        <>
          <EmployeeProfileTabs
            activeValue={selected}
            handleChangeTab={handleChange}
            {...props.profile}
            shouldRenderTab={shouldRenderComponent}
          />
          {/* </HasPrivileges> */}
          <div className="pt-4">
            {!!props.profile?.id ? renderSelectedTab() : <></>}
          </div>
        </>
      )}

      {/* (Start) Balance Modal */}
      <BalanceModal refetchQueries={refetchQueries} />
      {/* (End) Balance Modal */}

      {/* (Start) Edit Modal */}
      <EditEmployeeModal />
      {/* (End) Edit Modal */}

      {/* (Start) Suspension Modal */}
      <SuspensionModal />
      {/* (End) Suspension Modal */}

			{/* Start of activation and suspension records modal */}
			{activiationSuspensionModalIsOpen ? (
				<ActivaitionSuspensionRecordsModal />
			) : null}
			{/* End of activation and suspension records modal */}

			{/* (Start) Manage privilages Modal */}
			<ManagePrivilagesModal />
			{/* (End) Manage privilages Modal */}
      {/* (Start) Activation Modal */}
      {props.activationModalActions?.isVissible ? <ActivationModal /> : null}
      {/* (End) Activation Modal */}

      {/* (Start) Manage privilages Modal */}
      <ManagePrivilagesModal />
      {/* (End) Manage privilages Modal */}

      {/* (Start) Edit Att History Modal */}

      <EditWorkTimePreview />
      {/* (End) Edit Att History Modal */}

      {/* (Start) Confirm Delete Manager Modal */}
      <ConfirmDeleteManagerModal />
      {/* (End) Confirm Delete Manager Modal */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.super.selectedEmployeeProfile,
    activationModalActions: state.super.activationModalActions,
    sendPhoneVerificationLoading: state.user.sendPhoneVerificationLoading,
    sendEmailVerificationLoading: state.user.sendEmailVerificationLoading,
    isResendCredentailsLoading: state?.super?.resendCredentailsLoading,
    isInitDeleteManagerLoading: state?.super?.isInitDeleteManagerLoading,
    isInitSuspendManagerLoading: state?.super?.isInitSuspendManagerLoading,
    isViewWorkTimeLoading: state?.super?.isViewWorkTimeLoading,
  };
};

export default connect(mapStateToProps, {
  resetEmployeeProfileViewAction,
})(ViewEmployeeProfile);

import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import PropTypes from "prop-types";
import HelperFns from "../../Helpers/HelperFns";
import { getVerticalNavbarStyles, getHorizontalNavbarStyles } from "./styles";

import Tab from "@mui/material/Tab";
import { Link } from "react-router-dom";
import MuiTabs from "@mui/material/Tabs";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
const checkPrivileges = ({ privileges, allowBP }) => {
  return (
    !privileges.length || HelperFns.checkPrivileges({ privileges, allowBP })
  );
};

/**
 *
 * Start of TabsList
 *
 */

const tabsPropTypes = {
  isRouteSidebar: PropTypes.bool,
  path: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string, // fallback => value prop
      icon: PropTypes.element,
      allowBP: PropTypes.bool,
      value: PropTypes.string.isRequired,
      privileges: PropTypes.array.isRequired,
    })
  ).isRequired,
};

/**
 * @type {React.FC<PropTypes.InferProps<tabsPropTypes>>}
 * @returns {React.ReactElement}
 */
const TabsList = ({ path, tabs, isRouteSidebar }) => {
  const { t } = useTranslation();
  const { classes } = isRouteSidebar
    ? getVerticalNavbarStyles()
    : getHorizontalNavbarStyles();

  // Constants
  const activeValue = useQuery().get("tab");
  const value = React.useMemo(() => {
    return window.location.pathname === path && activeValue
      ? activeValue
      : false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, activeValue]);

  return (
    <MuiTabs
      value={value}
      variant="scrollable"
      scrollButtons="auto"
      orientation={isRouteSidebar ? "vertical" : "horizontal"}
      classes={{
        root: classes.root,
        indicator: classes.indicator,
        flexContainer: classes.flexContainer,
        scrollButtons: classes.scrollButtons,
      }}
    >
      {tabs.map((tab) =>
        checkPrivileges({
          allowBP: tab?.allowBP,
          privileges: tab.privileges,
        }) ? (
          <Tab
            key={tab.value}
            value={tab.value}
            component={Link}
            icon={tab.icon}
            iconPosition="start"
            to={`${path}?tab=${tab.value}`}
            label={t(tab?.label || tab.value)}
            classes={{
              root: classes.tabRoot,
              selected: classes.selected,
            }}
          />
        ) : (
          []
        )
      )}
    </MuiTabs>
  );
};

TabsList.propTypes = tabsPropTypes;

/**
 * End of TabsList
 *
 * Start of TabPanel
 */

const panelPropTypes = {
  path: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      allowBP: PropTypes.bool,
      value: PropTypes.string.isRequired,
      panel: PropTypes.element.isRequired,
      privileges: PropTypes.array.isRequired,
    })
  ).isRequired,
};

/**
 * @type {React.FC<PropTypes.InferProps<panelPropTypes>>}
 * @returns {React.ReactElement}
 */
const TabActivePanel = ({ tabs, path }) => {
  const history = useHistory();

  // Constants
  const activeValue = useQuery().get("tab");
  const activeTab = React.useMemo(
    () => tabs.find((t) => t.value === activeValue),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeValue]
  );

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    if (!activeValue) getFirstTab();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeValue]);

  /* ↓ Helpers ↓ */

  const getFirstTab = React.useCallback(() => {
    for (let i = 0; i < tabs.length; i++) {
      const tab = tabs[i];
      if (
        checkPrivileges({
          privileges: tab.privileges,
          allowBP: tab?.allowBP,
        })
      ) {
        history.push(`${path}?tab=${tab.value}`);
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return activeTab
    ? checkPrivileges({
        privileges: activeTab.privileges,
        allowBP: activeTab?.allowBP,
      })
      ? activeTab?.panel
      : null
    : null;
};

TabActivePanel.propTypes = panelPropTypes;

/**
 *
 * End of TabPanel
 *
 */

const NavigationTabs = {
  TabsList: TabsList,
  ActivePanel: TabActivePanel,
};

export default NavigationTabs;

import * as Types from "./types";

export const toggleAssetTypeModal = ({ isOpen = false }) => {
  return {
    type: Types.TOGGLE_ASSET_TYPE_MODAL,
    payload: { isOpen },
  };
};

export const toggleWarehouseModal = ({ isOpen = false }) => {
  return {
    type: Types.TOGGLE_WAREHOUSE_MODAL,
    payload: { isOpen },
  };
};

// Start of company assets actions
export const toggleCompanyAssetModal = ({ isOpen = false }) => {
  return {
    type: Types.TOGGLE_COMPANY_ASSET_MODAL,
    payload: { isOpen },
  };
};

export const addItemsInputAction = (formName) => {
  return {
    type: Types.ADD_ASSET_ITEMS_INPUT,
    payload: {
      formName,
    },
  };
};

export const removeItemsInputAction = (formName, index) => {
  return {
    type: Types.REMOVE_ASSET_ITEMS_INPUT,
    payload: {
      index,
      formName,
    },
  };
};

export const assetChildInputChange = (
  formName,
  childName,
  name,
  index,
  value
) => {
  return {
    type: Types.ON_ASSET_CHILD_INPUT_CHANGE,
    payload: {
      formName,
      childName,
      name,
      index,
      value,
    },
  };
};

export const addAssetInputsAction = (formName) => {
  return {
    type: Types.ADD_ASSET_INPUTS,
    payload: {
      formName,
    },
  };
};

export const toggleAssetUnitsModal = ({ isOpen = false, assetUnits = {} }) => {
  return {
    type: Types.TOGGLE_ASSET_UNITS_MODAL,
    payload: { isOpen, assetUnits },
  };
};

export const toggleAddNewUnitsModal = ({ isOpen = false }) => {
  return {
    type: Types.TOGGLE_ADD_NEW_UNITS_MODAL,
    payload: { isOpen },
  };
};

export const toggleAssignRetriveAssetModal = ({ isOpen = false }) => {
  return {
    type: Types.TOGGLE_ASSIGN_RETRIEVE_ASSET_MODAL,
    payload: { isOpen },
  };
};

export const resetAssignRetriveAssetModalValues = () => {
  return {
    type: Types.RESET_ASSIGN_RETRIEVE_ASSET_MODAL_VALUES,
  };
};
// End of company assets actions

export const toggleScrappItemModal = ({ isOpen = false, item = {} }) => {
  return {
    type: Types.TOGGLE_SCRAPP_ITEM_MODAL,
    payload: { isOpen, item },
  };
};

export const toggleUnscrappItemModal = ({ isOpen = false, item = {} }) => {
  return {
    type: Types.TOGGLE_UNSCRAPP_ITEM_MODAL,
    payload: { isOpen, item },
  };
};

export const toggleWarehouseDetailsModal = ({
  isOpen = false,
  warehouseId = null,
}) => {
  return {
    type: Types.TOGGLE_WAREHOUSE_DETAILS_MODAL,
    payload: { isOpen, warehouseId },
  };
};

export const toggleTransferAssetsModal = ({
  isOpen = false,
  asset = null,
  warehouseId = null,
}) => {
  return {
    type: Types.TOGGLE_TRANSFER_ASSETS_MODAL,
    payload: { isOpen, asset, warehouseId },
  };
};

export const toggleEmployeeAssetRequestModal = ({ isOpen = false }) => {
  return {
    type: Types.TOGGLE_EMPLOYEE_ASSET_REQUEST_MODAL,
    payload: { isOpen },
  };
};

export const toggleAcceptAssetRequestModal = ({
  isOpen = false,
  request = null,
}) => {
  return {
    type: Types.TOGGLE_ACCEPT_ASSET_REQUEST_MODAL,
    payload: { isOpen, request },
  };
};

export const toggleRetriveAssetModal = ({ isOpen = false, itemId = null }) => {
  return {
    type: Types.TOGGLE_RETRIVE_ASSET_MODAL,
    payload: { isOpen, itemId },
  };
};

export const toggleExchangeItemModal = ({ isOpen = false, itemId = null }) => {
  return {
    type: Types.TOGGLE_EXCHANGE_ITEM_MODAL,
    payload: { isOpen, itemId },
  };
};

export const toggleScrapNonSerializedAssets = ({
  isOpen = false,
  items = [],
}) => {
  return {
    type: Types.TOGGLE_SCRAP_NON_SERIALIZED_ASSETS,
    payload: { isOpen, items },
  };
};

/*
  Attendance History
*/

/*
  Penalties
*/

/*
  Assignments
*/

export const assignmentPrivileges = {
  CHANGE_SHIFT: "290",
  VIEW_EMPLOYEE_ASSIGNMENTS: "281",
  ADD_EDIT_DELETE_EMPLOYEE_ASSIGNMENTS: "208",
  VIEW_ASSIGNMENTS_IN_MY_DEPARTMENT: "282",
  ADD_EDIT_DELETE_ASSIGNMENTS_IN_MY_DEPARTMENT: "283",
  VIEW_ASSIGNMENTS_IN_OTHER_DEPARTMENTS: "284",
  ADD_EDIT_DELETE_ASSIGNMENTS_IN_OTHER_DEPARTMENTS: "285",
  VIEW_ASSIGNMENTS_IN_MY_OFFICE: "286",
  ADD_EDIT_DELETE_ASSIGNMENTS_IN_MY_OFFICE: "287",
  VIEW_ASSIGNMENTS_IN_OTHER_OFFICES: "288",
  ADD_EDIT_DELETE_ASSIGNMENTS_IN_OTHER_OFFICES: "289",

  VIEW_ASSIGNMENTS: "206",
  VIEW_ASSIGNMENTS_DEFAULT_PRIVILEGE: "207",
};

export const assignmentTabPrivileges = [
  assignmentPrivileges["VIEW_EMPLOYEE_ASSIGNMENTS"],
  assignmentPrivileges["VIEW_ASSIGNMENTS_IN_MY_OFFICE"],
  assignmentPrivileges["VIEW_ASSIGNMENTS_IN_OTHER_OFFICES"],
  assignmentPrivileges["VIEW_ASSIGNMENTS_IN_MY_DEPARTMENT"],
  assignmentPrivileges["VIEW_ASSIGNMENTS_IN_OTHER_DEPARTMENTS"],
];

export const addEditDeleteAssignmentPrivileges = [
  assignmentPrivileges["ADD_EDIT_DELETE_EMPLOYEE_ASSIGNMENTS"],
  assignmentPrivileges["ADD_EDIT_DELETE_ASSIGNMENTS_IN_MY_OFFICE"],
  assignmentPrivileges["ADD_EDIT_DELETE_ASSIGNMENTS_IN_OTHER_OFFICES"],
  assignmentPrivileges["ADD_EDIT_DELETE_ASSIGNMENTS_IN_MY_DEPARTMENT"],
  assignmentPrivileges["ADD_EDIT_DELETE_ASSIGNMENTS_IN_OTHER_DEPARTMENTS"],
];

/*
  Monthly Report
*/

/*
  Yearly Report
*/

/*
  Facial Report
*/

import React from "react";
import { useTranslation } from "react-i18next";
import HelperFns from "../../Helpers/HelperFns";
import Privilages from "../../Constants/Privilages";
import { useLocation, useHistory } from "react-router-dom";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";


const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ExpensesTabs = ({setActiveValue, activeValue, ...props}) => {
  
    
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    setActiveValue(newValue);
  };

  const possibleValues = [{
    value:"company-expenses", 
    label:"Company Expenses",
    privileges:Privilages.VIEW_THE_EXPENSES_OF_MY_OFFICE
  }, 
  {
    value:"petty-cash", 
    label:"Petty Cash",
    privileges:Privilages.VIEW_PETTY_CASH_BALANCE_ADJUSTMENT_LIST
  }];



  return (
    <>
    
    <TabContext value={activeValue}>
        {/* Tabs */}
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange}>
            {possibleValues.map((v) => { 
              return HelperFns.checkPrivileges({
                allowBP: true,
                privileges: [v.privileges],
              }) ? 
                <Tab key={v.value} label={t(v.label)} value={v.value} /> 
                : null 
              })
            }
          </TabList>
        </Box>
      </TabContext>
    </>
  );
};

export default ExpensesTabs;

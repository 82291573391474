import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import moment from "moment";
import { showToast } from "../../Helpers/HelperFns";
import { dateTimeListFormat } from "../../Constants";
import { storeCommentMutation } from "../../Graphql/mutation";

import Loader from "../Loader";
import MainModal from "../MainModal";
import { InputForm } from "form-builder";
import SendIcon from "@mui/icons-material/Send";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import IconButtonWithTooltip from "../IconButtonWithTooltip";

const CommentModal = ({ data, canCommentPrivileges, onClose, refetchList }) => {
  const { t } = useTranslation();

  // Local State
  const [comment, setComment] = useState("");

  // Server State
  const [saveComment, { loading }] = useMutation(storeCommentMutation);

  /* ↓ Helpers ↓ */

  const handleSaveComment = () => {
    saveComment({
      variables: {
        comment,
        id: data.id,
        type: data.type.toUpperCase(),
      },
      onCompleted: () => {
        refetchList();
        onClose();
        showToast("success");
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message
        );
      },
    });
  };

  return (
    <MainModal
      isOpen
      toggle={onClose}
      modalTitle={t("comments")}
      hasModalFooter={false}
    >
      {loading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      {data.comments?.length ? (
        data.comments.map(({ comment, user, created_at }, i) => (
          <div key={i}>
            <div className="d-flex justify-content-between">
              <strong>{user?.name}</strong>
              <time dateTime={created_at}>
                {moment(created_at).format(dateTimeListFormat)}
              </time>
            </div>
            <p>{comment}</p>
          </div>
        ))
      ) : (
        <p>no comments added yet</p>
      )}

      <HasPrivileges reqireMain={canCommentPrivileges}>
        <div className="d-flex align-items-center gap-20">
          <InputForm
            value={comment}
            rootStyle="flex-fill"
            inputContainerStyle="w-100"
            onChange={(e) => setComment(e.target.value)}
          />
          <IconButtonWithTooltip
            label="add comment"
            onClick={handleSaveComment}
            icon={<SendIcon sx={{ color: "#23aaeb" }} />}
          />
        </div>
      </HasPrivileges>
    </MainModal>
  );
};

export default CommentModal;

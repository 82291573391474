import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";

import {
  storeTrialMutation,
  changeTrialMutation,
} from "../../Graphql/mutation";
import HelperFns, {
  showToast,
  formatSendingInputs,
} from "../../Helpers/HelperFns";
import moment from "moment";
import Constants from "../../Constants";
import { trialFormQuery } from "../../Graphql/query";
import { serializePayments } from "../../Helpers/HelperFns/PaymentTracking";
import { clearCRMValidations, setCRMValidations } from "../../Store/Actions";

import {
  BSelect,
  InputForm,
  RadioboxForm,
  DateTimePickerForm,
  CheckboxBooleanForm,
} from "form-builder";
import Loader from "../Loader";
import { Box } from "@mui/material";
import MainModal from "../MainModal";
import { AddButton } from "../Buttons";
import { LeadsServerRestSelect } from "../CRM";
import IntlTelInput from "react-intl-tel-input";
import { PaymentInfo } from "../PaymentTracking";
import { RemoveIconButton } from "../IconButtonWithTooltip";

const formNameValidation = "trialClientValidation";
const formServerValidation = "trialServerValidation";
const businessPartnerInitState = {
  name: "",
  phone: "",
  email: "",
  phone_country_code: "",
  country_short_name: "",
};

const TrialModal = ({
  data,
  onClose,
  isLeadProfile,
  leadProfileData,
  refetchList,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isEdit = Boolean(data);

  // Local State
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [options, setOptions] = useState({
    leads: [],
    plans: [],
    managers: [],
  });
  const [formData, setFormData] = useState({
    name: "",
    from: "",
    limit: "",
    plan: null,
    period: "W1",
    payment_method: null,
    primarySAAS: null,
    secondarySAAS: [],
    lead: leadProfileData || null,
    business_partners: [businessPartnerInitState],
    week_day: null,
    year_start_month: null,
    month_start_day: null,
    allow_am_access: 0,
  });

  // Reducer State
  const paymentDetails = useSelector((state) => state.crm.trialPayments);
  const trialClientValidation = useSelector(
    (state) => state.crm[formNameValidation]
  );
  const trialServerValidation = useSelector(
    (state) => state.crm[formServerValidation]
  );

  // Server State
  const [storeTrial, { loading: isStoreLoading }] =
    useMutation(storeTrialMutation);
  const [changeTrial, { loading: isChangeLoading }] =
    useMutation(changeTrialMutation);
  const { loading: isDataLoading } = useQuery(trialFormQuery, {
    variables: { id: data?.id, isTrialSkip: !Boolean(data) },
    onCompleted: ({ trial = {}, managers, plans }) => {
      // Data
      if (Boolean(data)) {
        const { company, limit, plan, start_date } = trial?.subscription;
        setFormData({
          plan,
          limit,
          id: trial?.id,
          lead: trial?.lead,
          name: company?.name,
          allow_am_access: +Boolean(company?.allow_am_access),
          period: trial?.period,
          from: moment(start_date),
          primarySAAS: company?.primarySAAS?.user,
          secondarySAAS: company?.secondarySAAS?.length
            ? company?.secondarySAAS?.map((sass) => sass?.user)
            : [],
          business_partners: company?.businessPartners?.length
            ? company?.businessPartners.map((b) => b?.user)
            : [businessPartnerInitState],
        });
      }

      // Options
      setOptions((prev) => ({
        ...prev,
        plans: plans?.data || [],
        managers: managers?.data || [],
      }));
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors[0]?.extensions?.reason ||
          err?.graphQLErrors[0]?.message ||
          err?.message
      );
    },
  });

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    return () => {
      dispatch(clearCRMValidations(formServerValidation));
    };
  }, []);

  /* ↓ Helpers ↓ */

  const handleAddBusinessPartner = () => {
    setFormData((prev) => ({
      ...prev,
      business_partners: [...prev.business_partners, businessPartnerInitState],
    }));
  };

  const handleRemoveBusinessPartner = (index) => {
    setFormData((prev) => ({
      ...prev,
      business_partners: prev.business_partners.filter((_, i) => i !== index),
    }));
  };

  const handlePhoneChange = (input, partnerIdx) => {
    if (input?.isPhone && isNaN(input?.num)) return;
    setFormData((prev) => ({
      ...prev,
      business_partners: prev.business_partners.map((b, i) =>
        i === partnerIdx
          ? {
              ...b,
              phone: input?.num,
              phone_country_code: input?.dialCode,
              country_short_name: input?.iso2,
            }
          : b
      ),
    }));
  };

  const handleChangeBusinessPartner = (event, index) => {
    setFormData((prev) => ({
      ...prev,
      business_partners: prev.business_partners.map((partner, i) =>
        index === i
          ? { ...partner, [event.target.name]: event.target.value }
          : partner
      ),
    }));
  };

  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSelectChange = (val, { name: key }) => {
    setFormData((prev) => ({ ...prev, [key]: val }));
  };
  const handleCheckboxChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: +e?.target?.checked }));
  };

  const handleDateChange = (name, val) => {
    setFormData((prev) => ({
      ...prev,
      [name]: val,
    }));
  };

  const handleUpsert = () => {
    setFormSubmitting(true);
    if (trialClientValidation.length) return;

    const upsert = isEdit ? changeTrial : storeTrial;
    const {
      name,
      lead,
      plan,
      sass,
      payment_method,
      limit,
      contact,
      primarySAAS,
      secondarySAAS,
      business_partners,
      week_day,
      year_start_month,
      month_start_day,
      allow_am_access,
      from,
      period,
      ...rest
    } = formData;
    const businessPartners = business_partners.map(
      ({
        name,
        email,
        phone,
        phone_country_code,
        country_short_name,
        ...rest
      }) => ({
        ...rest,
        name,
        email,
        phone: phone
          ? [phone_country_code, phone, country_short_name]
          : ["", "", ""],
      })
    );
    upsert({
      variables: {
        name,
        business_partners: businessPartners,
        lead_id: lead?.id,
        limit: limit ? +limit : undefined,
        contact_id: contact?.id || undefined,
        allow_am_access: +Boolean(allow_am_access),
        ...formatSendingInputs({
          ...rest,
          plan_id: plan,
          primaryId: primarySAAS?.id,
          ...(!isEdit && { from, period }),
        }),
        secondaryIds: secondarySAAS
          ? secondarySAAS?.map((sass) => sass?.id)
          : [],
        ...(!isEdit && {
          week_day: week_day?.value,
          month_start_day: +month_start_day?.id,
          year_start_month: +year_start_month?.key,
          payment_method: serializePayments(paymentDetails),
        }),
      },
      onCompleted: () => {
        refetchList();
        onClose();
        showToast("success");
      },
      onError: (err) => {
        err?.graphQLErrors?.[0]?.extensions?.validation &&
          dispatch(
            setCRMValidations({
              ref: formServerValidation,
              validations: HelperFns.parseValidatorErrors(
                err?.graphQLErrors?.[0]?.extensions?.validation
              ),
            })
          );

        (err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message) &&
          showToast(
            "error",
            err?.graphQLErrors?.[0]?.extensions?.reason ||
              err?.graphQLErrors?.[0]?.message ||
              err?.message
          );
      },
    });
  };

  return (
    <MainModal
      isOpen
      toggle={onClose}
      btnOnClick={handleUpsert}
      modalTitle={t(
        `${isEdit ? "edit" : "new"} ${
          props?.isCompanyList ? "company" : "trial"
        }`
      )}
      btnSubmitLoading={isStoreLoading || isChangeLoading}
    >
      {data && isDataLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <div className="d-flex flex-column gap-20">
        {isLeadProfile ? (
          <>
            <strong>{t("lead")}</strong>
            <span>{formData.lead.name}</span>
          </>
        ) : (
          <LeadsServerRestSelect
            type="Trials"
            name="lead"
            label="lead"
            icon="employee"
            keepDefaultStyle
            value={formData.lead}
            onChange={handleSelectChange}
            validateBy="textRequired"
            formSubmitting={formSubmitting}
            formNameValidation={formNameValidation}
          />
        )}
        <InputForm
          name="name"
          label="company name"
          value={formData.name}
          onChange={handleChange}
          validateBy="textRequired"
          inputContainerStyle="w-100"
          formServerValidation={formServerValidation}
          formNameValidation={formNameValidation}
          formSubmitting={formSubmitting}
          validationName="company name"
          reducer="crm"
        />
        <BSelect
          name="primarySAAS"
          icon="employees"
          keepDefaultStyle
          label="primary account manager"
          value={formData?.primarySAAS}
          onChange={handleSelectChange}
          options={options?.managers?.filter(
            (manager) =>
              !formData?.secondarySAAS
                ?.map((saas) => saas?.id)
                .includes(manager?.id)
          )}
          isLoading={isDataLoading}
          validateBy="textRequired"
          formSubmitting={formSubmitting}
          formNameValidation={formNameValidation}
          formServerValidation={formServerValidation}
          validationName="primaryId"
          reducer="crm"
        />

        <BSelect
          isMulti
          name="secondarySAAS"
          icon="employees"
          keepDefaultStyle
          label="secondary account managers"
          value={formData?.secondarySAAS}
          onChange={handleSelectChange}
          options={options?.managers?.filter(
            (manager) => manager?.id !== formData?.primarySAAS?.id
          )}
          isLoading={isDataLoading}
          formSubmitting={formSubmitting}
          formNameValidation={formNameValidation}
          formServerValidation={formServerValidation}
          validationName="secondaryIds"
          reducer="crm"
        />

        <CheckboxBooleanForm
          formSubmitting={formSubmitting}
          formNameValidation={formNameValidation}
          formServerValidation={formServerValidation}
          validationName="allow_am_access"
          setWithValue
          name="allow_am_access"
          options={["Allow account managers access to company"]}
          onChange={handleCheckboxChange}
          checked={Boolean(formData?.allow_am_access)}
        />

        {isEdit ? null : (
          <div className="row">
            <BSelect
              name="year_start_month"
              validateBy="textRequired"
              validationName={`input.year_start_month`}
              label="first month of the year"
              labelStyle="mb-2"
              keepDefaultStyle
              optionLabel="value"
              optionValue="key"
              options={Constants.MonthsData}
              inputContainerStyle=""
              rootStyle="col-6"
              placeholder={t("select month")}
              icon="calendar"
              formSubmitting={formSubmitting}
              formNameValidation={formNameValidation}
              formServerValidation={formServerValidation}
              value={formData?.year_start_month}
              onChange={handleSelectChange}
            />
            <BSelect
              name="month_start_day"
              validateBy="textRequired"
              validationName={`input.month_start_day`}
              label="Month Start Day"
              labelStyle="mb-2"
              keepDefaultStyle
              options={Constants.payrollStartOptions}
              inputContainerStyle=""
              rootStyle="col-6"
              placeholder={t("select day")}
              icon="calendar"
              formSubmitting={formSubmitting}
              formNameValidation={formNameValidation}
              formServerValidation={formServerValidation}
              value={formData?.month_start_day}
              onChange={handleSelectChange}
            />
            <BSelect
              name="week_day"
              validateBy="textRequired"
              validationName={`input.week_day`}
              label="payroll week start day"
              labelStyle="mb-2"
              keepDefaultStyle
              optionLabel="label"
              optionValue="value"
              options={Constants.CompanyModalWeekDays}
              inputContainerStyle=""
              rootStyle="col-6"
              placeholder={t("select day")}
              icon="calendar"
              formSubmitting={formSubmitting}
              formNameValidation={formNameValidation}
              formServerValidation={formServerValidation}
              value={formData?.week_day}
              onChange={handleSelectChange}
            />
          </div>
        )}

        {/* Business Partners */}
        <div className="d-flex align-items-center gap-20">
          <strong className="text-16">{t("Business Partners")}</strong>
          <AddButton isIconOnly onClick={handleAddBusinessPartner} />
        </div>
        {formData.business_partners.map((businessPartner, i) => (
          <div key={i} className="d-flex flex-wrap gap-10">
            <div className="w-100 d-flex align-items-center justify-content-between">
              <label>{t("name")}</label>
              {formData.business_partners.length > 1 && (
                <RemoveIconButton
                  onClick={() => handleRemoveBusinessPartner(i)}
                />
              )}
            </div>
            <InputForm
              name="name"
              icon="employee"
              rootStyle="w-100"
              labelStyle="w-100"
              inputContainerStyle="w-100"
              value={businessPartner?.name}
              onChange={(e) => handleChangeBusinessPartner(e, i)}
              validateBy="textRequired"
              formSubmitting={formSubmitting}
              formNameValidation={formNameValidation}
            />
            <Box className="w-50" sx={{ ".intl-tel-input": { width: 1 } }}>
              <label
                className={`d-block${
                  trialServerValidation?.[`business_partners.${i}.phone.0`]
                    ? " red-color"
                    : ""
                }`}
              >
                {t("phone")}
              </label>

              <IntlTelInput
                value={businessPartner?.phone}
                separateDialCode
                autoComplete="tel"
                defaultCountry={businessPartner?.country_short_name || "eg"}
                inputClassName="input-style-default"
                onSelectFlag={(num, { dialCode, iso2 }) =>
                  handlePhoneChange({ dialCode, num, iso2 }, i)
                }
                onPhoneNumberChange={(_, num, { dialCode, iso2 }) =>
                  handlePhoneChange({ dialCode, num, iso2, isPhone: true }, i)
                }
              />
              {trialServerValidation?.[`business_partners.${i}.phone.0`] ? (
                <p className="my-1 text-12 red-color">
                  {t(trialServerValidation?.[`business_partners.${i}.phone`])}
                </p>
              ) : null}
            </Box>
            <InputForm
              name="email"
              icon="mail"
              label="email"
              rootStyle="flex-fill"
              containerStyle="d-block"
              value={businessPartner.email}
              onChange={(e) => handleChangeBusinessPartner(e, i)}
              formServerValidation={formServerValidation}
              formSubmitting={formSubmitting}
              validationName={`business_partners.${i}.email`}
              reducer="crm"
            />
          </div>
        ))}

        {/* Trial Info */}
        <strong className="text-16">{t("trial info")}</strong>
        <div className="d-flex gap-10">
          <BSelect
            name="plan"
            label="package"
            icon="document"
            keepDefaultStyle
            rootStyle="w-50"
            inputContainerStyle="w-100"
            value={formData.plan}
            options={options.plans}
            isLoading={isDataLoading}
            onChange={handleSelectChange}
            validateBy="textRequired"
            formSubmitting={formSubmitting}
            formNameValidation={formNameValidation}
          />
          <InputForm
            type="number"
            name="limit"
            icon="employee"
            label="max. number of employees"
            rootStyle="flex-fill"
            containerStyle="d-block"
            labelStyle="mb-2 pb-1"
            value={formData.limit}
            onChange={handleChange}
            validateBy="textRequired"
            formSubmitting={formSubmitting}
            formNameValidation={formNameValidation}
          />
        </div>
        <div className="d-flex gap-10">
          <DateTimePickerForm
            hasIcon
            label="start date"
            rootStyle="flex-fill"
            value={formData.from}
            datePickerContainer="w-100"
            onChange={(val) => handleDateChange("from", val)}
            validateBy="textRequired"
            formSubmitting={formSubmitting}
            formNameValidation={formNameValidation}
            disabled={isEdit}
          />
          <div className="flex-fill">
            <RadioboxForm
              name="period"
              label="trial Period"
              value={formData.period}
              labelStyle="mb-3"
              optionInputStyle=" "
              containerStyle="mb-0"
              optionItemStyle="d-inline-block mx-2"
              optionsContainerStyle="d-flex gap-10"
              options={[
                {
                  label: "1 week",
                  value: "W1",
                  optProps: { onChange: handleChange, disabled: isEdit },
                },
                {
                  label: "2 week",
                  value: "W2",
                  optProps: { onChange: handleChange, disabled: isEdit },
                },
              ]}
            />
          </div>
        </div>

        {/* Payment Info */}
        {isEdit ? null : (
          <>
            <strong className="text-16">{t("payment info")}</strong>
            <PaymentInfo
              FormProps={{
                reducer: "crm",
                formName: "trialPayments",
                formSubmitting,
                formNameValidation,
                formServerValidation,
              }}
            />
          </>
        )}
      </div>
    </MainModal>
  );
};

export default TrialModal;

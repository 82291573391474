import React from "react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import Loader from "../../Components/Loader";
import { useTranslation } from "react-i18next";
import { exportPayslipAction } from "../../Store/Actions";
import { Spinner } from "reactstrap";
import { useMutation } from "@apollo/client";
import { exportEmployeePayslip } from "../../Graphql/mutation";
import HelperFns, { showToast } from "../../Helpers/HelperFns";

const PayslipModal = (props) => {
  // to approximate the numbers
  function roundToTwo(num) {
    return (+num)?.toFixed(2);
  }

  const [attemptExportPayslip, { loading: exportPaySlipLoading }] = useMutation(
    exportEmployeePayslip,
    {
      variables: {
        input: {
          user_id: props?.paySlipData?.user?.id,
          paid_at: props?.paidAt,
          type: props?.type,
          from: props?.from,
          month_year: props?.paidAt, // should not send this one but it's included in the schema
        },
      },
      onCompleted: (data) => {
        props?.modal_toggle();
        showToast(
          data?.export_payslip_report.status,
          data?.export_payslip_report.message
        );
        HelperFns.downloadFile(data?.export_payslip_report?.file);
      },
    }
  );

  const { t } = useTranslation();

  if (props?.isPayslipLoading) {
    return (
      <div className="loader_wrapper_style">
        <Loader />
      </div>
    );
  }

  const handleExportPayslip = () => {
    attemptExportPayslip();
  };

  return (
    <div>
      <Modal
        isOpen={props?.modal_isOpen}
        toggle={props?.modal_toggle}
        centered
        fade={false}
        className="payslip_modal_wrapper_style"
      >
        <ModalHeader toggle={props?.modal_toggle}>{t("payslip")}</ModalHeader>

        <ModalBody className="pb-2">
          <div className="d-flex justify-content-between align-items-baseline">
            <div>
              <img src="assets/img/logo.svg" height="70" width="135" />

              <div>
                <h1 className="font-weight-bold">
                  {props?.paySlipData?.user?.office?.company?.name}
                </h1>

                <h1>{props?.paySlipData?.user?.office?.address}</h1>
              </div>
            </div>

            <HasPrivileges reqireMain={[Privilages.EXPORT_PAYSLIPS]} allowBP>
              <button
                type="button"
                className="print__btn"
                onClick={handleExportPayslip}
              >
                <FontAwesomeIcon className="print__btn-icon" icon={faPrint} />
                {exportPaySlipLoading ? (
                  <Spinner
                    style={{ width: "0.8rem", height: "0.8rem", color: "#fff" }}
                  />
                ) : (
                  t("Print Payslip")
                )}
              </button>
            </HasPrivileges>
          </div>

          <hr className="sperator_style mt-1" />

          <div className="my-4">
            <h4 className="sub-title-style mb-1">
              {t("payslip_title")}{" "}
              {moment(props?.paySlipData?.date).format("MMMM YYYY")}
            </h4>
            <hr className="title_underline_style" />
          </div>

          <div className="row">
            <div className="col-12 col-lg-6">
              <div>
                <label>{t("name")}</label>
                <p className="font-weight-bold">
                  {props?.paySlipData?.user?.name}
                </p>
              </div>
            </div>

            <div className="col-12 col-lg-6">
              <div>
                <label>{t("office")}</label>
                <p className="font-weight-bold">
                  {props?.paySlipData?.user?.office?.name}
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-lg-6">
              <div>
                <label>{t("department")}</label>
                <p className="font-weight-bold">
                  {props?.paySlipData?.user?.department?.name}
                </p>
              </div>
            </div>

            <div className="col-12 col-lg-6">
              <div>
                <label>{t("position")}</label>
                <p className="font-weight-bold">
                  {props?.paySlipData?.user?.position?.name}
                </p>
              </div>
            </div>
          </div>

          <hr className="sperator_style mt-1" />

          <div className="row mb-2">
            <div className="col-12 col-lg-6">
              <div>
                <h5 className="d-flex salary_amount_style">
                  {t("base_salary")}
                </h5>
              </div>
            </div>

            <div className="col-12 col-lg-6">
              <div>
                <h5 className="d-flex salary_amount_style">
                  <p>
                    {props?.paySlipData?.currency?.symbol}
                    <span>{roundToTwo(+props?.paySlipData?.base_salary)}</span>
                  </p>
                </h5>
              </div>
            </div>
          </div>

          {!props?.paySlipData?.allowances?.length ? (
            ""
          ) : (
            <div className="row mb-2">
              <div className="col-12">
                <div>
                  <h5 className="d-flex salary_amount_style">
                    {t("allowances")}
                  </h5>
                  {props?.paySlipData?.allowances?.map((allowanceInfo, i) => (
                    <div className="row">
                      <div className="col-12 col-lg-6">
                        <h6 key={i}>
                          {"-"} {allowanceInfo?.name}
                        </h6>
                      </div>

                      <div className="col-12 col-lg-6">
                        <h5 className="d-flex salary_amount_style">
                          <p key={Math.random()}>
                            {props?.paySlipData?.currency?.symbol}
                            <span>{roundToTwo(allowanceInfo?.amount)}</span>
                          </p>
                        </h5>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {!props?.paySlipData?.bonuses?.length ? (
            ""
          ) : (
            <div className="row mb-2">
              <div className="col-12">
                <div>
                  <div className="row">
                    <h5 className="d-flex salary_amount_style col-6">
                      {t("bonus")}
                    </h5>
                    <div className="col-6">
                      <h5 className="d-flex salary_amount_style">
                        <p key={Math.random()}>
                          {props?.paySlipData?.currency?.symbol}
                          <span>{props?.paySlipData?.totalBonus}</span>
                        </p>
                      </h5>
                    </div>
                  </div>
                  {props?.paySlipData?.bonuses?.map((bonusInfo) => (
                    <div className="row">
                      <div className="col-12 col-lg-6">
                        <h6 key={Math.random()}>
                          {"-"} {bonusInfo?.name}
                        </h6>
                      </div>

                      <div className="col-12 col-lg-6">
                        <h5 className="d-flex salary_amount_style">
                          <p key={Math.random()}>
                            {props?.paySlipData?.currency?.symbol}
                            <span>{roundToTwo(bonusInfo?.amount)}</span>
                          </p>
                        </h5>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {props?.paySlipData?.overtime == 0 ? (
            ""
          ) : (
            <div className="row mb-2">
              <div className="col-12 col-lg-6">
                <div>
                  <h5 className="d-flex salary_amount_style">
                    {t("overtime")}
                  </h5>
                </div>
              </div>

              <div className="col-12 col-lg-6">
                <div>
                  <h5 className="d-flex salary_amount_style">
                    <p>
                      {props?.paySlipData?.currency?.symbol}
                      <span>{roundToTwo(props?.paySlipData?.overtime)}</span>
                    </p>
                  </h5>
                </div>
              </div>
            </div>
          )}

          {!props?.paySlipData?.claims?.length ? (
            ""
          ) : (
            <div className="row mb-2">
              <div className="col-12">
                <div>
                  <h5 className="d-flex salary_amount_style">{t("claims")}</h5>
                  {props?.paySlipData?.claims?.map((claimsInfo) => (
                    <div className="row">
                      <div className="col-12 col-lg-6">
                        <h6 key={Math.random()}>
                          {"-"} {claimsInfo?.reason}
                        </h6>
                      </div>

                      <div className="col-12 col-lg-6">
                        <h5 className="d-flex salary_amount_style">
                          <p key={Math.random()}>
                            {props?.paySlipData?.currency?.symbol}
                            <span>{roundToTwo(claimsInfo?.amount)}</span>
                          </p>
                        </h5>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {!props?.paySlipData?.loans?.length ? (
            ""
          ) : (
            <div className="row mb-2">
              <div className="col-12">
                <div>
                  <h5 className="d-flex salary_amount_style">{t("loans")}</h5>
                  {props?.paySlipData?.loans?.map((loansInfo) => (
                    <div className="row">
                      <div className="col-12 col-lg-6">
                        <h6 key={Math.random()}>
                          {"-"} {loansInfo?.name}
                        </h6>
                      </div>

                      <div className="col-12 col-lg-6">
                        <h5 className="d-flex salary_amount_style">
                          <p key={Math.random()}>
                            {loansInfo?.currencySymbol}
                            <span>{loansInfo?.amount}</span>
                          </p>
                        </h5>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {(props?.paySlipData?.bonuses?.length ||
            props?.paySlipData?.claims?.length ||
            props?.paySlipData?.allowances?.length ||
            props?.paySlipData?.loans?.length ||
            props?.paySlipData?.overtime != 0) > 0 && (
            <div className="row mb-2">
              <div className="col-12 col-lg-6">
                <div>
                  <h5 className="d-flex salary_amount_style font-weight-bold dark-text">
                    {t("gross_salary")}
                  </h5>
                </div>
              </div>

              <div className="col-12 col-lg-6">
                <div>
                  <h5 className="d-flex salary_amount_style">
                    <p className="font-weight-bold">
                      {props?.paySlipData?.currency?.symbol}
                      <span>
                        {roundToTwo(props?.paySlipData?.gross_salary)}
                      </span>
                    </p>
                  </h5>
                </div>
              </div>
            </div>
          )}

          {!props?.paySlipData?.penalties?.length ? (
            ""
          ) : (
            <div className="row mb-2">
              <div className="col-12">
                <div>
                  <h5 className="d-flex salary_amount_style">
                    {t("penalties")}
                  </h5>
                  {props?.paySlipData?.penalties?.map((penaltiesInfo) => (
                    <div className="row">
                      <div className="col-12 col-lg-6">
                        <h6 key={Math.random()}>
                          {"-"}
                          {t(penaltiesInfo?.reason?.toLowerCase())}
                        </h6>
                      </div>

                      <div className="col-12 col-lg-6">
                        <h5 className="d-flex salary_amount_style">
                          {"("}
                          <p key={Math.random()}>
                            {props?.paySlipData?.currency?.symbol}
                            <span>
                              {roundToTwo(Math.abs(penaltiesInfo?.amount))}
                            </span>
                          </p>
                          {")"}
                        </h5>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {props?.paySlipData?.social == 0 ? (
            ""
          ) : (
            <div className="row mb-2">
              <div className="col-12 col-lg-6">
                <div>
                  <h5 className="d-flex salary_amount_style">
                    {t("social insurance")}
                  </h5>
                </div>
              </div>

              <div className="col-12 col-lg-6">
                <div>
                  <h5 className="d-flex salary_amount_style">
                    {"("}
                    <p>
                      {props?.paySlipData?.currency?.symbol}
                      <span>
                        {roundToTwo(Math.abs(props?.paySlipData?.social))}
                      </span>
                    </p>
                    {")"}
                  </h5>
                </div>
              </div>
            </div>
          )}

          {props?.paySlipData?.medical == 0 ? (
            ""
          ) : (
            <div className="row mb-2">
              <div className="col-12 col-lg-6">
                <div>
                  <h5 className="d-flex salary_amount_style">
                    {t("medical insurance")}
                  </h5>
                </div>
              </div>

              <div className="col-12 col-lg-6">
                <div>
                  <h5 className="d-flex salary_amount_style">
                    {"("}
                    <p>
                      {props?.paySlipData?.currency?.symbol}
                      <span>
                        {roundToTwo(Math.abs(props?.paySlipData?.medical))}
                      </span>
                    </p>
                    {")"}
                  </h5>
                </div>
              </div>
            </div>
          )}

          {props?.paySlipData?.taxes == 0 ? (
            ""
          ) : (
            <div className="row mb-2">
              <div className="col-12 col-lg-6">
                <div>
                  <h5 className="d-flex salary_amount_style">{t("taxes")}</h5>
                </div>
              </div>

              <div className="col-12 col-lg-6">
                <div>
                  <h5 className="d-flex salary_amount_style">
                    {"("}
                    <p>
                      {props?.paySlipData?.currency?.symbol}
                      <span>
                        {roundToTwo(Math.abs(props?.paySlipData?.taxes))}
                      </span>
                    </p>
                    {")"}
                  </h5>
                </div>
              </div>
            </div>
          )}

          {!props?.paySlipData?.installments?.length ? (
            ""
          ) : (
            <div className="row mb-2">
              <div className="col-12">
                <div>
                  <h5 className="d-flex salary_amount_style">
                    {t("Installments")}
                  </h5>
                  {props?.paySlipData?.installments?.map((installmentsInfo) => (
                    <div className="row">
                      <div className="col-12 col-lg-6">
                        <h6 key={installmentsInfo?.id}>
                          {"- "}
                          {installmentsInfo?.loanName}
                        </h6>
                      </div>

                      <div className="col-12 col-lg-6">
                        <h5 className="d-flex salary_amount_style">
                          {"("}
                          <p key={installmentsInfo?.id}>
                            {installmentsInfo?.currencySymbol}
                            <span>{installmentsInfo?.amount}</span>
                          </p>
                          {")"}
                        </h5>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {props?.paySlipData?.unpaid == 0 ? (
            ""
          ) : (
            <div className="row mb-2">
              <div className="col-12 col-lg-6">
                <div>
                  <h5 className="d-flex salary_amount_style">
                    {t("unpaid leaves")}
                  </h5>
                </div>
              </div>

              <div className="col-12 col-lg-6">
                <div>
                  <h5 className="d-flex salary_amount_style">
                    {"("}
                    <p>
                      {props?.paySlipData?.currency?.symbol}
                      <span>
                        {roundToTwo(Math.abs(props?.paySlipData?.unpaid))}
                      </span>
                    </p>
                    {")"}
                  </h5>
                </div>
              </div>
            </div>
          )}

          {props?.paySlipData?.deductions?.length ||
            (props?.paySlipData?.deductibles?.length > 0 && (
              <div>
                <div className="row">
                  <h5 className="salary_amount_style col-6">
                    {t("Other")} {t("deductibles")}
                  </h5>
                  <div className="col-6">
                    <h5 className="d-flex salary_amount_style">
                      <p key={Math.random()}>
                        {props?.paySlipData?.currency?.symbol}
                        <span>{props?.paySlipData?.totalDeduction}</span>
                      </p>
                    </h5>
                  </div>
                </div>

                {props?.paySlipData?.deductions?.map((deductionInfo) => (
                  <div className="row">
                    <div className="col-12 col-lg-6">
                      <h6 key={Math.random()}>
                        {"-"} {deductionInfo?.name}
                      </h6>
                    </div>

                    <div className="col-12 col-lg-6">
                      <h5 className="d-flex salary_amount_style">
                        {"("}
                        <p key={Math.random()}>
                          {props?.paySlipData?.currency?.symbol}
                          <span>
                            {roundToTwo(Math.abs(deductionInfo?.amount))}
                          </span>
                        </p>
                        {")"}
                      </h5>
                    </div>
                  </div>
                ))}

                {props?.paySlipData?.deductibles?.map((deductiblesInfo) => (
                  <div className="row">
                    <div className="col-12 col-lg-6">
                      <h6 key={Math.random()}>
                        {"-"} {deductiblesInfo?.name}
                      </h6>
                    </div>

                    <div className="col-12 col-lg-6">
                      <h5 className="d-flex salary_amount_style">
                        {"("}
                        <p key={Math.random()}>
                          {props?.paySlipData?.currency?.symbol}
                          <span>
                            {roundToTwo(Math.abs(deductiblesInfo?.amount))}
                          </span>
                        </p>
                        {")"}
                      </h5>
                    </div>
                  </div>
                ))}
              </div>
            ))}

          <hr className="sperator_style mb-3" />
          {!props?.paySlipData?.taxSettlement ? (
            ""
          ) : (
            <div className="row mb-2">
              <div className="col-12">
                <div>
                  <h5 className="d-flex salary_amount_style">
                    {t("tax settlements")}
                  </h5>
                  <div className="row">
                    <div className="col-12 col-lg-6">
                      <h6 key={props?.paySlipData?.taxSettlement?.id}>
                        {"-"} {props?.paySlipData?.taxSettlement?.start}{" "}
                        {props?.paySlipData?.taxSettlement?.year} -{" "}
                        {props?.paySlipData?.taxSettlement?.end}{" "}
                        {props?.paySlipData?.taxSettlement?.year}
                      </h6>
                    </div>

                    <div className="col-12 col-lg-6">
                      <h5 className="d-flex salary_amount_style">
                        <p key={props?.paySlipData?.taxSettlement?.value}>
                          <span>
                            {props?.paySlipData?.taxSettlement?.value * -1}
                          </span>
                        </p>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <hr className="sperator_style mb-3" />

          <div className="row">
            <div className="col-12 col-lg-6">
              <h5 className="d-flex salary_amount_style font-weight-bold dark-text">
                {t("net_salary")}
              </h5>
            </div>

            <div className="col-12 col-lg-6">
              <h5 className="d-flex salary_amount_style">
                <p className="font-weight-bold">
                  {props?.paySlipData?.currency?.symbol}
                  <span>{roundToTwo(props?.paySlipData?.net_salary)}</span>
                </p>
              </h5>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

// export default connect(mapStateToProps, {
//   exportPayslipAction,
// })();

export default PayslipModal;

import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import moment from "moment";
import Privileges from "../../Constants/Privilages";
import { showToast } from "../../Helpers/HelperFns";
import { dateTimeListFormat } from "../../Constants";
import { meetingsListQuery } from "../../Graphql/query";

import IconButtonWithTooltip, {
  CopyIconButton,
  EditIconButton,
} from "../../Components/IconButtonWithTooltip";
import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import { AddButton } from "../../Components/Buttons";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { UpsertMeetingModal } from "../../Components/CRMFormModals";

const modalInitState = { isOpen: false, data: null };
const modals = [{ name: "upsertMeeting", Modal: UpsertMeetingModal }];
const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};

const MeetingsList = () => {
  const { t } = useTranslation();

  // Local State
  const [meetings, setMeetings] = useState([]);
  const [pagination, setPagination] = useState(paginationInitState);
  const [modalsState, setModalsState] = useState(
    modals.map(({ name }) => ({ name, ...modalInitState }))
  );

  // Server State
  const { refetch: refetchList, loading: isListLoading } = useQuery(
    meetingsListQuery,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        first: pagination.perPage,
        page: pagination.currentPage,
      },
      onCompleted: ({ meetings }) => {
        setMeetings(meetings?.data || []);
        setPagination(meetings?.paginatorInfo || paginationInitState);
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message
        );
      },
    }
  );

  // Constants
  const columns = [
    { name: t("name"), selector: "name", grow: 2 },
    {
      name: t("date"),
      grow: 2,
      cell: (row) => moment(row?.date).format(dateTimeListFormat),
    },
    { name: t("user"), selector: "user.name", grow: 2 },
    {
      name: t("agent URL"),
      cell: (row) =>
        row?.start_url ? (
          <IconButtonWithTooltip
            isExternalLink
            href={row?.start_url}
            buttonProps={{ target: "_blank" }}
            icon={<ArrowOutwardIcon fontSize="small" />}
          />
        ) : (
          ""
        ),
    },
    {
      name: t("client URL"),
      cell: (row) =>
        row?.join_url ? (
          <div className="d-flex gap-10">
            <CopyIconButton
              onClick={() => {
                navigator.clipboard.writeText(row?.join_url);
                showToast("success", t("copied"));
              }}
            />
            <IconButtonWithTooltip
              isExternalLink
              href={row?.join_url}
              icon={<ArrowOutwardIcon fontSize="small" />}
            />
          </div>
        ) : (
          ""
        ),
    },
    {
      name: t("meeting URL"),
      cell: (row) =>
        row?.directory ? (
          <IconButtonWithTooltip
            isExternalLink
            href={row?.directory}
            buttonProps={{ target: "_blank" }}
            icon={<ArrowOutwardIcon fontSize="small" />}
          />
        ) : (
          ""
        ),
    },
    {
      cell: (row) =>
        row?.directory ? null : (
          <>
            <HasPrivileges
              reqireMain={[
                Privileges.SUPER_PRIVILEGE,
                Privileges.ADD_EDIT_CRM_MEETING,
              ]}
            >
              <EditIconButton
                onClick={() =>
                  handleOpenModal("upsertMeeting", { id: row?.id })
                }
              />
            </HasPrivileges>
          </>
        ),
    },
  ];

  /* ↓ Helpers ↓ */

  const handleOpenModal = (name, data = null) => {
    const modalState = { name, data, isOpen: true };

    setModalsState((prev) =>
      prev.map((p) => (p.name === name ? modalState : p))
    );
  };

  const handleCloseModal = (name) => {
    setModalsState((prev) =>
      prev.map((p) => (p.name === name ? { name, ...modalInitState } : p))
    );
  };

  const handlePaginate = (page = pagination.currentPage) => {
    refetchList({ page });
  };

  return (
    <>
      {/* Actions */}
      <div className="d-flex justify-content-end mt-3 mb-lg-2">
        <HasPrivileges
          reqireMain={[Privileges.SUPER_PRIVILEGE, Privileges.ADD_EDIT_CRM_MEETING]}
        >
          <AddButton
            label="meeting"
            onClick={() => handleOpenModal("upsertMeeting")}
          />
        </HasPrivileges>
      </div>

      {/* List */}
      <DataTable
        noHeader
        data={meetings}
        columns={columns}
        className="cards_table"
        progressPending={isListLoading}
        progressComponent={<Loader />}
        pagination
        paginationServer
        paginationComponent={() => (
          <Pagination
            styleWraper=""
            onPaginate={handlePaginate}
            customPaginator={pagination}
          />
        )}
      />

      {/* Modals */}
      {modals.map(({ name, Modal }, i) =>
        modalsState[i].isOpen ? (
          <Modal
            key={name}
            refetchList={refetchList}
            data={modalsState[i]?.data}
            onClose={() => handleCloseModal(name)}
          />
        ) : null
      )}
    </>
  );
};

export default MeetingsList;

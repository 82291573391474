import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { BSelect, InputForm } from "form-builder";

// Utils
import {
  setFromMultipleInputsAction,
  updateValueAction,
} from "../../../Store/Actions";

// Icons
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

const LeaveAndBalanceSection = ({ FormProps }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const leavesBreaks = useSelector(
    (state) => state?.super?.editEmployeeFormData?.leavesBreaks
  );

  const selectedLeaveBreakProfileId = useSelector(
    (state) => state?.super?.employeeForm?.permissionLeavesBreakSettingId
  );
  const mainSelectedLeaveBreakProfileId = useSelector(
    (state) => state?.super?.employeeForm?.mainPermissionLeavesBreakSettingId
  );

  


  const selectedMainLeaveBreakProfile =
    leavesBreaks?.find(
      (leaveBreakProfile) =>
        leaveBreakProfile.id === mainSelectedLeaveBreakProfileId
    ) ?? {};

  const versionsLeaveBreakOpts = [
    selectedMainLeaveBreakProfile,
    ...(selectedMainLeaveBreakProfile?.children || []),
  ];

  const selectedLeaveBreakProfile =
  [...leavesBreaks||[],...versionsLeaveBreakOpts||[]].flat(Infinity)?.find(
    (leaveBreakProfile) =>
      leaveBreakProfile.id === selectedLeaveBreakProfileId
  ) ?? {};

  useEffect(() => {
    dispatch(
      setFromMultipleInputsAction(FormProps?.formName, {
        annual_leaves: "0",
        emergency_leaves: "0",
        sick_leaves: "0",
      })
    );
    return () => {};
  }, [selectedLeaveBreakProfileId, FormProps?.formName, dispatch]);

  const onMainLeaveProfileChange = (e, value) => {
    dispatch(
      updateValueAction(
        FormProps?.formName,
        "permissionLeavesBreakSettingId",
        value?.id || null
      )
    );
  };

  return (
    <div className="boxContainer">
      <div className="name_container">
        <InsertDriveFileIcon />
        <h4 className="sub-title-style mb-0">{t("Leave And Balance info")}</h4>
      </div>
      <hr />
      <div className="row mt-2">
        <BSelect
          {...FormProps}
          name="mainPermissionLeavesBreakSettingId"
          label={t("leave and break profile")}
          keepDefaultStyle
          placeholder={t("select option")}
          options={leavesBreaks || []}
          validateBy="textRequired"
          validationName="input.permissionLeavesBreakSettingId"
          skipLocalization
          icon="person"
          rootStyle="col-md-6"
          onInterceptInputOnChange={onMainLeaveProfileChange}
        />
        <BSelect
          {...FormProps}
          name="permissionLeavesBreakSettingId"
          label={t("leave and break profile version")}
          keepDefaultStyle
          placeholder={t("select option")}
          options={versionsLeaveBreakOpts || []}
          validateBy="textRequired"
          validationName="input.permissionLeavesBreakSettingId"
          skipLocalization
          icon="person"
          rootStyle="col-md-6"
        />
      </div>
      <div className="row align-items-center mt-3">
        {Boolean(
          selectedLeaveBreakProfile?.annualLeaveSetting?.allow_annual_leaves
        ) ||
        Boolean(
          selectedLeaveBreakProfile?.annualLeaveSetting
            ?.allowed_emergency_leaves
        ) ||
        Boolean(
          selectedLeaveBreakProfile?.sickLeaveSetting?.allowed_sick_leaves
        ) ? (
          <div className="col-12 mb-2">
            <label className="font-weight-bold sub_title">
              {t("beginning leave balance")}
            </label>
          </div>
        ) : null}

        {Boolean(
          selectedLeaveBreakProfile?.annualLeaveSetting?.allow_annual_leaves
        ) ? (
          <div className="leaves_field col-xl-4">
            <label>{t("annual leaves")}</label>
            <InputForm
              {...FormProps}
              name="annual_leaves"
              validateBy="textRequired"
              validationName="input.user_input.beginning_balance.annual_leaves"
              type="number"
              hasSuffix
              suffixTitle="days"
              rootStyle="mx-xl-4"
              inputContainerStyle="input-container-style-default w-100"
            />
          </div>
        ) : null}

        {Boolean(
          selectedLeaveBreakProfile?.annualLeaveSetting
            ?.allowed_emergency_leaves
        ) ? (
          <div className="leaves_field col-xl-4">
            <label>{t("emergency credit")}</label>
            <InputForm
              {...FormProps}
              name="emergency_leaves"
              validateBy="textRequired"
              validationName="input.user_input.beginning_balance.emergency_leaves"
              type="number"
              hasSuffix
              suffixTitle="days"
              rootStyle="mx-xl-4"
              inputContainerStyle="input-container-style-default w-100"
            />
          </div>
        ) : null}

        {Boolean(
          selectedLeaveBreakProfile?.sickLeaveSetting?.allowed_sick_leaves
        ) ? (
          <div className="leaves_field col-xl-4">
            <label>{t("sick leaves")}</label>
            <InputForm
              {...FormProps}
              name="sick_leaves"
              validationName="input.user_input.beginning_balance.sick_leaves"
              type="number"
              hasSuffix
              suffixTitle="days"
              rootStyle="mx-xl-4"
              inputContainerStyle="input-container-style-default w-100"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default LeaveAndBalanceSection;

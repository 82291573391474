import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";

import {
  publishAnnouncementMutation,
  removeAnnouncementMutation,
} from "../../Graphql/mutation";
import moment from "moment";
import Privilages from "../../Constants/Privilages";
import { announcementsListQuery } from "../../Graphql/query";
import { openSwalConfirm, showToast } from "../../Helpers/HelperFns";

import IconButtonWithTooltip, {
  EditIconButton,
  RemoveIconButton,
  DownloadFileIconButton,
} from "../../Components/IconButtonWithTooltip";
import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import ReactShowMoreText from "react-show-more-text";
import { AddButton } from "../../Components/Buttons";
import {AnnouncementsModal} from "../../Components/AdminPanelModals";
import { InputForm, RadioboxForm } from "form-builder";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const dateFormat = "DD-MM-YYYY hh:mm A";
const modalInitState = { isOpen: false, data: null };
const statusOptions = [
  {
    label: "All",
    value: "All",
  },
  {
    label: "Drafted",
    value: "Drafted",
  },
  {
    label: "Published",
    value: "Published",
  },
];
const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};

const Announcements = ({ isAdminPanel = false }) => {
  const { t } = useTranslation();

  // Local State
  const [announcements, setAnnouncements] = useState([]);
  const [modalState, setModalState] = useState(modalInitState);
  const [pagination, setPagination] = useState(paginationInitState);
  const [filters, setFilters] = useState({ title: "", status: "All" });

  // Server State
  const [publishAnnouncement, { loading: publishLoading }] = useMutation(
    publishAnnouncementMutation
  );
  const [removeAnnouncement, { loading: removeLoading }] = useMutation(
    removeAnnouncementMutation
  );
  const { loading: listLoading, refetch: refetchList } = useQuery(
    announcementsListQuery,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        ...filters,
        first: pagination.perPage,
        page: pagination.currentPage,
        title: filters?.title || undefined,
        type: isAdminPanel ? "Admin" : "Company",
      },
      onCompleted: ({ announcements = {} }) => {
        setAnnouncements(announcements?.data || []);
        announcements?.paginatorInfo &&
          setPagination(announcements?.paginatorInfo);
      },
    }
  );

  // Constants
  const columns = [
    { name: t("title"), selector: "title" },
    {
      grow: 1.5,
      name: t("body"),
      cell: ({ body = "" }) => (
        <ReactShowMoreText lines={1}>{body}</ReactShowMoreText>
      ),
    },
    {
      grow: 1.5,
      name: t("Sent To"),
      cell: ({ description = "" }) => (
        <ReactShowMoreText lines={1}>{description}</ReactShowMoreText>
      ),
    },
    {
      name: t("Attachments"),
      wrap: true,
      sortable: false,
      grow: 1,
      cell: (row) => (
        <>
          {row?.file === null ? (
            "--------------"
          ) : (
            <div className="cards_table_actions">
              <DownloadFileIconButton
                key={row?.file?.id}
                href={row?.file?.path}
              />
            </div>
          )}
        </>
      ),
    },
    {
      grow: 1.3,
      name: t("Created At"),
      cell: ({ created_at = "" }) => moment(created_at).format(dateFormat),
    },
    { name: t("Created By"), selector: "user.name" },
    {
      grow: 1.3,
      name: t("Published On"),
      cell: ({ published_at = "" }) =>
        published_at
          ? moment(published_at).format(dateFormat)
          : "-- - -- - ----",
    },
    {
      cell: (row) =>
        row?.published_at ? (
          <strong>{t("Published")}</strong>
        ) : (
          <HasPrivileges
            allowBP
            reqireMain={
              isAdminPanel
                ? [
                    Privilages.SUPER_PRIVILEGE,
                    Privilages.ADD_EDIT_DELETE_ADMIN_ANNOUNCEMENT,
                  ]
                : [Privilages.ADD_EDIT_DELETE_COMPANY_ANNOUNCEMENT]
            }
          >
            <IconButtonWithTooltip
              label="Publish"
              onClick={() => handlePublish(row?.id)}
              icon={<ArrowUpwardIcon fontSize="small" />}
            />
            <EditIconButton onClick={() => handleEdit(row?.id)} />
            <RemoveIconButton onClick={() => handleRemove(row?.id)} />
          </HasPrivileges>
        ),
    },
  ];

  /* ↓ State Effects ↓ */

  /* ↓ Helpers ↓ */

  const handleFiltersChange = (e) => {
    setFilters((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handlePaginate = (page = pagination.currentPage) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
  };

  const handlePublish = (id) => {
    openSwalConfirm((isConfirmed) => {
      isConfirmed &&
        publishAnnouncement({
          variables: { id },
          onCompleted: () => {
            refetchList();
            showToast("success");
          },
          onError: (err) => {
            showToast(
              "error",
              err?.graphQLErrors[0]?.extensions?.reason ||
                err?.graphQLErrors[0]?.message ||
                err?.message
            );
          },
        });
    });
  };

  const handleEdit = (id) => {
    handleOpenModal({ isAdminPanel, isEdit: true, id });
  };

  const handleRemove = (id) => {
    openSwalConfirm((isConfirmed) => {
      isConfirmed &&
        removeAnnouncement({
          variables: { id },
          onCompleted: () => {
            refetchList();
            showToast("success");
          },
          onError: (err) => {
            showToast(
              "error",
              err?.graphQLErrors[0]?.extensions?.reason ||
                err?.graphQLErrors[0]?.message ||
                err?.message
            );
          },
        });
    });
  };

  const handleOpenModal = (data) => {
    setModalState({
      isOpen: true,
      data: { isAdminPanel, isEdit: false, ...data },
    });
  };

  const handleCloseModal = () => {
    setModalState(modalInitState);
  };

  return (
    <>
      {publishLoading || removeLoading ? <Loader fixed /> : null}

      {/* Filters */}
      <div className="d-flex justify-content-between align-items-center mt-3 mb-4 gap-20">
        <RadioboxForm
          name="status"
          value={filters?.status}
          options={statusOptions}
          onChange={handleFiltersChange}
          optionInputStyle=" "
          containerStyle="mb-0"
          optionItemStyle="px-0"
          optionsContainerStyle="d-flex gap-10"
        />
        <InputForm
          name="title"
          type="search"
          icon="search"
          rootStyle="flex-1"
          containerStyle=" "
          value={filters?.title}
          onChange={handleFiltersChange}
          placeholder={t("Search Titles")}
        />
        <HasPrivileges
          allowBP
          reqireMain={
            isAdminPanel
              ? [
                  Privilages.SUPER_PRIVILEGE,
                  Privilages.ADD_EDIT_DELETE_ADMIN_ANNOUNCEMENT,
                ]
              : [Privilages.ADD_EDIT_DELETE_COMPANY_ANNOUNCEMENT]
          }
        >
          <AddButton onClick={handleOpenModal} />
        </HasPrivileges>
      </div>

      {/* List */}
      <DataTable
        noHeader
        columns={columns}
        data={announcements}
        className="cards_table mt-4"
        progressPending={listLoading}
        progressComponent={<Loader inner />}
        pagination
        paginationServer
        paginationComponent={() => (
          <Pagination
            styleWraper=""
            onPaginate={handlePaginate}
            customPaginator={pagination}
          />
        )}
      />

      {/* Modals */}
      {modalState?.isOpen ? (
        <AnnouncementsModal
          data={modalState?.data}
          refetchList={refetchList}
          onClose={handleCloseModal}
        />
      ) : null}
    </>
  );
};

export default Announcements;

import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useLazyQuery } from "@apollo/client";

import {
  showToast,
  showErrorToast,
  updateValueAction,
  onFormResetAction,
  addAllowanceInputAction,
  addDeductibleInputAction,
  setSalaryCalculationToFixed,
} from "../../Store/Actions";
import HelperFns, {
  serializeSubmitHourlyRating,
} from "../../Helpers/HelperFns";
import moment from "moment";
import Store from "../../Store";
import { monthOptionDays } from "../../Constants";
import { GET_RELEVANT_TAX_INFO } from "../../Graphql/query";
import PaymentFactors from "../../Constants/PaymentFactors";
import { RegulationTypes } from "../../Constants/RegulationTypes";
import { upsertSalaryConfigurationPresetsMutation } from "../../Graphql/mutation";

import Loader from "../Loader";
import MainModal from "../MainModal";
import { AddButton } from "../Buttons";
import TaxSection from "../SalaryConfigForm/TaxSection";
import { OvertTimeRangeSetting } from "../SalaryConfigForm";
import SocialSection from "../SalaryConfigForm/SocialSection";
import { BSelect, InputForm, RadioboxForm } from "form-builder";
import MedicalSection from "../SalaryConfigForm/MedicalSection";
import AllowanceSection from "../SalaryConfigForm/AllowanceSection";
import BonusCalculation from "../SalaryConfigForm/BonusCalculation";
import UnpaidCalculation from "../SalaryConfigForm/UnpaidCalculation";
import PenaltyCalculation from "../SalaryConfigForm/PenaltyCalculation";
import DeuductableSection from "../SalaryConfigForm/DeuductableSection";
import OvertimeCalculation from "../SalaryConfigForm/OvertimeCalculation";
import HourlySectionDetails from "../SalaryConfigForm/HourlySectionDetails";

const formName = "salaryConfigForm";
const formNameValidation = "salaryConfigFormValidation";
const formServerValidation = "upsertSalaryConfigServerValidation";

const UpsertSalaryConfigPresetsModal = ({ data, onClose, refetchList }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [formSubmitting, setFormSubmitting] = React.useState(false);

  // Reducer State
  const formValidations = useSelector(
    (state) => state?.super?.[formNameValidation]
  );
  const base_salary = useSelector(
    (state) => state.super.salaryConfigForm?.base_salary
  );
  const salary_allowances = useSelector(
    (state) => state.super.salaryConfigForm?.salary_allowances
  );
  const salary_deductibles = useSelector(
    (state) => state.super.salaryConfigForm?.salary_deductibles
  );
  const socialDeduction = useSelector(
    (state) => state.super.salaryConfigForm?.socialDeduction
  );
  const medicalDeduction = useSelector(
    (state) => state.super.salaryConfigForm?.medicalDeduction
  );
  const regulation_type_id = useSelector(
    (state) => state.super.salaryConfigForm?.regulation_type_id
  );
  const taxDeduction = useSelector(
    (state) => state.super.salaryConfigForm?.taxDeduction
  );
  const calculate_salary_by = useSelector(
    (state) => state.super.salaryConfigForm?.calculate_salary_by
  );
  const tax_info_id = useSelector(
    (state) => state.super.salaryConfigForm?.tax_info_id
  );
  const payment_interval = useSelector(
    (state) => state.super.salaryConfigForm?.payment_interval
  );
  const userRegulationTypesOptions = useSelector(
    (state) => state.super?.userRegulationTypesOptions
  );
  const userCurrenciesOptions = useSelector(
    (state) => state.super?.userCurrenciesOptions
  );
  const loading = useSelector(
    (state) => state.super.upsertSalaryConfigModalActions.loadingStatus
  );
  const timeRangeProfileSetting = useSelector(
    (state) => state?.super?.timeRangeProfileSetting
  );

  // Server State
  const [upsert, { loading: upsertLoading }] = useMutation(
    upsertSalaryConfigurationPresetsMutation
  );
  const [attemptGetRelevantTaxInfo, { data: relevantTaxInfoData }] =
    useLazyQuery(GET_RELEVANT_TAX_INFO, {
      onCompleted: (data) => {
        dispatch(
          updateValueAction(formName, "tax_info_id", data?.relevantTaxInfo?.id)
        );
      },
      onError: (err) => {
        dispatch(updateValueAction(formName, "tax_info_id", null));
        dispatch(showErrorToast(err?.[0]?.message ?? "something went wrong"));
      },
    });

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    return () => {
      dispatch(onFormResetAction(formName));
    };
  }, []);

  React.useEffect(() => {
    if (!!regulation_type_id && data?.countryId) {
      attemptGetRelevantTaxInfo({
        variables: {
          input: {
            regulation_type_id: regulation_type_id,
            // state_id: null,
            date: moment().format("YYYY-MM"),
            country_id: data?.countryId,
          },
        },
      });
    }
  }, [regulation_type_id, data?.countryId]);

  // Constants
  const FormProps = {
    formName,
    formNameValidation,
    formSubmitting,
  };

  /* ↓ Helpers ↓ */

  const handleInterceptPaymnetIntervalChange = (e) => {
    if (
      e.target.name === "calculate_salary_by" &&
      e.target.value !== "payment_interval"
    ) {
      dispatch(setSalaryCalculationToFixed());
    }
  };

  const totalNet = () => {
    let base = +base_salary;
    let gross = +totalGross();

    return `${salary_deductibles
      ?.map((deductible) =>
        deductible.payment_factor_id == PaymentFactors.FIXED_AMOUNT ||
        deductible.payment_factor_id ==
          PaymentFactors.PAYMENT_FACTOR_ACCORDING_TO_CUSTOM_EQUATION ||
        deductible.payment_factor_id == PaymentFactors.PERCENT_OF_INSURED_SALARY
          ? +deductible.value
          : deductible.payment_factor_id == PaymentFactors.BASE_PERCENTAGE
          ? +(+deductible.value * base) / 100
          : deductible.payment_factor_id == PaymentFactors.GROSS_PERCENTAGE
          ? +(+deductible.value * gross) / 100
          : 0
      )
      .reduce(
        (acc, curr) => acc - curr,
        gross - +socialDeduction - +medicalDeduction - +taxDeduction
      )
      .toFixed(2)}`;
  };

  const handleAddAllowanceInput = () => {
    dispatch(addAllowanceInputAction());
  };

  const handleAddDeductibleInput = () => {
    dispatch(addDeductibleInputAction());
  };

  const totalGross = () => {
    let base = +base_salary;
    return `${salary_allowances
      ?.map((allowance) =>
        allowance.payment_factor_id == PaymentFactors.FIXED_AMOUNT
          ? +allowance.value
          : allowance.payment_factor_id == PaymentFactors.BASE_PERCENTAGE
          ? +(+allowance.value * base) / 100
          : 0
      )
      .reduce((acc, curr) => acc + curr, base)
      .toFixed(2)}`;
  };

  const isInvalidClient = () => {
    if (formValidations.length) return true;

    return false;
  };

  const handleUpsert = () => {
    setFormSubmitting(true);
    if (isInvalidClient()) return;

    const {
      bonusConfiguration,
      unpaidConfiguration,
      penaltyConfiguration,
      overtimeConfiguration,
      ...salaryConfigForm
    } = Store.getState().super.salaryConfigForm;
    const details = {
      type:
        salaryConfigForm?.calculate_salary_by === "payment_interval"
          ? "Fulltime"
          : salaryConfigForm?.calculate_salary_by === "daily"
          ? "Day"
          : "Hour",

      typeConfiguration:
        salaryConfigForm?.calculate_salary_by === "payment_interval"
          ? {
              salary: salaryConfigForm.base_salary,
              grossSalary: totalGross(),
              netSalary: totalNet(),
              overtimeConfiguration: {
                paymentFactor:
                  overtimeConfiguration?.paymentFactor ==
                  PaymentFactors.FIXED_AMOUNT
                    ? "Fixed"
                    : overtimeConfiguration?.paymentFactor ==
                      PaymentFactors.BASE_PERCENTAGE
                    ? "Percent of base salary"
                    : overtimeConfiguration?.paymentFactor ==
                      PaymentFactors.GROSS_PERCENTAGE
                    ? "Percent of gross salary"
                    : overtimeConfiguration?.paymentFactor ==
                      PaymentFactors.NET_PERCENTAGE
                    ? "Percent of net salary"
                    : null,
                value: overtimeConfiguration?.amount,
                // if overtime settings array has more than one then it has a factor for each one so we should not send the parent factor
                factor: !overtimeConfiguration?.advancedOvertimeSettings?.length // there's no advanced overtime settings
                  ? overtimeConfiguration?.factor
                  : null,

                periods: overtimeConfiguration?.advancedOvertimeSettings?.length // there's advanced overtime settings
                  ? overtimeConfiguration?.advancedOvertimeSettings?.map(
                      (overtimeSetting, index) => ({
                        from:
                          index === 0
                            ? overtimeSetting?.from
                            : overtimeConfiguration?.advancedOvertimeSettings?.[
                                index - 1
                              ]?.to,
                        to: overtimeSetting?.to,
                        factor: overtimeSetting?.factor,
                      })
                    )
                  : [],
              },
            }
          : salaryConfigForm?.calculate_salary_by === "daily"
          ? {
              rpd: salaryConfigForm?.rate_per_day?.toString(), // rate per day
              overtimeConfiguration: {
                paymentFactor:
                  overtimeConfiguration?.paymentFactor ==
                  PaymentFactors.FIXED_AMOUNT
                    ? "Fixed"
                    : overtimeConfiguration?.paymentFactor ==
                      PaymentFactors.BASE_PERCENTAGE
                    ? "Percent of base salary"
                    : overtimeConfiguration?.paymentFactor ==
                      PaymentFactors.GROSS_PERCENTAGE
                    ? "Percent of gross salary"
                    : overtimeConfiguration?.paymentFactor ==
                      PaymentFactors.NET_PERCENTAGE
                    ? "Percent of net salary"
                    : null,
                value: overtimeConfiguration?.amount,
                // if overtime settings array has more than one then it has a factor for each one so we should not send the parent factor
                factor: !overtimeConfiguration?.advancedOvertimeSettings?.length // there's no advanced overtime settings
                  ? overtimeConfiguration?.factor
                  : null,

                periods: overtimeConfiguration?.advancedOvertimeSettings?.length // there's advanced overtime settings
                  ? overtimeConfiguration?.advancedOvertimeSettings?.map(
                      (overtimeSetting, index) => ({
                        from:
                          index === 0
                            ? overtimeSetting?.from
                            : overtimeConfiguration?.advancedOvertimeSettings?.[
                                index - 1
                              ]?.to,
                        to: overtimeSetting?.to,
                        factor: overtimeSetting?.factor,
                      })
                    )
                  : [],
              },
            }
          : {
              allHours: salaryConfigForm?.calculate_all_work_hours,

              hppl: salaryConfigForm?.hours_per_paid_leave?.toString(), //Hours per paid leave

              rpph: salaryConfigForm?.paid_leave_hourly_rate?.toString(), //Rate per paid hour

              overtimeConfiguration: {
                paymentFactor: null, // null -> because when hourly, user only enters a factor
                value: null, // null -> because when hourly, user only enters a factor
                // if overtime settings array has more than one then it has a factor for each one so we should not send the parent factor
                factor: !overtimeConfiguration?.advancedOvertimeSettings?.length // there's no advanced overtime settings
                  ? overtimeConfiguration?.factor
                  : null,

                periods: overtimeConfiguration?.advancedOvertimeSettings?.length // there's advanced overtime settings
                  ? overtimeConfiguration?.advancedOvertimeSettings?.map(
                      (overtimeSetting, index) => ({
                        from:
                          index === 0
                            ? overtimeSetting?.from
                            : overtimeConfiguration?.advancedOvertimeSettings?.[
                                index - 1
                              ]?.to,
                        to: overtimeSetting?.to,
                        factor: overtimeSetting?.factor,
                      })
                    )
                  : [],
              },
              periods: serializeSubmitHourlyRating(salaryConfigForm), // salary configuration periods in case of hourly
            },

      penaltyConfiguration: {
        paymentFactor:
          penaltyConfiguration?.paymentFactor == PaymentFactors.FIXED_AMOUNT
            ? "Fixed"
            : penaltyConfiguration?.paymentFactor ==
              PaymentFactors.BASE_PERCENTAGE
            ? "Percent of base salary"
            : penaltyConfiguration?.paymentFactor ==
              PaymentFactors.GROSS_PERCENTAGE
            ? "Percent of gross salary"
            : penaltyConfiguration?.paymentFactor ==
              PaymentFactors.NET_PERCENTAGE
            ? "Percent of net salary"
            : null,

        // if it's custom days send the number of days in the same key (problem with editing)
        monthDays:
          penaltyConfiguration?.paymentFactor == PaymentFactors.FIXED_AMOUNT
            ? null
            : penaltyConfiguration?.monthOption == "1" // send null in case of calendar month
            ? null
            : penaltyConfiguration?.monthOption == "5"
            ? penaltyConfiguration?.customDays
            : monthOptionDays[+penaltyConfiguration?.monthOption],
        value:
          penaltyConfiguration?.paymentFactor == PaymentFactors.FIXED_AMOUNT
            ? penaltyConfiguration?.amount
            : null,

        oneHour: salaryConfigForm?.one_hour_penalty,
      },

      unpaidConfiguration: {
        paymentFactor:
          unpaidConfiguration?.paymentFactor == PaymentFactors.FIXED_AMOUNT
            ? "Fixed"
            : unpaidConfiguration?.paymentFactor ==
              PaymentFactors.BASE_PERCENTAGE
            ? "Percent of base salary"
            : unpaidConfiguration?.paymentFactor ==
              PaymentFactors.GROSS_PERCENTAGE
            ? "Percent of gross salary"
            : unpaidConfiguration?.paymentFactor ==
              PaymentFactors.NET_PERCENTAGE
            ? "Percent of net salary"
            : null,
        monthDays:
          unpaidConfiguration?.paymentFactor == PaymentFactors.FIXED_AMOUNT
            ? null
            : unpaidConfiguration?.monthOption == "1" // send null in case of calendar month
            ? null
            : unpaidConfiguration?.monthOption == "5"
            ? unpaidConfiguration?.customDays
            : monthOptionDays[+unpaidConfiguration?.monthOption],

        value:
          unpaidConfiguration?.paymentFactor == PaymentFactors.FIXED_AMOUNT
            ? unpaidConfiguration?.amount
            : null,
      },

      bonusConfiguration: {
        paymentFactor:
          bonusConfiguration?.paymentFactor == PaymentFactors.FIXED_AMOUNT
            ? "Fixed"
            : bonusConfiguration?.paymentFactor ==
              PaymentFactors.BASE_PERCENTAGE
            ? "Percent of base salary"
            : bonusConfiguration?.paymentFactor ==
              PaymentFactors.GROSS_PERCENTAGE
            ? "Percent of gross salary"
            : bonusConfiguration?.paymentFactor == PaymentFactors.NET_PERCENTAGE
            ? "Percent of net salary"
            : null,
        monthDays:
          bonusConfiguration?.paymentFactor == PaymentFactors.FIXED_AMOUNT
            ? null
            : bonusConfiguration?.monthOption == "1" // send null in case of calendar month
            ? null
            : bonusConfiguration?.monthOption == "5"
            ? bonusConfiguration?.customDays
            : monthOptionDays[+bonusConfiguration?.monthOption],
        value:
          bonusConfiguration?.paymentFactor == PaymentFactors.FIXED_AMOUNT
            ? bonusConfiguration?.amount
            : null,
      },

      allowanceConfiguration:
        salaryConfigForm?.salary_allowances?.map((allowance) => ({
          id: allowance?.allowance_id,
          paymentFactor:
            allowance?.payment_factor_id == PaymentFactors.FIXED_AMOUNT
              ? "Fixed"
              : "Percent of base salary",
          value: allowance?.value,
          payableOn: [
            ...(allowance?.not_payable_on_absent_days ? [] : ["ABSENT"]),
            ...(allowance?.not_payable_on_paid_days ? [] : ["UNPAID"]),
            ...(allowance?.not_payable_on_un_paid_days ? [] : ["PAID"]),
          ],
          monthDays: null, // static null and should be removed
        })) ?? [],

      deductionConfiguration:
        salaryConfigForm?.salary_deductibles?.map((deductible) => ({
          id: deductible?.deductible_id,
          paymentFactor:
            deductible?.payment_factor_id == PaymentFactors.FIXED_AMOUNT
              ? "Fixed"
              : deductible?.payment_factor_id == PaymentFactors.BASE_PERCENTAGE
              ? "Percent of base salary"
              : "Percent of gross salary",
          value: deductible?.value,
          monthDays: null, // static null and should be removed
        })) ?? [],

      socialDeduction:
        salaryConfigForm?.regulation_type_id == RegulationTypes.MAWARED_CUSTOM // only send the value if custom because user enters a value otherwise backend will calculate it's value
          ? salaryConfigForm?.socialDeduction
          : null,

      medicalDeduction: salaryConfigForm?.medicalDeduction,

      taxDeduction:
        salaryConfigForm?.regulation_type_id == RegulationTypes.MAWARED_CUSTOM // only send the value if custom because user enters a value otherwise backend will calculate it's value
          ? salaryConfigForm?.taxDeduction
          : null,

      currencyId: +salaryConfigForm.currency_id,

      temp: +salaryConfigForm?.temporary_employee,

      taxRegulation: {
        typeId: +salaryConfigForm?.regulation_type_id,

        taxInfo: +salaryConfigForm?.tax_info_id,
      },
    };

    const { min_overtime_cutoff, max_overtime_limit, min_work_hours, ...rest } =
      timeRangeProfileSetting;
    let timeRangeProfileSettingInput = {
      ...rest,
      min_overtime_cutoff: HelperFns.hoursToTime(min_overtime_cutoff),
      max_overtime_limit: HelperFns.hoursToTime(max_overtime_limit),
      min_work_hours: HelperFns.hoursToTime(min_work_hours),
    };

    upsert({
      variables: {
        input: {
          preset_id: data?.id || null,
          name: salaryConfigForm?.name,
          type: "Internal",
          payment:
            salaryConfigForm?.payment_interval == "weekly"
              ? "Weekly"
              : "Monthly",

          fixedSettings: timeRangeProfileSettingInput,

          details: {
            fulltimeType: details?.type === "Fulltime" ? details : null,
            dayType: details?.type === "Day" ? details : null,
            hourType: details?.type === "Hour" ? details : null,
          },
        },
      },
      onCompleted: () => {
        refetchList();
        handleCloseModal();
        showToast("success");
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message
        );
      },
    });
  };

  const handleCloseModal = () => {
    onClose();
  };

  return (
    <MainModal
      isOpen
      size="lg"
      toggle={handleCloseModal}
      btnOnClick={handleUpsert}
      btnSubmitLoading={upsertLoading}
      modalTitle={`${data?.isEdit ? "Edit" : "New"} Preset`}
    >
      {loading ? <Loader fixed /> : null}

      <InputForm
        name="name"
        label={"name"}
        {...FormProps}
        placeholder={t("name")}
        labelStyle="my-2 font-weight-bold"
        containerStyle=""
        inputContainerStyle="w-100"
        validateBy="textRequired"
      />
      <div className="row align-items-center">
        <div className="col-12 col-lg-6">
          <BSelect
            {...FormProps}
            name="regulation_type_id"
            label="Regulation"
            labelStyle="my-2 font-weight-bold"
            optionLabel="name"
            optionValue="id"
            options={userRegulationTypesOptions || []}
            keepDefaultStyle
            containerStyle=""
            isClearable
            preSelectValue={
              userRegulationTypesOptions?.find((reg) => reg?.isDefault)?.id
            }
            placeholder={t("select Regulation Type")}
            isDisabled={payment_interval === "weekly"}
          />
        </div>

        <div className="col-12 col-lg-6">
          <BSelect
            {...FormProps}
            label="Currency"
            labelStyle="my-2 font-weight-bold"
            name="currency_id"
            optionLabel="name"
            optionValue="id"
            options={userCurrenciesOptions ?? []}
            keepDefaultStyle
            containerStyle=""
            placeholder={t("select currency")}
            validateBy="textRequired"
            icon="currency"
          />
        </div>
      </div>

      <RadioboxForm
        {...FormProps}
        label={t("payment interval")}
        containerStyle="flex-column justify-content-start"
        labelStyle="my-2 font-weight-bold"
        optionsContainerStyle="gap-60 mx-0 row my-1"
        optionItemStyle="d-flex align-items-center"
        optionLabelStyle="mb-0 mr-3"
        optionInputStyle=" "
        validateBy="textRequired"
        options={[
          { label: "Month", value: "monthly" },
          { label: "Week", value: "weekly" },
        ]}
        name="payment_interval"
        interceptChange={handleInterceptPaymnetIntervalChange}
        validationName="args.payment"
      />

      <RadioboxForm
        {...FormProps}
        label="Calculate Salary by"
        containerStyle="flex-column justify-content-start"
        labelStyle="my-2 font-weight-bold"
        optionsContainerStyle="gap-60 mx-0 row my-1"
        optionItemStyle="d-flex align-items-center"
        optionLabelStyle="mb-0 mr-3"
        optionInputStyle=" "
        validateBy="textRequired"
        options={[
          {
            label:
              payment_interval?.toLowerCase() == "monthly" ? "month" : "week",
            value: "payment_interval",
          },
          {
            label: "day",
            value: "daily",
          },
          {
            label: "hour",
            value: "hourly",
          },
        ]}
        name="calculate_salary_by"
        interceptChange={handleInterceptPaymnetIntervalChange}
      />

      <div className="row my-2 align-items-center">
        <div className="col-12 col-lg-8 mx-0">
          <InputForm
            name="base_salary"
            type="number"
            label={"Base Salary"}
            onWheelCapture={(e) => {
              e.target.blur();
            }}
            {...FormProps}
            placeholder={t("base_salary")}
            labelStyle="my-2 font-weight-bold"
            containerStyle=""
            inputContainerStyle="w-100"
            validateBy="textRequired"
            // validationName={`input.user_salary_config_input.salary_detail.base_salary`}
            icon="money"
            dependOn="calculate_salary_by"
            dependancyType="equal"
            dependancyValue={["payment_interval"]}
          />
          <InputForm
            name="rate_per_day"
            type="text"
            label={`rate per day`}
            onWheelCapture={(e) => {
              e.target.blur();
            }}
            {...FormProps}
            placeholder={t("rate per day")}
            labelStyle="my-2 font-weight-bold"
            containerStyle=""
            inputContainerStyle="w-100"
            validateBy="textRequired"
            // validationName={`input.user_salary_config_input.salary_detail.ratePerDay`}
            icon="money"
            dependOn="calculate_salary_by"
            dependancyType="equal"
            dependancyValue={["daily"]}
            isNumberInput
          />
        </div>
        <div className="col-12 mx-0">
          {calculate_salary_by == "hourly" ? (
            <HourlySectionDetails FormProps={FormProps} />
          ) : null}
        </div>
      </div>

      {/* Start of Allowances section */}

      <div className="d-flex align-items-baseline mt-3">
        <div className="mr-3 mb-2">
          <h4 className="blue-color font-weight-bold fa-1x">
            {t("allowances")}
          </h4>
        </div>
        <AddButton isIconOnly onClick={handleAddAllowanceInput} />
      </div>

      {salary_allowances?.map((allowance, i) => (
        <AllowanceSection
          key={`${i}`}
          allowance={allowance}
          index={i}
          {...FormProps}
        />
      ))}
      {/* End of Allowances section */}

      {/* Start of deductibles section */}
      <div className="mt-3">
        <div className="row align-items-baseline mb-3">
          <div className="mb-2 mx-3">
            <h4 className="blue-color font-weight-bold fa-1x">
              {t("deductibles")}
            </h4>
          </div>
          <AddButton isIconOnly onClick={handleAddDeductibleInput} />
        </div>
      </div>

      <div className="d-flex flex-wrap gap-10">
        <SocialSection
          {...FormProps}
          type={regulation_type_id}
          relevantTaxInfo={relevantTaxInfoData?.relevantTaxInfo}
          totalGross={totalGross()}
        />

        <MedicalSection {...FormProps} />

        <TaxSection
          date={moment().format("YYYY-MM")}
          {...FormProps}
          type={regulation_type_id}
          taxInfoId={tax_info_id}
          totalGross={totalGross()}
        />

        {salary_deductibles
          ?.sort((a, b) => b?.id - a?.id)
          ?.map((deductible, i) => (
            <DeuductableSection
              key={`${i}`}
              {...FormProps}
              deductible={deductible}
              index={i}
            />
          ))}
      </div>

      {/* End of deductibles section */}

      {/* Start of calculations section */}
      <div className="mt-3">
        <div className="mb-2">
          <h4 className="blue-color font-weight-bold fa-1x">
            {t("calculations")}
          </h4>
        </div>
      </div>

      <div className="d-flex flex-column gap-10">
        <PenaltyCalculation
          totalNet={totalNet}
          totalGross={totalGross}
          FormProps={FormProps}
        />

        <BonusCalculation
          totalNet={totalNet}
          totalGross={totalGross}
          FormProps={FormProps}
        />

        <UnpaidCalculation
          totalNet={totalNet}
          totalGross={totalGross}
          FormProps={FormProps}
        />

        <OvertimeCalculation
          totalNet={totalNet}
          totalGross={totalGross}
          FormProps={FormProps}
        />
      </div>

      <OvertTimeRangeSetting formServerValidation={formServerValidation} />
    </MainModal>
  );
};

export default UpsertSalaryConfigPresetsModal;

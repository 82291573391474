import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery } from "@apollo/client";

import {
  pricingOptions,
  paymentTypeOptions,
  billingPeriodOptions,
} from "../../Constants/PaymentTracking";
import {
  storePlanMutation,
  changePlanMutation,
} from "../../Graphql/mutation/PaymentTracking";
import {
  serializeUpsertPlan,
  serializeFetchPlan,
} from "../../Helpers/HelperFns/PaymentTracking";
import clsx from "clsx";
import { showToast } from "../../Helpers/HelperFns";
import { onFormResetAction } from "../../Store/Actions";
import { planFormQuery } from "../../Graphql/query/PaymentTracking";

import Loader from "../Loader";
import MainModal from "../MainModal";
import { AddButton } from "../Buttons";
import { RemoveIconButton } from "../IconButtonWithTooltip";
import { InputForm, BSelect, RadioboxForm } from "form-builder";

const reducer = "paymentTracking";
const formName = "planUpsertModal";
const formNameValidation = "paymentTrackingClientValidation";
const formServerValidation = "paymentTrackingServerValidation";
const tierInitState = { name: "", from: "-", to: "", price: "" };
const UNLIMITED = "Unlimited";

const PlanUpsertModal = ({ data, onClose, refetchList }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [errMsg, setErrMsg] = React.useState("");
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [tiers, setTiers] = React.useState([
    { name: "", from: 1, to: UNLIMITED, price: "" },
  ]);

  // Constants
  const isEdit = data?.id && !data?.isClone;
  const isClone = data?.id && data?.isClone;
  const FormProps = {
    reducer,
    formName,
    formSubmitting,
    formNameValidation,
    formServerValidation,
  };

  // Reducer State
  const formData = useSelector((state) => state?.[reducer]?.[formName]);
  const formClientValidation = useSelector(
    (state) => state?.[reducer]?.[formNameValidation]
  );
  const options = useSelector(
    (state) => state?.[reducer]?.paymentTrackingOptions
  );

  // Server State
  const [store, { loading: storeLoading }] = useMutation(storePlanMutation);
  const [change, { loading: changeLoading }] = useMutation(changePlanMutation);
  const { loading } = useQuery(planFormQuery, {
    skip: !isEdit && !isClone,
    variables: { includePlan: isEdit || isClone, id: +data?.id },
    onCompleted: ({ plan }) => {
      const { tiers, ...formattedPlan } = serializeFetchPlan(plan);
      setTiers(tiers);
      dispatch(
        onFormResetAction(formName, {
          ...formattedPlan,
          package: formattedPlan?.package?.id,
          currency: formattedPlan?.currency?.id,
        })
      );
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });

  /* ↓ State Effects ↓ */

  /* ↓ Helpers ↓ */

  const handleAddTier = () => {
    setTiers((prev) =>
      prev
        .map((p, i) =>
          i === prev.length - 1 ? [tierInitState, { ...p, from: "-" }] : p
        )
        .flat()
    );
  };

  const handleRemoveTier = (idx) => {
    let arr = [...tiers];

    // Remove tier
    arr = arr.filter((_, i) => i !== idx);

    // Set last tier to value
    let prevTo;
    arr = arr.map((a, i) => {
      if (arr.length - 2 === i) prevTo = a?.to;
      return arr.length - 1 === i
        ? { ...a, to: UNLIMITED, from: prevTo ? prevTo + 1 : 1 }
        : a;
    });

    setTiers(arr);
  };

  const handleTiersChange = (e, idx) => {
    const val = +e.target.value;

    if (e.target.name === "to") {
      setTiers((prev) => {
        if (isNaN(val)) {
          return prev;
        } else {
          let to;
          return prev?.map((p, i) => {
            if (i === idx - 1) to = +p?.to;
            if (i === idx) return { ...p, to: val, from: to ? to + 1 : 1 };
            if (i === idx + 1) return { ...p, from: val ? val + 1 : "-" };
            else return p;
          });
        }
      });
    } else if (e.target.name === "name") {
      setTiers((prev) =>
        prev?.map((p, i) => (i === idx ? { ...p, name: e.target.value } : p))
      );
    } else {
      setTiers((prev) =>
        isNaN(val)
          ? prev
          : prev.map((p, i) => (i === idx ? { ...p, [e.target.name]: val } : p))
      );
    }
  };

  const handleCloseModal = () => {
    onClose();
    dispatch(onFormResetAction(formName));
    dispatch(onFormResetAction(formNameValidation));
    dispatch(onFormResetAction(formServerValidation));
  };

  const isInvalidClientValidation = () => {
    if (formClientValidation.length) return true;

    // tiers validation
    if (tiers.length === 1) {
      if (!tiers[0].price) {
        setErrMsg("You should enter all tiers section inputs");
        return true;
      }
    } else {
      return tiers.reduce((acc, curr) => {
        if (!curr.from || !curr.to || !curr.price) {
          setErrMsg("You should enter all tiers section inputs");
          return true;
        } else {
          if (curr.to === UNLIMITED) return acc;
          switch (true) {
            case +curr.from === +curr.to:
            case curr.from > curr.to:
              setErrMsg("Set proper to/from values");
              return true;

            default:
              setErrMsg("");
              return acc;
          }
        }
      }, false);
    }

    return false;
  };

  const handleSubmit = () => {
    setFormSubmitting(true);
    if (isInvalidClientValidation()) return;

    const upsert = isEdit ? change : store;
    upsert({
      variables: serializeUpsertPlan({ tiers, id: data?.id, ...formData }),
      onCompleted: () => {
        refetchList();
        handleCloseModal();
        showToast("success");
      },
      onError: (err) => {
        const validation = err?.graphQLErrors?.[0]?.extensions?.validation;
        dispatch(onFormResetAction(formServerValidation, validation || {}));

        const msg =
          validation?.json?.[0] ||
          err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message;
        setErrMsg(msg || "");
      },
    });
  };

  return (
    <MainModal
      isOpen
      toggle={handleCloseModal}
      btnOnClick={handleSubmit}
      btnSubmitLoading={storeLoading || changeLoading}
      modalTitle={`${isEdit ? "Edit" : isClone ? "Clone" : "New"} Plan`}
    >
      {(isEdit || isClone) && loading ? <Loader fixed /> : null}

      <InputForm
        {...FormProps}
        name="name"
        label="name"
        icon="document"
        rootStyle="w-100"
        containerStyle="w-100"
        validateBy="textRequired"
        validationName="name"
      />
      <BSelect
        {...FormProps}
        isMulti
        label="companies"
        name="companyIds"
        icon="money"
        rootStyle="flex-1"
        options={options.companies}
        isLoading={!options.companies.length}
        validationName="companyIds"
      />
      <BSelect
        {...FormProps}
        label="currency"
        name="currency"
        icon="money"
        rootStyle="flex-1"
        options={options.currencies}
        isLoading={!options.currencies.length}
        validateBy="textRequired"
        validationName="currency_id"
      />
      <BSelect
        {...FormProps}
        label="package"
        name="package"
        icon="document"
        rootStyle="flex-1"
        options={options.packages}
        isLoading={!options.packages?.length}
        validateBy="textRequired"
        validationName="package_id"
      />
      <BSelect
        {...FormProps}
        label="Billing period"
        name="billingPeriod"
        icon="calendar"
        rootStyle="flex-1"
        options={billingPeriodOptions}
        validateBy="textRequired"
        validationName="period"
      />
      <InputForm
        {...FormProps}
        name="contractDuration"
        label="Contract Duration"
        labelStyle="mb-2 pt-1"
        containerStyle="w-100"
        rootStyle="flex-1"
        placeholder="No. Of Billing Cycles"
        validateBy="textRequired"
        validationName="duration"
        icon="time"
      />
      <InputForm
        {...FormProps}
        type="number"
        name="suspend_after"
        label="suspense after days"
        labelStyle="mb-2 pt-1"
        containerStyle="w-100"
        rootStyle="flex-1"
        placeholder="suspense after days"
        validateBy="textRequired"
        validationName="duration"
        icon="time"
      />
      <RadioboxForm
        {...FormProps}
        name="paymentType"
        label="Payment Type"
        labelStyle="font-weight-bold mb-2"
        options={paymentTypeOptions.map((p) =>
          p.label === "Ramp" ? { ...p, optProps: { disabled: true } } : p
        )}
        optionItemStyle=" "
        optionInputStyle=" "
        containerStyle="my-2"
        optionsContainerStyle="d-flex gap-20"
        validationName="type"
      />
      <RadioboxForm
        {...FormProps}
        label="Pricing"
        name="pricing"
        labelStyle="font-weight-bold mb-2"
        options={pricingOptions.map((p) =>
          p.label === "Stairstep" ? p : { ...p, optProps: { disabled: true } }
        )}
        optionInputStyle=" "
        optionItemStyle=" "
        optionsContainerStyle="d-flex gap-20"
        dependOn="paymentType"
        dependancyType="equal"
        dependancyValue={["Fixed"]}
        validationName="pricing"
      />

      {/* Tiers */}
      {formData.pricing === "Stairstep" ? (
        <>
          <div className="d-flex align-items-center gap-10 mt-3 mb-2">
            <b className="blue-color">{t("Tiers")}</b>
            <AddButton isIconOnly onClick={handleAddTier} />
          </div>
          <div className="d-flex mb-2">
            <b className="flex-1">{t("Employees")}</b>
            <b style={{ width: "20%" }}>{t("Price")}</b>
            <b style={{ width: "21%" }}>{t("Name")}</b>
          </div>
          {tiers.map(({ from, to, price, name }, i) => (
            <div
              key={i}
              style={{ borderRadius: 4 }}
              className={clsx("d-flex gap-20 border p-2", {
                "mb-2": tiers.length - 1 !== i,
              })}
            >
              <div className="d-flex gap-5 align-items-center flex-1">
                <span
                  style={{ width: 80, display: "flex", paddingInlineStart: 4 }}
                >
                  {from || "--"} <span className="d-block mx-2" /> to
                </span>
                <InputForm
                  name="to"
                  value={to}
                  disabled={tiers.length - 1 === i}
                  onChange={(e) => handleTiersChange(e, i)}
                  rootStyle="w-100"
                  containerStyle="w-100"
                />
              </div>
              <div className="d-flex gap-10" style={{ width: "40%" }}>
                <InputForm
                  name="price"
                  value={price}
                  onChange={(e) => handleTiersChange(e, i)}
                  rootStyle="flex-1"
                  containerStyle="w-100"
                />
                <InputForm
                  name="name"
                  value={name}
                  onChange={(e) => handleTiersChange(e, i)}
                  rootStyle="flex-1"
                  containerStyle="w-100"
                />
                <RemoveIconButton
                  disabled={!i}
                  sx={{ opacity: i ? undefined : "0 !important" }}
                  onClick={() => handleRemoveTier(i)}
                />
              </div>
            </div>
          ))}
        </>
      ) : null}

      {errMsg ? (
        <p role="alert" className="red-color mt-2 mb-0">
          {t(errMsg)}
        </p>
      ) : null}
    </MainModal>
  );
};

export default PlanUpsertModal;

import React from "react";
import { useMutation } from "@apollo/client";

import { showToast } from "../../Helpers/HelperFns";
import { upsertPaymentCommentMutation } from "../../Graphql/mutation/PaymentTracking";

import Loader from "../Loader";
import MainModal from "../MainModal";
import { InputForm } from "form-builder";
import SendIcon from "@mui/icons-material/Send";
import IconButtonWithTooltip from "../IconButtonWithTooltip";

const UpsertPaymentCommentModal = ({ data, onClose, refetchList }) => {
  // Local State
  const [errMsg, setErrMsg] = React.useState("");
  const [comment, setComment] = React.useState("");
  const [comments, setComments] = React.useState(data?.comments || []);

  // Server State
  const [upsertPaymentComment, { loading: upsertPaymentCommentLoading }] =
    useMutation(upsertPaymentCommentMutation);

  /* ↓ Helpers ↓ */

  const handleSubmit = () => {
    setErrMsg("");
    if (comment) {
      upsertPaymentComment({
        variables: {
          id: data?.id,
          comment,
        },
        onCompleted: ({ payment_tracking_comment }) => {
          refetchList();
          setComment("");
          showToast("success");
          setComments((prev) => [...prev, payment_tracking_comment]);
        },
        onError: (err) => {
          const validation = err?.graphQLErrors?.[0]?.extensions?.validation;
          if (validation) {
            const msg =
              validation?.json?.[0] ||
              err?.graphQLErrors?.[0]?.extensions?.reason ||
              err?.graphQLErrors?.[0]?.message ||
              err?.message;
            setErrMsg(msg || "");
          } else {
            showToast(
              "error",
              err?.graphQLErrors?.[0]?.message || err?.message
            );
          }
        },
      });
    } else {
      setErrMsg("This Field Is Required.");
    }
  };

  return (
    <MainModal
      isOpen
      modalTitle="Comments"
      toggle={onClose}
      hasModalFooter={false}
    >
      {upsertPaymentCommentLoading ? <Loader fixed /> : null}

      {comments?.length ? (
        comments.map(({ body, user, created_at = "" }, i) => (
          <div key={i}>
            <div className="d-flex justify-content-between">
              <strong>{user?.name}</strong>
              {/* <time dateTime={created_at}>{created_at}</time> */}
            </div>
            <p>{body}</p>
          </div>
        ))
      ) : (
        <p>no comments added yet</p>
      )}

      <div className="d-flex align-items-center gap-20">
        <InputForm
          value={comment}
          rootStyle="flex-fill"
          inputContainerStyle="w-100"
          onChange={(e) => setComment(e.target.value)}
        />
        <IconButtonWithTooltip
          label="add comment"
          onClick={handleSubmit}
          icon={<SendIcon sx={{ color: "#23aaeb" }} />}
        />
      </div>

      {errMsg ? (
        <p role="alert" className="red-color mb-0">
          {errMsg}
        </p>
      ) : null}
    </MainModal>
  );
};

export default UpsertPaymentCommentModal;

import React, { useState, useEffect, forwardRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PayrollWidget from "../../Components/PayrollWidget";
import Constants from "../../Helpers/Constants";
import { BSelect, RadioboxForm, CheckboxBooleanForm } from "form-builder";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faExchangeAlt,
  faFileInvoiceDollar,
  faFileExport,
  faInfo,
  faExclamationCircle,
  faAngleUp,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
// import ExportModal from "../../Components/ExportModal";
import PayslipModal from "../../Components/PayslipModal";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import CanViewEmployeeProfile from "../../Helpers/HOC/CanViewEmployeeProfile";
import Privilages from "../../Constants/Privilages";

// import ExportPayrollModal from "./ExportPayrollModal";
import { Link, useLocation } from "react-router-dom";
import AntSwitch from "../../Components/AntSwitch";
import moment from "moment";
import {
  GET_PAYROLL_DETAILS,
  GET_PAYROLL_FILTERS_DATA,
  GET_USER_RELATIVE_SALARY_CONFIGURATION,
  allowancesDetailsQuery,
  bonusDetailsQuery,
  fetchExportPayrollOptsQuery,
  otherAddedDetailsQuery,
  otherDeductablesDetailsQuery,
  overtimeDetailsQuery,
  penaltyDetailsQuery,
  userPayslipQuery,
  weeklyPayrollQuery,
} from "../../Graphql/query";
import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import swal from "sweetalert";
import { InfoIconButton } from "../../Components/IconButtonWithTooltip";
import { GET_WEEK_OPTIONS } from "../../Graphql/query";
import { SUBMIT_PAYROLLS } from "../../Graphql/mutation";
// import ViewWeeklyPayrollDetails from "./ViewWeeklyPayrollDetails";
import {
  fetchExportPayrollOptionsSuccess,
  fetchUserPaySlipAction,
  onFormResetAction,
  onInputResetWithValueAction,
  showEditAmountModalAction,
  showPenaltyModal,
} from "../../Store/Actions";
// import EditWeeklyDeductiblesModal from "./EditWeeklyDeductiblesModal";
import { Tooltip } from "@mui/material";
import { showErrorToast } from "../../Store/Actions";
import ViewWeeklyPayrollDetails from "../../Containers/Payroll/ViewWeeklyPayrollDetails";
import EditPenaltyModal from "../../Components/EditPenaltyModal";

const normalizeChildrenPayroll = (parentPayroll, childPayroll) => ({
  user: {
    id: parentPayroll?.user?.id,
    user_type: parentPayroll?.user?.user_type,
  },
  from: childPayroll?.FROM,
  paid_at: parentPayroll?.paid_at,
  base_salary: childPayroll?.SALARY,
  allowances_amount: childPayroll?.ALLOWANCE,
  bonuses_amount: childPayroll?.BONUS,
  overtime_amount: childPayroll?.OVERTIME,
  extra_others: childPayroll?.OTHERS?.EXTRA,
  penalties_amount: childPayroll?.PENALTY,
  social_amount: childPayroll?.SOCIAL,
  medical_amount: childPayroll?.MEDICAL,
  taxes_amount: childPayroll?.TAX,
  deductible_others: childPayroll?.OTHERS?.DEDUCTION,
  payable_amount: childPayroll?.TOTAL,
  status: childPayroll?.STATUS,
  config_type: childPayroll?.MANAGEMENT_TYPE,
  currency: parentPayroll?.currency ?? {},
  is_commission: childPayroll?.IS_COMMISSION || 0,
});

const payrollManagementTypes = {
  EXTERNAL: "External",
  INTERNAL: "Internal",
};

const externalTableStyle = {
  backgroundColor: "#fff !important",
  // borderRightColor: "#fff !important",
  borderLeftColor: "#fff !important",
};

const extraTableStyle = {
  backgroundColor: "rgba(220, 220, 220, 0.84)",
};

const editAmountModalInitialState = {
  isOpen: false,
  title: null,
  userId: null,
  paidAt: null,
  from: null,
  deductibleType: null,
  oldAmount: null,
  currency: null,
};

const dataTableRef = "payrollList";

const MyPayrollView = (props) => {
  const dispatch = useDispatch();

  const [getExportPayrollOptions, { loading: exportPayrollOptionsLoading }] =
    useLazyQuery(fetchExportPayrollOptsQuery, {
      onCompleted: (data) => {
        dispatch(fetchExportPayrollOptionsSuccess(data));
      },
    });

  const [payrollsToSubmit, setPayrollsToSubmit] = useState([]);

  const [isExpected, setIsExpected] = useState(false);
  const [isExternal, setIsExternal] = useState(false);

  const handleToggleShowExternal = (_, value) => {
    setIsExternal(value);
  };

  const authId = useSelector((state) => state?.auth?.userProfile?.id);

  const payrollList = useSelector((state) => state?.super?.payrollList);

  // const { data: payrollFiltersData } = useQuery(GET_PAYROLL_FILTERS_DATA, {
  //   variables: {
  //     office_id: payrollList?.office_id,
  //   },
  //   onCompleted: (data) => {
  //     const preSelectedOffice =
  //       data?.payrollReportOfficesFilter?.data?.length > 1
  //         ? data?.payrollReportOfficesFilter?.data?.find(
  //             (office) => !!office?.head_quarter
  //           )
  //         : data?.payrollReportOfficesFilter?.data?.length === 1
  //         ? data?.payrollReportOfficesFilter?.data?.[0]
  //         : null;
  //   },
  //   fetchPolicy: "cache-and-network",
  // });

  const { t } = useTranslation();

  const [
    attemptGetSalaryConfig,
    { data: userSalaryCofigData, loading: userSalaryConfigLoading },
  ] = useLazyQuery(GET_USER_RELATIVE_SALARY_CONFIGURATION, {
    variables: {
      userId: authId, // should change it to auth id
      date: moment().format("YYYY-MM-DD"),
    },
  });

  const isMonthly =
    userSalaryCofigData?.employee?.activeSalaryConfig?.payment?.toLowerCase() ==
    "monthly";

  console.log("isMonthly", isMonthly);

  const [editAmountModal, setEditAmountModal] = useState(
    editAmountModalInitialState
  );

  const handleCloseEditAmountModal = () => {
    setEditAmountModal(editAmountModalInitialState);
  };

  const user = useSelector((state) => state?.auth?.userProfile);

  const closedMonths = useSelector(
    (state) => state?.super?.closedPayrollMonths
  );

  // const selectedCurrency =
  //   payrollFiltersData?.payrollReportCurrenciesFilter?.find(
  //     (currency) => currency?.id === payrollList?.currency_id
  //   );

  const groupColumns = [
    {
      name: "",
      grow: 3.07,
    },
    {
      name: <div className="extra_main_title_style mx-auto">{t("extra")}</div>,
      grow: 4,
      center: true,
    },
    {
      name: (
        <div className="deductions_main_title_style">{t("deductions")}</div>
      ),
      grow: 5.48,
      center: true,
    },
    {
      name: "",
      grow: 1.24,
    },
  ];

  const weeklyPayrollQueryVariables = {
    user_id: authId,
    page: 1,
    first: 3,

    status: "Paid",
    isExpected: 0,
    isExternal: 1,
    isAuthPayroll: true,
    orderBy: [
      {
        column: "paid_at",
        order: "DESC",
      },
    ],
  };

  const [
    attemptGetPayrollData,
    { data, loading, refetch: refetchPayrollList },
  ] = useLazyQuery(weeklyPayrollQuery, {
    // skip: !isMonthly && !!!payrollList?.office_id,
    variables: weeklyPayrollQueryVariables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    attemptGetSalaryConfig({
      onCompleted: (data) => {
        const paymentInterval = data?.employee?.activeSalaryConfig?.payment;

        attemptGetPayrollData({
          variables: {
            type: paymentInterval?.toUpperCase(),
          },
        });
      },
    });
  }, []);

  // const payrollAggregator = JSON.parse(data?.payrollReport?.aggregator ?? "{}");

  // const PaidPayrollAmount = parseFloat(payrollAggregator?.paid ?? 0);
  // const PendingPayrollAmount = parseFloat(payrollAggregator?.pending ?? 0);

  // const totalPayrollAmount = parseFloat(
  //   +payrollAggregator?.paid + +payrollAggregator?.pending
  // );

  // const [showExportModal, setShowExportModal] = useState(false);
  // const toggleShowExportModal = () => setShowExportModal(!showExportModal);

  // for show payslip modal and use modal component one time
  const [showPayslipModal, setShowPayslipModal] = useState(false);

  const [
    attemptGetUserPayslip,
    { data: payslipDetailsData, loading: userPayslipLoading },
  ] = useLazyQuery(userPayslipQuery);

  const [payrollDetailsForPaySlip, setPayrollDetailsForPaySlip] =
    useState(null);

  const handleViewPayslipModal = (payrollDetails) => {
    const payrollFromDate = payrollDetails?.details
      ? JSON.parse(payrollDetails?.details?.[0] ?? "{}")?.FROM
      : payrollDetails?.from;

    setPayrollDetailsForPaySlip({
      paid_at: payrollDetails?.paid_at,
      from: payrollFromDate,
    });

    attemptGetUserPayslip({
      variables: {
        input: {
          user_id: authId,
          type: isMonthly ? "Monthly" : "Weekly",
          from: payrollFromDate,
          paid_at: payrollDetails?.paid_at,
          monthly_view: isExpected,
          month_year: payrollDetails?.paid_at, // should not send this one but it's included in the schema
        },
      },
      onCompleted: (data) => {
        setShowPayslipModal(true);
      },
    });

    // dispatch(
    //   fetchUserPaySlipAction({
    //     user_id: payslipDetailsData?.user?.id,
    //     month_year_payroll: payslipDetailsData?.month_year_payroll,
    //   })
    // );
  };

  const toggleHidePayslipModal = () => {
    setShowPayslipModal(false);
    setPayrollDetailsForPaySlip(null);
  };

  // start handle view cell details

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [detailsModalData, setDetailsModalData] = useState({});

  const dismissDetailsModal = () => {
    setIsDetailsModalOpen(false);
    setDetailsModalData({});
  };

  const [attemptGetPayrollDetails, { loading: payrollDetailsLoading }] =
    useLazyQuery(GET_PAYROLL_DETAILS, {
      variables: {
        payrollType: isMonthly ? "Monthly" : "Weekly",
      },
    });

  const [penaltyViewDetails, setPenaltyViewDetails] = useState({});

  const handleViewDetails = ({ detailsType, title, payroll }) => {
    const { user, paid_at } = payroll;

    const from = payroll?.from
      ? payroll?.from // child case
      : JSON.parse(payroll?.details?.[0])?.FROM; // parent case

    attemptGetPayrollDetails({
      variables: {
        detailsType,
        user_id: user?.id,
        paid_at,
        from,
        isExpected: isExpected ? "1" : "0",
      },
      onCompleted: (data) => {
        setIsDetailsModalOpen(true);

        setDetailsModalData({
          title,
          month_year: paid_at,
          name: user?.name,
          data:
            detailsType === "ALLOWANCE"
              ? JSON.parse(data?.payroll_v2_details)?.map((allowance) => {
                  return {
                    name: allowance?.id,
                    amount: allowance?.q,
                  };
                })
              : detailsType === "PENALTY"
              ? JSON.parse(data?.payroll_v2_details)?.map((penalty) => {
                  return {
                    id: penalty?.id,
                    apply_flag: penalty?.applyFlag,
                    canEdit: penalty?.canEdit,
                    canApply: penalty?.canApply,
                    date: penalty?.date,
                    quantity: penalty?.q,
                    quantity_unit: penalty?.unit,
                    PenaltyAmount: penalty?.amount,
                    full_cause: penalty?.reason,
                  };
                })
              : detailsType === "BONUS"
              ? JSON.parse(data?.payroll_v2_details)?.map((bonus) => {
                  return {
                    name: bonus?.name ?? "",
                    value: bonus?.value ?? "",
                    unit: bonus?.unit ?? "",
                    amount: bonus?.amount ?? "",
                    description: bonus?.reason ?? "",
                    createdBy: bonus?.user ?? "",
                    payroll_at: bonus?.date ?? "",
                  };
                })
              : detailsType === "OVERTIME"
              ? JSON.parse(data?.payroll_v2_details)?.map((overtime) => {
                  return {
                    req_day: overtime?.date ?? "",
                    interval_in_time: overtime?.interval ?? "",
                    payable_interval_in_time: overtime?.paid ?? "",
                    amount: parseFloat(overtime?.amount) ?? "",
                    accepted_by: overtime?.approved_by ?? "",
                    payable_at: overtime?.payable_at ?? "",
                    month_year_payroll: overtime?.payroll ?? "",
                  };
                })
              : detailsType === "DEDUCTION"
              ? {
                  deductions: JSON.parse(
                    data?.payroll_v2_details
                  )?.deductions?.map((deduction) => {
                    return {
                      name: deduction?.name ?? "",
                      amount: parseFloat(deduction?.amount) ?? "",
                      // payment_factor_id: deduction?.payment_factor ?? "",
                      // value: "",
                      // description: "",
                      // createdBy: "",
                      // payroll_at: "",
                    };
                  }),
                  installments: JSON.parse(
                    data?.payroll_v2_details
                  )?.installments?.map((installment) => {
                    return {
                      name: installment?.loan?.name,
                      amount: installment?.loan?.amount,
                      installment_amount: installment?.amount,
                    };
                  }),
                  taxSettlements: JSON.parse(data?.payroll_v2_details)
                    ?.tax_settlements?.filter(
                      (arr) => Array.isArray(arr) && arr?.length
                    )
                    ?.map((taxSettlement) => {
                      return {
                        start: taxSettlement[0]?.from,
                        end: taxSettlement[0]?.to,
                        year: taxSettlement[0]?.year,
                        value: parseFloat(taxSettlement[0]?.amount),
                      };
                    }),
                  // deductibles: [
                  //   {
                  //     deductible: "",
                  //     amount: "",
                  //     target_amount: "",
                  //   },
                  // ],
                }
              : detailsType === "EXTRA"
              ? {
                  claims: JSON.parse(data?.payroll_v2_details)?.claims?.map(
                    (claim) => ({
                      amount: claim?.amount ?? "",
                      reason: claim?.reason ?? "",
                      incurred_at: claim?.incurred_at ?? "",
                      actionBy: claim?.action_by ?? "",
                    })
                  ),
                  loans: JSON.parse(data?.payroll_v2_details)?.loans?.map(
                    (loan) => ({
                      name: loan?.name ?? "",
                      amount: loan?.amount ?? "",
                      createdBy: loan?.user ?? "",
                    })
                  ),
                  // taxSettlements: [
                  //   {
                  //     start: "",
                  //     end: "",
                  //     year: "",
                  //     value: "",
                  //   },
                  // ],
                }
              : [],
        });
      },
    });
  };

  const [fetchOvertimeDetails, { loading: loadingOvertimeDetails, variables }] =
    useLazyQuery(overtimeDetailsQuery, {
      onError: (error) => {
        dismissDetailsModal();
        swal({
          title: t("error"),
          text:
            error?.graphQLErrors?.[0].extensions?.reason ??
            error?.message ??
            t("something went wrong"),
          icon: "error",
          className: "swal-error-style",
          timer: 2000,
          buttons: false,
        });
      },
      onCompleted: (res) => {
        setDetailsModalData((prevState) => ({
          ...prevState,
          data: res?.detailsData,
          payroll_id: variables?.payroll_id,
        }));
      },
    });

  const [fetchBonusDetails, { loading: loadingBonusDetails }] = useLazyQuery(
    bonusDetailsQuery,
    {
      onError: (error) => {
        dismissDetailsModal();
        swal({
          title: t("error"),
          text:
            error?.graphQLErrors?.[0].extensions?.reason ??
            error?.message ??
            t("something went wrong"),
          icon: "error",
          className: "swal-error-style",
          timer: 2000,
          buttons: false,
        });
      },
      onCompleted: (res) => {
        setDetailsModalData((prevState) => ({
          ...prevState,
          data: res?.detailsData,
        }));
      },
    }
  );
  const [fetchPenaltyDetails, { loading: loadingPenaltyDetails }] =
    useLazyQuery(penaltyDetailsQuery, {
      onError: (error) => {
        dismissDetailsModal();
        swal({
          title: t("error"),
          text:
            error?.graphQLErrors?.[0].extensions?.reason ??
            error?.message ??
            t("something went wrong"),
          icon: "error",
          className: "swal-error-style",
          timer: 2000,
          buttons: false,
        });
      },
      onCompleted: (res) => {
        setDetailsModalData((prevState) => ({
          ...prevState,
          data: res?.detailsData,
        }));
      },
    });

  const [fetchOtherDeductablesDetails, { loading: loadingOtherDeductDetails }] =
    useLazyQuery(otherDeductablesDetailsQuery, {
      onError: (error) => {
        dismissDetailsModal();
        swal({
          title: t("error"),
          text:
            error?.graphQLErrors?.[0].extensions?.reason ??
            error?.message ??
            t("something went wrong"),
          icon: "error",
          className: "swal-error-style",
          timer: 2000,
          buttons: false,
        });
      },
      onCompleted: (res) => {
        setDetailsModalData((prevState) => ({
          ...prevState,
          data: res?.detailsData,
        }));
      },
    });

  const [fetchOtherAddedDetails, { loading: loadingOtherAddedDetails }] =
    useLazyQuery(otherAddedDetailsQuery, {
      onError: (error) => {
        dismissDetailsModal();
        swal({
          title: t("error"),
          text:
            error?.graphQLErrors?.[0].extensions?.reason ??
            error?.message ??
            t("something went wrong"),
          icon: "error",
          className: "swal-error-style",
          timer: 2000,
          buttons: false,
        });
      },
      onCompleted: (res) => {
        setDetailsModalData((prevState) => ({
          ...prevState,
          data: res?.detailsData,
        }));
      },
    });

  const [fetchAllowancesDetails, { loading: loadingAllowancesDetails }] =
    useLazyQuery(allowancesDetailsQuery, {
      onError: (error) => {
        dismissDetailsModal();
        swal({
          title: t("error"),
          text:
            error?.graphQLErrors?.[0].extensions?.reason ??
            error?.message ??
            t("something went wrong"),
          icon: "error",
          className: "swal-error-style",
          timer: 2000,
          buttons: false,
        });
      },
      onCompleted: (res) => {
        setDetailsModalData((prevState) => ({
          ...prevState,
          data: res?.detailsData?.userAllowancesHistory,
        }));
      },
    });
  // end handle View cell details
  const TableCell = ({
    configType,
    symbol,
    value,
    allowNegativeValue = false,
  }) =>
    configType != payrollManagementTypes.EXTERNAL ? (
      <div className="d-flex align-items-center">
        <div className="pr-1">{symbol}</div>
        <p className="mb-0">
          {value
            ? roundToTwo(
                allowNegativeValue && value.toFixed(2) < 0
                  ? value
                  : Math.abs(value)
              )
            : "0.00"}
        </p>
      </div>
    ) : (
      <div></div>
    );

  const canEditOrViewRow = (row) => {
    if (isManagedExternlly(row)) {
      return false;
    }
    if (!row?.details) {
      //child record
      return true;
    } else {
      //parent record
      return row?.details?.length === 1;
    }
  };

  const isManagedExternlly = (row) => {
    return row?.details
      ? row?.details?.every(
          (detail) =>
            JSON.parse(detail)?.MANAGEMENT_TYPE ==
            payrollManagementTypes.EXTERNAL
        )
      : row?.config_type == payrollManagementTypes.EXTERNAL;
  };

  const columns = [
    {
      name: t("date"),
      wrap: true,
      selector: (row) => row?.paid_at,
      sortable: false,
      grow: 1,
      style: {
        backgroundColor: "#fff",
      },
    },
    {
      name: t("base"),
      wrap: true,
      selector: "base_salary",
      sortable: false,
      grow: 1,
      style: {
        backgroundColor: "rgba(35, 170, 235, 0.14) !important",
      },
      // omit:
      //   payrollList?.currency_id &&
      //   props?.extraPayrollRow?.base_salary == 0,
      conditionalCellStyles: [
        {
          when: (row) => isManagedExternlly(row),
          style: externalTableStyle,
        },
        {
          when: (row) => row?.isExtra,
          style: extraTableStyle,
        },
      ],
      cell: (row) => (
        <TableCell
          configType={
            isManagedExternlly(row)
              ? payrollManagementTypes.EXTERNAL
              : payrollManagementTypes.INTERNAL
          }
          symbol={row?.currency?.symbol}
          value={
            payrollList.monthly_amount
              ? row?.monthly_base_salary
              : row?.base_salary
          }
        />
      ),
    },
    {
      name: t("allowances"),
      wrap: true,
      selector: "allowances_amount",
      sortable: false,
      grow: 1,
      // omit:
      //   payrollList?.currency_id &&
      //   props?.extraPayrollRow?.allowances_amount == 0,
      style: {
        backgroundColor: "rgba(39, 180, 12, 0.18) !important",
      },
      conditionalCellStyles: [
        {
          when: (row) => isManagedExternlly(row),
          style: externalTableStyle,
        },
        {
          when: (row) => row?.isExtra,
          style: extraTableStyle,
        },
      ],
      cell: (row) => (
        <div className="d-flex align-items-center w-100 justify-content-between">
          <TableCell
            configType={
              isManagedExternlly(row)
                ? payrollManagementTypes.EXTERNAL
                : payrollManagementTypes.INTERNAL
            }
            symbol={row?.currency?.symbol}
            value={
              payrollList.monthly_amount
                ? row?.monthly_allowances
                : row?.allowances_amount
            }
          />
          {Math.abs(row?.allowances_amount) > 0 && canEditOrViewRow(row) ? (
            <div className="data-table-action">
              <InfoIconButton
                onClick={() =>
                  handleViewDetails({
                    detailsType: "ALLOWANCE",
                    title: "allowances",
                    payroll: row,
                  })
                }
              />
            </div>
          ) : null}
        </div>
      ),
    },
    {
      name: t("bonuses"),
      wrap: true,
      selector: "bonuses_amount",
      sortable: false,
      grow: 1,
      // omit:
      //   payrollList?.currency_id &&
      //   props?.extraPayrollRow?.bonuses_amount == 0,
      style: {
        backgroundColor: "rgba(39, 180, 12, 0.18) !important",
      },
      conditionalCellStyles: [
        {
          when: (row) => isManagedExternlly(row),
          style: externalTableStyle,
        },
        {
          when: (row) => row?.isExtra,
          style: extraTableStyle,
        },
      ],
      cell: (row) => (
        <div className="d-flex align-items-center w-100 justify-content-between">
          <TableCell
            configType={
              isManagedExternlly(row)
                ? payrollManagementTypes.EXTERNAL
                : payrollManagementTypes.INTERNAL
            }
            symbol={row?.currency?.symbol}
            value={row?.bonuses_amount}
          />
          {Math.abs(row?.bonuses_amount) > 0 && canEditOrViewRow(row) ? (
            <div className="data-table-action">
              <InfoIconButton
                onClick={() =>
                  handleViewDetails({
                    detailsType: "BONUS",
                    title: "bonus",
                    payroll: row,
                  })
                }
              />
            </div>
          ) : null}
        </div>
      ),
    },
    {
      name: t("overtime"),
      wrap: true,
      selector: "overtime_amount",
      sortable: false,
      grow: 1,
      // omit:
      //   payrollList?.currency_id &&
      //   props?.extraPayrollRow?.overtime_amount == 0,
      style: {
        backgroundColor: "rgba(39, 180, 12, 0.18) !important",
      },
      conditionalCellStyles: [
        {
          when: (row) => isManagedExternlly(row),
          style: externalTableStyle,
        },
        {
          when: (row) => row?.isExtra,
          style: extraTableStyle,
        },
      ],
      cell: (row) => (
        <div className="d-flex align-items-center w-100 justify-content-between">
          <TableCell
            configType={
              isManagedExternlly(row)
                ? payrollManagementTypes.EXTERNAL
                : payrollManagementTypes.INTERNAL
            }
            symbol={row?.currency?.symbol}
            value={row?.overtime_amount}
          />
          {Math.abs(row?.overtime_amount) > 0 && canEditOrViewRow(row) ? (
            <div className="data-table-action">
              <InfoIconButton
                onClick={() =>
                  handleViewDetails({
                    detailsType: "OVERTIME",
                    title: "overtime",
                    payroll: row,
                  })
                }
              />
            </div>
          ) : null}
        </div>
      ),
    },
    {
      name: t("others"),
      wrap: true,
      selector: "extra_others",
      sortable: false,
      grow: 1,
      style: {
        backgroundColor: "rgba(39, 180, 12, 0.18) !important",
      },
      // omit:
      //   payrollList?.currency_id &&
      //   props?.extraPayrollRow?.extra_others == 0,
      conditionalCellStyles: [
        {
          when: (row) => isManagedExternlly(row),
          style: {
            ...externalTableStyle,
            // padding: "5px 2px 5px 36px !important",
          },
        },
        {
          when: (row) => row?.isExtra,
          style: extraTableStyle,
        },
      ],
      cell: (row) =>
        isManagedExternlly(row) ? (
          <div className="w-100 text-right font-weight-bold managed-style">
            {t("managed")}
          </div>
        ) : (
          <div className="d-flex align-items-center w-100 justify-content-between">
            <TableCell
              configType={
                isManagedExternlly(row)
                  ? payrollManagementTypes.EXTERNAL
                  : payrollManagementTypes.INTERNAL
              }
              symbol={row?.currency?.symbol}
              value={row?.extra_others}
            />
            {Math.abs(row?.extra_others) > 0 && canEditOrViewRow(row) ? (
              <div className="data-table-action">
                <InfoIconButton
                  onClick={() =>
                    handleViewDetails({
                      detailsType: "EXTRA",
                      title: "other added",
                      payroll: row,
                    })
                  }
                />
              </div>
            ) : null}
          </div>
        ),
    },
    {
      name: t("penalties"),
      wrap: true,
      selector: "penalties_amount",
      sortable: false,
      grow: 1,
      // omit:
      //   payrollList?.currency_id &&
      //   props?.extraPayrollRow?.penalties_amount == 0,
      style: {
        backgroundColor: "rgba(255, 106, 106, 0.08) !important",
      },
      conditionalCellStyles: [
        {
          when: (row) => isManagedExternlly(row),
          style: {
            ...externalTableStyle,
            // padding: "5px 36px 5px 2px !important",
          },
        },
        {
          when: (row) => row?.isExtra,
          style: extraTableStyle,
        },
      ],
      cell: (row) =>
        isManagedExternlly(row) ? (
          <div className="w-100 text-left font-weight-bold external-style">
            {t("externally")}
          </div>
        ) : (
          <div className="d-flex align-items-center w-100 justify-content-between">
            <TableCell
              configType={
                isManagedExternlly(row)
                  ? payrollManagementTypes.EXTERNAL
                  : payrollManagementTypes.INTERNAL
              }
              symbol={row?.currency?.symbol}
              value={row?.penalties_amount}
            />

            {Math.abs(row?.penalties_amount) > 0 && canEditOrViewRow(row) ? (
              <div className="data-table-action">
                <InfoIconButton
                  onClick={() => {
                    const penaltyDetails = {
                      title: "penalty",
                      detailsType: "PENALTY",
                      payroll: row,
                    };

                    setPenaltyViewDetails(penaltyDetails);
                    handleViewDetails(penaltyDetails);
                  }}
                />
              </div>
            ) : null}
          </div>
        ),
    },
    {
      name: t("social"),
      wrap: true,
      selector: "social_amount",
      sortable: false,
      grow: 1.3,
      // omit:
      //   payrollList?.currency_id &&
      //   props?.extraPayrollRow?.social_amount == 0,
      style: {
        backgroundColor: "rgba(255, 106, 106, 0.08) !important",
      },
      conditionalCellStyles: [
        {
          when: (row) => isManagedExternlly(row),
          style: externalTableStyle,
        },
        {
          when: (row) => row?.isExtra,
          style: extraTableStyle,
        },
        {
          when: (row) => row?.social_fixed,
          style: {
            backgroundColor: "#fff",
            color: "#8997a4 !important",
          },
        },
      ],
      cell: (row) => (
        <div className="d-flex align-items-center w-100 justify-content-between">
          <TableCell
            configType={
              isManagedExternlly(row)
                ? payrollManagementTypes.EXTERNAL
                : payrollManagementTypes.INTERNAL
            }
            symbol={row?.currency?.symbol}
            value={
              payrollList.monthly_amount
                ? row?.monthly_social
                : row?.social_amount
            }
          />
          {(JSON.parse(row?.details?.[0] ?? "{}")?.STATUS?.toLowerCase() ===
            "pending" ||
            row?.status?.toLowerCase() === "pending") &&
            canEditOrViewRow(row) && (
              // !row?.isExtra &&
              // row?.config_type == payrollManagementTypes.INTERNAL &&
              <HasPrivileges
                reqireMain={[Privilages.EDIT_SOCIAL_MEDICAL_TAXES_AMOUNT]}
                allowBP
                avalibleOnExpire={false}
                altExpireChildren={
                  <div className="data-table-action">
                    <span className="btn p-0">
                      <FontAwesomeIcon icon={faPen} />
                    </span>
                  </div>
                }
              >
                <div className="data-table-action">
                  <span
                    className="btn p-0"
                    onClick={() => {
                      setEditAmountModal({
                        isOpen: true,
                        title: "Edit Social Insurance",
                        userId: row?.user?.id,
                        paidAt: row?.paid_at,
                        from: row?.from
                          ? row?.from
                          : JSON.parse(row?.details?.[0] ?? "{}")?.FROM,
                        deductibleType: "SOCIAL",
                        oldAmount: parseFloat(row?.social_amount),
                        currency: row?.currency,
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </span>
                </div>
              </HasPrivileges>
            )}
        </div>
      ),
    },
    {
      name: t("medical"),
      wrap: true,
      selector: "medical_amount",
      sortable: false,
      grow: 1.3,
      // omit:
      //   payrollList?.currency_id &&
      //   props?.extraPayrollRow?.medical_amount == 0,
      style: {
        backgroundColor: "rgba(255, 106, 106, 0.08) !important",
      },
      conditionalCellStyles: [
        {
          when: (row) => isManagedExternlly(row),
          style: externalTableStyle,
        },
        {
          when: (row) => row?.isExtra,
          style: extraTableStyle,
        },
        {
          when: (row) => row?.medical_fixed,
          style: {
            backgroundColor: "#fff",
            color: "#8997a4 !important",
          },
        },
      ],
      cell: (row) => (
        <div className="d-flex align-items-center w-100 justify-content-between">
          <TableCell
            configType={
              isManagedExternlly(row)
                ? payrollManagementTypes.EXTERNAL
                : payrollManagementTypes.INTERNAL
            }
            symbol={row?.currency?.symbol}
            value={
              payrollList.monthly_amount
                ? row?.monthly_medical
                : row?.medical_amount
            }
          />
          {(JSON.parse(row?.details?.[0] ?? "{}")?.STATUS?.toLowerCase() ===
            "pending" ||
            row?.status?.toLowerCase() === "pending") &&
            canEditOrViewRow(row) && (
              // !row?.isExtra &&
              // row?.config_type == payrollManagementTypes.INTERNAL &&
              <HasPrivileges
                reqireMain={[Privilages.EDIT_SOCIAL_MEDICAL_TAXES_AMOUNT]}
                allowBP
                avalibleOnExpire={false}
                altExpireChildren={
                  <div className="data-table-action">
                    <span className="btn p-0">
                      <FontAwesomeIcon icon={faPen} />
                    </span>
                  </div>
                }
              >
                <div className="data-table-action">
                  <span
                    className="btn p-0"
                    onClick={() => {
                      setEditAmountModal({
                        isOpen: true,
                        title: "Edit Medical Insurance",
                        userId: row?.user?.id,
                        paidAt: row?.paid_at,
                        from: row?.from
                          ? row?.from
                          : JSON.parse(row?.details?.[0] ?? "{}")?.FROM,
                        deductibleType: "MEDICAL",
                        oldAmount: parseFloat(row?.medical_amount),
                        currency: row?.currency,
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </span>
                </div>
              </HasPrivileges>
            )}
        </div>
      ),
    },
    {
      name: t("taxes"),
      wrap: true,
      selector: "taxes_amount",
      // omit:
      //   payrollList?.currency_id &&
      //   props?.extraPayrollRow?.taxes_amount == 0,
      sortable: false,
      grow: 1.3,
      style: {
        backgroundColor: "rgba(255, 106, 106, 0.08) !important",
      },
      conditionalCellStyles: [
        {
          when: (row) => isManagedExternlly(row),
          style: externalTableStyle,
        },
        {
          when: (row) => row?.isExtra,
          style: extraTableStyle,
        },
        {
          when: (row) => row?.taxes_fixed,
          style: {
            backgroundColor: "#fff",
            color: "#8997a4 !important",
          },
        },
      ],
      cell: (row) => (
        <div className="d-flex align-items-center w-100 justify-content-between">
          <TableCell
            configType={
              isManagedExternlly(row)
                ? payrollManagementTypes.EXTERNAL
                : payrollManagementTypes.INTERNAL
            }
            symbol={row?.currency?.symbol}
            value={
              payrollList.monthly_amount
                ? row?.monthly_taxes
                : row?.taxes_amount
            }
          />
          {(JSON.parse(row?.details?.[0] ?? "{}")?.STATUS?.toLowerCase() ===
            "pending" ||
            row?.status?.toLowerCase() === "pending") &&
            canEditOrViewRow(row) && (
              // !row?.isExtra &&
              // row?.config_type == payrollManagementTypes.INTERNAL &&
              <HasPrivileges
                reqireMain={[Privilages.EDIT_SOCIAL_MEDICAL_TAXES_AMOUNT]}
                allowBP
                avalibleOnExpire={false}
                altExpireChildren={
                  <div className="data-table-action">
                    <span className="btn p-0">
                      <FontAwesomeIcon icon={faPen} />
                    </span>
                  </div>
                }
              >
                <div className="data-table-action">
                  <span
                    className="btn p-0"
                    onClick={() => {
                      setEditAmountModal({
                        isOpen: true,
                        title: "Edit Taxes",
                        userId: row?.user?.id,
                        paidAt: row?.paid_at,
                        from: row?.from
                          ? row?.from
                          : JSON.parse(row?.details?.[0] ?? "{}")?.FROM,
                        deductibleType: "TAX",
                        oldAmount: parseFloat(row?.taxes_amount),
                        currency: row?.currency,
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </span>
                </div>
              </HasPrivileges>
            )}
        </div>
      ),
    },
    {
      name: t("others"),
      wrap: true,
      selector: "deductible_others",
      sortable: false,
      grow: 1,
      // omit:
      //   payrollList?.currency_id &&
      //   props?.extraPayrollRow?.deductible_others == 0,
      style: {
        backgroundColor: "rgba(255, 106, 106, 0.08) !important",
      },
      conditionalCellStyles: [
        {
          when: (row) => isManagedExternlly(row),
          style: externalTableStyle,
        },
        {
          when: (row) => row?.isExtra,
          style: extraTableStyle,
        },
      ],
      cell: (row) => (
        <div className="d-flex align-items-center w-100 justify-content-between">
          <TableCell
            configType={
              isManagedExternlly(row)
                ? payrollManagementTypes.EXTERNAL
                : payrollManagementTypes.INTERNAL
            }
            symbol={row?.currency?.symbol}
            value={
              payrollList.monthly_amount
                ? row?.monthly_deductibles
                : row?.deductible_others
            }
          />
          {Math.abs(row?.deductible_others) > 0 && canEditOrViewRow(row) ? (
            <div className="data-table-action">
              <InfoIconButton
                onClick={() =>
                  handleViewDetails({
                    detailsType: "DEDUCTION",
                    title: "other deductables",
                    payroll: row,
                  })
                }
              />
            </div>
          ) : null}
        </div>
      ),
    },
    {
      name: t("payable"),
      wrap: true,
      selector: "payable_amount",
      sortable: false,
      grow: 1.4,
      // omit:
      //   payrollList?.currency_id &&
      //   props?.extraPayrollRow?.payable_amount == 0,
      style: {
        backgroundColor: "#f8f9fa !important",
        minWidth: "150px",
      },
      conditionalCellStyles: [
        {
          when: (row) => isManagedExternlly(row),
          style: externalTableStyle,
        },
        {
          when: (row) => row?.isExtra,
          style: extraTableStyle,
        },
      ],
      cell: (row) => (
        <div className="d-flex align-items-center w-100">
          <TableCell
            configType={
              isManagedExternlly(row)
                ? payrollManagementTypes.EXTERNAL
                : payrollManagementTypes.INTERNAL
            }
            symbol={row?.currency?.symbol}
            value={
              payrollList.monthly_amount
                ? row?.monthly_payable_amount
                : parseFloat(row?.payable_amount)
            }
            allowNegativeValue={true}
          />
          {(JSON.parse(row?.details?.[0] ?? "{}")?.STATUS?.toLowerCase() ==
            "paid" ||
            row?.status?.toLowerCase() == "paid") &&
            canEditOrViewRow(row) &&
            (!row?.details || (row?.details && row?.details?.length == 1)) &&
            !!!row?.is_commission && (
              <HasPrivileges
                reqireMain={[Privilages.VIEW_PAYSLIP]}
                allowBP
                avalibleOnExpire={false}
                altExpireChildren={
                  <div className="data-table-action">
                    <span className="btn p-0">
                      <FontAwesomeIcon icon={faPen} />
                    </span>
                  </div>
                }
              >
                <div className="data-table-action ml-1">
                  <span
                    className="payslip_icon_style btn p-0"
                    onClick={() => handleViewPayslipModal(row)}
                  >
                    <FontAwesomeIcon icon={faFileInvoiceDollar} />
                  </span>
                </div>
              </HasPrivileges>
            )}
        </div>
      ),
    },
  ];

  function roundToTwo(num) {
    // return +(Math.round(num + "e+2") + "e-2");
    return num.toFixed(2);
  }

  const ExpandedRow = ({ data: parentPayroll }) => {
    const payrolls = parentPayroll?.details?.map((stringifiedPayroll) => {
      const childPayroll = JSON.parse(stringifiedPayroll);

      const result = normalizeChildrenPayroll(parentPayroll, childPayroll);

      return {
        id: JSON.stringify({
          userId: result?.user?.id,
          from: result?.from,
          isParent: false,
        }),
        ...result,
      };
    });

    return (
      <DataTable
        className="requests-profile-data-list payroll_datatable_wrapper_style"
        columns={columns}
        data={payrolls}
        noHeader
        noTableHead
        expandableRows
        expandableIcon={<></>}
        expandableRowDisabled={() => true}
      />
    );
  };

  return (
    <>
      {payrollDetailsLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <DataTable
        className="requests-profile-data-list payroll_main_datatable_wrapper_style"
        columns={groupColumns}
        data={[]}
        noHeader
        persistTableHead
        customStyles={{
          noData: "d-flex flex-column",
        }}
        noDataComponent={
          <DataTable
            noDataComponent={<div className="p-4"> {t("no_records")} </div>}
            className="requests-profile-data-list payroll_datatable_wrapper_style"
            columns={columns}
            data={data?.payrollReport.data?.map((payroll) => ({
              id: JSON.stringify({
                userId: payroll?.user?.id,
                froms: JSON.stringify(
                  payroll?.details
                    ?.filter(
                      (payroll) =>
                        JSON.parse(payroll)?.STATUS?.toLowerCase() == "pending"
                    )
                    ?.map((detail) => JSON.parse(detail)?.FROM)
                ),
                isParent: true,
              }),
              ...payroll,
            }))}
            expandableRowExpanded={(row) =>
              !!row?.details && row?.details?.length > 1
            }
            noHeader
            persistTableHead
            sortServer
            defaultSortAsc={payrollList.sorting.dir == "asc"}
            defaultSortField={payrollList.sorting.key}
            progressPending={loading || userSalaryConfigLoading}
            progressComponent={<Loader />}
            expandableRows={true}
            expandableRowDisabled={(row) => row?.details?.length < 2}
            expandableRowsComponent={<ExpandedRow />}
            expandableIcon={{
              expanded: <FontAwesomeIcon icon={faAngleUp} />,
              collapsed: <FontAwesomeIcon icon={faAngleDown} />,
            }}
          />
        }
      />

      {/* Start of Payslip Modal */}
      <PayslipModal
        paySlipData={payslipDetailsData?.usersPaySlip}
        isPayslipLoading={userPayslipLoading}
        modal_isOpen={showPayslipModal}
        modal_toggle={toggleHidePayslipModal}
        type={isMonthly ? "Monthly" : "Weekly"}
        paidAt={payrollDetailsForPaySlip?.paid_at}
        from={payrollDetailsForPaySlip?.from}
      />
      {/* End of Payslip Modal  */}

      {/* Strt of View payroll cell details  */}
      <ViewWeeklyPayrollDetails
        isModalVissible={isDetailsModalOpen}
        dismissModal={dismissDetailsModal}
        data={detailsModalData}
        refetchPayrollAndPenalties={() => {}} // this is unused in case of employee
        isLoading={
          loadingOvertimeDetails ||
          loadingBonusDetails ||
          loadingPenaltyDetails ||
          loadingOtherDeductDetails ||
          loadingOtherAddedDetails ||
          loadingAllowancesDetails
        }
        isManager={false}
      />

      <EditPenaltyModal
        refetchList={() => {}} /* unused method (in case of employee) */
      />
    </>
  );
};

export default MyPayrollView;

import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import moment from "moment";
import Constants, {
  assignmentTypesConstants,
  workPlaceSettingOptions,
} from "../../Constants";

/**
 * start of AssignmentPreviewNamePart
 */

export const AssignmentPreviewNamePart = ({ name }) => {
  const { t } = useTranslation();
  return (
    <>
      <h5 className="assignment-preview-info-title mb-2 mr-2">{t("name")}</h5>
      <span className="assignment-preview-list w-100">{name}</span>
    </>
  );
};

/**
 * end of AssignmentPreviewNamePart
 *
 * start of AssignmentPreviewCanEditPart
 */

export const AssignmentPreviewCanEditPart = () => {
  const { t } = useTranslation();

  // Reducer State
  const {
    id,
    name,
    applied_on_names,
    excluded_employees_names,
    normalWorkTiming,
    firstHalfWorkTiming,
    secondHalfWorkTiming,
    retain_default_work_timing,
    assignment_location_names,
    workplace_setting,
    workplace_setting_signout,
    compensation,
    payment_factor_id,
    award_bonus_days_quantity,
    year,
    month,
    award_additional_leaves_quantity,
    penalise,
    no_compensation,
    createdBy,
    created_at,
    make_it_a_day_off,
    ignoredEmployees,
    treat_as_normal,
    allow_permission,
    allow_overtime,
    allow_home,
    workplace_setting_identical,
    assignmentType,
    inLieuOfWorkdayDate,
    cost_center_name,
  } = useSelector((state) => state.assignments.upsertAssignmentForm);

  /* ↓ Helpers ↓ */

  const normalizeIgnoredEmployeees = () => {
    let normalizationResult = [];
    ignoredEmployees?.forEach((ignoredEmployeeData) => {
      let index = normalizationResult?.findIndex(
        (normalizedEle) => normalizedEle?.date === ignoredEmployeeData?.date
      );
      if (index > -1) {
        normalizationResult[index] = {
          ...normalizationResult[index],
          employees: [
            ...normalizationResult[index].employees,
            ignoredEmployeeData?.name,
          ],
        };
      } else {
        normalizationResult.push({
          date: ignoredEmployeeData?.date,
          employees: [ignoredEmployeeData?.name],
        });
      }
    });

    return normalizationResult;
  };

  const getLocationLabel = (key) => {
    if (key === "work_timing_location") return "Work Timing Location";
    return workPlaceSettingOptions.find((o) => o.value === key).label;
  };

  // const RenderLocationConfiguration = () => {
  //   switch (workplace_setting) {
  //     case "work_timing_location":
  //       return (
  //         <span className="col-12 assignment-preview-list-item">
  //           location settings are according to the work timing selected
  //         </span>
  //       );
  //     case "any_location":
  //       return (
  //         <span className="col-12 assignment-preview-list-item">
  //           employee can sign in from any location
  //         </span>
  //       );
  //     default:
  //       return (
  //         <span className="col-12 assignment-preview-list-item">
  //           employee must sign in from specific locations
  //         </span>
  //       );
  //   }
  // };

  // const RenderSpecificLocations = () => {
  //   if (workplace_setting === "specific_locations") {
  //     return (
  //       <div>
  //         <h5 className="assignment-locations-header mb-2">
  //           {t("workplaces")}
  //         </h5>
  //         <div className="assignment-preview-list">
  //           {assignment_location_names?.map((location, i) => (
  //             <span key={i} className="col-3 assignment-preview-list-item">
  //               {location}
  //             </span>
  //           ))}
  //         </div>
  //       </div>
  //     );
  //   } else {
  //     return null;
  //   }
  // };

  return (
    <div className="preview-modal-info-container">
      {/* from & to section start */}

      {/* applid on section start */}
      <div>
        <h5 className="assignment-form-header mb-2">{t("applied on")}</h5>
        <div className="assignment-preview-list">
          {applied_on_names.map((applied_on, i) => (
            <span key={i} className="col-3 assignment-preview-list-item">
              {applied_on}
            </span>
          ))}
        </div>
      </div>
      {excluded_employees_names?.length ? (
        <div>
          <h5 className="assignment-preview-info-title">excluding</h5>
          <div className="assignment-preview-list">
            {excluded_employees_names?.map((excluded_employee, i) => (
              <span key={i} className="col-3 assignment-preview-list-item">
                {excluded_employee}
              </span>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}

      {/* applid on section end */}

      {/* work schedule section start */}

      <div>
        <h5 className="assignment-form-header mb-2">{t("work schedule")}</h5>
        <div className="assignment-preview-list d-block">
          {!make_it_a_day_off ? (
            <>
              <div className="d-flex justify-content-between gap-20 mb-2">
                <div>
                  <span className="d-block assignment-preview-list-item font-weight-bold">
                    {t("Normal Work timing")}
                  </span>
                  <span className="d-block assignment-preview-list-item">
                    {normalWorkTiming?.name}
                  </span>
                </div>
                {firstHalfWorkTiming ? (
                  <div>
                    <span className="d-block assignment-preview-list-item font-weight-bold">
                      {t("first half work timing")}
                    </span>
                    <span className="d-block assignment-preview-list-item">
                      {firstHalfWorkTiming?.name ?? "---"}
                    </span>
                  </div>
                ) : null}
                {secondHalfWorkTiming ? (
                  <div>
                    <span className="d-block assignment-preview-list-item font-weight-bold">
                      {t("second half work timing")}
                    </span>
                    <span className="d-block assignment-preview-list-item">
                      {secondHalfWorkTiming?.name ?? "---"}
                    </span>
                  </div>
                ) : null}
              </div>

              <div className="d-flex justify-content-between gap-20">
                <BooleanCell
                  label="retain default shifts on this day"
                  val={retain_default_work_timing}
                />
                <BooleanCell
                  label="Treat this assignment as a normal work day"
                  val={treat_as_normal}
                />
              </div>
            </>
          ) : (
            <span className="col-3 assignment-preview-list-item">
              {t("day off")}
            </span>
          )}
        </div>
      </div>
      {/* work schedule section end */}

      {/* locations section start */}

      {!make_it_a_day_off ? (
        <>
          {!!cost_center_name ? (
            <div>
              <h5 className="assignment-form-header mb-2">
                {t("Cost center")}
              </h5>
              <p className="m-0">{cost_center_name}</p>
            </div>
          ) : null}

          <div>
            <h5 className="assignment-form-header mb-2">{t("locations")}</h5>
            <div className="assignment-preview-list">
              Employee Can Sign In{workplace_setting_identical ? "/Out" : ""}{" "}
              From: {getLocationLabel(workplace_setting)}
              {!workplace_setting_identical ? (
                <>
                  <br />
                  {`Employee Can Sign Out From: ${getLocationLabel(
                    workplace_setting_signout
                  )}`}
                </>
              ) : null}
            </div>
          </div>
        </>
      ) : null}
      {/* locations section end */}

      {/* compensation section start */}
      {compensation ? (
        <div>
          <h5 className="assignment-form-header mb-2">{t("compensation")}</h5>
          <div className="assignment-preview-list">
            {compensation == "request" ? (
              <span className="col-12 assignment-preview-list-item">
                {t("in lieu of workday")}{" "}
                {moment(inLieuOfWorkdayDate ?? undefined).format("DD-MM-YYYY")}
              </span>
            ) : compensation === "bonus" ? (
              <>
                <span className="col-6 assignment-preview-list-item">
                  {`${award_bonus_days_quantity}  ${
                    Constants.BounsTypes.find(
                      (type) => type.value === +payment_factor_id
                    ).label
                  } / day worked`}
                </span>
                <span className="col-6 assignment-preview-list-item">
                  {`payable on ${moment(`${month}-${year}`, "MM-YYYY").format(
                    "MMM YYYY"
                  )}`}
                </span>
              </>
            ) : (
              <span className="col-12 assignment-preview-list-item">
                {`${award_additional_leaves_quantity} leave days / day worked`}
              </span>
            )}
          </div>
        </div>
      ) : null}
      {/* compensation section end */}

      {/* requests section start */}
      {assignmentType === assignmentTypesConstants.EXCEPTIONAL_SHIFT ? (
        <>
          <h5 className="assignment-form-header mb-2">{t("Requests")}</h5>
          <div className="assignment-preview-list justify-content-between">
            <BooleanCell label="Allow Permissions" val={allow_permission} />
            <BooleanCell label="Allow Overtime" val={allow_overtime} />
            <BooleanCell label="Allow Work Remotely" val={allow_home} />
          </div>
        </>
      ) : null}

      {/* requests section end */}

      {/* in case of abaence sectin start */}
      {!make_it_a_day_off && !treat_as_normal ? (
        <>
          <h5 className="assignment-form-header mb-2">
            {t("in case of absence")}
          </h5>
          <div className="assignment-preview-list">
            <BooleanCell label="penalise" val={penalise} />
            {compensation ? (
              <BooleanCell label="apply compensation" val={no_compensation} />
            ) : null}
          </div>
        </>
      ) : null}

      {/* in case of abaence sectin end */}

      {normalizeIgnoredEmployeees()?.length ? (
        <div>
          <h5 className="assignment-form-header mb-2">
            {t("ignored employees")}
          </h5>
          <div className="assignment-preview-list flex-column align-items-start">
            {normalizeIgnoredEmployeees().map((ignoredEmployee) => (
              <>
                <span className="assignment-preview-list-item font-weight-bold">
                  {ignoredEmployee?.date}
                </span>
                <div>
                  {ignoredEmployee?.employees?.map((employee) => (
                    <span className="assignment-preview-list-item col-3">
                      - {employee}
                    </span>
                  ))}
                </div>
              </>
            ))}
          </div>
        </div>
      ) : null}

      {/* created by section start */}
      <div className="assignment-preview-list assignment-created-by-list px-4">
        <span className="assignment-preview-list-item mr-custom-5">
          <span className="font-weight-bold mr-1">{t("created by")}</span>{" "}
          {createdBy?.name}
        </span>
        <span className="assignment-preview-list-item">
          <span className="font-weight-bold mr-1">{t("on")}</span>{" "}
          {moment(created_at, "YYYY-MM-DD hh:mm:ss").format("DD-MM-YYYY")}
        </span>
      </div>
      {/* created by section end */}
    </div>
  );
};

const BooleanCell = ({ label, val }) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex align-items-center gap-10 font-13">
      {t(label)}
      {val ? (
        <span className="green-color">{t("Yes")}</span>
      ) : (
        <span className="red-color">{t("No")}</span>
      )}
    </div>
  );
};

/**
 * end of AssignmentPreviewCanEditPart
 *
 * start of
 */

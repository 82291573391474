import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Constants from "../../../Constants";
import { updateValueAction } from "../../../Store/Actions";

import { CheckboxBooleanForm, InputForm, RadioboxForm } from "form-builder";
import AttSignInSection from "../../../Components/AttProfileSections/AttSignInSection";
import AttTardinessSection from "../../../Components/AttProfileSections/AttTardinessSection";
import LimitWorkTimingsSection from "../../../Components/LimitWorkTimingsSection";

const WorkTimingSignInStep = ({ onNext, setupWizard, ...commonFormProps }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Reducer State
  const data = useSelector((state) => state.super.workTimings);
  const workTimingsModal = useSelector((state) => state.super.workTimingsModal);

  /* ↓ Helpers ↓ */

  const handleUpdateWeight = (_, val) => {
    dispatch(
      updateValueAction(
        commonFormProps?.formName,
        "weight",
        val === "half" ? 0.5 : 1
      )
    );
  };

  return (
    <div className="pt-1 pb-1">
      <InputForm
        {...commonFormProps}
        name="name"
        placeholder={t("name")}
        validationName="input.name"
        validateBy="textRequired"
        label="name"
        labelStyle="mb-0 mr-3"
        containerStyle="flex-row d-flex align-items-center"
        inputContainerStyle="my-2 w-100"
        icon="person"
      />

      <div className="d-flex align-items-center  justify-content-between gap-20">
        {!data?.id && !workTimingsModal?.createdFrom && !setupWizard ? (
          <RadioboxForm
            {...commonFormProps}
            options={Constants.WORK_TIME_PROFILE_TYPES}
            validateBy="textRequired"
            label="work time type"
            name="work_timing_profile_type"
            validationName="input.work_timing_profile_type"
            optionsContainerStyle="d-flex justify-content-end"
            containerStyle="custom-tardiness-container justify-content-start mt-1 mb-2"
            optionItemStyle="option-item"
            optionInputStyle=" "
            optionLabelStyle="optionLabelStyle"
            interceptChange={handleUpdateWeight}
          />
        ) : null}
        <InputForm
          {...commonFormProps}
          name="weight"
          label="weight"
          validationName="input.weight"
          validateBy="textRequired"
          rootStyle="w-141"
          containerStyle="d-flex align-items-center gap-10"
          icon="person"
          type="number"
          stepInput
        />
      </div>

      <CheckboxBooleanForm
        {...commonFormProps}
        options={["Employees Need to Sign In At The Start Of Each Work Day"]}
        name="sign_in_req"
        type="checkbox"
        containerStyle="mb-2"
      />

      <AttSignInSection formProps={commonFormProps} />

      <AttTardinessSection formProps={commonFormProps} />
      {data?.sign_in_req ? (
        <div className="mt-3 mb-1 text-right">
          <button
            type="submit"
            onClick={onNext}
            // onClick={()=>console.log(props.data.attendaceProfile)}

            className="btn btn-primary submit_btn_style"
          >
            {t("next")}
          </button>
        </div>
      ) : null}

      {/* Start of limit work timings section */}
      {!data?.sign_in_req ? (
        <LimitWorkTimingsSection formProps={commonFormProps} />
      ) : null}
      {/* End of limit work timings section */}
    </div>
  );
};

export default WorkTimingSignInStep;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MainModal from "../../../Components/MainModal";
import { InputForm } from "form-builder";
import { useMutation } from "@apollo/client";
import {
  onFormResetAction,
  toggleScrapNonSerializedAssets,
} from "../../../Store/Actions";
import { showToast } from "../../../Helpers/HelperFns";

const formName = "scrapNonSerializedForm";
const formNameValidation = "scrapNonSerializedFormClientValidations";

const ScrapNonSerializedAssetsModal = ({ handleRefetch }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [formSubmitting, setFormSubmitting] = useState(false);

  const isOpen = useSelector(
    (state) => state?.assets?.scrapNonSerializedAssetModal?.isOpen
  );
  const items = useSelector(
    (state) => state?.assets?.scrapNonSerializedAssetModal?.items
  );
  const formData = useSelector((state) => state?.assets?.[formName]);
  const clientValidations = useSelector(
    (state) => state?.assets?.[formNameValidation]
  );

  useEffect(() => {
    return () => {
      dispatch(onFormResetAction(formName));
      dispatch(onFormResetAction(formNameValidation));
    };
  }, []);

  const loading = false;

  // const [attemptScrap, { loading }] = useMutation(null, {
  //   onCompleted: (data) => {
  //     if (data?.null?.__typename == "GeneralException") {
  //       showToast("error", data?.null?.message);

  //       return;
  //     }
  //     dispatch(toggleScrapNonSerializedAssets({ isOpen: false }));
  //     handleRefetch();
  //   },
  //   onError(err) {
  //     showToast(
  //       "error",
  //       err?.graphQLErrors?.[0].extensions?.reason ?? err?.message
  //     );
  //   },
  // });

  const handleSubmit = () => {
    setFormSubmitting(true);

    if (!clientValidations?.length) {
      const itemsIds = items?.map((item) => item?.id);
      console.log("items scrappable", itemsIds);
      //   attemptScrap({
      //     variables: {
      //       input: {
      //         id: formData?.id,
      //         name: formData?.name,
      //       },
      //     },
      //   });
    }
  };

  const dismiss = () => {
    dispatch(toggleScrapNonSerializedAssets({ isOpen: false }));
  };

  return (
    <MainModal
      isOpen={isOpen}
      toggle={dismiss}
      modalTitle={formData?.id ? t("edit asset type") : t("new asset type")}
      btnLabel={t("save")}
      btnOnClick={handleSubmit}
      btnSubmitLoading={loading}
    >
      <InputForm
        formName={formName}
        formNameValidation={formNameValidation}
        formSubmitting={formSubmitting}
        reducer="assets"
        validateBy="textRequired"
        name="number"
        placeholder={t("number")}
        label="No. of units"
        labelStyle="mb-2"
        containerStyle="mt-0 mb-3"
        type="number"
      />
    </MainModal>
  );
};

export default ScrapNonSerializedAssetsModal;

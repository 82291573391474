import React, { useEffect, useState } from "react";
import MainModal from "../../Components/MainModal";
import { DateTimePickerForm, CheckboxBooleanForm, BSelect } from "form-builder";
import { connect } from "react-redux";
import {
  dismissAcceptClaimRequestModalAction,
  acceptClaimRequestAction,
} from "../../Store/Actions";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import { useLazyQuery } from "@apollo/client";
import { GET_COST_CENTERS_MENU } from "../../Graphql/query";
import useAllowCostCenter from "../../Helpers/Hooks/useAllowCostCenter";

const formName = "acceptClaimRequestModal";
const formNameValidation = "acceptClaimRequestModalValidation";
const formServerValidation = "acceptClaimRequestModalFormServerValidation";

const AcceptClaimRequestModal = (props) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { allowCostCenter } = useAllowCostCenter();

  const isModalVissible = props.modalData.isVissible;
  const modalMessage = props.modalData.modalMessage;
  const isLoading = props.modalData.isLoading;

  const [
    getCostCentersMenu,
    { loading: costCentersMenuLoading, data: costCentersMenuQueryData },
  ] = useLazyQuery(GET_COST_CENTERS_MENU);

  useEffect(() => {
    if (isModalVissible) {
      getCostCentersMenu();
    }
  }, [isModalVissible]);

  const handleSubmitBtn = () => {
    setIsSubmitting(true);

    if (!props.modalValidation.length) {
      swal({
        title: t("default_warning_accept_message"),
        icon: "warning",
        className: "swal-warning-style",
        dangerMode: true,
        buttons: [t("Cancel"), t("OK")],
      }).then((willDelete) => {
        if (willDelete) {
          props.acceptClaimRequestAction(
            "acceptClaimRequestModalActions",
            props?.acceptClaimRequestModal?.id,
            props?.acceptClaimRequestModal?.payroll_at,
            props?.acceptClaimRequestModal?.include_in_payroll,
            props?.acceptClaimRequestModal?.cost_center_id
          );
        }
      });
    }
  };

  const toggleModal = () => {
    props.dismissAcceptClaimRequestModalAction({
      ref: "acceptClaimRequestModalActions",
      formName,
    });
  };

  useEffect(() => {
    setIsSubmitting(false);
  }, [isModalVissible]);

  return (
    <MainModal
      isOpen={isModalVissible}
      btnLabelCancel="Cancel"
      btnLabel="save"
      btnOnCancelClick={toggleModal}
      modalTitle={`Accept ${props?.acceptClaimRequestModal?.name} Claim Request`}
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={isLoading}
      className="add_accept_claim_request_modal_wrapper_style"
    >
      <div>
        <div className="row">
          <div className="d-flex align-items-baseline justify-content-between col-12">
            <div className="d-flex align-items-baseline">
              <label className="mr-5">{t("amount")}</label>
              <p className="amount_wrapper_style">
                <span>{props?.acceptClaimRequestModal?.currency}</span>
                {props?.acceptClaimRequestModal?.amount}
              </p>
            </div>

            <div className="icon_wrapper_style">
              <span>
                <FontAwesomeIcon icon={faFileAlt} />
              </span>
            </div>
          </div>
        </div>

        <div className="row mb-2">
          <div className="d-flex align-items-baseline col-12">
            <label className="mr-5">{t("reason")}</label>
            <p className="mb-1">{props?.acceptClaimRequestModal?.reason}</p>
          </div>
        </div>

        <div className="row mb-1">
          <div className="col-12">
            <DateTimePickerForm
              name="payroll_at"
              labelStyle="mb-2"
              formName={formName}
              formNameValidation={formNameValidation}
              validationName={`input.payroll_at`}
              formServerValidation={formServerValidation}
              formSubmitting={isSubmitting}
              validateBy="textRequired"
              label={t("payable at")}
              containerStyle="mb-2"
              datePickerContainer="w-100"
              hasIcon
              requestFormat="yyyy-MM-D"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <CheckboxBooleanForm
              setWithValue
              formName={formName}
              formNameValidation={formNameValidation}
              validationName={`input.include_in_payroll`}
              formServerValidation={formServerValidation}
              formSubmitting={isSubmitting}
              options={["Don't include in payroll"]}
              name="include_in_payroll"
              type="checkbox"
            />
          </div>
        </div>

        {allowCostCenter ? (
          <BSelect
            formName={formName}
            formNameValidation={formNameValidation}
            formServerValidation={formServerValidation}
            formSubmitting={isSubmitting}
            name="cost_center_id"
            validationName="input.cost_center_id"
            placeholder={t("select cost center")}
            options={costCentersMenuQueryData?.cost_centers?.data ?? []}
            label="Cost center"
            labelStyle="mt-3 mb-2"
            containerStyle="d-flex align-items-start flex-column"
            inputContainerStyle="w-100"
            icon="money"
            rootStyle="flex-1"
            isLoading={costCentersMenuLoading}
            isDisabled={costCentersMenuLoading}
          />
        ) : null}

        {/* (Start) Form server validation message */}
        <span className="warnig-msg-style">
          {props.serverVaildation[`input.description`] ?? " "}
        </span>
        {/* (End) Form server validation message */}

        {/* (Start) Error Message */}
        {modalMessage && isSubmitting && (
          <div className="warnig-msg-style">{modalMessage}</div>
        )}
        {/* (End) Error Message */}
      </div>
    </MainModal>
  );
};

const mapStateToProps = (state) => ({
  modalData: state.super.acceptClaimRequestModalActions,
  modalValidation: state.super[formNameValidation],
  acceptClaimRequestModal: state.super[formName],
  serverVaildation: state.super[formServerValidation],
});

export default connect(mapStateToProps, {
  dismissAcceptClaimRequestModalAction,
  acceptClaimRequestAction,
})(AcceptClaimRequestModal);

import React, { useEffect, useState } from "react";
import { BSelect, DateTimePickerForm } from "form-builder";
import NotificationHistoryEmployeeCard from "./NotificationHistoryEmployeeCard";
import { useDispatch, useSelector } from "react-redux";
import {
  checkAllNotificationToReadAttempt,
  getNotificationHistoryAttempt,
  NotificationHistoryReset,
  onFormResetAction,
  onInputResetAction,
  setNotificationsPageAction,
} from "../../Store/Actions";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import { Refresh } from "@mui/icons-material";
import { Spinner } from "reactstrap";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";

function NotificationHistory({ showChangeShiftModal }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [infiniteScrollYAxos, setInfiniteScrollYAxos] = useState(0);
  const [isRefreshing, setisRefreshing] = useState(false);
  const formName = "notificationHistoryFilters";
  const watchedAndManagedEmployees = useSelector((state) => state?.super?.watchedAndManagedEmployees);

  const notifications = useSelector(
    (state) => state?.super?.notificationHistory?.data
  );

  const { paginatorInfo, isLoading } = useSelector(
    (state) => state?.super?.notificationHistory
  );

  const notificationsFilters = useSelector(
    (state) => state?.super?.notificationHistoryFilters
  );

  let { employee_id, from, to, page } = notificationsFilters;

  const fetchNotifications = ({
    resetPage,
    resetData,
    fetchEmployeesFilterOptions = false,
  }) => {
    dispatch(
      getNotificationHistoryAttempt({
        employee_id: employee_id ? employee_id : null,
        from: from ? from : null,
        to: to ? to : null,
        page: resetPage ? 1 : page,
        resetData,
        fetchEmployeesFilterOptions: fetchEmployeesFilterOptions,
      })
    );
  };

  useEffect(() => {
    fetchNotifications({
      resetPage: true,
      resetData: true,
      fetchEmployeesFilterOptions: true,
    });
    return () => {
      dispatch(NotificationHistoryReset());
      dispatch(onFormResetAction(formName));
      dispatch(checkAllNotificationToReadAttempt());
    };
  }, []);

  useEffect(() => {
    if (page !== 1) {
      // not resetting page number and notifications data
      fetchNotifications({
        resetPage: false,
        resetData: false,
      });
    }
  }, [page]);

  const fetchNotificationsAndSkipFilterOptions = () => {
    fetchNotifications({
      resetPage: true,
      resetData: true,
    });
  };

  const skipFirstRender = useDidUpdateEffect(
    fetchNotificationsAndSkipFilterOptions,
    [employee_id, from, to]
  );

  useEffect(() => {
    if (!isLoading) {
      setisRefreshing(false);
    }
  }, [isLoading]);

  const handleEndReached = () => {
    if (!isLoading && paginatorInfo?.hasMorePages) {
      dispatch(setNotificationsPageAction(page + 1));
    }
  };

  const handleRefresh = () => {
    setisRefreshing(true);
    setInfiniteScrollYAxos(0);
    // resetting page number and notifications data
    fetchNotifications({
      resetPage: true,
      resetData: true,
    });
    dispatch(onInputResetAction(formName, "page"));
  };

  return (
    <HasPrivileges allowBP reqireMain={[Privilages.VIEW_NOTIFICATIONS_HISTORY]}>
      <div className="notifications-history-container">
        <button
          onClick={handleRefresh}
          className={`notifications-refresh-button ${
            isRefreshing ? "notifications-refresh-button-active" : ""
          }`}
        >
          <Refresh />
        </button>
        <div className="notification-history-filter-wrapper mb-2">
          <div className="notification-history-filter-header row">
            <div className="col-12 notification-history-header-title">
              {t("employee")}
            </div>
            <div className="notification-history-filter-input-wrapper row"></div>
            <div className="col-12 notification-history-header-title">
              <BSelect
                reducer="super"
                name="employee_id"
                formName={formName}
                options={watchedAndManagedEmployees.sort((a, b) =>
                  a?.name > b?.name ? 1 : -1
                )}
                keepDefaultStyle
                optionLabel="name"
                optionValue="id"
                isMulti={false}
                placeholder="select employee"
                labelStyle=""
                isClearable
                icon="person"
              />
            </div>
            <div className="col-6 notification-history-header-title">
              <DateTimePickerForm
                formName={formName}
                containerStyle="custom_datepicker_wrapper_style"
                name="from"
                label="from"
                labelStyle="mb-2"
                hasIcon
                allowClear
              />
            </div>
            <div className="col-6 notification-history-header-title">
              <DateTimePickerForm
                formName={formName}
                containerStyle="custom_datepicker_wrapper_style"
                name="to"
                label="to"
                labelStyle="mb-2"
                hasIcon
                allowClear
              />
            </div>
          </div>
        </div>
        {isLoading && notifications.length < 1 ? (
          <div className="notifications-first-loader-container">
            <Spinner
              style={{ width: "3rem", height: "3rem", color: "#23aaeb" }}
            />
          </div>
        ) : (
          <div
            id="scrollableDiv"
            style={{
              height: 500,
              overflow: "auto",
            }}
          >
            {/*Put the scroll bar always on the bottom*/}
            <InfiniteScroll
              dataLength={notifications?.length} //This is important field to render the next data
              next={handleEndReached}
              hasMore={paginatorInfo?.hasMorePages}
              initialScrollY={infiniteScrollYAxos}
              loader={
                <div className="notifications-list-fetch-more-loader">
                  <Spinner
                    style={{
                      width: "2rem",
                      height: "2rem",
                      color: "#23aaeb",
                    }}
                  />
                </div>
              }
              scrollableTarget="scrollableDiv"
            >
              {notifications.length ? (
                notifications.map((item, index) => {
                  return (
                    <NotificationHistoryEmployeeCard
                      showChangeShiftModal={showChangeShiftModal}
                      handleRefresh={handleRefresh}
                      notification={item}
                      key={item.id}
                    />
                  );
                })
              ) : (
                <div className="notification-list-no-data">
                  {t("notifications history is empty")}
                </div>
              )}
            </InfiniteScroll>
          </div>
        )}
      </div>
    </HasPrivileges>
  );
}

export default NotificationHistory;

import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";

import {
  fetchEditRoleAction,
  showSystemRolePlanModal,
  toogleViewPlanPrivileges,
} from "../../Store/Actions";
import {
  deletePackageMutation,
  setPackageStatusMutation,
  deletePackageRoleMutation,
} from "../../Graphql/mutation/PaymentTracking";
import swal from "sweetalert";
import { connect } from "react-redux";
import { showToast } from "../../Helpers/HelperFns";
import Privileges from "../../Constants/Privilages";
import { packageProfileQuery } from "../../Graphql/query/PaymentTracking";
import { serializeFetchPackageProfile } from "../../Helpers/HelperFns/PaymentTracking";

import {
  AddPackageRoleModal,
  EditPackageRoleModal,
  ViewPackagePrivileges,
} from "../../Components/PaymentTrackingModals";
import {
  EditIconButton,
  RemoveIconButton,
} from "../../Components/IconButtonWithTooltip";
import BlockUi from "react-block-ui";
import { Link } from "react-router-dom";
import Loader from "../../Components/Loader";
import AntSwitch from "../../Components/AntSwitch";
import { AddButton } from "../../Components/Buttons";
import { KeyboardBackspace } from "@mui/icons-material";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faEye, faPen } from "@fortawesome/free-solid-svg-icons";

const PackageProfile = (props) => {
  const { id } = useParams();
  const { t } = useTranslation();

  // Local State
  const [data, setData] = React.useState({});

  // Server State
  const [deletePackage, { loading: deleteLoading }] = useMutation(
    deletePackageMutation
  );
  const [setPackageStatus, { loading: setStatusLoading }] = useMutation(
    setPackageStatusMutation
  );
  const [deletePackageRole, { loading: deleteRoleLoading }] = useMutation(
    deletePackageRoleMutation
  );
  const { loading, refetch } = useQuery(packageProfileQuery, {
    notifyOnNetworkStatusChange: true,
    variables: { id },
    onCompleted: (res) => {
      setData(res?.package ? serializeFetchPackageProfile(res?.package) : {});
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });

  /* ↓ Helpers ↓ */

  const handleTogglePackageStatus = (active) => {
    setPackageStatus({
      variables: { id, status: active ? "active" : "inactive" },
      onCompleted: () => {
        refetch();
        showToast("success");
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message
        );
      },
    });
  };

  const handleDeletePackage = () => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((confirmed) => {
      if (confirmed) {
        deletePackage({
          variables: { id },
          onCompleted: () => {
            refetch();
            showToast("success");
          },
          onError: (err) => {
            showToast(
              "error",
              err?.graphQLErrors?.[0]?.extensions?.reason ||
                err?.graphQLErrors?.[0]?.message ||
                err?.message
            );
          },
        });
      }
    });
  };

  const handleAddPackageRole = () => {
    props.showSystemRolePlanModal();
  };

  const handleDeletePackageRole = (roleId) => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((confirmed) => {
      if (confirmed) {
        deletePackageRole({
          variables: { roleId },
          onCompleted: () => {
            refetch();
            showToast("success");
          },
          onError: (err) => {
            showToast(
              "error",
              err?.graphQLErrors?.[0]?.extensions?.reason ||
                err?.graphQLErrors?.[0]?.message ||
                err?.message
            );
          },
        });
      }
    });
  };

  return (
    <BlockUi tag="section" className="content pt-3" blocking={loading}>
      {deleteLoading || setStatusLoading || deleteRoleLoading ? (
        <Loader fixed />
      ) : null}

      {/* Header */}
      <header>
        <Link
          className="back-btn d-flex align-items-center position-sticky fit-width"
          to={`/payment-tracking?tab=packages`}
        >
          <KeyboardBackspace className="back-icon" />
          <span>{t("back")}</span>
        </Link>

        <div className="d-flex align-items-center justify-content-between mt-4">
          <div className="d-flex align-items-center gap-20">
            <span className="main-title-style text-capitalize h3 my-0">
              {data?.name}
            </span>
            <span className="h4 mb-0">
              {data?.is_free ? t("free") : t("paid")}
              {data?.trial_value ? ` - ${data?.trial_value} days trial` : null}
            </span>
          </div>

          <div className="d-flex align-items-center gap-10">
            <HasPrivileges
              reqireMain={[
                Privileges.VIEW_PACKAGES_PRIVILEGES,
                Privileges.SUPER_PRIVILEGE,
              ]}
            >
              <HasPrivileges
                reqireMain={[Privileges.SUPER_PRIVILEGE]}
                altChildren={
                  <button
                    className="btn btn-secondary fit-width h-25px text-capitalize py-0"
                    onClick={() => props.toogleViewPlanPrivileges()}
                  >
                    <FontAwesomeIcon
                      size="md"
                      color="#fff"
                      icon={faEye}
                      className="mr-2"
                    />
                    {t("view privileges")}
                  </button>
                }
              >
                <button
                  className="btn btn-secondary fit-width h-25px text-capitalize py-0"
                  onClick={() => props.toogleViewPlanPrivileges()}
                >
                  <FontAwesomeIcon
                    size="md"
                    color="#fff"
                    icon={faPen}
                    className="mr-2"
                  />
                  {t("Edit privileges")}
                </button>
              </HasPrivileges>
            </HasPrivileges>
            <HasPrivileges
              reqireMain={[
                Privileges.ACTIVATE_DEACTIVATE_PACKAGES,
                Privileges.SUPER_PRIVILEGE,
              ]}
              altChildren={
                <span className="mx-2" role="button">
                  <AntSwitch checked={data?.status === "active"} disabled />
                </span>
              }
            >
              <span className="mx-2" role="button">
                <AntSwitch
                  checked={data?.status === "active"}
                  onChange={(_, val) => handleTogglePackageStatus(val)}
                />
              </span>
            </HasPrivileges>
            <HasPrivileges
              reqireMain={[
                Privileges.SUPER_PRIVILEGE,
                Privileges.ADD_EDIT_DELETE_PACKAGES,
              ]}
            >
              <RemoveIconButton onClick={handleDeletePackage} />
              <EditIconButton href={`/payment-tracking/edit-package/${id}`} />
            </HasPrivileges>
          </div>
        </div>

        <div
          aria-hidden="true"
          style={{ height: "2px" }}
          className="bg-dark d-flex my-1"
        />
      </header>

      {/* Content */}
      <div
        className="card p-4 mt-4"
        style={{
          borderRadius: "3px",
          backgroundColor: "#fff",
          border: "solid 1px rgba(220, 220, 220, 0.84)",
        }}
      >
        <div className="d-flex flex-wrap align-items-center justify-content-between gap-20">
          <ViewCell
            label="active subscribers"
            value={data?.activeSubscriptions}
          />
          <div className="d-flex flex-wrap justify-content-between gap-20">
            <ViewCell
              label="offices"
              value={
                data?.features?.filter((feat) => feat?.id === "2")[0]?.limit ??
                t("unlimited")
              }
            />
            <ViewCell
              label="attendance profiles"
              value={
                data?.features?.filter((feat) => feat?.id === "6")[0]?.limit ??
                t("unlimited")
              }
            />
            <ViewCell
              label="normal work timings"
              value={
                data?.features?.filter((feat) => feat?.id === "5")[0]?.limit ??
                t("unlimited")
              }
            />
            <ViewCell
              label="employees"
              value={
                data?.features?.filter((feat) => feat?.id === "1")[0]?.limit ??
                t("unlimited")
              }
            />
          </div>
        </div>
        <hr />

        {/* Roles Templates */}
        <HasPrivileges
          reqireMain={[
            Privileges.VIEW_PACKAGE_ROLE_TEMPLATES,
            Privileges.SUPER_PRIVILEGE,
          ]}
        >
          <div className="d-flex align-items-center gap-20">
            <b className="text-16">{t("roles templates")}</b>
            <HasPrivileges
              reqireMain={[
                Privileges.ADD_EDIT_DELETE_PACKAGE_ROLE_TEMPLATES,
                Privileges.SUPER_PRIVILEGE,
              ]}
            >
              <AddButton isIconOnly onClick={handleAddPackageRole} />
            </HasPrivileges>
          </div>

          <div className="mt-3">
            {data?.roles?.map((role, i) => (
              <div key={i} className="d-flex align-items-center justify-content-between max-w-308">
                <div className="d-flex align-items-center gap-10">
                  <FontAwesomeIcon size="xs" color="#23aaeb" icon={faCircle} />
                  <span>{role.name}</span>
                </div>

                <HasPrivileges
                  reqireMain={[
                    Privileges.ADD_EDIT_DELETE_PACKAGE_ROLE_TEMPLATES,
                    Privileges.SUPER_PRIVILEGE,
                  ]}
                >
                  <div className="d-flex align-items-center gap-10">
                    <EditIconButton
                      onClick={() => props.fetchEditRoleAction(id, role?.id)}
                    />
                    <RemoveIconButton
                      onClick={() => handleDeletePackageRole(role?.id)}
                    />
                  </div>
                </HasPrivileges>
              </div>
            ))}
          </div>
        </HasPrivileges>
      </div>

      {/* Modals */}
      <AddPackageRoleModal />
      <EditPackageRoleModal />
      <ViewPackagePrivileges />
    </BlockUi>
  );
};

const mapStateToProps = (state) => {
  return {
    systemPlan: state.admin?.viewedPlan,
  };
};

export default connect(mapStateToProps, {
  showSystemRolePlanModal,
  toogleViewPlanPrivileges,
  fetchEditRoleAction,
})(PackageProfile);

const ViewCell = ({ label, value }) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex align-items-center gap-10">
      <b className="h4 m-0">{t(label)}</b>
      <span className="sub-title-style">{value}</span>
    </div>
  );
};

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";

// Apollo
import { useLazyQuery } from "@apollo/client";
import { fetchWorkGroupsQuery, fetchWorkTeamsQuery } from "../../Graphql/query";

// Icons
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { WorkTeamModal } from "../../Components/WorkTeamModal";

// Helper Functions
import HelperFns from "../../Helpers/HelperFns";

// Ui
import Swal from "sweetalert2";
import Loader from "../../Components/Loader";
import { BSelect, InputForm } from "form-builder";
import { useSelector, useDispatch } from "react-redux";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import { onFormResetAction } from "../../Store/Actions";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import { PreviewIconButton } from "../../Components/IconButtonWithTooltip";
import EmployeesModal from "../../Components/SharedModals/EmployeesModal";

const employeeModalInitState = { data: null, isOpen: false };
const formProps = {
  formName: "workTeamFilters",
  reducer: "shifts",
};

const ViewWorkTeams = () => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [employeeModalState, setEmployeeModalState] = useState(
    employeeModalInitState
  );
  const { t } = useTranslation();
  const [pagination, setPagination] = useState({
    lastItem: 20,
    total: 20,
    perPage: 20,
    currentPage: 1,
    hasMorePages: true,
    firstItem: 1,
    lastPage: 4,
  });

  const { search, workGroupId } = useSelector(
    (state) => state?.shifts?.[formProps.formName]
  );

  // Apollo hooks
  const [fetchWorkTeams, { data, loading: fetchWorkTeamsLoading }] =
    useLazyQuery(fetchWorkTeamsQuery, {
      variables: {
        name: search,
        first: pagination?.perPage,
        page: pagination?.currentPage,
        workGroupId: workGroupId ? workGroupId : null,
      },
      onCompleted: (data) => {
        setPagination(data?.workTeams?.paginatorInfo);
      },
      onError: (error) => {
        Swal.fire({
          title: t("error"),
          text: error.message ? error.message : t("something went wrong"),
          icon: "error",
          className: "swal-error-style",
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
          showDenyButton: false,
        });
      },
    });

  useEffect(() => {
    fetchWorkTeams();
    return () => {
      dispatch(onFormResetAction(formProps.formName));
    };
  }, []);

  const skipFirstRender = useDidUpdateEffect(() => {
    fetchWorkTeams({
      variables: {
        page: 1,
      },
    });
  }, [search, workGroupId]);

  const handlePaginate = (page) => {
    fetchWorkTeams({
      variables: {
        page,
      },
    });
  };

  const columns = [
    {
      name: t("name"),
      wrap: true,
      selector: "name",
      sortable: false,
      grow: 1,
      cell: (row) => (
        <div className="name_entry">
          <PersonOutlineIcon />
          <span dir={HelperFns.isStringRTL(row?.name) ? "rtl" : "ltr"}>
            <Link to={`/employees/work-teams/${row?.id}`}>{row?.name}</Link>
          </span>
        </div>
      ),
    },
    {
      name: t("work group"),
      selector: "workGroup",
      wrap: true,
      sortable: false,
      grow: 1,
      cell: (row) => (
        <span dir={HelperFns.isStringRTL(row?.workGroup?.name) ? "rtl" : "ltr"}>
          {row?.workGroup?.name ?? "----------"}
        </span>
      ),
    },
    {
      name: t("No. Of Employees"),
      wrap: true,
      selector: "employees",
      sortable: false,
      grow: 1,
      cell: (row) => row?.employees?.length ?? "----------",
    },
    {
      cell: (row) => (
        <PreviewIconButton
          label="Preview employees"
          onClick={() =>
            setEmployeeModalState({
              isOpen: true,
              data: { id: row?.id, isWorkgroup: false },
            })
          }
        />
      ),
    },
  ];

  return (
    <>
      {/* Search Header */}
      <div className="d-flex align-items-end gap-20 mt-3">
        <div className="input-container-style-default search_container">
          <InputForm
            {...formProps}
            name="search"
            placeholder={t("search")}
            icon="search"
            containerStyle=" "
            rootStyle="w-100"
            inputStyle="ml-2"
          />
        </div>

        <div className="input-container-style-default search_container">
          <BSelect
            {...formProps}
            name="workGroupId"
            keepDefaultStyle
            placeholder={t("Select work group")}
            options={data?.workGroupsOptions?.data}
            skipLocalization
            icon="people"
            isClearable
            rootStyle="w-100"
            containerStyle=" "
          />
        </div>

        <HasPrivileges reqireMain={[Privilages.ADD_EDIT_TEAMS]} allowBP>
          <button
            className="btn add_new_btn_style"
            onClick={() => setIsModalOpen(true)}
          >
            <AddIcon className="add_btn_icon" />
            {t("add new")}
          </button>
        </HasPrivileges>
      </div>

      <div className="w-100 mt-4" style={{ overflow: "auto" }}>
        <div style={{ minWidth: "1200px" }}>
          <DataTable
            className="cards_table"
            columns={columns}
            data={data?.workTeams?.data}
            noHeader
            persistTableHead
            sortServer
            progressPending={fetchWorkTeamsLoading}
            progressComponent={<Loader />}
          />
        </div>
      </div>

      <Pagination
        customPaginator={pagination}
        styleWraper=""
        onPaginate={handlePaginate}
      />

      {isModalOpen ? (
        <WorkTeamModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          fetchWorkTeams={fetchWorkTeams}
        />
      ) : null}
      {employeeModalState.isOpen ? (
        <EmployeesModal
          data={employeeModalState.data}
          onClose={() => setEmployeeModalState(employeeModalInitState)}
        />
      ) : null}
    </>
  );
};

export default ViewWorkTeams;

import moment from "moment";
import { assignmentTypesConstants } from "../../Constants";

/*
  Attendance History
*/

/*
  Penalties
*/

/*
  Assignments
*/

export const serializeFetchingAssignment = ({
  id,
  name,
  from,
  to,
  apply_on,
  normal_work_timing_id,
  first_half_work_timing_id,
  second_half_work_timing_id,
  retain_default_work_timing,
  assignmentApplicants,
  employeeExcludes,
  offices,
  assignmentCompensationConfigurations,
  assignmentAbsenceAction,
  canEditFrom,
  canEditTo,
  canEdit,
  canEditRetain,
  normalWorkTiming,
  firstHalfWorkTiming,
  secondHalfWorkTiming,
  createdBy,
  created_at,
  ignoredEmployees,
  treat_as_normal,
  allow_permission,
  allow_overtime,
  allow_home,
  workplace_setting,
  additional_work_places,
  workplace_setting_signout,
  additional_work_places_signout,
  workplace_setting_identical,
  weight,
  is_holiday = false,
  change_shift,
  cost_center = {},
}) => {
  let award_bonus_index = assignmentCompensationConfigurations?.find(
    (compensation) => compensation?.type?.toLowerCase() === "bonus"
  );
  let additional_leaves_index = assignmentCompensationConfigurations?.find(
    (compensation) => compensation?.type?.toLowerCase() === "leaves"
  );
  let in_lieu_of_workday = assignmentCompensationConfigurations?.find(
    (compensation) => compensation?.type?.toLowerCase() === "request"
  );
  let fetchedData = {
    id,
    name,
    from,
    to,
    apply_on,
    treat_as_normal,
    payment_factor_id: award_bonus_index?.payment_factor_id,
    normal_work_timing_id,
    first_half_work_timing_id,
    second_half_work_timing_id,
    penalise: assignmentAbsenceAction?.penalise,
    no_compensation: assignmentAbsenceAction?.compensate ? 0 : 1,
    retain_default_work_timing: +retain_default_work_timing,
    exclude_department_employees:
      apply_on.toLowerCase() == "department" && employeeExcludes.length ? 1 : 0,
    exclude_office_employees:
      apply_on.toLowerCase() == "office" && employeeExcludes.length ? 1 : 0,
    department_employee_excludes:
      apply_on.toLowerCase() == "department"
        ? employeeExcludes?.map((emp) => emp?.user?.id)
        : [],
    office_employee_excludes:
      apply_on.toLowerCase() == "office"
        ? employeeExcludes?.map((emp) => emp?.user?.id)
        : [],
    employee_applicable_on_ids:
      apply_on == "employee"
        ? assignmentApplicants.map(
            (applicant) => applicant?.applicable?.user?.id
          )
        : [],
    department_applicable_on_ids:
      apply_on == "department"
        ? assignmentApplicants.map((applicant) => applicant?.applicable?.id)
        : [],
    office_applicable_on_ids:
      apply_on == "office"
        ? assignmentApplicants.map((applicant) => applicant?.applicable?.id)
        : [],
    compensate_for_extra_time_worked:
      assignmentCompensationConfigurations?.length ? 1 : 0,
    award_bonus_days_quantity: award_bonus_index
      ? award_bonus_index.quantity
      : null,
    compensation: additional_leaves_index
      ? "leaves"
      : award_bonus_index
      ? "bonus"
      : in_lieu_of_workday
      ? "request"
      : "",
    inLieuOfWorkdayDate:
      assignmentCompensationConfigurations?.[0]?.request?.time_from,
    inLieuOfWorkdayRequestable:
      assignmentCompensationConfigurations?.[0]?.request?.requestables?.[0]?.id,
    award_additional_leaves_quantity: additional_leaves_index
      ? additional_leaves_index.quantity
      : null,
    allow_employees_to_request_half_days:
      first_half_work_timing_id || second_half_work_timing_id ? 1 : 0,
    payable_at: award_bonus_index
      ? moment(award_bonus_index.month_year_payroll, "DD-MM-YYYY").format(
          "YYYY/MM/DD"
        )
      : "",
    // month: award_bonus_index
    //   ? moment(award_bonus_index.month_year_payroll, "DD-MM-YYYY").format("YYYY/MM/DD")
    //   : new Date().getMonth() + 1,
    // year: award_bonus_index
    //   ? moment(award_bonus_index.month_year_payroll, "MM-YYYY").format("YYYY")
    //   : new Date().getFullYear(),
    canEditFrom,
    canEditTo,
    canEdit,
    canEditRetain,
    assignmentType: is_holiday
      ? assignmentTypesConstants.HOLIDAY
      : !!normal_work_timing_id
      ? treat_as_normal
        ? assignmentTypesConstants.NORMAL_WORK_DAY
        : assignmentTypesConstants.EXCEPTIONAL_SHIFT
      : assignmentTypesConstants.DAY_OFF,
    applied_on_names:
      apply_on.toLowerCase() == "employee"
        ? assignmentApplicants.map(
            (applicant) => applicant?.applicable?.user?.name
          )
        : assignmentApplicants.map((applicant) => applicant?.applicable?.name),
    excluded_employees_names: employeeExcludes?.map((emp) => emp.user?.name),
    normalWorkTiming,
    firstHalfWorkTiming,
    secondHalfWorkTiming,
    assignment_location_names: offices?.map((offices) => offices?.name),
    createdBy,
    created_at,
    ignoredEmployees,
    allow_permission,
    allow_overtime,
    allow_home,
    apply_work_timing_location: +(workplace_setting === "work_timing_location"),
    workplace_setting_identical,
    workplace_setting,
    workplace_setting_signout,
    additional_work_places,
    additional_work_places_signout,
    weight,
    change_shift,
    cost_center_id: cost_center?.id,
    cost_center_name: cost_center?.name,
  };
  return fetchedData;
};

export const serializeUpsertingAssignment = ({
  id,
  name,
  from,
  to,
  apply_on,
  employee_applicable_on_ids,
  department_applicable_on_ids,
  office_applicable_on_ids,
  department_employee_excludes,
  exclude_department_employees,
  exclude_office_employees,
  office_employee_excludes,
  normal_work_timing_id,
  first_half_work_timing_id,
  second_half_work_timing_id,
  penalise,
  no_compensation,
  // year,
  // month,
  payable_at,
  retain_default_work_timing,
  award_bonus_days_quantity,
  award_additional_leaves_quantity,
  compensation,
  allow_permission,
  allow_overtime,
  allow_home,
  workplace_setting,
  additional_work_places,
  workplace_setting_signout,
  additional_work_places_signout,
  workplace_setting_identical,
  apply_work_timing_location,
  weight,
  assignmentType,
  allow_employees_to_request_half_days,
  inLieuOfWorkdayDate,
  inLieuOfWorkdayRequestableObject,
  payment_factor_id,
  cost_center_id,
}) => {
  let compensationConfig = [
    compensation === "bonus"
      ? {
          type: "bonus",
          payment_factor_id,
          quantity: parseFloat(award_bonus_days_quantity),
          month_year_payroll: moment(payable_at).format("YYYY-MM-DD"),
        }
      : null,
    compensation === "leaves"
      ? {
          type: "leaves",
          quantity: parseFloat(award_additional_leaves_quantity),
          month_year_payroll: null,
        }
      : null,
    compensation === "request"
      ? {
          type: "request",
          request: {
            date: moment(inLieuOfWorkdayDate).format("DD-MM-YYYY"),
            requestable: {
              requestableId: inLieuOfWorkdayRequestableObject?.id,
              requestableType: inLieuOfWorkdayRequestableObject?.typename,
            },
          },
        }
      : null,
  ];
  let submitData = {
    id,
    name,
    weight:
      assignmentType == assignmentTypesConstants.NORMAL_WORK_DAY
        ? parseFloat(weight)
        : 0,
    from: from?.replaceAll("/", "-"),
    to: to?.replaceAll("/", "-"),
    apply_on: apply_on,
    applicable_on_ids:
      apply_on?.toLowerCase() === "employee"
        ? employee_applicable_on_ids?.map((applicable_id) => +applicable_id)
        : apply_on?.toLowerCase() === "department"
        ? department_applicable_on_ids?.map((applicable_id) => +applicable_id)
        : office_applicable_on_ids?.map((applicable_id) => +applicable_id),

    assignment_employee_excludes: exclude_department_employees
      ? department_employee_excludes?.map(
          (excluded_employee) => +excluded_employee
        )
      : exclude_office_employees
      ? office_employee_excludes?.map((excluded_employee) => +excluded_employee)
      : [],

    cost_center_id,

    ...(assignmentType != assignmentTypesConstants.DAY_OFF &&
    assignmentType != assignmentTypesConstants.HOLIDAY
      ? {
          normal_work_timing_id: +normal_work_timing_id,
          first_half_work_timing_id:
            assignmentType == assignmentTypesConstants.NORMAL_WORK_DAY &&
            !!allow_employees_to_request_half_days
              ? +first_half_work_timing_id
              : null,
          second_half_work_timing_id:
            assignmentTypesConstants.NORMAL_WORK_DAY &&
            !!allow_employees_to_request_half_days
              ? +second_half_work_timing_id
              : null,
          retain_default_work_timing:
            assignmentType == assignmentTypesConstants.NORMAL_WORK_DAY
              ? retain_default_work_timing
              : 1, // if the assingment is an exception it should always retain

          treat_as_normal:
            assignmentType == assignmentTypesConstants.NORMAL_WORK_DAY,
          workplace_setting_identical:
            apply_work_timing_location || workplace_setting_identical,
          workplace_setting: apply_work_timing_location
            ? "work_timing_location"
            : workplace_setting,
          additional_work_places,
          workplace_setting_signout: apply_work_timing_location
            ? "work_timing_location"
            : workplace_setting_identical
            ? workplace_setting
            : workplace_setting_signout,
          additional_work_places_signout: !workplace_setting_identical
            ? additional_work_places_signout
            : additional_work_places,

          ...(assignmentType == assignmentTypesConstants.EXCEPTIONAL_SHIFT
            ? {
                allow_permission,
                allow_overtime,
                allow_home,
                assignment_compensation_configurations:
                  assignmentType ==
                    assignmentTypesConstants.EXCEPTIONAL_SHIFT && !!compensation
                    ? compensationConfig?.filter((conf) => conf != null)
                    : [],
                assignment_absence_action: {
                  penalise: penalise ? true : false,
                  compensate: no_compensation ? false : true,
                },
              }
            : {}),
        }
      : {
          retain_default_work_timing: 0,
          treat_as_normal: true,
          ...(assignmentType == assignmentTypesConstants.HOLIDAY
            ? {
                is_holiday: true,
              }
            : {}),
        }),
  };

  return submitData;
};

export const serializeFetchChangeAssignment = (data) => {
  return {
    ...data,
    date: data?.from,
    retain_default_work_timing: +data?.retain_default_work_timing,
    employee_applicable_on_ids: data?.assignmentApplicants.map(
      (applicant) => applicant?.applicable?.user?.id
    ),
    apply_work_timing_location: +(
      data?.workplace_setting === "work_timing_location"
    ),
    allow_employees_to_request_half_days:
      data?.first_half_work_timing_id || data?.second_half_work_timing_id
        ? 1
        : 0,
    cost_center_id: data?.cost_center?.id,
  };
};

export const serializeUpsertChangeAssignment = ({ isChangeShift, ...data }) => {
  return {
    id: data?.id,
    change_shift: true,
    name: isChangeShift ? "change shift" : "additional shift",
    from: data?.date?.replaceAll("/", "-"),
    to: data?.date?.replaceAll("/", "-"),
    weight: parseFloat(data?.weight),
    treat_as_normal: true,
    apply_on: "employee",
    normal_work_timing_id: +data?.normal_work_timing_id,
    first_half_work_timing_id: +data?.first_half_work_timing_id,
    second_half_work_timing_id: +data?.second_half_work_timing_id,
    retain_default_work_timing: isChangeShift ? 0 : 1,
    applicable_on_ids: data?.employee_applicable_on_ids?.map(
      (applicable_id) => +applicable_id
    ),
    workplace_setting_identical:
      data?.apply_work_timing_location || data?.workplace_setting_identical,
    workplace_setting: data?.apply_work_timing_location
      ? "work_timing_location"
      : data?.workplace_setting,
    additional_work_places: data?.additional_work_places,
    workplace_setting_signout: data?.apply_work_timing_location
      ? "work_timing_location"
      : data?.workplace_setting_identical
      ? data?.workplace_setting
      : data?.workplace_setting_signout,
    additional_work_places_signout: !data?.workplace_setting_identical
      ? data?.additional_work_places_signout
      : data?.additional_work_places,
    cost_center_id: data?.cost_center_id,
  };
};

/*
  Monthly Report
*/

/*
  Yearly Report
*/

/*
  Facial Report
*/

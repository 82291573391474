import React from "react";
import { useParams, useLocation } from "react-router-dom";

import {
  LeadsList,
  TrialsList,
  RemindersList,
  ActivitiesList,
} from "../../Containers/CRMLists";
import BlockUi from "react-block-ui";
import { CRMTabs } from "./CRMPage";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

let loading = false;
const tabsPanels = [
  {
    value: "1",
    label: "Leads",
    Panel: LeadsList,
  },
  {
    value: "2",
    label: "Activities",
    Panel: ActivitiesList,
  },
  {
    value: "3",
    label: "Reminders",
    Panel: RemindersList,
  },
  {
    value: "4",
    label: "Trials",
    Panel: TrialsList,
  },
];

const CRMAdminPage = () => {
  const { id } = useParams();
  const location = useLocation();
  const [value, setValue] = React.useState("1");

  // Constants
  const { name } = location.state || {};

  /* Helpers */

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <BlockUi tag="div" className="content pt-3" blocking={loading}>
      <CRMTabs />

      <div className="mt-4">
        <h1>{name}</h1>
      </div>

      <TabContext value={value}>
        {/* Tabs */}
        <Box sx={{ mt: 3, borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange}>
            {tabsPanels.map((report) => (
              <Tab
                key={report.value}
                label={report.label}
                value={report.value}
              />
            ))}
          </TabList>
        </Box>

        {/* Panels */}
        {tabsPanels.map((report) => (
          <TabPanel key={report.value} value={report.value} sx={{ p: 1 }}>
            <report.Panel adminID={id} />
          </TabPanel>
        ))}
      </TabContext>
    </BlockUi>
  );
};

export default CRMAdminPage;

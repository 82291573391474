import { client } from "../../Services/Client";
import Types from "../Actions/types";
import { merge } from "lodash";

export const initState = {
  user: {},
  userProfile: {},
  userProfileRef: {},
  authForm: {
    username: "",
  },
  isLoading: false,
  logingStatus: false,
  errorMessage: "",
  isLogedIn: false,
  token: null,
  tokenRef: null,
  loginError: null,
  isSignedIn: false,
  officesList: [{ id: "", name: "all" }],
  shouldExitCalendarPage: false,
};

export const authReducer = (state = initState, action) => {
  switch (action.type) {
    case Types.LOGING_IN_ATTEMPT:
      return {
        ...state,
        isLoading: true,
        loginErr: null,
        authForm: {
          username: action?.payload?.user?.username,
        },
      };

    case Types.LOGED_IN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        token: action?.payload?.token ?? null,

        // errorMessage:""
        // isLogedIn:true
      };

    case Types.LOGED_IN_FAILED:
      return { ...state, isLoading: false, errorMessage: action.payload };

    // fetching Profile
    case Types.FETCH_PROFILE_SUCCESS:
    case Types.UPSERT_USER_LOCALE_SUCCESS:
      return {
        ...state,
        userProfile: { ...state.userProfile, ...action.payload.user },
      };

    case Types.ON_INPUT_RESET:
      if ([action.payload.formName] in state) {
        return {
          ...state,
          [action.payload.formName]: {
            ...state[action.payload.formName],
            [action.payload.name]:
              initState[action.payload.formName][action.payload.name],
          },
        };
      } else {
        return state;
      }

    case Types.ON_INPUT_RESET_WITH_VALUE:
      if ([action.payload.formName] in state) {
        return {
          ...state,
          [action.payload.formName]: {
            ...state[action.payload.formName],
            [action.payload.name]: action.payload?.resetValue,
          },
        };
      } else {
        return state;
      }

    case Types.LOG_OUT:
      return { ...initState };

    case "SWITCH_ACCOUNT":
      client.resetStore();

      const persistedLocalStorage = {
        ...JSON.parse(localStorage.getItem("persist:attendance_root")),
      };

      const authReducer = {
        ...JSON.parse(persistedLocalStorage?.auth),
      };

      const resultState = {
        ...persistedLocalStorage,
        auth: JSON.stringify({
          ...authReducer,
          userProfile: action?.payload?.account?.user,
          token: action?.payload?.account?.access_token,
        }),
      };

      localStorage.setItem(
        "persist:attendance_root",
        JSON.stringify(resultState)
      );

      setTimeout(() => {
        window.location.href = "/";
      }, 0);

      localStorage.setItem("should-refresh", Date.now());

      return {
        ...initState,
        userProfile: action?.payload?.account?.user,
        token: action?.payload?.account?.access_token,
      };

    case Types.SET_SHOULD_EXIT_SCHEDULE_PAGE: {
      return {
        ...state,
        shouldExitCalendarPage: action.payload,
      };
    }

    case "LOG_ON_OUT_BEHALF":
      client.resetStore();
      const persistedData = {
        ...JSON.parse(localStorage.getItem("persist:attendance_root")),
      };

      const authReducerTwo = {
        ...JSON.parse(persistedData?.auth),
      };

      const finalState = {
        ...persistedData,
        auth: JSON.stringify({
          ...authReducerTwo,
          ...action.payload,
        }),
      };

      localStorage.setItem(
        "persist:attendance_root",
        JSON.stringify(finalState)
      );

      setTimeout(() => {
        window.location.href = action?.isOut ? "/admin-panel" : "/";
      }, 0);

      localStorage.setItem("should-refresh", Date.now());

      return merge({}, state, action.payload);

    default:
      return state;
  }
};

import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";

import i18n from "i18next";
import Constants from "../../Constants";
import { makeStyles } from "tss-react/mui";
import HelperFns from "../../Helpers/HelperFns";
import Privilages from "../../Constants/Privilages";

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import DangerousIcon from "@mui/icons-material/Dangerous";

const verticalNavbarStyles = makeStyles()(() => Constants.verticalNavbarStyles);
const horizontalNavbarStyles = makeStyles()(
  () => Constants.horizontalNavbarStyles
);

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const AssetsTabs = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  let query = useQuery();

  const handleChange = (_event, newValue) => {
    history.push(`/assets?tab=${newValue}`);
  };

  const activeValue = query.get("tab");

  const { classes } = props?.isRouteSidebar
    ? verticalNavbarStyles()
    : horizontalNavbarStyles();

  const isRtl =
    i18n.dir(useSelector((state) => state.user.userProfile.lng)) === "rtl"
      ? true
      : false;

  const possibleValues = [
    "company-assets",
    "asset-requests",
    "asset-types",
    "storage-location",
    "scrapped-assets",
  ];

  return (
    <>
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        value={possibleValues.includes(activeValue) ? activeValue : false}
        onChange={handleChange}
        orientation={props?.isRouteSidebar ? "vertical" : "horizontal"}
        classes={{
          root: classes.root,
          indicator: classes.indicator,
          flexContainer: classes.flexContainer,
          scrollButtons: classes.scrollButtons,
        }}
      >
        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_COMPANY_ASSETS],
        }) ? (
          <Tab
            value={"company-assets"}
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: isRtl ? classes.selectedRtl : classes.selected,
            }}
            label={
              <div className="routeBar-text">
                <FontAwesomeIcon
                  icon={Icons["faBoxes"]}
                  className="mx-2 routeBar-icon"
                />
                {t("company assets")}
              </div>
            }
          />
        ) : null}

        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_ASSET_REQUEST],
        }) ? (
          <Tab
            value={"asset-requests"}
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: isRtl ? classes.selectedRtl : classes.selected,
            }}
            label={
              <div className="routeBar-text">
                <FontAwesomeIcon
                  icon={Icons["faHands"]}
                  className="mx-2 routeBar-icon"
                />{" "}
                {t("asset requests")}
              </div>
            }
          />
        ) : null}

        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_ASSET_TYPES],
        }) ? (
          <Tab
            value={"asset-types"}
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: isRtl ? classes.selectedRtl : classes.selected,
            }}
            label={
              <div className="routeBar-text">
                <PersonOutlineIcon className="mx-2 routeBar-icon" />
                {t("asset types")}
              </div>
            }
          />
        ) : null}

        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_STORAGE_LOCATIONS],
        }) ? (
          <Tab
            value={"storage-location"}
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: isRtl ? classes.selectedRtl : classes.selected,
            }}
            label={
              <div className="routeBar-text">
                <FontAwesomeIcon
                  icon={Icons["faLocationArrow"]}
                  className="mx-2 routeBar-icon"
                />
                {t("storage location")}
              </div>
            }
          />
        ) : null}

        {HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_SCRAPPED_ASSETS],
        }) ? (
          <Tab
            value={"scrapped-assets"}
            classes={{
              root: classes.tabRoot,
              wrapper: "top-bar-label",
              selected: isRtl ? classes.selectedRtl : classes.selected,
            }}
            label={
              <div className="routeBar-text">
                <DangerousIcon className="mx-2 routeBar-icon" />
                {t("scrapped assets")}
              </div>
            }
          />
        ) : null}
      </Tabs>
    </>
  );
};

export default AssetsTabs;

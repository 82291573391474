import React from "react";
import { useLocation } from "react-router-dom";

import {
  UsersList,
  ShiftsList,
  BranchesList,
} from "../../../Containers/FoodicsLists";
import FoodicsTabs from "./FoodicsTabs";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const FoodicsPage = () => {
  const query = useQuery();

  // Constants
  const activeValue = query.get("tab");

  /* ↓ Helpers ↓ */

  const renderSelectedTab = () => {
    switch (activeValue?.toLowerCase()) {
      case "users":
        return <UsersList />;
      case "branches":
        return <BranchesList />;
      case "shifts":
        return <ShiftsList />;
      default:
        break;
    }
  };

  return (
    <div className="content pt-3">
      <FoodicsTabs />
      {renderSelectedTab()}
    </div>
  );
};

export default FoodicsPage;

import React, { useEffect, useState } from "react";
import MainModal from "../../../Components/MainModal";
import { useDispatch, useSelector } from "react-redux";
import {
  onFormResetAction,
  toggleAcceptAssetRequestModal,
  toggleEmployeeAssetRequestModal,
  toggleRetriveAssetModal,
} from "../../../Store/Actions";
import { useTranslation } from "react-i18next";
import { InputForm, BSelect } from "form-builder";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  GET_ASSET_ITEMS_ACCORDING_TO_WAREHOUSE,
  GET_ASSETS_MENU,
  GET_WAREHOUSES_MENU,
} from "../../../Graphql/query";
import {
  ACCEPT_ASSET_REQUEST,
  REQUEST_ASSETS,
  RETRIEVE_ASSET,
} from "../../../Graphql/mutation";
import { showToast } from "../../../Helpers/HelperFns";
import Dropzone from "react-dropzone-uploader";

const formName = "retriveAssetForm";
const formNameValidation = "retriveAssetClientValidations";

const RetriveAssetModal = ({ handleRefetch = () => undefined }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { data, loading } = useQuery(GET_WAREHOUSES_MENU);

  const [formSubmitting, setFormSubmitting] = useState(false);

  const isOpen = useSelector(
    (state) => state?.assets?.retriveAssetModal?.isOpen
  );

  const itemId = useSelector(
    (state) => state?.assets?.retriveAssetModal?.itemId
  );

  const formData = useSelector((state) => state?.assets?.[formName]);

  const clientValidations = useSelector(
    (state) => state?.assets?.[formNameValidation]
  );

  const [attemptRetrieveAsset, { loading: retriveAssetLoading }] = useMutation(
    RETRIEVE_ASSET,
    {
      onCompleted: (data) => {
        if (data?.retrieveAssignOrder?.__typename == "GeneralException") {
          showToast("error", data?.retrieveAssignOrder?.message);

          return;
        }
        dispatch(toggleRetriveAssetModal({ isOpen: false, itemId: null }));
        handleRefetch();
      },
      onError(err) {
        showToast(
          "error",
          err?.graphQLErrors?.[0].extensions?.reason ?? err?.message
        );
      },
    }
  );

  useEffect(() => {
    return () => {
      dispatch(onFormResetAction(formName));
      dispatch(onFormResetAction(formNameValidation));
    };
  }, []);

  const dismiss = () => {
    dispatch(toggleRetriveAssetModal({ isOpen: false, itemId: null }));
  };

  const handleSubmit = () => {
    setFormSubmitting(true);

    if (!clientValidations?.length) {
      attemptRetrieveAsset({
        variables: {
          input: {
            items: [itemId],
            warehouse_id: formData?.warehouseId,
          },
        },
      });
    }
  };

  return (
    <MainModal
      isOpen={isOpen}
      toggle={dismiss}
      modalTitle={t("Retrive asset")}
      btnLabel={t("save")}
      btnOnClick={handleSubmit}
      btnSubmitLoading={retriveAssetLoading}
    >
      <div className="mb-3">
        <p className="m-0 font-weight-bold">{t("asset")}</p>
        <p className="m-0">asset here</p>
      </div>

      <BSelect
        formName={formName}
        formNameValidation={formNameValidation}
        formSubmitting={formSubmitting}
        reducer="assets"
        label="storage location"
        name="warehouseId"
        validateBy="textRequired"
        labelStyle="mb-2"
        keepDefaultStyle
        isLoading={loading}
        options={data?.companyWarehouses?.data ?? []}
        inputContainerStyle=""
        placeholder={t("select storage location")}
        icon="faWarehouse"
        rootStyle="mb-3"
      />
    </MainModal>
  );
};

export default RetriveAssetModal;

import React from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { showToast } from "../../Helpers/HelperFns";
import { onFormResetAction } from "../../Store/Actions";
import { updatePlanCompaniesMutation } from "../../Graphql/mutation/PaymentTracking";

import MainModal from "../MainModal";
import { BSelect } from "form-builder";

const reducer = "paymentTracking";
const formName = "updatePlanCompaniesModal";
const formNameValidation = "paymentTrackingClientValidation";
const formServerValidation = "paymentTrackingServerValidation";

const UpdatePlanCompanies = ({ data, onClose, refetchList }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [formSubmitting, setFormSubmitting] = React.useState(false);

  // Constants
  const FormProps = {
    reducer,
    formName,
    formSubmitting,
    formNameValidation,
    formServerValidation,
  };

  // Reducer State
  const formData = useSelector((state) => state?.[reducer]?.[formName]);
  const formClientValidation = useSelector(
    (state) => state?.[reducer]?.[formNameValidation]
  );
  const options = useSelector(
    (state) => state?.[reducer]?.paymentTrackingOptions
  );

  // Server State
  const [updatePlanCompanies, { loading: updatePlanCompaniesLoading }] =
    useMutation(updatePlanCompaniesMutation);

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    dispatch(
      onFormResetAction(formName, {
        ...formData,
        companyIds: data?.companiesIds,
      })
    );
  }, []);

  /* ↓ Helpers ↓ */

  const handleCloseModal = () => {
    onClose();
    dispatch(onFormResetAction(formName));
    dispatch(onFormResetAction(formNameValidation));
    dispatch(onFormResetAction(formServerValidation));
  };

  const handleSubmit = () => {
    setFormSubmitting(true);
    if (formClientValidation.length) return;

    updatePlanCompanies({
      variables: {
        id: +data?.id,
        companyIds: formData.companyIds.map((id) => +id),
      },

      onCompleted: () => {
        refetchList();
        handleCloseModal();
        showToast("success");
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message
        );
      },
    });
  };

  return (
    <MainModal
      isOpen
      toggle={handleCloseModal}
      btnOnClick={handleSubmit}
      modalTitle={`Update Plan Companies`}
      btnSubmitLoading={updatePlanCompaniesLoading}
    >
      <BSelect
        {...FormProps}
        isMulti
        label="companies"
        name="companyIds"
        icon="money"
        rootStyle="flex-1"
        options={options.companies}
        isLoading={!options.companies.length}
        validationName="companyIds"
      />
    </MainModal>
  );
};

export default UpdatePlanCompanies;

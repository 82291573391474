import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Constants from "../../../Constants";
import { updateObjectMultiSelectAction } from "../../../Store/Actions";

import Select from "react-select";

const CustomLocationsSection = ({ formName, type }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Reducer State
  const locations = useSelector(
    (state) => state.super.editEmployeeFormData?.locations
  );
  const Friday_sign_in_location = useSelector(
    (state) => state.super?.[formName]?.Friday_sign_in_location
  );
  const Friday_sign_out_location = useSelector(
    (state) => state.super?.[formName]?.Friday_sign_out_location
  );
  const Saturday_sign_in_location = useSelector(
    (state) => state.super?.[formName]?.Saturday_sign_in_location
  );
  const Saturday_sign_out_location = useSelector(
    (state) => state.super?.[formName]?.Saturday_sign_out_location
  );
  const Sunday_sign_in_location = useSelector(
    (state) => state.super?.[formName]?.Sunday_sign_in_location
  );
  const Sunday_sign_out_location = useSelector(
    (state) => state.super?.[formName]?.Sunday_sign_out_location
  );
  const Monday_sign_in_location = useSelector(
    (state) => state.super?.[formName]?.Monday_sign_in_location
  );
  const Monday_sign_out_location = useSelector(
    (state) => state.super?.[formName]?.Monday_sign_out_location
  );
  const Tuesday_sign_in_location = useSelector(
    (state) => state.super?.[formName]?.Tuesday_sign_in_location
  );
  const Tuesday_sign_out_location = useSelector(
    (state) => state.super?.[formName]?.Tuesday_sign_out_location
  );
  const Wednesday_sign_in_location = useSelector(
    (state) => state.super?.[formName]?.Wednesday_sign_in_location
  );
  const Wednesday_sign_out_location = useSelector(
    (state) => state.super?.[formName]?.Wednesday_sign_out_location
  );
  const Thursday_sign_in_location = useSelector(
    (state) => state.super?.[formName]?.Thursday_sign_in_location
  );
  const Thursday_sign_out_location = useSelector(
    (state) => state.super?.[formName]?.Thursday_sign_out_location
  );

  // Constants
  const inOutLocations = {
    Saturday_sign_in_location,
    Sunday_sign_in_location,
    Monday_sign_in_location,
    Tuesday_sign_in_location,
    Wednesday_sign_in_location,
    Thursday_sign_in_location,
    Friday_sign_in_location,

    Saturday_sign_out_location,
    Sunday_sign_out_location,
    Monday_sign_out_location,
    Tuesday_sign_out_location,
    Wednesday_sign_out_location,
    Thursday_sign_out_location,
    Friday_sign_out_location,
  };

  /* ↓ Helpers ↓ */

  const handleChange = (value, e) => {
    dispatch(
      updateObjectMultiSelectAction({
        data: value ?? [],
        formName,
        name: e.name,
      })
    );
  };

  return Constants.Days?.map((day) => {
    const key = `${day}_${type}_location`;

    return (
      <div key={key} className="d-flex align-items-center">
        <div className="w-110px">{t(day)}</div>
        <div className="d-flex align-items-center w-100 ">
          <div className="w-100 my-2">
            <button
              className="link-btn mb-1"
              onClick={() => handleChange(locations, { name: key })}
            >
              {t("select all")}
            </button>
            <Select
              name={key}
              placeholder={t("select location")}
              options={locations}
              onChange={handleChange}
              isMulti={true}
              classNamePrefix="custom_select_style"
              className="select-def-input-container b-select-style"
              value={inOutLocations?.[key]}
            />
          </div>
        </div>
      </div>
    );
  });
};

export default CustomLocationsSection;

import React from "react";
import { connect } from "react-redux";
import { useQuery } from "@apollo/client";

import Loader from "../../Components/Loader";
import { OutlinedInput } from "@mui/material";

import { verifyUSerPhoneAction } from "../../Store/Actions";
import { Spinner } from "reactstrap";
import LoginLayout from "../../Components/LoginLayout";
import { useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import gql from "graphql-tag";
import { showToast } from "../../Helpers/HelperFns";

const GET_USER_DATA = gql`
  query getUserData($phone_token: String!) {
    userByPhoneToken(token: $phone_token) {
      id
      name
      phone
    }
  }
`;

const useQueryParams = () => {
  return new URLSearchParams(useLocation().search);
};

const VerifyPhonePage = (props) => {
  let query = useQueryParams();
  const { t } = useTranslation();

  // Local State
  const [user, setUser] = React.useState({ name: "", phone: "" });

  // constants
  const phone_token = query.get("phone_token");

  // Server State
  const { loading } = useQuery(GET_USER_DATA, {
    skip: !phone_token,
    variables: { phone_token },
    onCompleted: ({ userByPhoneToken = {} }) => {
      if (userByPhoneToken?.id) {
        setUser({
          name: userByPhoneToken?.name || "",
          phone: userByPhoneToken?.phone || "",
        });
      } else {
        showToast("error", "token expired");
      }
    },
    onError: (error) => {
      showToast(
        "error",
        error?.graphQLErrors?.[0]?.extensions?.reason || error?.message
      );
    },
  });

  /* ↓ Helpers ↓ */

  const handleSubmitBtn = (e) => {
    e.preventDefault();

    props.verifyUSerPhoneAction(phone_token);
  };

  return (
    <LoginLayout>
      {loading ? <Loader fixed /> : null}
      <form>
        <h4 className="text-capitalize">{t("verify Phone Number")}</h4>
        <div className="d-flex flex-column login_input_wrapper_style my-2">
          <label>{t("name")}</label>
          <OutlinedInput
            type="text"
            value={user?.name}
            disabled
            classes={{
              root: "custom_outlined_input_style",
            }}
          />
        </div>
        <div className="d-flex flex-column login_input_wrapper_style my-2">
          <label>{t("phone")}</label>
          <OutlinedInput
            type="text"
            value={user?.phone}
            disabled
            classes={{
              root: "custom_outlined_input_style",
            }}
          />
        </div>

        <div className="d-flex mt-4 pt-2">
          <button
            className="btn btn-block login_btn_style mx-1 mt-0 row justify-content-between text-capitalize"
            onClick={handleSubmitBtn}
            type="submit"
          >
            {props.verifyPhoneLoading ? (
              <Spinner
                style={{ width: "1rem", height: "1rem" }}
                size="sm"
                color="#fff"
              />
            ) : (
              t("verify")
            )}
          </button>

          <Link
            to={`/login`}
            style={{
              pointerEvents: props.verifyPhoneLoading ? "none" : "auto",
            }}
            className="btn btn-block cancel_btn_style mx-1 mt-0 text-capitalize"
          >
            {t("Cancel")}
          </Link>
        </div>
      </form>
    </LoginLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    verifyPhoneLoading: state.user.verifyPhoneLoading,
  };
};

export default connect(mapStateToProps, {
  verifyUSerPhoneAction,
})(VerifyPhonePage);

import React from "react";
import { useTranslation } from "react-i18next";

import { AddButton } from "../Buttons";
import { StopCircleOutlined } from "@mui/icons-material";
import IconButtonWithTooltip from "../IconButtonWithTooltip";
import { Modal, Box, Typography, SvgIcon, Button } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 454,
  height: 244,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 1,
  p: 4,
};

const EndSubscriptionConfirmModal = ({
  data,
  onClose,
  openEndSubscriptionModal,
}) => {
  const { t } = useTranslation();

  // Local State

  /* ↓ Helpers ↓ */

  const handleAddSubscription = () => {
    openEndSubscriptionModal({ isCreateNew: true });
    handleClose();
  };

  const handleEndSubscription = () => {
    openEndSubscriptionModal({ isCreateNew: false });
    handleClose();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      open
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <IconButtonWithTooltip
          label="Close"
          onClick={handleClose}
          icon={<CloseIcon />}
          sx={{ position: "absolute", top: 10, right: 10 }}
        />

        <div className="h-100 d-flex flex-column justify-content-center gap-10">
          <Typography
            id="modal-title"
            variant="h6"
            component="h1"
            sx={{ color: "black", fontSize: 24, lineHeight: "normal" }}
          >
            {t("You are about to end your current subscription")}
          </Typography>
          <Typography
            id="modal-description"
            sx={{ color: "#9EA3A2", fontSize: 15 }}
          >
            {t(
              "Do you want to terminate your contract with the company or switch to a new subscription plan?"
            )}
          </Typography>

          {/* Actions */}
          <div className="d-flex justify-content-between mt-3">
            <AddButton onClick={handleAddSubscription}>
              {t("End & Create New")}
            </AddButton>
            <Button
              size="small"
              variant="outlined"
              onClick={handleEndSubscription}
              startIcon={<StopCircleOutlined />}
              sx={{
                color: "rgba(196, 30, 30, 1)",
                borderColor: "rgba(196, 30, 30, 1)",
                "&:hover": { borderColor: "rgba(196, 30, 30, 1)" },
              }}
            >
              {t("End Subscription")}
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default EndSubscriptionConfirmModal;

const CloseIcon = () => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M18 6L6 18"
      stroke="#57585A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 6L18 18"
      stroke="#57585A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

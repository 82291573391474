import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MainModal from "../../../Components/MainModal";
import { showToast } from "../../../Helpers/HelperFns";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import {
  onFormResetAction,
  toggleTransferAssetsModal,
} from "../../../Store/Actions";
import { GET_WAREHOUSES_MENU } from "../../../Graphql/query";
import { useMutation, useQuery } from "@apollo/client";
import { BSelect, InputForm } from "form-builder";
import { TRANSFER_ASSETS } from "../../../Graphql/mutation";

const formName = "transferAssetForm";
const formNameValidation = "transferAssetsFormClientValidation";

const TransferAssetsModal = ({ handleRefetch }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [formSubmitting, setFormSubmitting] = useState(false);

  const clientValidations = useSelector(
    (state) => state?.assets?.[formNameValidation]
  );

  const transferAssetsModal = useSelector(
    (state) => state?.assets?.transferAssetsModal
  );

  const { isOpen, asset = {}, warehouseId } = transferAssetsModal;

  const formData = useSelector((state) => state?.assets?.[formName]);

  const [attemptTransferAssets, { loading }] = useMutation(TRANSFER_ASSETS, {
    onCompleted: (data) => {
      if (data?.changeItemsWarehouse?.__typename == "GeneralException") {
        showToast("error", data?.changeItemsWarehouse?.message);

        return;
      }
      dispatch(
        toggleTransferAssetsModal({
          isOpen: false,
          asset: null,
          warehouseId: null,
        })
      );
      handleRefetch();
    },
    onError(err) {
      showToast(
        "error",
        err?.graphQLErrors?.[0].extensions?.reason ?? err?.message
      );
    },
  });

  useEffect(() => {
    return () => {
      dispatch(onFormResetAction(formName));
      dispatch(onFormResetAction(formNameValidation));
    };
  }, []);

  const dismiss = () => {
    dispatch(
      toggleTransferAssetsModal({
        isOpen: false,
        asset: null,
        warehouseId: null,
      })
    );
  };

  const { data: warehousesMenuQueryData, loading: warehousesLoading } =
    useQuery(GET_WAREHOUSES_MENU);

  const handleSubmit = () => {
    setFormSubmitting(true);

    if (!clientValidations?.length) {
      attemptTransferAssets({
        variables: {
          input: {
            asset_id: asset?.id,
            warehouse_id: formData?.warehouseId,
            ...(asset?.productType?.toLowerCase() == "serialized"
              ? {
                  items: formData?.itemIds?.map((id) => ({
                    id,
                  })),
                }
              : {
                  number_of_items: +formData?.numberOfUnits,
                }),
          },
        },
      });
    }
  };

  return (
    <MainModal
      isOpen={isOpen}
      toggle={dismiss}
      modalTitle={t("transfer assets")}
      btnOnClick={handleSubmit}
      btnSubmitLoading={loading}
      btnLabel={t("transfer")}
    >
      <div className="d-flex flex-column gap-10">
        <h5
          className="m-0"
          style={{
            color: "#2764ac",
          }}
        >{`${asset?.name} ${asset?.type} ${asset?.model}`}</h5>

        <BSelect
          reducer="assets"
          label="from"
          labelStyle="mb-2"
          keepDefaultStyle
          isLoading={warehousesLoading}
          options={warehousesMenuQueryData?.companyWarehouses?.data ?? []}
          inputContainerStyle=""
          value={warehousesMenuQueryData?.companyWarehouses?.data?.find(
            (wh) => wh?.id == warehouseId
          )}
          placeholder={t("Select storage location")}
          icon="location"
          isDisabled={true}
        />

        <BSelect
          formName={formName}
          formNameValidation={formNameValidation}
          formSubmitting={formSubmitting}
          reducer="assets"
          validateBy="textRequired"
          name="warehouseId"
          label="to"
          labelStyle="mb-2"
          keepDefaultStyle
          isLoading={warehousesLoading}
          options={warehousesMenuQueryData?.companyWarehouses?.data ?? []}
          inputContainerStyle=""
          placeholder={t("Select storage location")}
          icon="location"
        />

        {asset?.productType?.toLowerCase() == "serialized" ? (
          <BSelect
            formName={formName}
            formNameValidation={formNameValidation}
            formSubmitting={formSubmitting}
            reducer="assets"
            validateBy="textRequired"
            name="itemIds"
            label="serial numbers"
            labelStyle="mb-2"
            keepDefaultStyle
            options={asset?.items ?? []}
            optionLabel="serial_number"
            optionValue="id"
            inputContainerStyle=""
            placeholder={t("Select storage location")}
            icon="item"
            isMulti
          />
        ) : (
          <InputForm
            formName={formName}
            formNameValidation={formNameValidation}
            formSubmitting={formSubmitting}
            reducer="assets"
            validateBy="textRequired"
            name="numberOfUnits"
            type="text"
            label="No. of units"
            placeholder={t("number of units")}
            labelStyle="mb-2"
            containerStyle="mt-0 mb-2"
            inputContainerStyle=" "
          />
        )}
      </div>
    </MainModal>
  );
};

export default TransferAssetsModal;

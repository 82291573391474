import React, { useEffect, useState } from "react";
import MainModal from "../../Components/MainModal";
import { useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { showToast } from "../../Helpers/HelperFns";
import Select from "react-select";
import Picker from "rc-picker";
import DateRangeIcon from "@mui/icons-material/DateRange";
import en from "rc-picker/lib/locale/en_US";
import ar from "rc-picker/lib/locale/ar_EG";
import generateConfig from "rc-picker/lib/generate/moment";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  loansCurrencyQuery,
  loansUserSalaryConfigCurrencyQuery,
} from "../../Graphql/query";
import * as yup from "yup";
import "./style.scss";
import AddIcon from "@mui/icons-material/Add";
import {
  acceptLoanRequestMutation,
  rejectLoanRequestMutation,
} from "../../Graphql/mutation";

const AcceptLoanRequestModal = (props) => {
  const { t } = useTranslation();
  const [validator, setValidator] = useState(null);

  const inputInitState = {
    name: props?.req?.name,
    notes: props?.req?.notes,
    starting_from: props?.req?.starting_from
      ? moment(props?.req?.starting_from, "YYYY-MM-DD").format("DD/MM/YYYY")
      : null,
    amount: props?.req?.amount,
    granted_on: props?.req?.granted_on
      ? moment(props?.req?.granted_on, "YYYY-MM-DD").format("DD/MM/YYYY")
      : null,
    include_payroll: props?.req?.include_payroll
      ? props?.req?.include_payroll
      : false,
    installments: props?.req?.installments,
    currency_id: props?.req?.currency_id,
  };

  const [input, setInput] = useState(inputInitState);
  const [installments_no, setInstallments_no] = useState(
    props.req.installments_number
  );

  const { data: currencyData, loading } = useQuery(loansCurrencyQuery, {
    onError(err) {
      showToast(
        "error",
        err?.graphQLErrors[0]?.extensions?.reason ||
          err?.graphQLErrors[0]?.message ||
          err?.message
      );
    },
  });

  // handle fetch loans user salary config currency options query
  const [ShowIncludePayroll, setShowIncludePayroll] = useState(false);
  const [disableCurrencyField, setDisableCurrencyField] = useState(false);

  const { loading: configCurrencyloading } = useQuery(
    loansUserSalaryConfigCurrencyQuery,
    {
      variables: {
        user_id: props?.req.employee.id,
        date: input.granted_on
          ? moment(input.granted_on, "DD/MM/YYYY").format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
      },
      onError(err) {
        showToast(
          "error",
          err?.graphQLErrors[0]?.extensions?.reason ||
            err?.graphQLErrors[0]?.message
        );
      },
      onCompleted: (data) => {
        if (
          data?.employee?.activeSalaryConfig?.type?.toLowerCase() === "internal"
        ) {
          setInput((prev) => {
            return {
              ...prev,
              currency_id:
                data?.employee?.activeSalaryConfig?.details?.currency?.id,
            };
          });
          setDisableCurrencyField(true);
          setShowIncludePayroll(true);
        } else {
          setShowIncludePayroll(false);
          setDisableCurrencyField(false);
        }
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const [
    acceptLoanRequest,
    { loading: acceptLoanRequestLoading, error: acceptLoanRequestError },
  ] = useMutation(acceptLoanRequestMutation, {
    onCompleted: (res) => {
      if (res?.accept_loan_request?.id) {
        showToast(res?.accept_loan_request?.status, "success");
        props.refetch();
        props.close();
        return;
      }
      showToast("error", res?.accept_loan_request?.message);
    },
    onError: (err) => {
      if (err?.graphQLErrors && err?.graphQLErrors[0]?.extensions?.validation) {
        let validation = err?.graphQLErrors[0]?.extensions?.validation;
        Object.keys(validation).forEach((key) => {
          setValidator((prev) => ({
            ...prev,
            // remove "input." so it matches Yup validator state key names
            [key.substring(6)]: validation[key].toString(),
          }));
        });
      } else {
        showToast(
          "error",
          err?.graphQLErrors && err?.graphQLErrors[0]?.extensions?.reason
            ? err?.graphQLErrors[0]?.extensions?.reason
            : err.message
        );
      }
    },
  });

  const requiredMsg = "This Field Is Required.";
  const inputSchema = yup.object().shape({
    name: yup.string(requiredMsg).required(requiredMsg),
    amount: yup.string(requiredMsg).required(requiredMsg),
    currency_id: yup.string(requiredMsg).required(requiredMsg),
    granted_on: yup.string(requiredMsg).required(requiredMsg),
    starting_from: yup.string(requiredMsg).required(requiredMsg),
    installments_no: yup
      .string("you should create at least one installment")
      .required("you should create at least one installment"),
    installments: yup.array().of(
      yup.object().shape({
        amount: yup.string().required(t("please enter amount")),
        payment_date: yup.string().required(t("please select date")),
      })
    ),
  });

  const handleSave = () => {
    let tempInput = { ...input };
    tempInput.granted_on = input.granted_on
      ? moment(input.granted_on, "DD/MM/YYYY").format("YYYY-MM-DD")
      : "";
    tempInput.starting_from = input.starting_from
      ? moment(input.starting_from, "DD/MM/YYYY").format("YYYY-MM-DD")
      : "";
    tempInput.amount = input?.amount ? parseFloat(input.amount) : "";
    tempInput.request_id = props.req.id;
    tempInput.user_id = props?.req?.employee?.id;

    if (props.allowEdit) {
      inputSchema
        .validate(
          {
            ...tempInput,
            installments_no: installments_no === 0 ? "" : installments_no,
          },
          { abortEarly: false }
        )
        .then(() => {
          setValidator({});
          acceptLoanRequest({
            variables: {
              input: tempInput,
            },
          });
        })
        .catch((err) => {
          let yupErrors = {};
          err.inner.forEach((error) => {
            yupErrors[error.path] = error.message;
          });
          console.log(yupErrors);
          setValidator(yupErrors);
          return false;
        });
    } else {
      acceptLoanRequest({
        variables: {
          input: tempInput,
        },
      });
    }
  };

  const [
    rejectLoanRequest,
    { loading: rejectLoanRequestLoading, error: rejectLoanRequestError },
  ] = useMutation(rejectLoanRequestMutation, {
    onCompleted: (res) => {
      if (res?.reject_loan_request?.id) {
        showToast(res?.reject_loan_request?.status, t("done"));
        props.refetch();
        props.close();
        return;
      }
      showToast("error", res?.reject_loan_request?.message);
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors[0]?.extensions?.reason || err.message
      );
    },
  });

  const handleRejectLoan = () => {
    rejectLoanRequest({
      variables: {
        id: props?.req?.id,
      },
    });
  };

  const handleAmountIncrement = () => {
    if (!props.allowEdit) return;
    setInstallments_no((prevState) => prevState + 1);
  };

  const handleAmountDecrement = () => {
    if (!props.allowEdit) return;
    if (installments_no > 0) {
      setInstallments_no((prevState) => prevState - 1);
    }
  };

  const handleInputChange = (key, val) => {
    if (!props.allowEdit) return;
    setInput((prev) => {
      return { ...prev, [key]: val };
    });
  };

  const handleGenerate = () => {
    if (!props.allowEdit) return;
    const { starting_from } = input;
    if (!starting_from || installments_no <= 0) return;

    const startDate = moment(starting_from, "DD/MM/YYYY");
    let currentDate = startDate.clone();
    let installmentsAmount = input.amount / installments_no;

    const generatedDates = [];
    for (let i = 0; i < installments_no; i++) {
      generatedDates.push({
        id: null,
        amount: parseFloat(installmentsAmount),
        payment_date: currentDate.format("YYYY-MM-DD"),
      });
      currentDate.add(1, "months");
    }

    setInput((prev) => ({ ...prev, installments: generatedDates }));
  };

  const handleRemoveIstallment = (i) => {
    if (!props.allowEdit) return;
    setInput((prev) => {
      let updatedInstallments = [...prev.installments];
      updatedInstallments.splice(i, 1);
      return { ...prev, installments: updatedInstallments };
    });

    // remove validation for the item removed
    setValidator((prev) => {
      const updatedValidator = { ...prev };
      for (const key in updatedValidator) {
        if (
          Object.prototype.hasOwnProperty.call(updatedValidator, key) &&
          key.includes(`installments[${i}]`)
        ) {
          delete updatedValidator[key];
        }
      }
      return updatedValidator;
    });
  };

  const handleAddInstallments = () => {
    if (!props.allowEdit) return;
    const newInstallment = { id: props.req.id, amount: "", payment_date: "" };

    setInput((prev) => {
      const updatedInstallments = [...prev.installments, newInstallment];
      return { ...prev, installments: updatedInstallments };
    });
  };

  const handleInstallmentDateChange = (i, date) => {
    if (!props.allowEdit) return;
    setInput((prev) => {
      let updatedInstallments = [...prev.installments];
      updatedInstallments[i].payment_date = moment(date, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      );
      return { ...prev, installments: updatedInstallments };
    });
  };

  const handleInstallmentAmountChange = (i, val) => {
    if (!props.allowEdit) return;
    setInput((prev) => {
      let updatedInstallments = [...prev.installments];
      updatedInstallments[i].amount = parseFloat(val) + 0.0;
      console.log(updatedInstallments);
      return { ...prev, installments: updatedInstallments };
    });
  };

  return (
    <MainModal
      isOpen={props.isOpen}
      size="lg"
      modalTitle={t("accept loan")}
      btnOnClick={handleSave}
      toggle={props.close}
      className="p-5 "
      btnSubmitLoading={acceptLoanRequestLoading}
      btnLabelCancel={t("reject")}
      btnOnCancelClick={handleRejectLoan}
      btnCancelLoading={rejectLoanRequestLoading}
      btnLabel="accept"
    >
      <div className="d-flex flex-column flex-lg-row ">
        <div className="col-6">
          <strong>{t("employee")}</strong>
          <p>{props?.req?.employee?.name}</p>
        </div>
      </div>

      <div className={`col-12`}>
        <div
          className={`container-style-default ${
            validator?.["name"] ? "invalid-container-style" : ""
          }`}
        >
          {/* Input label */}
          <label className={`label-style-default validity-label-style`}>
            {t("name")}
          </label>
          <input
            dir="auto"
            className={`input-style-default  validity-input-style`}
            name={"name"}
            onChange={(e) => handleInputChange("name", e.target.value)}
            onBlur={() => {}}
            type={"text"}
            value={input.name}
            disabled={!props.allowEdit}
          />
          <div className="validity-msg-style text-left">
            {t(validator?.["name"])}
          </div>
        </div>
      </div>

      <div className="my-2">
        <div className="d-flex flex-lg-row">
          <div className={`d-flex align-bottom col-6`}>
            <div
              className={
                `col-6 container-style-default ` +
                (validator?.["amount"] ? "invalid-container-style" : "")
              }
            >
              {/* Input label */}
              <label className={`label-style-default validity-label-style`}>
                {t("amount")}
              </label>
              <input
                dir="auto"
                className={`input-style-default  validity-input-style`}
                name={"name"}
                value={input?.amount}
                onChange={(e) => handleInputChange("amount", e.target.value)}
                onBlur={() => {}}
                type={"number"}
                disabled={!props.allowEdit}
              />
            </div>

            <div
              className={`container-style-default w-100 flex-column justify-content-between w-100 `}
            >
              {ShowIncludePayroll ? (
                <label
                  className={
                    "label-style-default validity-label-style d-flex w-100 "
                  }
                >
                  <input
                    type="checkbox"
                    name={"include_payroll"}
                    onChange={(e) =>
                      handleInputChange(
                        "include_payroll",
                        !input.include_payroll
                      )
                    }
                    checked={input?.include_payroll}
                    disabled={!props.allowEdit}
                  />
                  <span></span>

                  <div
                    className={`booleanInputLabelStyle mx-2  ${
                      input?.include_payroll ? "lightActiveColor" : ""
                    }`}
                  >
                    {t("Include in Payroll")}
                  </div>
                </label>
              ) : (
                <div
                  className={
                    "label-style-default validity-label-style d-flex booleanInputLabelStyle text-hide w-100 "
                  }
                ></div>
              )}
              <div className="w-100">
                <Select
                  className={
                    "select-def-input-containe b-select-style w-100 " +
                    (validator?.["currency_id"]
                      ? "invalid-container-style"
                      : "")
                  }
                  classNamePrefix={"b-select-style"}
                  value={currencyData?.currencies.find(
                    (curr) => curr.id === input?.currency_id
                  )}
                  onChange={(val) => handleInputChange("currency_id", val.id)}
                  name={"currency_id"}
                  isClearable={true}
                  isRtl={document?.body?.dir?.toLowerCase() == "rtl"}
                  isSearchable
                  placeholder={t("Currency")}
                  options={currencyData?.currencies}
                  getOptionLabel={(opt) => opt?.name}
                  getOptionValue={(opt) => opt?.id}
                  isLoading={loading || configCurrencyloading}
                  isDisabled={disableCurrencyField || !props.allowEdit}
                />
              </div>
            </div>
          </div>

          <div
            className={
              "col-lg-6 d-flex flex-column gap-5 w-100  " +
              (validator?.["granted_on"] ? "invalid-container-style" : "")
            }
          >
            <label className={"label-style-default validity-label-style"}>
              {t("Granted On")}
            </label>
            <Picker
              name={"granted_on"}
              allowClear={false}
              className={`date-picker-input-default validity-input-style w-100 `}
              dropdownClassName="def-date-picker-dropdown"
              onChange={(e, h) => handleInputChange("granted_on", h)}
              use12Hours
              showSecond={false}
              locale={
                document.documentElement.lang?.includes("ar")
                  ? { ...ar, locale: "ar-custome" }
                  : en
              }
              value={
                input.granted_on ? moment(input.granted_on, "DD/MM/YYYY") : null
              }
              suffixIcon={<DateRangeIcon />}
              placeholder={t("select date")}
              generateConfig={{ ...generateConfig }}
              format={"DD/MM/YYYY"}
              disabled={!props.allowEdit}
            />
          </div>
        </div>

        <div className="d-flex ">
          <div className="d-flex w-100  ">
            {(validator?.["amount"] || validator?.["currency_id"]) && (
              <div
                className="text-left col-6 w-100  "
                style={{ display: "block", fontSize: "12px", color: "#ff6a6a" }}
              >
                {t(validator?.["amount"])}
              </div>
            )}

            {validator?.["currency_id"] && (
              <div
                className="text-left w-100  "
                style={{ display: "block", fontSize: "12px", color: "#ff6a6a" }}
              >
                {t(validator?.["currency_id"])}
              </div>
            )}
          </div>
          {(validator?.["granted_on"] || validator?.["currency_id"]) && (
            <div
              className=" text-left col-6 "
              style={{ display: "block", fontSize: "12px", color: "#ff6a6a" }}
            >
              {t(validator?.["granted_on"])}
            </div>
          )}
        </div>
      </div>

      <div className={`col-12`}>
        <div className={`container-style-default`}>
          {/* Input label */}
          <label className={`label-style-default validity-label-style`}>
            {t("notes")}
          </label>

          <textarea
            dir="auto"
            className={`alidity-input-style`}
            name={"notes"}
            value={input?.notes}
            onChange={(e) => handleInputChange("notes", e.target.value)}
            onBlur={() => {}}
            type={"text"}
            style={{ resize: "none" }}
            disabled={!props.allowEdit}
          />
        </div>
      </div>

      {/* installments secton */}
      {input.installments.length === 0 && (
        <div className="d-flex align-bottom  justify-content-end w-100 ">
          <div
            className={`col-6 container-style-default flex-column justify-content-start align-items-start gap-5 ${
              validator?.["installments"] ? "invalid-container-style" : ""
            }`}
          >
            <label className={`label-style-default`}>{t("installments")}</label>
            <div className="d-flex w-100 ">
              <button
                className={`btn  btn-light border-radius-0`}
                type="button"
                onClick={handleAmountDecrement}
                disabled={!props.allowEdit}
              >
                -
              </button>
              <input
                dir="auto"
                className={`input-style-default validity-input-style p-2 d-flex justify-content-center align-content-center `}
                name={"name"}
                value={installments_no}
                type={"number"}
                style={{ textAlign: "center", height: "34px" }}
                disabled={!props.allowEdit}
                onChange={(e) => setInstallments_no(e?.target?.value ?? "")}
              />
              <button
                className={`btn btn-light border-radius-0`}
                type="button"
                onClick={handleAmountIncrement}
                disabled={!props.allowEdit}
              >
                +
              </button>
            </div>
            <div className="validity-msg-style text-left">
              {t(validator?.["installments"])}
            </div>
          </div>

          <div
            className={`ml-auto col-6 container-style-default flex-column justify-content-start align-items-start gap-5  ${
              validator?.["starting_from"] ? "invalid-container-style" : ""
            }`}
          >
            <label className={`label-style-default validity-label-style`}>
              {t("Starting From")}
            </label>
            <div className="d-flex w-100 ">
              <Picker
                name={"starting_from"}
                allowClear={false}
                className={`date-picker-input-default validity-input-style w-100 `}
                dropdownClassName="def-date-picker-dropdown"
                onChange={(e, h) => handleInputChange("starting_from", h)}
                use12Hours
                showSecond={false}
                locale={
                  document.documentElement.lang?.includes("ar")
                    ? { ...ar, locale: "ar-custome" }
                    : en
                }
                value={
                  input.starting_from
                    ? moment(input.starting_from, "DD/MM/YYYY")
                    : null
                }
                suffixIcon={<DateRangeIcon />}
                placeholder={t("select date")}
                generateConfig={{ ...generateConfig }}
                format={"DD/MM/YYYY"}
                style={{
                  height: "34px",
                  borderColor: "transparent",
                  outline: "none",
                }}
                disabled={!props.allowEdit}
              />
              <button
                className="btn btn-light"
                type="button"
                onClick={handleGenerate}
                disabled={!props.allowEdit}
              >
                {t("generate")}
              </button>
            </div>
            <div className="validity-msg-style text-left">
              {t(validator?.["starting_from"])}
            </div>
          </div>
        </div>
      )}

      {input.installments.length !== 0 && (
        <>
          <div
            onClick={handleAddInstallments}
            className="col-2 d-flex align-items-center mt-2 label-style-default"
          >
            {t("add")}
            <AddIcon
              className="m-1 border rounded-circle "
              style={{ fontSize: "18px" }}
              disabled={!props.allowEdit}
            />
          </div>
          <div className="d-flex flex-wrap pt-1 w-100 ">
            {input.installments.map((ins, i) => (
              <div
                className={` col-6 pb-3  ${
                  validator?.[`installments[${i}].amount`] ||
                  validator?.[`installments[${i}].payment_date`]
                    ? "invalid-container-style"
                    : ""
                }`}
              >
                <div className="d-flex align-items-end   ">
                  <div className={`container-style-default m-0 p-0 `}>
                    <input
                      dir="auto"
                      className={`input-style-default validity-input-style`}
                      name={""}
                      value={ins?.amount}
                      onChange={(e) =>
                        handleInstallmentAmountChange(i, e?.target?.value)
                      }
                      onBlur={() => {}}
                      type={"number"}
                      placeholder={t("amount")}
                      disabled={!props.allowEdit}
                    />
                  </div>
                  <Picker
                    name={""}
                    allowClear={false}
                    className={`date-picker-input-default validity-input-style`}
                    dropdownClassName="def-date-picker-dropdown"
                    onChange={(e, h) => handleInstallmentDateChange(i, h)}
                    use12Hours
                    showSecond={false}
                    locale={
                      document.documentElement.lang?.includes("ar")
                        ? { ...ar, locale: "ar-custome" }
                        : en
                    }
                    value={
                      input?.installments[i]?.payment_date
                        ? moment(ins?.payment_date, "YYYY-MM-DD")
                        : null
                    }
                    suffixIcon={<DateRangeIcon />}
                    placeholder={"select date"}
                    generateConfig={{ ...generateConfig }}
                    format={"DD/MM/YYYY"}
                    disabled={!props.allowEdit}
                  />
                  <button
                    className="btn btn-light "
                    type="button"
                    onClick={() => handleRemoveIstallment(i)}
                    disabled={!props.allowEdit}
                  >
                    <FontAwesomeIcon
                      role="button"
                      size="sm"
                      color="#000"
                      onClick={() => {}}
                      icon={faTimes}
                    />
                  </button>
                </div>
                <div className="validity-msg-style text-left">
                  {t(validator?.[`installments[${i}].amount`])}

                  <span
                    className={`${
                      validator?.[`installments[${i}].amount`] ? "col-6" : null
                    }`}
                  >
                    {t(validator?.[`installments[${i}].payment_date`])}
                  </span>
                </div>
              </div>
            ))}
          </div>
          <div
            className={`col-12 container-style-default flex-column justify-content-start align-items-start gap-5 ${
              validator?.["installments"] ? "invalid-container-style" : ""
            }`}
          >
            <div className="validity-msg-style text-left">
              {t(validator?.["installments"])}
            </div>
          </div>
        </>
      )}
      {validator?.["include_payroll"] && (
        <div
          className=" text-left col-6 "
          style={{ display: "block", fontSize: "12px", color: "#ff6a6a" }}
        >
          {t(validator?.["include_payroll"])}
        </div>
      )}
    </MainModal>
  );
};

export default AcceptLoanRequestModal;

import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { handleDashbaordUiToggleCheckboxAction } from "../../Store/Actions";

import MainModal from "../MainModal";
import { CheckboxBooleanForm } from "form-builder";

const ColumnsVisibilityModal = ({ onClose }) => {
  const dispatch = useDispatch();

  // Reducer State
  const cols = useSelector((state) => state?.dashboardUi?.crmColumnsVisibility);

  // Constants
  const colNames = Object.keys(cols || "{}");

  /* ↓ Helpers ↓ */

  const handleChange = (e) => {
    dispatch(handleDashbaordUiToggleCheckboxAction("crmColumnsVisibility", e));
  };

  return (
    <MainModal
      isOpen
      toggle={onClose}
      hasModalFooter={false}
      modalTitle="Select Columns"
    >
      {colNames?.map((name) => (
        <CheckboxBooleanForm
          key={name}
          name={name}
          options={[name]}
          checked={cols?.[name]}
          onChange={handleChange}
        />
      ))}
    </MainModal>
  );
};

export default ColumnsVisibilityModal;

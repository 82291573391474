import React from "react";

import FoodicsPage from "./FoodicsPage";

const FoodicsRoutes = () => {
  return (
    <>
      <FoodicsPage />
    </>
  );
};

export default FoodicsRoutes;

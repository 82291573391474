import React from "react";
import moment from "moment";
import Chip from "@mui/material/Chip";

const FiltersChips = ({ filters, handleDeleteFilter }) => {
  const keys = Object.keys(filters);
  return Object?.values(filters)?.reduce((acc, filter, idx) => {
    const key = keys[idx];
    const label = filter?.name || filter?.label || filter;

    switch (true) {
      // Date
      case moment.isMoment(filter):
        return [
          ...acc,
          <Chip
            variant="outlined"
            onDelete={() => handleDeleteFilter(key)}
            label={key + ": " + filter.format("DD-MM-YYYY")}
          />,
        ];

      // Array
      case Array.isArray(filter):
        if (!filter?.length) return acc;
        return [
          ...acc,
          <Chip
            variant="outlined"
            onDelete={() => handleDeleteFilter(key)}
            label={filter.map((f) => f?.name || f?.label).join(", ")}
          />,
        ];

      // Any truthy value
      case Boolean(label):
        return [
          ...acc,
          <Chip
            variant="outlined"
            onDelete={() => handleDeleteFilter(key)}
            label={typeof filter === "string" ? key + ": " + filter : label}
          />,
        ];

      default:
        return acc;
    }
  }, []);
};

export default FiltersChips;

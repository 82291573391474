import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Privileges from "../../Constants/Privilages";
import { generateDemoAction } from "../../Store/Actions";

import { ColorButton } from "../../Components/Buttons";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import CircularProgress from "@mui/material/CircularProgress";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";

const DemoButton = () => {
  const dispatch = useDispatch();
  const isGenerateDemoLoading = useSelector(
    (state) => state.admin.isGenerateDemoLoading
  );

  const handleGenerateDemo = () => {
    dispatch(generateDemoAction());
  };

  return (
    <HasPrivileges reqireMain={[Privileges.GENERATE_DEMO]}>
      <ColorButton
        size="large"
        variant="text"
        onClick={handleGenerateDemo}
        isDisabled={isGenerateDemoLoading}
        endIcon={
          isGenerateDemoLoading ? (
            <CircularProgress
              size={15}
              thickness={7}
              sx={{ color: "#009efb" }}
            />
          ) : (
            <AutoFixHighIcon sx={{ color: "#23aaeb" }} />
          )
        }
        sx={{
          mb: 1.5,
          fontSize: 16,
          display: "flex",
          color: "#23aaeb",
          fontWeight: "bold",
          marginInlineStart: "auto",
        }}
      >
        generate demo
      </ColorButton>
    </HasPrivileges>
  );
};

export default DemoButton;

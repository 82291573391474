import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MainModal from "../../../Components/MainModal";
import Dropzone from "react-dropzone-uploader";
import { Tab, Tabs } from "@mui/material";
import { InputForm, BSelect } from "form-builder";
import { useMutation, useLazyQuery } from "@apollo/client";
import {
  assignAssetMutation,
  retrieveAssetMutation,
} from "../../../Graphql/mutation";
import { fetchAssetInfoQuery } from "../../../Graphql/query";
import {
  onFormResetAction,
  toggleAssignRetriveAssetModal,
  addAssetInputsAction,
  removeItemsInputAction,
  assetChildInputChange,
  resetAssignRetriveAssetModalValues,
} from "../../../Store/Actions";
import { showToast } from "../../../Helpers/HelperFns";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AddButton } from "../../../Components/Buttons";
import _ from "lodash";

const formName = "assignRetriveAssetForm";
const formNameValidation = "assignRetriveAssetFormClientValidations";

const AssignRetriveAssetsModal = ({ employeeId = null, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Redux States
  const isOpen = useSelector(
    (state) => state?.assets?.assignRetriveAssetModal?.isOpen
  );
  const formData = useSelector((state) => state?.assets?.[formName]);
  const clientValidations = useSelector(
    (state) => state?.assets?.[formNameValidation]
  );

  // Local State
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [tabValue, setTabValue] = useState(
    formData?.cameFrom === "cameFromAssetAssignUnitsModal"
      ? "assign"
      : formData?.cameFrom === "cameFromAssetRetriveUnitsModal"
      ? "retrieve"
      : "assign"
  );
  const [assetChildrenValues, setAssetChildrenValues] = useState({
    assetIdValue: null,
    warehouseIdValue: null,
  });
  const [retrieveSectionValidation, setRetrieveSectionValidation] =
    useState("");

  const [assignSectionValidation, setAssignSectionValidation] = useState("");

  // tabs values
  const assignRetrieveTabs = [
    {
      label: `Assign`,
      value: "assign",
    },
    {
      label: `Retrieve`,
      value: "retrieve",
    },
  ];

  // Server State
  useEffect(() => {
    // fetch asset info data
    fetchAssetInfo({
      variables: {
        employeeId: "",
        warehouseId: "",
        assetId: "",
        firstFetch: true,
        fetchAssignedItems: false,
      },
    });

    return () => {
      // reset values
      handleResetModalValues();
    };
  }, []);

  // handle fetch items belong to storage location choosen
  useEffect(() => {
    if (
      formData?.warehouseId !== null ||
      assetChildrenValues?.warehouseIdValue !== null
    ) {
      // fetch asset info data
      fetchAssetInfo({
        variables: {
          employeeId: formData?.employee_id ?? "",
          assetId: formData?.assetId ?? assetChildrenValues?.assetIdValue,
          warehouseId:
            formData?.warehouseId ?? assetChildrenValues?.warehouseIdValue,
          firstFetch: false,
          fetchAssignedItems: false,
        },
      });
    }
  }, [formData?.warehouseId, assetChildrenValues?.warehouseIdValue]);

  // handle fetch storage location according to asset id choosen
  useEffect(() => {
    if (
      formData?.assetId !== null ||
      assetChildrenValues?.assetIdValue !== null
    ) {
      // fetch asset info data
      fetchAssetInfo({
        variables: {
          employeeId: formData?.employee_id ?? "",
          warehouseId:
            formData?.warehouseId ??
            assetChildrenValues?.warehouseIdValue ??
            "",
          assetId: formData?.assetId ?? assetChildrenValues?.assetIdValue,
          firstFetch: false,
          fetchAssignedItems: false,
        },
      });
    }
  }, [formData?.assetId, assetChildrenValues?.assetIdValue]);

  // handle fetch items belong to employee in retrieve case
  useEffect(() => {
    if (formData?.employee_id !== null && tabValue === "retrieve") {
      // fetch asset info data
      fetchAssetInfo({
        variables: {
          employeeId: formData?.employee_id,
          warehouseId: "",
          assetId: "",
          firstFetch: true,
          fetchAssignedItems: true,
        },
      });
    }
  }, [formData?.employee_id]);

  // Start of handle fetch asset info query
  const [fetchAssetInfo, { data: assetInfoData, loading: assetInfoIsLoading }] =
    useLazyQuery(fetchAssetInfoQuery, {
      onError(err) {
        // error toaster
        showToast(
          "error",
          err?.graphQLErrors[0]?.extensions?.reason ||
            err?.graphQLErrors[0]?.message ||
            err?.message
        );
      },
      onCompleted: (data) => {
        console.log("asset info data", data);
        if (data?.fetchAsset?.id) {
          // handle add item on fetch
          dispatch(
            assetChildInputChange(
              formName,
              "items",
              "product_type",
              _.findIndex(
                formData?.items,
                (e) => {
                  return (
                    e?.product_type === "" ||
                    e?.asset_id === data?.fetchAsset?.id
                  );
                },
                0
              ),
              data?.fetchAsset?.product_type
            )
          );

          dispatch(
            assetChildInputChange(
              formName,
              "items",
              "warehouseOptions",
              _.findIndex(
                formData?.items,
                (e) => {
                  return (
                    e?.warehouseOptions?.length === 0 ||
                    e?.asset_id === data?.fetchAsset?.id
                  );
                },
                0
              ),
              data?.fetchAsset?.warehouses
            )
          );

          dispatch(
            assetChildInputChange(
              formName,
              "items",
              "serialOptions",
              _.findIndex(
                formData?.items,
                (e) => {
                  return (
                    e?.serialOptions?.length === 0 ||
                    e?.asset_id === data?.fetchAsset?.id
                  );
                },
                0
              ),
              data?.fetchAsset?.items_according_to_warhouse
            )
          );
        }
      },
    });
  // End of handle fetch asset info query

  // Start of handle assign asset mutation
  const [assignAsset, { loading: assignAssetIsLoading }] = useMutation(
    assignAssetMutation,
    {
      onCompleted: (data) => {
        if (data?.storeAssignOrder?.__typename == "GeneralException") {
          // error toaster
          showToast("error", data?.storeAssignOrder?.message);
          return;
        }
        // success toaster
        showToast("success");

        // handle dismiss modal
        handleDismissModal();

        // handle refetch data list
        props?.handleRefetch();
      },
      onError(err) {
        // error toaster
        showToast(
          "error",
          err?.graphQLErrors?.[0].extensions?.reason ?? err?.message
        );
      },
    }
  );
  // End of handle assign asset mutation

  // Start of handle retrieve asset mutation
  const [retrieveAsset, { loading: retreiveAssetIsLoading }] = useMutation(
    retrieveAssetMutation,
    {
      onCompleted: (data) => {
        if (data?.retrieveAssignOrder?.__typename == "GeneralException") {
          // error toaster
          showToast("error", data?.retrieveAssignOrder?.message);
          return;
        }
        // success toaster
        showToast("success");

        // handle dismiss modal
        handleDismissModal();

        // handle refetch data list
        props?.handleRefetch();
      },
      onError(err) {
        // error toaster
        showToast(
          "error",
          err?.graphQLErrors?.[0].extensions?.reason ?? err?.message
        );
      },
    }
  );
  // End of handle retrieve asset mutation

  /* ↓ Helpers ↓ */

  // handle reset modal values function
  const handleResetModalValues = () => {
    setFormSubmitting(false);
    dispatch(onFormResetAction(formName));
    dispatch(onFormResetAction(formNameValidation));
    dispatch(resetAssignRetriveAssetModalValues());
  };

  // handle toggle between tabs function
  const handleToggleTabs = (event, value) => {
    // reset form on toggle change
    handleResetModalValues();
    setAssetChildrenValues({
      assetIdValue: null,
      warehouseIdValue: null,
    });

    setTabValue(value);
  };

  // Start of add/remove items multiple inputs
  const handleAddItemInput = () => {
    dispatch(addAssetInputsAction(formName));
  };

  const handleRemoveItemInput = (index) => {
    dispatch(removeItemsInputAction(formName, index));

    // reset asset option value
    setAssetChildrenValues((prev) => ({
      ...prev,
      assetIdValue: null,
    }));
  };

  const handleChildInputChange = (event, childName, index) => {
    dispatch(
      assetChildInputChange(
        formName,
        childName,
        event?.target?.name,
        index,
        event?.target?.value
      )
    );
  };

  // handle onChange for select
  const handleChildSelectInputChange = (event, val, childName, index) => {
    if (event?.name === "warehouse_id") {
      // handle warehouseId useState value to fetch accordding to it the items in this warehouse
      setAssetChildrenValues((prev) => ({
        ...prev,
        warehouseIdValue: val?.id,
      }));
    }
    if (event?.name === "asset_id") {
      setAssetChildrenValues((prev) => ({
        ...prev,
        assetIdValue: val?.id,
      }));
    }

    if (val?.length) {
      dispatch(
        assetChildInputChange(
          formName,
          childName,
          event?.name,
          index,
          val?.map((el) => el.id) ?? []
        )
      );
    } else {
      dispatch(
        assetChildInputChange(
          formName,
          childName,
          event?.name,
          index,
          val ? val?.id : ""
        )
      );
    }
  };
  // End of add/remove items multiple inputs

  // initail vlaue for requestFiles set to empty array
  const [requestFiles, setRequestFiles] = useState([]);

  const serializedAttachemnts = requestFiles?.map(({ file, extension }) => ({
    file,
    extension,
  }));

  // function to add object to requestFiles array
  const addRequestFileObject = (file) => {
    setRequestFiles((requestFiles) => [...requestFiles, file]);
  };

  // function to remove an object from an requestFiles array
  const removeRequestFileObject = (id) => {
    setRequestFiles((requestFiles) =>
      requestFiles.filter((file) => file?.id !== id)
    );
  };

  // handle request image change
  const handleImageChange = ({ file, meta }, status) => {
    // to convert image to base64 string
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      let base64 = reader.result?.split(",");
      let fileType = file?.type?.split("/");

      if (status == "done") {
        let target = {
          file: base64[1],
          extension: fileType[1],
          id: meta?.id,
        };
        addRequestFileObject(target);
      }
    };

    if (status == "removed") {
      removeRequestFileObject(meta?.id);
    }
    return;
  };

  // handle remove all attachments
  const removeAllSelectedFiles = (_, allFiles) => {
    allFiles?.forEach((f) => f?.remove());
  };

  // handle submit form function
  const handleSubmit = () => {
    setFormSubmitting(true);
    setRetrieveSectionValidation("");
    setAssignSectionValidation("");

    // handle validation for add asset in assigned section
    if (
      formData?.items.filter(
        (el) =>
          el?.asset_id === "" ||
          el?.warehouse_id === "" ||
          (el?.id === null &&
            el?.product_type?.toLowerCase() === "serialized") ||
          (el?.number_of_items === "" &&
            el?.product_type?.toLowerCase() === "non_serialized")
      ).length > 0 &&
      (tabValue === "assign" || !!employeeId)
    ) {
      // error validation
      setAssignSectionValidation("Please fill all the Required Fields");
      return;
    }

    if (!clientValidations?.length) {
      if (tabValue === "assign" || !!employeeId) {
        assignAsset({
          variables: {
            input: {
              employee_id: !!employeeId ? employeeId : formData?.employee_id,
              ...(!!serializedAttachemnts[0]
                ? {
                    file: { ...serializedAttachemnts[0] },
                  }
                : {}),
              assets: formData?.items?.map(
                ({ asset_id, product_type, number_of_items, id }) => ({
                  asset_id,
                  ...(product_type?.toLowerCase() === "non_serialized"
                    ? {
                        number_of_items: +number_of_items,
                      }
                    : {
                        items: id !== null && id,
                      }),
                })
              ),
            },
          },
        });
      } else if (tabValue === "retrieve") {
        if (assetInfoData?.employeeAssignItems?.data?.length > 0) {
          retrieveAsset({
            variables: {
              input: {
                items:
                  formData?.retrieveItemsId !== null &&
                  formData?.retrieveItemsId,
                warehouse_id: formData?.retrieveWarehouseId,
              },
            },
          });
        } else {
          // error validation
          setRetrieveSectionValidation("Employee has no assets assigned");
        }
      } else {
        return;
      }
    }
  };

  // handle dismiss modal function
  const handleDismissModal = () => {
    dispatch(toggleAssignRetriveAssetModal({ isOpen: false }));

    // reset values
    handleResetModalValues();
    setAssetChildrenValues({
      assetIdValue: null,
      warehouseIdValue: null,
    });
  };

  return (
    <MainModal
      isOpen={isOpen}
      toggle={handleDismissModal}
      modalTitle={
        formData?.cameFrom === "cameFromAssetAssignUnitsModal"
          ? t("assign asset")
          : formData?.cameFrom === "cameFromAssetRetriveUnitsModal"
          ? t("retrieve asset")
          : t("assign / retrieve asset")
      }
      btnLabel={t("save")}
      btnOnClick={handleSubmit}
      btnSubmitLoading={assignAssetIsLoading || retreiveAssetIsLoading}
    >
      {formData?.cameFrom === "cameFromAssetAssignUnitsModal" ||
      formData?.cameFrom === "cameFromAssetRetriveUnitsModal" ||
      !!employeeId ? (
        !!employeeId ? null : (
          <div className="row asset_units_modal_wrapper_style">
            <div className="col-12">
              <div className="d-flex align-items-baseline">
                <div className="asset_name_style">{formData?.assetName}</div>

                <div className="asset_type_model_brand_style">
                  {formData?.assetType} - {formData?.assetBrand}{" "}
                  {formData?.assetModel}
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        <div className="row mb-3">
          <div className="col-12">
            <div className="justify-content-start align-items-center">
              <Tabs
                indicatorColor="primary"
                className="tab_border_bottom_style"
                value={tabValue}
                onChange={handleToggleTabs}
                centered
                variant="fullWidth"
              >
                {assignRetrieveTabs.map((opt) => (
                  <Tab
                    key={opt?.label}
                    label={t(opt?.label?.toLowerCase())}
                    value={opt?.value}
                  />
                ))}
              </Tabs>
            </div>
          </div>
        </div>
      )}

      {employeeId ? null : (
        <div className="row mb-2">
          <div className="col-12">
            <BSelect
              formName={formName}
              formNameValidation={formNameValidation}
              formSubmitting={formSubmitting}
              reducer="assets"
              name="employee_id"
              label="employee"
              keepDefaultStyle
              placeholder={t("select option")}
              validateBy="textRequired"
              isLoading={assetInfoIsLoading}
              isDisabled={assetInfoIsLoading}
              options={assetInfoData?.company_users?.data ?? []}
              optionLabel="name"
              optionValue="id"
              labelStyle="mb-1"
              inputContainerStyle="b-select-style "
              skipLocalization
            />
          </div>
        </div>
      )}

      {formData?.cameFrom === "cameFromAssetAssignUnitsModal" ||
      formData?.cameFrom === "cameFromAssetRetriveUnitsModal" ? null : (
        <>
          {tabValue === "assign" && (
            <>
              <div className="row mb-2">
                <div className="col-12">
                  <div className="d-flex gap-20 align-items-center mb-2">
                    <strong className="sub-title-style">{t("asset")}</strong>

                    {formData?.items?.length ===
                    assetInfoData?.companyAssets?.data?.length ? null : (
                      <AddButton isIconOnly onClick={handleAddItemInput} />
                    )}
                  </div>

                  {formData?.items?.map((item, i) => (
                    <>
                      <div
                        className="d-flex w-100 gap-20 align-items-center"
                        key={`asset-${i}`}
                      >
                        <BSelect
                          formName={formName}
                          formNameValidation={formNameValidation}
                          formSubmitting={formSubmitting}
                          reducer="assets"
                          name="asset_id"
                          label="assets"
                          keepDefaultStyle
                          placeholder={t("select option")}
                          isLoading={assetInfoIsLoading}
                          isDisabled={assetInfoIsLoading}
                          options={
                            formData?.items?.length > 1
                              ? assetInfoData?.companyAssets?.data.filter(
                                  (el) =>
                                    !formData?.items.find(
                                      (obj) => obj?.asset_id === el?.id
                                    )
                                )
                              : assetInfoData?.companyAssets?.data ?? []
                          }
                          value={
                            assetInfoData?.companyAssets?.data?.filter(
                              (el) => el?.id === item?.asset_id
                            )?.[0]
                          }
                          onChange={(e, val) =>
                            handleChildSelectInputChange(val, e, "items", i)
                          }
                          optionLabel="name"
                          optionValue="id"
                          labelStyle="mb-1"
                          rootStyle="flex-1"
                          inputContainerStyle="b-select-style "
                          skipLocalization
                        />

                        {item?.asset_id === assetChildrenValues?.assetIdValue &&
                        assetInfoIsLoading ? null : (
                          <>
                            <BSelect
                              formName={formName}
                              formNameValidation={formNameValidation}
                              formSubmitting={formSubmitting}
                              reducer="assets"
                              name="warehouse_id"
                              label="storage locations"
                              keepDefaultStyle
                              placeholder={t("select option")}
                              isLoading={assetInfoIsLoading}
                              isDisabled={assetInfoIsLoading}
                              options={item?.warehouseOptions ?? []}
                              value={item?.warehouseOptions?.filter(
                                (el) => el?.id === item?.warehouse_id
                              )}
                              onChange={(e, val) =>
                                handleChildSelectInputChange(val, e, "items", i)
                              }
                              optionLabel="name"
                              optionValue="id"
                              labelStyle="mb-1"
                              rootStyle="flex-1"
                              inputContainerStyle="b-select-style "
                              skipLocalization
                            />

                            {item?.product_type?.toLowerCase() ===
                              "non_serialized" && (
                              <InputForm
                                formName={formName}
                                formNameValidation={formNameValidation}
                                formSubmitting={formSubmitting}
                                reducer="assets"
                                label="units"
                                type="number"
                                min="1"
                                name="number_of_items"
                                value={
                                  assetInfoData?.fetchAsset
                                    ?.items_according_to_warhouse?.length <
                                  item?.number_of_items
                                    ? assetInfoData?.fetchAsset
                                        ?.items_according_to_warhouse?.length
                                    : item?.number_of_items < 1 &&
                                      item?.number_of_items !== ""
                                    ? ""
                                    : item?.number_of_items
                                }
                                onChange={(e) =>
                                  handleChildInputChange(e, "items", i)
                                }
                                labelStyle=""
                                containerStyle="mt-0"
                                inputContainerStyle=" "
                              />
                            )}

                            {item?.product_type?.toLowerCase() ===
                              "serialized" && (
                              <BSelect
                                formName={formName}
                                formNameValidation={formNameValidation}
                                formSubmitting={formSubmitting}
                                isMulti
                                reducer="assets"
                                name="id"
                                label="serial numbers"
                                keepDefaultStyle
                                placeholder={t("select option")}
                                isLoading={assetInfoIsLoading}
                                isDisabled={assetInfoIsLoading}
                                value={
                                  item?.serialOptions?.filter(
                                    (obj) => obj?.id === item?.id
                                  )?.[0]
                                }
                                onChange={(e, val) =>
                                  handleChildSelectInputChange(
                                    val,
                                    e,
                                    "items",
                                    i
                                  )
                                }
                                options={item?.serialOptions ?? []}
                                optionLabel="serial_number"
                                optionValue="id"
                                labelStyle="mb-1"
                                rootStyle="flex-1"
                                inputContainerStyle="b-select-style "
                                skipLocalization
                              />
                            )}

                            <button
                              className="btn delete_btn_style mx-2"
                              type="button"
                              onClick={() => handleRemoveItemInput(i)}
                            >
                              <FontAwesomeIcon icon={faTimes} />
                            </button>
                          </>
                        )}
                      </div>
                    </>
                  ))}
                </div>
              </div>

              {/* (Start) Error Message */}
              {assignSectionValidation && (
                <div className="warnig-msg-style py-1">
                  {assignSectionValidation}
                </div>
              )}
              {/* (End) Error Message */}

              <div className="row">
                <div className="col-12">
                  <label className="mb-2">{t("attachements")}</label>

                  <div className="documents_dropzone_style">
                    <Dropzone
                      onChangeStatus={handleImageChange}
                      canRemove
                      accept="image/*"
                      name="file"
                      multiple={false}
                      maxFiles={1}
                      inputContent={t("face_placeholder")}
                      onSubmit={removeAllSelectedFiles}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {tabValue === "retrieve" &&
            assetInfoData?.employeeAssignItems?.data?.length > 0 && (
              <>
                <div className="row align-items-center mb-1">
                  <div className="col-12 col-md-6">
                    <BSelect
                      formName={formName}
                      formNameValidation={formNameValidation}
                      formSubmitting={formSubmitting}
                      reducer="assets"
                      isMulti
                      name="retrieveItemsId"
                      label="assets"
                      keepDefaultStyle
                      placeholder={t("select option")}
                      validateBy="textRequired"
                      isLoading={assetInfoIsLoading}
                      isDisabled={assetInfoIsLoading}
                      options={assetInfoData?.employeeAssignItems?.data ?? []}
                      optionLabel="name"
                      optionValue="id"
                      getOptionLabel={(opt) => (
                        <span dir="auto">
                          {opt?.serial_number === ""
                            ? `${opt?.asset?.name}`
                            : `${opt?.asset?.name} - ${opt?.serial_number}`}
                        </span>
                      )}
                      labelStyle="mb-1"
                      inputContainerStyle="b-select-style "
                      skipLocalization
                    />
                  </div>

                  <div className="col-12 col-md-6">
                    <BSelect
                      formName={formName}
                      formNameValidation={formNameValidation}
                      formSubmitting={formSubmitting}
                      reducer="assets"
                      name="retrieveWarehouseId"
                      label="storage location"
                      keepDefaultStyle
                      placeholder={t("select option")}
                      validateBy="textRequired"
                      isLoading={assetInfoIsLoading}
                      isDisabled={assetInfoIsLoading}
                      options={assetInfoData?.companyWarehouses?.data ?? []}
                      optionLabel="name"
                      optionValue="id"
                      labelStyle="mb-1"
                      inputContainerStyle="b-select-style "
                      skipLocalization
                    />
                  </div>
                </div>

                {/* (Start) Error Message */}
                {retrieveSectionValidation && (
                  <div className="warnig-msg-style px-3 pt-1">
                    {retrieveSectionValidation}
                  </div>
                )}
                {/* (End) Error Message */}
              </>
            )}
        </>
      )}
    </MainModal>
  );
};

export default AssignRetriveAssetsModal;

import React from "react";

import { paymentOptions } from "../../Constants/PaymentTracking";

import Tooltip from "@mui/material/Tooltip";

const types = paymentOptions.paymentMethods;

const PaymentMethodCell = ({ method }) => {
  return (
    <Tooltip
      title={
        method?.name ? (
          <>
            {method?.name}
            {method?.no ? (
              <>
                <br />
                {method?.no}
              </>
            ) : null}
            {method?.iban ? (
              <>
                <br />
                {method?.iban}
              </>
            ) : null}
          </>
        ) : (
          ""
        )
      }
    >
      {types?.find((type) => type?.id === method?.type)?.name || ""}
    </Tooltip>
  );
};

export default PaymentMethodCell;

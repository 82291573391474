import React from "react";
import { useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";

import {
  billingPeriodOptions,
  promotionTypeOptions,
} from "../../Constants/PaymentTracking";
import {
  getPlansOptions,
  formatRatePerPeriod,
  serializeFetchPlanTiers,
} from "../../Helpers/HelperFns/PaymentTracking";
import moment from "moment";
import { EgyptId } from "../../Constants";
import { showToast } from "../../Helpers/HelperFns";
import { onFormResetAction, updateValueAction } from "../../Store/Actions";
import { subscriptionFormQuery } from "../../Graphql/query/PaymentTracking";

import {
  BSelect,
  InputForm,
  RadioboxForm,
  DateTimePickerForm,
  CheckboxBooleanForm,
} from "form-builder";
import Tooltip from "@mui/material/Tooltip";

const reducer = "paymentTracking";
const formName = "subscriptionForm";
const formNameValidation = "paymentTrackingClientValidation";
const formServerValidation = "paymentTrackingServerValidation";
const formatOption = (str) => ({ id: str, name: str });
const calcEndDate = (period, duration, startDate) => {
  let endDate;
  const date = moment(startDate);
  switch (period) {
    case "Monthly":
      endDate = date.add("months", +duration);
      break;
    case "Quarterly":
      endDate = date.add("months", 3 * +duration);
      break;
    case "Semi-annually":
      endDate = date.add("months", 6 * +duration);
      break;
    case "Annually":
      endDate = date.add("years", +duration);
      break;
    default:
      break;
  }

  return endDate.subtract("days", 1).format("yyyy-MM-DD");
};
const getTierPrice = (config, empNum) => {
  const tiers = serializeFetchPlanTiers(config);
  if (tiers.length === 1) {
    return tiers[0]?.price;
  } else {
    const currTier = tiers.find((t) => t.from <= empNum && t.to >= empNum);
    return currTier ? currTier.price : tiers.at(-1).price;
  }
};

const SubscriptionForm = ({ data }) => {
  const dispatch = useDispatch();

  // Local State
  const [plans, setPlans] = React.useState([]);

  // Reducer State
  const formData = useSelector((state) => state?.[reducer]?.[formName]);
  const options = useSelector(
    (state) => state?.[reducer]?.paymentTrackingOptions
  );

  // Server State
  const { loading } = useQuery(subscriptionFormQuery, {
    variables: {},
    onCompleted: ({ plans, countries }) => {
      // Options
      setPlans(
        getPlansOptions(plans?.data, +data?.companyId || formData?.company)
      );
      dispatch(
        onFormResetAction("paymentTrackingOptions", {
          ...options,
          plans: plans?.data || [],
          countries: countries || [],
        })
      );
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });

  // Constants
  const selectedPlanCurrency = options?.plans.find(
    (plan) => plan?.id === formData?.plan
  )?.currency?.name;
  const FormProps = {
    reducer,
    formName,
    formSubmitting: data?.formSubmitting,
    formNameValidation,
    formServerValidation,
  };

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    if (
      formData.billingPeriod &&
      formData.contractDuration &&
      formData.startDate
    ) {
      handleSelectEndDate();
    }
  }, [formData.billingPeriod, formData.contractDuration, formData.startDate]);

  React.useEffect(() => {
    if (formData.plan && formData.employees) {
      const plan = options.plans.find((o) => o.id === formData.plan);
      dispatch(
        updateValueAction(
          formName,
          "ratePerPeriod",
          formatRatePerPeriod(
            plan,
            getTierPrice(plan?.configuration, +formData?.employees)
          )
        )
      );
    } else {
      dispatch(updateValueAction(formName, "ratePerPeriod", ""));
    }
  }, [formData.plan, formData.employees]);

  /* ↓ Helpers ↓ */

  const onSelectCompany = (_, company) => {
    setPlans(getPlansOptions(options?.plans, company?.id));
    dispatch(updateValueAction(formName, "plan", null));
  };

  const onSelectPlan = (_, plan) => {
    dispatch(
      onFormResetAction(formName, {
        ...formData,
        billingPeriod: plan.billingPeriod,
        contractDuration: plan.contractDuration,
      })
    );
  };

  const handleSelectEndDate = () => {
    const endDate = calcEndDate(
      formData.billingPeriod,
      formData.contractDuration,
      formData.startDate
    );

    dispatch(updateValueAction(formName, "endDate", endDate));
  };

  const generatePromotions = () => {
    formData?.contractDuration &&
      dispatch(
        updateValueAction(
          formName,
          "promotions",
          Array.from(Array(+formData?.contractDuration), (_, i) => i + 1).map(
            () => ({ type: "Fixed", amount: "0" })
          )
        )
      );
  };

  const handlePromotionTypeChange = (promotionIdx, type) => {
    dispatch(
      updateValueAction(
        formName,
        "promotions",
        formData.promotions.map((p, i) =>
          promotionIdx === i ? { ...p, type } : p
        )
      )
    );
  };

  const handlePromotionAmountChange = (promotionIdx, amount) => {
    dispatch(
      updateValueAction(
        formName,
        "promotions",
        formData.promotions.map((p, i) =>
          promotionIdx === i ? { ...p, amount } : p
        )
      )
    );
  };

  return (
    <>
      {!data?.isSubscriptionProfile ? (
        <>
          {data?.isCompanyForm ? null : (
            <BSelect
              {...FormProps}
              label="company"
              name="company"
              icon="department"
              rootStyle="w-100"
              validateBy="textRequired"
              options={options.companies}
              isLoading={!options.companies.length}
              onInterceptInputOnChange={onSelectCompany}
              validationName="company_id"
            />
          )}
          <div className="d-flex gap-10">
            <BSelect
              {...FormProps}
              label="country"
              name="country"
              icon="globe"
              validateBy="textRequired"
              rootStyle="flex-1"
              options={options.countries}
              isLoading={!options.countries.length}
              validationName="country_id"
            />
            <BSelect
              {...FormProps}
              label="taxation"
              name="taxation"
              icon="document"
              validateBy="textRequired"
              rootStyle="flex-1"
              options={[
                { id: "Custom", name: "Custom" },
                { id: "Egyptian taxation", name: "Egyptian taxation" },
              ]}
              dependOn="country"
              dependancyType="equal"
              dependancyValue={[EgyptId]}
              validationName="taxation"
            />
          </div>
          <div className="d-flex align-items-end justify-content-between gap-10 my-2">
            <RadioboxForm
              {...FormProps}
              name="applyTax"
              options={[
                { label: "Apply VAT", value: 1 },
                { label: "Don't Apply VAT", value: 0 },
              ]}
              optionInputStyle=" "
              optionItemStyle=" "
              containerStyle=" "
              optionsContainerStyle="d-flex gap-20"
              rootStyle="flex-1"
            />
            {formData.applyTax ? (
              <InputForm
                {...FormProps}
                name="taxAmount"
                placeholder="taxation percentage"
                labelStyle="mb-2 pt-1"
                containerStyle="w-100"
                rootStyle="flex-1"
                validateBy="textRequired"
                dependOn="taxation"
                dependancyType="equal"
                dependancyValue="Custom"
                validationName="taxation"
              />
            ) : null}
          </div>
          <CheckboxBooleanForm
            {...FormProps}
            name="generateEInvoices"
            options={["Generate e-invoices"]}
          />
        </>
      ) : null}

      <div
        {...(!data?.isSubscriptionProfile && {
          className: "border p-2 mt-4",
          style: { borderRadius: 4 },
        })}
      >
        <BSelect
          {...FormProps}
          label="plan"
          name="plan"
          icon="document"
          rootStyle="w-50 d-inline-block pr-2"
          validateBy="textRequired"
          options={plans}
          isLoading={loading}
          onInterceptInputOnChange={onSelectPlan}
          validationName="plan_id"
        />
        <InputForm
          {...FormProps}
          type="number"
          name="suspend_after"
          label="suspense after days"
          labelStyle="mb-2 pt-1"
          containerStyle="w-100"
          rootStyle="w-50 d-inline-block"
          placeholder="suspense after days"
          validateBy="textRequired"
          validationName="duration"
          icon="time"
        />
        <BSelect
          {...FormProps}
          isDisabled
          label="Billing period"
          name="billingPeriod"
          icon="calendar"
          rootStyle="w-50 d-inline-block pr-2"
          options={billingPeriodOptions}
          validateBy="textRequired"
        />
        <Tooltip
          title={
            !formData?.applyPromotionAll
              ? "unselect 'Apply Promotion To All Invoices' to be able edit"
              : ""
          }
        >
          <span>
            <InputForm
              {...FormProps}
              name="contractDuration"
              label="Contract Duration"
              labelStyle="mb-2 pt-1"
              containerStyle="w-100"
              rootStyle="w-50 d-inline-block"
              placeholder="No. Of Billing Cycles"
              validateBy="textRequired"
              disabled={!formData?.plan || !formData?.applyPromotionAll}
              icon="time"
              validationName="duration"
            />
          </span>
        </Tooltip>
        <InputForm
          {...FormProps}
          name="employees"
          label="Employees"
          labelStyle="mb-2 pt-1"
          containerStyle="w-100"
          rootStyle="w-50 d-inline-block pr-2"
          placeholder="No. of Employees"
          validateBy="textRequired"
          icon="employees"
          validationName="users"
        />
        <InputForm
          {...FormProps}
          disabled
          name="ratePerPeriod"
          label="Rate Per Period"
          labelStyle="mb-2 pt-1"
          containerStyle="w-100"
          rootStyle="w-50 d-inline-block"
          validateBy="textRequired"
          icon="calculator"
        />
        <DateTimePickerForm
          {...FormProps}
          name="startDate"
          label="Start Date"
          labelStyle="mb-2 pt-1"
          containerStyle="w-100"
          rootStyle="w-50 d-inline-block pr-2"
          validateBy="textRequired"
          hasIcon
          isDisabled={!formData.plan && !formData.contractDuration}
          validationName="from"
        />
        <DateTimePickerForm
          {...FormProps}
          name="endDate"
          label="End Date"
          labelStyle="mb-2 pt-1"
          containerStyle="w-100"
          rootStyle="w-50 d-inline-block"
          validateBy="textRequired"
          disabled
          hasIcon
        />
        <CheckboxBooleanForm
          {...FormProps}
          name="prorateAmount"
          containerStyle="mt-2"
          options={["Prorate the amount of the first month"]}
          disabled
        />
      </div>

      {/* Promotions */}
      <div className="border px-2 py-3 mt-4">
        <Tooltip
          title={
            Boolean(+formData?.contractDuration)
              ? ""
              : "enter contract duration to customize the promotions"
          }
        >
          <span>
            <CheckboxBooleanForm
              {...FormProps}
              name="applyPromotion"
              options={["Apply Promotion"]}
              disabled={!Boolean(+formData?.contractDuration)}
              onInterceptInputOnChange={generatePromotions}
            />
          </span>
        </Tooltip>

        {formData?.applyPromotion && formData?.applyPromotionAll ? (
          <>
            <BSelect
              label="Promotion Type"
              icon="document"
              placeholder="Select Promotion Type"
              rootStyle="w-50 d-inline-block pr-2 mt-2"
              options={promotionTypeOptions}
              value={
                formData?.promotions?.[0]?.type
                  ? formatOption(formData?.promotions?.[0]?.type)
                  : null
              }
              onChange={(val) => handlePromotionTypeChange(0, val.id)}
            />
            <InputForm
              name="amount"
              label={
                formData?.promotions?.[0]?.type === "Percentage"
                  ? "Percent"
                  : "Amount"
              }
              labelStyle="mb-2 pt-1"
              containerStyle="w-100"
              inputStyle="w-100 text-left"
              inputContainerStyle="w-100"
              rootStyle="w-50 d-inline-block mt-2"
              placeholder={
                formData?.promotions?.[0]?.type === "Percentage"
                  ? "Enter percentage"
                  : "Enter amount"
              }
              hasSuffix
              suffixTitle={
                formData?.promotions?.[0]?.type === "Percentage"
                  ? "%"
                  : selectedPlanCurrency
              }
              value={formData?.promotions?.[0]?.amount}
              onChange={(e) => handlePromotionAmountChange(0, e.target.value)}
            />
          </>
        ) : null}
        {formData?.promotions?.length > 1 ? (
          <CheckboxBooleanForm
            {...FormProps}
            name="applyPromotionAll"
            options={["Apply Promotion to all invoices"]}
            dependOn="applyPromotion"
            dependancyType="equal"
            dependancyValue={[1]}
          />
        ) : null}

        {formData?.applyPromotionAll ? null : (
          <div className="mt-3">
            {formData?.promotions?.map((prom, idx) => (
              <div key={idx} className="d-flex align-items-center gap-10 mb-1">
                <span style={{ minWidth: 70 }}>Invoice #{idx + 1}</span>
                <BSelect
                  icon="document"
                  placeholder="Select Promotion Type"
                  rootStyle="flex-1"
                  options={promotionTypeOptions}
                  value={prom?.type ? formatOption(prom?.type) : null}
                  onChange={(val) => handlePromotionTypeChange(idx, val.id)}
                />
                <InputForm
                  name="amount"
                  labelStyle="mb-2 pt-1"
                  containerStyle="w-100"
                  inputStyle="w-100 text-left"
                  inputContainerStyle="w-100"
                  rootStyle="flex-1"
                  placeholder={
                    prom?.type === "Percentage"
                      ? "Enter percentage"
                      : "Enter amount"
                  }
                  hasSuffix
                  suffixTitle={
                    prom?.type === "Percentage" ? "%" : selectedPlanCurrency
                  }
                  value={prom?.amount}
                  onChange={(e) =>
                    handlePromotionAmountChange(idx, e.target.value)
                  }
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default SubscriptionForm;

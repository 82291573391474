import React, { useEffect, useState } from 'react'
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import gql from "graphql-tag";

import { BSelect } from "form-builder";
import ShowAllModal from './ShowAllModal';
import DashboardWidget from '../../DashboardWidget'
import { WidgetPlaceholder } from '../../../LoadingSection/Dashboard'

export const getDashboardWidgetsQuery = gql`
  query getDashboardWidgets($office_id: ID = null, $firstRender: Boolean!) {
    t_attendance(office_id: $office_id) {
      all
      present
      late
      absent
      leave
      not_signed_in_yet
      weekend: weekend_holidays
    }
    company_offices(first: 999) @include(if: $firstRender) {
      data {
        value: id
        label: name
      }
    }
  }
`;

const initVal = {
  "All": false,
  "Absent": false,
  "Present": false,
  "Late": false,
  "Leave": false,
  "Day Off": false,
  "Not signed in": false,
}

const DashboardWidgets = () => {

  const { t } = useTranslation();

  const [offices, setOffices] = useState([])
  const [office_id, setOffice_id] = useState(null)

  const [isOpen, setIsOpen] = useState(initVal)

  const {
    data: widgetsData,
    loading: widgetsLoading,
  } = useQuery(getDashboardWidgetsQuery, {
    variables: {
      office_id: office_id,
      firstRender: offices.length === 0
    },
    onCompleted: () => {
      if (widgetsData?.company_offices) {
        setOffices(widgetsData.company_offices.data)
      }
    },
    fetchPolicy: "network-only",
  });



  const handleDismissModal = (status) => {
    setIsOpen(prev => ({ ...prev, [status]: false }))
  };

  const toggleShowEmployeesModal = (status) => {
    setIsOpen(prev => ({ ...prev, [status]: true }))
  }


  useEffect(() => {
    if (widgetsData?.company_offices) {
      setOffices(widgetsData.company_offices.data)
    }
  }, [widgetsData])


  const handleOfficeChange = (v) => {
    if (v) {
      setOffice_id(v?.value)
    } else {
      setOffice_id(null)
    }
  }

  return (
    <>
      <div className="d-flex justify-content-end mb-2 px-2">
        <div className="widgets-select-width">
          <BSelect
            onChange={(v) => handleOfficeChange(v)}
            optionLabel="label"
            optionValue="value"
            bValue={office_id}
            options={offices}
            isClearable
            isLoading={offices.length === 0 && widgetsLoading}
            placeholder={t("all offices")}
            icon="office"
          />
        </div>
      </div>
      <div className="d-flex flex-column flex-lg-row">
        <div className="w-100 px-2">
          {widgetsLoading ? (
            <WidgetPlaceholder />
          ) : (
            <DashboardWidget
              status="All"
              bgImageClass="employees_bg"
              value={widgetsData?.t_attendance.all}
              title={t("employees")}
              handleShowAllModal={toggleShowEmployeesModal}
            />
          )}
        </div>

        <div className="w-100 px-2">
          {widgetsLoading ? (
            <WidgetPlaceholder />
          ) : (
            <DashboardWidget
              status="Present"
              bgImageClass="present_bg"
              value={widgetsData?.t_attendance.present}
              title={t("ontime")}
              handleShowAllModal={toggleShowEmployeesModal}
            />
          )}
        </div>
        <div className="w-100 px-2">
          {widgetsLoading ? (
            <WidgetPlaceholder />
          ) : (
            <DashboardWidget
              status="Late"
              bgImageClass="late_comers_bg"
              value={widgetsData?.t_attendance.late}
              title={t("late")}
              handleShowAllModal={toggleShowEmployeesModal}
            />
          )}
        </div>

        <div className="w-100 px-2">
          {widgetsLoading ? (
            <WidgetPlaceholder />
          ) : (
            <DashboardWidget
              status="Absent"
              bgImageClass="absent_bg"
              value={widgetsData?.t_attendance.absent}
              title={t("absent")}
              handleShowAllModal={toggleShowEmployeesModal}
            />
          )}
        </div>

        <div className="w-100 px-2 text-nowrap">
          {widgetsLoading ? (
            <WidgetPlaceholder />
          ) : (
            <DashboardWidget
              status="Not signed in"
              bgImageClass="absent_bg"
              value={widgetsData?.t_attendance.not_signed_in_yet}
              title={t("Not Signed In")}
              handleShowAllModal={toggleShowEmployeesModal}
            />
          )}
        </div>

        <div className="w-100 px-2 text-nowrap">
          {widgetsLoading ? (
            <WidgetPlaceholder />
          ) : (
            <DashboardWidget
              status="Leave"
              bgImageClass="on_leave_bg"
              value={widgetsData?.t_attendance.leave}
              title={t("leave")}
              handleShowAllModal={toggleShowEmployeesModal}
            />
          )}
        </div>
        <div className="w-100 px-2 text-nowrap">
          {widgetsLoading ? (
            <WidgetPlaceholder />
          ) : (
            <DashboardWidget
              status="Day Off"
              bgImageClass="weekend_holiday_bg"
              value={widgetsData?.t_attendance.weekend}
              title={t("weekend_holiday")}
              handleShowAllModal={toggleShowEmployeesModal}
            />
          )}
        </div>
      </div>

      {/* (Start) Show All Modals */}
      {isOpen.All && <ShowAllModal
        status="All"
        modal_title={t("employees")}
        modal_toggle={handleDismissModal}
        showWorkingStatus
        office_id={office_id}
        isOpen={isOpen.All}
      />}

      {isOpen.Present && <ShowAllModal
        modal_title={t("present")}
        status="Present"
        modal_toggle={handleDismissModal}
        showWorkingStatus
        office_id={office_id}
        isOpen={isOpen.Present}
      />}

      {(isOpen.Absent || isOpen["Not signed in"]) && <ShowAllModal
        modal_title={isOpen.Absent ? t("absent") : t("Not Signed In Yet")}
        status={isOpen.Absent ? "Absent" : "Not signed in"}
        modal_toggle={handleDismissModal}
        showWorkingStatus
        office_id={office_id}
        isOpen={isOpen.Absent || isOpen["Not signed in"]}
      />}

      {isOpen.Late && <ShowAllModal
        modal_title={t("late")}
        status="Late"
        modal_toggle={handleDismissModal}
        showWorkingStatus
        office_id={office_id}
        isOpen={isOpen.Late}
      />}

      {isOpen.Leave && <ShowAllModal
        modal_title={t("leave")}
        status="Leave"
        modal_toggle={handleDismissModal}
        office_id={office_id}
        isOpen={isOpen.Leave}

      />}

      {isOpen["Day Off"] && <ShowAllModal
        modal_title={t("weekend_holiday")}
        status="Day Off"
        modal_toggle={handleDismissModal}
        office_id={office_id}
        isOpen={isOpen["Day Off"]}
        showWorkingStatus
      />}
      {/* (End) Show All Modals */}
    </>
  )
}

export default DashboardWidgets

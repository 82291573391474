import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MainModal from "../../Components/MainModal";
import {
  CheckboxBooleanForm,
  DateTimePickerForm,
  InputForm,
} from "form-builder";
import { useMutation } from "@apollo/client";
import { addGraceMinutesMutation } from "../../Graphql/mutation";
import {
  onFormResetAction,
  toggleAddGraceMinutesModal,
} from "../../Store/Actions";
import { showToast } from "../../Helpers/HelperFns";
import { Alert, AlertTitle } from "@mui/material";
import "./employeesActionsStyles.scss";

const formName = "addGraceMinutesModalForm";
const formNameValidation = "addGraceMinutesModalFormClientValidations";

const AddGraceMinutesModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [formSubmitting, setFormSubmitting] = useState(false);

  // Redux States
  const isOpen = useSelector(
    (state) => state?.super?.addGraceMinutesModal?.isOpen
  );
  const formData = useSelector((state) => state?.super?.[formName]);
  const clientValidations = useSelector(
    (state) => state?.super?.[formNameValidation]
  );

  // Server State
  useEffect(() => {
    return () => {
      dispatch(onFormResetAction(formName));
      dispatch(onFormResetAction(formNameValidation));
    };
  }, []);

  // Start of handle add grace minutes mutation
  const [bulkAddGraceMinutes, { loading }] = useMutation(
    addGraceMinutesMutation,
    {
      onCompleted: (data) => {
        if (data?.createBulkGraceMinute?.status == "error") {
          // error toaster
          showToast("error", data?.createBulkGraceMinute?.message);
          return;
        }
        // success toaster
        showToast("success", data?.createBulkGraceMinute?.message);

        // handle dismiss modal
        handleDismissModal();

        // reset employee selections
        props?.resetSelection();

        // handle refetch data list
        props?.refetch();
      },
      onError(err) {
        // error toaster
        showToast(
          "error",
          err?.graphQLErrors?.[0].extensions?.reason ?? err?.message
        );
      },
    }
  );
  // End of handle add grace minutes mutation

  /* ↓ Helpers ↓ */

  // handle submit form function
  const handleSubmit = () => {
    setFormSubmitting(true);

    if (!clientValidations?.length) {
      bulkAddGraceMinutes({
        variables: {
          input: {
            emp_ids: props?.selectedEmployees,
            allow_grace_minutes: formData?.allow_grace_minutes,
            sign_in_grace_minutes: formData?.sign_in_grace_minutes,
            start: formData?.start,
          },
        },
      });
    }
  };

  // handle dismiss modal function
  const handleDismissModal = () => {
    dispatch(toggleAddGraceMinutesModal({ isOpen: false }));
  };

  return (
    <MainModal
      isOpen={isOpen}
      toggle={handleDismissModal}
      modalTitle={`${t("Bulk Actions")} - ${t("add grace minutes")}`}
      btnLabel={t("save")}
      btnOnClick={handleSubmit}
      btnSubmitLoading={loading}
    >
      <div className="row mb-3">
        <div className="col-12">
          <Alert severity="info">
            <AlertTitle>{t("add grace minutes")}</AlertTitle>
            <strong>
              {t("Employees will be effected", {
                count: props?.selectedEmployees?.length,
              })}
            </strong>
          </Alert>
        </div>
      </div>

      <div className="row mb-2">
        <div className="col-12">
          <DateTimePickerForm
            name="start"
            validateBy="textRequired"
            label="Starting From"
            datePickerContainer="w-100"
            containerStyle="flex-column align-items-start"
            labelStyle="custom-label-style mb-2"
            disabled={loading}
            formName={formName}
            formNameValidation={formNameValidation}
            formSubmitting={formSubmitting}
            requestFormat="YYYY-MM-DD"
          />
        </div>
      </div>

      <div className="row mb-1">
        <div className="col-12">
          <CheckboxBooleanForm
            formName={formName}
            formNameValidation={formNameValidation}
            formSubmitting={formSubmitting}
            options={["allow grace minutes"]}
            name="allow_grace_minutes"
            type="checkbox"
            setWithValue
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <InputForm
            formName={formName}
            formNameValidation={formNameValidation}
            formSubmitting={formSubmitting}
            hasSuffix
            suffixTitle="minutes"
            validateBy="textRequired"
            label="grace minutes"
            name="sign_in_grace_minutes"
            type="number"
            containerStyle="container-style-default justify-content-between"
            dependOn="allow_grace_minutes"
            dependancyType="equal"
            dependancyValue={[true]}
          />
        </div>
      </div>
    </MainModal>
  );
};

export default AddGraceMinutesModal;

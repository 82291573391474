import React, { useEffect, useState } from "react";
import MainModal from "../../Components/MainModal";
import { DateTimePickerForm, InputForm } from "form-builder";
import { connect, useDispatch } from "react-redux";
import {
	acceptOvertimeRequestAction,
	updateOvertimeInputAction,
	dismissOvertimeModalAction,
	updateValueAction,
} from "../../Store/Actions";
import swal from "sweetalert";
import moment from "moment";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";

const formName = "overtimeModal";
const formNameValidation = "overtimeModalValidation";
const formServerValidation = "overtimeModalFormServerValidation";

const OvertimeModal = ({ refetchQueries, ...props }) => {
	const { userId } = useParams();
	const { t } = useTranslation();
	const [reqData, setReqData] = useState({
		hours: "00",
		minutes: "00",
	});

	useEffect(() => {
		if (props?.overtimeModal?.payable_interval_in_time && props.modalData.isVissible) {
			setReqData({
				hours: props?.overtimeModal?.payable_interval_in_time?.split(":")?.[0] || "00",
				minutes: props?.overtimeModal?.payable_interval_in_time?.split(":")?.[1] || "00",
			});
		} else {
			setReqData({ hours: "00", minutes: "00" });
		}
	}, [props?.overtimeModal?.payable_interval_in_time, props.modalData.isVissible]);
	const handleSubmitBtn = () => {
		setIsSubmitting(true);

		const payable_interval_in_time = `${reqData?.hours || "00"}:${reqData?.minutes || "00"}`;

		if (!props.modalValidation.length) {
			if (props?.overtimeModal?.modalType === "Edit") {
				props.acceptOvertimeRequestAction(
					props?.overtimeModal?.id,
					payable_interval_in_time,
					moment(props?.overtimeModal?.created_at).format("yyyy-MM-DD"),
					userId,
					props?.overtimeModal?.page_flag,
					false,
					refetchQueries
				);
			} else {
				swal({
					title: t("default_warning_accept_message"),
					icon: "warning",
					className: "swal-warning-style",
					dangerMode: true,
					buttons: true,
				}).then((willAccept) => {
					if (willAccept) {
						props.acceptOvertimeRequestAction(
							props?.overtimeModal?.id,
							payable_interval_in_time,
							moment(props?.overtimeModal?.created_at).format("yyyy-MM-DD"),
							userId,
							props?.overtimeModal?.page_flag,
							false,
							refetchQueries
						);
					}
				});
			}
		}
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		if (name == "minutes") {
			const regex = /^(?:[1-5]?[0-9])?$/;
			if (regex.test(value)) {
				setReqData((prevData) => ({
					...prevData,
					[name]: value,
				}));
			}
			return;
		} else {
			const regex = /^\d*$/;
			if (regex.test(value) && !value?.includes(".")) {
				setReqData((prevData) => ({
					...prevData,
					[name]: value,
				}));
			}
		}
	};

	const [isSubmitting, setIsSubmitting] = useState(false);

	const isModalVissible = props.modalData.isVissible;
	const modalMessage = props.modalData.modalMessage;
	const isLoading = props.modalData.isLoading;

	const toggleModal = () => {
		props.dismissOvertimeModalAction({
			ref: "overtimeModalActions",
			formName,
		});
	};

	useEffect(() => {
		setIsSubmitting(false);
	}, [isModalVissible]);
	return (
		<MainModal
			isOpen={isModalVissible}
			btnLabelCancel="Cancel"
			btnLabel="save"
			btnOnCancelClick={toggleModal}
			modalTitle={
				props?.overtimeModal?.modalType === "Edit"
					? t("edit_overtime_modal_title", {
							name: props?.overtimeModal?.name,
					  })
					: t("overtime_modal_title", {
							name: props?.overtimeModal?.name,
					  })
			}
			btnOnClick={handleSubmitBtn}
			btnSubmitLoading={isLoading}
			className="add_accept_claim_request_modal_wrapper_style"
		>
			<div>
				<div className="row mb-2">
					<div className="d-flex align-items-baseline col-6">
						<label className="mr-5">{t("day")}</label>
						<p>{moment(props?.overtimeModal?.req_day).format("DD/MM/yyyy")}</p>
					</div>

					<div className="d-flex align-items-baseline col-6">
						<label className="mr-5">{t("actual time")}</label>
						<p>{props?.overtimeModal?.interval_in_time}</p>
					</div>
				</div>

				<label>{t("payable overtime")}</label>
				<div className="row mx-0 gap-10">
					<div className="w-141">
						<InputForm
							inputStyle="text-center"
							label="hours"
							name="hours"
							hasSuffix
							suffixTitle="hours"
							labelStyle="mb-2"
							formName={formName}
							// formNameValidation={formNameValidation}
							validationName={`input.payable_interval_in_time`}
							formServerValidation={formServerValidation}
							formSubmitting={isSubmitting}
							// validateBy="textRequired"
							containerStyle="mb-2"
							type="number"
							onChange={handleInputChange}
							value={reqData?.hours}
						/>
					</div>
					<div className="w-141">
						<InputForm
							inputStyle="text-center"
							label="minutes"
							name="minutes"
							hasSuffix
							suffixTitle="minutes"
							labelStyle="mb-2"
							formName={formName}
							// formNameValidation={formNameValidation}
							validationName={`input.interval_in_time`}
							formServerValidation={formServerValidation}
							formSubmitting={isSubmitting}
							// validateBy="textRequired"
							containerStyle="mb-2"
							type="number"
							onChange={handleInputChange}
							value={reqData?.minutes}
						/>
					</div>
				</div>

				<div className="row">
					<div className="col-12">
						<DateTimePickerForm
							label="payable at"
							name="created_at"
							labelStyle="mb-2"
							formName={formName}
							formNameValidation={formNameValidation}
							validationName={`input.created_at`}
							formServerValidation={formServerValidation}
							formSubmitting={isSubmitting}
							validateBy="textRequired"
							containerStyle="mb-2"
							datePickerContainer="w-100"
							hasIcon
							requestFormat="yyyy-MM-DD"
						/>
					</div>
				</div>

				{/* (Start) Form server validation message */}
				<span className="warnig-msg-style">{props.serverVaildation[`input.description`] ?? " "}</span>
				{/* (End) Form server validation message */}

				{/* (Start) Error Message */}
				{modalMessage && isSubmitting && <div className="warnig-msg-style">{t(modalMessage)}</div>}
				{/* (End) Error Message */}
			</div>
		</MainModal>
	);
};

const mapStateToProps = (state) => ({
	modalData: state.super.overtimeModalActions,
	modalValidation: state.super[formNameValidation],
	overtimeModal: state.super[formName],
	serverVaildation: state.super[formServerValidation],
});

export default connect(mapStateToProps, {
	acceptOvertimeRequestAction,
	updateOvertimeInputAction,
	dismissOvertimeModalAction,
})(OvertimeModal);

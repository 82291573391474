import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DateRangeIcon from "@mui/icons-material/DateRange";
import moment from "moment";
import { useMutation } from "@apollo/client";
import {
  payLoanMutation,
  revertPaymentLoanMutation,
} from "../../Graphql/mutation";
import swal from "sweetalert";
import Loader from "../../Components/Loader";
import loanStatus from "../../Constants/LoanStatusConstants";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import {
  showErrorToast,
  showSuccessToast,
} from "../../Store/Actions/Feedback/actions";

const InstallmentsSection = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // states
  const loansList = useSelector((state) => state.super.loansList);

  // handleing pay swal
  const handlePaySwal = (installmentId, e) => {
    // to pervent card from toggling
    e.stopPropagation();

    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willPay) => {
      if (willPay) {
        handlePayLoan(installmentId);
      }
    });
  };

  // handle pay loan mutation
  const handlePayLoan = (installmentId) => {
    payLoan({
      variables: {
        installment_id: installmentId,
      },
    })
      .then((response) => {
        if (
          response?.errors ||
          response.data?.pay_installment?.__typename === "StatusResponse"
        ) {
          if (response?.data?.pay_installment?.status === "error") {
            dispatch(
              showErrorToast(
                response.data?.pay_installment?.message ||
                  response.errors[0]?.extensions?.reason
              )
            );
          }
        }
      })
      .catch((error) => {
        dispatch(showErrorToast(error?.message));
      });
  };

  const [
    payLoan,
    { data: payLoanResponse, loading: isPayLoanLoading, error: payLoanError },
  ] = useMutation(payLoanMutation, {
    onCompleted: (data) => {
      if (data?.pay_installment?.__typename === "Installment") {
        dispatch(showSuccessToast());

        // handle fetch loans list
        props?.handleFetchLoansData({
          variables: {
            input: {
              status: loansList?.status,
              user_id: props?.isInEmployeeProfile
                ? props?.userId
                : loansList?.user_id,
            },
            rows: loansList?.pagination?.perPage,
            page: loansList?.pagination?.currentPage,
          },
        });
      }
    },
  });

  // handleing revert payment swal
  const handleRevertPaymentSwal = (installmentId, e) => {
    // to pervent card from toggling
    e.stopPropagation();

    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willRevertPayment) => {
      if (willRevertPayment) {
        handleRevertPayment(installmentId);
      }
    });
  };

  // handle revert payment loan mutation
  const handleRevertPayment = (installmentId) => {
    revertPaymentLoan({
      variables: {
        installment_id: installmentId,
      },
    })
      .then((response) => {
        if (
          response?.errors ||
          response.data?.reverse_installment?.__typename === "StatusResponse"
        ) {
          if (response?.data?.reverse_installment?.status === "error") {
            dispatch(
              showErrorToast(
                response.data?.reverse_installment?.message ||
                  response.errors[0]?.extensions?.reason
              )
            );
          }
        }
      })
      .catch((error) => {
        dispatch(showErrorToast(error?.message));
      });
  };

  const [
    revertPaymentLoan,
    {
      data: revertPaymentResponse,
      loading: isRevertPaymentLoading,
      error: revertPaymentError,
    },
  ] = useMutation(revertPaymentLoanMutation, {
    onCompleted: (data) => {
      if (data?.reverse_installment?.__typename === "Installment") {
        dispatch(showSuccessToast());

        // handle fetch loans list
        props?.handleFetchLoansData({
          variables: {
            input: {
              status: loansList?.status,
              user_id: props?.isInEmployeeProfile
                ? props?.userId
                : loansList?.user_id,
            },
            rows: loansList?.pagination?.perPage,
            page: loansList?.pagination?.currentPage,
          },
        });
      }
    },
  });

  // handle render installment row function
  const handleRenderInstallmentRow = (installment, i) => {
    return (
      <div className="row justify-content-between align-items-center pb-3">
        <div className="col-4">
          <div
            className={
              installment?.status?.id == loanStatus.SETTLED_LOAN
                ? "d-flex align-items-center installment_settled_style"
                : "d-flex align-items-center"
            }
          >
            <div
              className={`installment_index_style ${
                i === 0 && "installment_index_style-first"
              } ${
                i + 1 === props?.installments?.length &&
                "installment_index_style-last"
              }`}
            >
              <DateRangeIcon />
            </div>

            <div className="installment_info_style mx-3">
              <span>
                {moment(installment?.payment_date).format("DD MMM, yyyy")}
              </span>
            </div>
          </div>
        </div>

        <div className="col-4">
          <div
            className={
              installment?.status?.id == loanStatus.SETTLED_LOAN
                ? "installment_info_style installment_settled_style"
                : "installment_info_style"
            }
          >
            <span>
              {installment?.amount} {installment?.loan?.currency?.name}
            </span>
          </div>
        </div>

        <div className="col-4">
          {installment?.status?.id == loanStatus.SETTLED_LOAN ? (
            <div className="d-flex align-items-baseline">
              <div className="installment_info_style">
                <span>
                  {moment(installment?.actual_payment_time).format(
                    "DD - MM - YYYY"
                  )}
                </span>
              </div>

              {props?.isInMyProfile ? null : (
                <HasPrivileges
                  reqireMain={[Privilages.SETTLE_INSTALLMENT_PAYMENTS]}
                  allowBP
                  avalibleOnExpire={false}
                  altExpireChildren={
                    <div className="installment_info_style">
                      <span className="btn px-3">
                        <FontAwesomeIcon icon={faExchangeAlt} />
                      </span>
                    </div>
                  }
                >
                  <div className="installment_info_style">
                    <span
                      onClick={(e) =>
                        handleRevertPaymentSwal(installment?.id, e)
                      }
                      className="btn px-3"
                    >
                      <FontAwesomeIcon icon={faExchangeAlt} />
                    </span>
                  </div>
                </HasPrivileges>
              )}
            </div>
          ) : (
            <>
              {props?.isInMyProfile ? null : (
                <HasPrivileges
                  reqireMain={[Privilages.SETTLE_INSTALLMENT_PAYMENTS]}
                  allowBP
                  avalibleOnExpire={false}
                  altExpireChildren={
                    <div>
                      <button
                        className="btn add_new_btn_style installment_pay_btn_style"
                        type="button"
                      >
                        <span>{t("pay")}</span>
                      </button>
                    </div>
                  }
                >
                  <div>
                    <button
                      className="btn add_new_btn_style installment_pay_btn_style"
                      type="button"
                      onClick={(e) => handlePaySwal(installment?.id, e)}
                    >
                      <span>{t("pay")}</span>
                    </button>
                  </div>
                </HasPrivileges>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className={
        props?.statusId == loanStatus.SETTLED_LOAN
          ? "settled_status_style installments_card"
          : "ongoing_status_style installments_card"
      }
    >
      {isPayLoanLoading || isRevertPaymentLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <div className="row pb-2">
        <div className="col-12">
          <h4>{t("Installments")}</h4>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-6">
          {props?.installments?.map((installment, i) =>
            i <= Math.ceil(props?.installments?.length / 2)
              ? handleRenderInstallmentRow(installment, i)
              : null
          )}
        </div>

        <div className="col-12 col-md-6">
          {props?.installments?.map((installment, i) =>
            i > Math.ceil(props?.installments?.length / 2)
              ? handleRenderInstallmentRow(installment, i)
              : null
          )}
        </div>
      </div>
    </div>
  );
};

export default InstallmentsSection;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MainModal from "../../../Components/MainModal";
import { InputForm, BSelect } from "form-builder";
import { useMutation } from "@apollo/client";
import { addNewUnitMutation } from "../../../Graphql/mutation";
import {
  onFormResetAction,
  toggleAddNewUnitsModal,
  addItemsInputAction,
  removeItemsInputAction,
  assetChildInputChange,
} from "../../../Store/Actions";
import { showToast } from "../../../Helpers/HelperFns";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const formName = "addNewUnitsForm";
const formNameValidation = "addNewUnitsFormClientValidations";

const AddNewUnitsModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [formSubmitting, setFormSubmitting] = useState(false);

  // Redux States
  const isOpen = useSelector(
    (state) => state?.assets?.addNewUnitsModal?.isOpen
  );
  const formData = useSelector((state) => state?.assets?.[formName]);
  const clientValidations = useSelector(
    (state) => state?.assets?.[formNameValidation]
  );

  // Server State
  useEffect(() => {
    // handle add new input for serliazed units
    if (formData?.product_type === "serialized" && props?.warehouse_id) {
      dispatch(addItemsInputAction(formName, props?.warehouse_id));
    }
    return () => {
      dispatch(onFormResetAction(formName));
      dispatch(onFormResetAction(formNameValidation));
    };
  }, []);

  // Start of handle add new units mutation
  const [storeItems, { loading }] = useMutation(addNewUnitMutation, {
    onCompleted: (data) => {
      if (data?.storeItems?.__typename == "GeneralException") {
        // error toaster
        showToast("error", data?.storeItems?.message);
        return;
      }
      // success toaster
      showToast("success");

      // handle dismiss modal
      handleDismissModal();

      // handle refetch data list
      props?.handleRefetch();
    },
    onError(err) {
      // error toaster
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ?? err?.message
      );
    },
  });
  // End of handle add new units mutation

  /* ↓ Helpers ↓ */

  // Start of add/remove items multiple inputs
  const handleAddItemInput = (e) => {
    if (e.key === "Enter") {
      dispatch(addItemsInputAction(formName));
    }
  };

  const handleRemoveItemInput = (index) => {
    dispatch(removeItemsInputAction(formName, index));
  };

  const handleChildInputChange = (event, childName, index) => {
    dispatch(
      assetChildInputChange(
        formName,
        childName,
        event?.target?.name,
        index,
        event?.target?.value
      )
    );
  };
  // End of add/remove items multiple inputs

  // handle submit form function
  const handleSubmit = () => {
    setFormSubmitting(true);

    if (formData?.product_type === "non_serialized") {
      // handle unserliazed units array
      let unserliazedUnitsArray = new Array(+formData?.number_of_units);

      if (!clientValidations?.length) {
        storeItems({
          variables: {
            input: {
              asset_id: formData?.id,
              company_id: props?.companyId,
              items: unserliazedUnitsArray?.fill({
                id: null,
                serial_number: "",
                warehouse_id: formData?.warehouse_id,
                status: "in_storage",
              }),
            },
          },
        });
      }
    } else {
      if (!clientValidations?.length) {
        storeItems({
          variables: {
            input: {
              asset_id: formData?.id,
              company_id: props?.companyId,
              items: formData?.items?.map(({ serial_number }) => ({
                id: null,
                serial_number,
                warehouse_id: formData?.warehouse_id,
                status: "in_storage",
              })),
            },
          },
        });
      }
    }
  };

  // handle dismiss modal function
  const handleDismissModal = () => {
    dispatch(toggleAddNewUnitsModal({ isOpen: false }));
  };

  return (
    <MainModal
      isOpen={isOpen}
      toggle={handleDismissModal}
      modalTitle={t("New Units")}
      btnLabel={t("save")}
      btnOnClick={handleSubmit}
      btnSubmitLoading={loading}
    >
      <div className="row asset_units_modal_wrapper_style">
        <div className="col-12">
          <label className="mb-2">{t("asset")}</label>

          <div className="d-flex align-items-baseline">
            <div className="asset_name_style">{formData?.name}</div>

            <div className="asset_type_model_brand_style">
              {formData?.assetType?.name} - {formData?.brand} {formData?.model}
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-12">
          <BSelect
            formName={formName}
            formNameValidation={formNameValidation}
            formSubmitting={formSubmitting}
            reducer="assets"
            name="warehouse_id"
            label="storage location"
            keepDefaultStyle
            placeholder={t("select option")}
            validateBy="textRequired"
            options={props?.storageLocationOptions}
            optionLabel="name"
            optionValue="id"
            labelStyle="mb-1"
            inputContainerStyle="b-select-style "
            skipLocalization
            isClearable
          />
        </div>
      </div>

      {formData?.product_type === "serialized" &&
      formData?.items?.length > 0 ? (
        <div className="row my-3">
          <div className="col-12">
            <label className="mb-2">{t("Serial Numbers")}</label>

            <div className="serail_numbers_wrapper_style">
              {formData?.items?.map((item, i) => (
                <>
                  <div className="d-flex">
                    <InputForm
                      formName={formName}
                      formNameValidation={formNameValidation}
                      formSubmitting={formSubmitting}
                      onKeyPress={(e) => handleAddItemInput(e)}
                      reducer="assets"
                      name="serial_number"
                      value={item?.serial_number}
                      disabled={item?.id}
                      onChange={(e) => handleChildInputChange(e, "items", i)}
                      labelStyle=""
                      containerStyle="mt-0"
                      inputContainerStyle=" "
                    />

                    {formData?.items?.length > 1 ? (
                      <>
                        {item?.serial_number ? (
                          <button
                            className="btn delete_btn_style mx-2"
                            type="button"
                            onClick={() => handleRemoveItemInput(i)}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </button>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-12">
            <InputForm
              formName={formName}
              formNameValidation={formNameValidation}
              formSubmitting={formSubmitting}
              reducer="assets"
              name="number_of_units"
              type="number"
              min="1"
              value={
                formData?.number_of_units < 1 &&
                formData?.number_of_units !== ""
                  ? 1
                  : formData?.number_of_units
              }
              placeholder={t("Enter No of Units")}
              label="No. of units"
              labelStyle="mb-2"
              containerStyle="mt-0 mb-3"
            />
          </div>
        </div>
      )}
    </MainModal>
  );
};

export default AddNewUnitsModal;

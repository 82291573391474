import Types from "../Actions/types";

export const initState = {
  editCheckInsModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  editCheckInsModal: {},
  editCheckInsModalValidation: [],
  editCheckInsModalServerValidation: {},

  checkInOutsLocationsOptions: [],
  fetchLocationsOptionsLoading: false,

  viewCheckInOutLocationModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  viewCheckInOutLocationModal: {},
};

export const checkInsReducer = (state = initState, action) => {
  switch (action.type) {
    case Types.SHOW_EDIT_CHECK_INS_MODAL:
    case Types.SHOW_VIEW_CHECK_INS_OUTS_LOCATIONS_MODAL:
      return {
        ...state,
        [action.payload.ref]: {
          isVissible: true,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload.formName]: {
          ...state[action.payload.formName],
          ...action.payload.data,
        },
      };
    case Types.HIDE_VIEW_CHECK_INS_OUTS_LOCATIONS_MODAL:
      return {
        ...state,
        [action.payload.ref]: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload.formName]: initState[action.payload.formName],
      };
    case Types.HIDE_EDIT_CHECK_INS_MODAL:
      return {
        ...state,
        editCheckInsModalServerValidation: {},
        editCheckInsModalActions: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload.formName]: initState[action.payload.formName],
      };
    case Types.UPDATE_CHECK_INS_ATTEMPT:
      return {
        ...state,
        editCheckInsModalServerValidation: {},
        editCheckInsModalActions: {
          ...state.editCheckInsModalActions,
          isLoading: true,
          modalMessage: "",
        },
      };
    case Types.UPDATE_CHECK_INS_SUCCESS:
      return {
        ...state,
        editCheckInsModalServerValidation: {},
        editCheckInsModalActions: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload?.formName]: initState[action.payload?.formName],
      };
    case Types.UPDATE_CHECK_INS_FAILED:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: false,
          modalMessage: action.payload?.modalMessage ?? "",
        },
        [action.payload.serverRef]: action.payload?.errors ?? {},
      };

    case Types.FETCH_CHECK_OUT_LOCATIONS_OPTIONS:
      return {
        ...state,
        fetchLocationsOptionsLoading: true,
      };
    case Types.FETCH_CHECK_OUT_LOCATIONS_OPTIONS_SUCCESS:
      return {
        ...state,
        fetchLocationsOptionsLoading: false,
        checkInOutsLocationsOptions: action?.payload?.locations,
      };
    case Types.FETCH_CHECK_OUT_LOCATIONS_OPTIONS_FAILED:
      return {
        ...state,
        fetchLocationsOptionsLoading: false,
      };

    case Types.LOG_OUT:
    case "SWITCH_ACCOUNT":
      return { ...initState };

    default:
      return state;
  }
};
